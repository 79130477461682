import { PageLimitType } from '@types';
import debounce from 'lodash.debounce';
import { useCallback, useMemo } from 'react';

import { useQueryParams, UseUrlQueryParamOptions } from './useQueryParams.hook';

const PAGE_KEY = 'page';
const LIMIT_KEY = 'limit';
const SEARCH_QUERY_KEY = 'query';
const DEFAULT_VALUE_PAGE = 1;
const DEFAULT_VALUE_LIMIT = 10 as PageLimitType;
const SEARCH_DEBOUNCE_TIME = 100;

interface PaginationOptions extends UseUrlQueryParamOptions {
  defaultLimit?: PageLimitType;
  pushHistory?: boolean;
  debounceSearch?: boolean;
  searchQueryKey?: string;
}

export const usePaginationQueryParams = (options?: PaginationOptions) => {
  const defaultLimit = options?.defaultLimit ?? DEFAULT_VALUE_LIMIT;
  const { getQueryParam, setQueryParam } = useQueryParams({
    pushHistory: options?.pushHistory,
  });
  const paramPageValue = getQueryParam(PAGE_KEY);
  const paramLimitValue = getQueryParam(LIMIT_KEY);
  const searchQueryKey = options?.searchQueryKey ?? SEARCH_QUERY_KEY;
  const search = getQueryParam(searchQueryKey) || '';

  const page = useMemo(() => {
    const pageNum = Number.parseInt(paramPageValue);
    if (Number.isNaN(pageNum) || !pageNum || pageNum < 0) {
      return DEFAULT_VALUE_PAGE;
    }
    return pageNum;
  }, [paramPageValue]);
  const limit = useMemo(() => {
    const pageLimit = Number.parseInt(paramLimitValue);
    if (Number.isNaN(pageLimit) || !pageLimit || pageLimit < 0) {
      return defaultLimit;
    }
    return pageLimit as PageLimitType;
  }, [paramLimitValue, defaultLimit]);

  const setPage = useCallback(
    (ev: CustomEvent<{ page: number }>) => {
      setQueryParam(PAGE_KEY, ev.detail.page, {
        resetValue: DEFAULT_VALUE_PAGE,
      });
    },
    [setQueryParam],
  );

  const setLimit = useCallback(
    (ev: CustomEvent<{ limit: number }>) => {
      setQueryParam(LIMIT_KEY, ev.detail.limit, {
        resetValue: defaultLimit,
      });
    },
    [setQueryParam, defaultLimit],
  );

  const setSearch = useCallback(
    (ev: CustomEvent<string>) => {
      setQueryParam(searchQueryKey, ev.detail, {
        clearParams: true,
        resetValue: '',
      });
    },
    [setQueryParam, searchQueryKey],
  );

  const setDebouncedSearch = useMemo(() => {
    return debounce(setSearch, SEARCH_DEBOUNCE_TIME);
  }, [setSearch]);

  const skip = useMemo(() => limit * (page - 1), [limit, page]);

  return {
    page,
    limit,
    skip,
    setPage,
    setLimit,
    search,
    setSearch: options?.debounceSearch ? setDebouncedSearch : setSearch,
  };
};
