import React, { useState, useRef, useCallback } from 'react';
import { DidomiTooltip } from '@didomi/ui-atoms-react';
import { useResizeObserver } from '@didomi/helpers-react';
import { TextHighlight } from '../TextHighlight/TextHighlight';

type OverflowWithTooltipProps = {
  'data-testid'?: string;
  className?: string;
  highlight?: string;
  hideOnScroll?: boolean;
  style?: React.HTMLAttributes<HTMLElement>['style'];
  value?: string;
  children?: React.ReactChild;
};

/**
 * Display a text with a tooltip overflow
 */
const OverflowWithTooltip = ({ 'data-testid': dataTestid, value, highlight, className, style, hideOnScroll = true, children }: OverflowWithTooltipProps): JSX.Element => {
  const [isValueOverflown, setIsValueOverflown] = useState(false);
  const valueRef = useRef();

  const handleResize = useCallback(entries => {
    if (!entries.length) {
      return;
    }
    const target = entries[0].target;

    setIsValueOverflown(isEllipsisActive(target));
  }, []);

  useResizeObserver(valueRef.current, handleResize);

  const isEllipsisActive = e => !!e && e.clientWidth < e.scrollWidth;

  return (
    <DidomiTooltip
      placement="top"
      distance="xxxs"
      hideOnScroll={hideOnScroll}
      data-testid={dataTestid ? dataTestid + '-tooltip' : undefined}
      className={className}
      style={style}
      content={/* istanbul ignore next */ isValueOverflown ? value : undefined}
    >
      <div className="w-full flex items-center justify-between">
        <div ref={valueRef} data-testid={dataTestid} className="truncate">
          {highlight ? <TextHighlight text={value} highlight={highlight} /> : value}
        </div>
        {children}
      </div>
    </DidomiTooltip>
  );
};

export { OverflowWithTooltip };
