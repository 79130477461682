import { AxiosError } from 'axios';
import { PaginatedResponse } from '@types';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useActiveOrganization } from '@didomi/utility-react';
import { apiRoutes } from '@constants';
import { useAxiosWithLogOut } from '../useAxiosWithInterceptors.hook';
import { Purpose, ErrorResponse } from '@interfaces';
import { usePaginationQueryParams } from '../../usePaginationQueryParams.hook';

export const usePurposes = (options?: UseQueryOptions<PaginatedResponse<Purpose>>) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();
  const { limit, page, skip, setLimit, setPage, search, setSearch } = usePaginationQueryParams({
    defaultLimit: 20,
    debounceSearch: true,
  });

  const params = { organization_id: organizationId, $limit: limit, $skip: skip, $translations: true };

  const fetch = async () => {
    const response = await axiosWithInterceptors.get<PaginatedResponse<Purpose>>(apiRoutes.purposes, {
      params,
    });
    return response.data;
  };

  return {
    ...useQuery<PaginatedResponse<Purpose>, AxiosError<ErrorResponse>>(['purposes', params], fetch, {
      ...options,
    }),
    paginator: {
      limit,
      page,
      setPage,
      setLimit,
      search,
      setSearch,
    },
  };
};
