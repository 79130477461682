import React, { useState, useMemo, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { FormikHelpers } from 'formik';
import { useSnackbar, useSPARouter } from '@didomi/utility-react';
import {
  DidomiBottomBar,
  DidomiTable,
  DidomiButton,
  DidomiTableHeading,
  DidomiTableBody,
  DidomiTableHeaderRow,
  DidomiTableRow,
  DidomiTableTd,
  DidomiTableTh,
  DidomiPaginator,
  DidomiSecondaryHeader,
  DidomiBackButtonLink,
  DidomiLoader,
  DidomiSearchBar,
} from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';
import { sanitizeHTML } from '@didomi/xss';
import { ChatBubbleSpacer, GenericEmptyState, TruncatedTextWithTooltip } from '@components';
import { CreatePurposeModal, PreviewPurposeModal, EditDeletePurposeConfirmationModal, LoadingModal } from '@modals';
import { getTranslatedValue, substrIncludes, DEFAULT_EMPTY_TRANSLATION } from '@utils';
import { SortDirType, NewPurposeInput } from '@types';
import { AddPurposeHelper } from './AddPurposeHelper';
import { usePurposes, useCreatePurpose, useConfigTrees, useCreateSelectedPurpose } from '@hooks';

const AddPurpose = ({ titleText, subtitle, description, backPath }) => {
  const { displaySnackbar } = useSnackbar();
  const { navigateTo } = useSPARouter();
  const { push } = useHistory();
  const [sortBy] = useState('createdAt');
  const [sortDir] = useState<SortDirType>('asc');
  const [displayBottomBar, setDisplayBottomBar] = useState(false);
  const [reloadingPurposesFromFailure, setReloadingPurposesFromFailure] = useState(false);
  const [isCreatePurposeModalOpen, setCreatePurposeModalState] = useState(false);
  const [newlySelectedPurposeIds, setNewlySelectedPurposeIds] = useState([]);
  const [displayPurposePreview, setDisplayPurposePreview] = useState(null);
  const [purposeToEditDelete, setPurposeToEditDelete] = useState<string | null>(null);
  const [addingPurposeIntoConfigTree, setAddingPurposeIntoConfigTree] = useState<boolean>(false);
  const purposeCountRef = useRef<HTMLHeadingElement>();
  const [isSavingPurpose, setIsSavingPurpose] = useState(false);
  const newlySelectedLength = newlySelectedPurposeIds.length;

  const {
    error: errorLoadingPurposes,
    isLoading: isFetchingPurposes,
    isRefetching: isRefetchingPurposes,
    refetch: refetchPurposes,
    data: { data: purposes = [], total: totalPurposes = null } = {},
    paginator: { limit, page: currPage, search, setLimit, setPage: setCurrPage, setSearch },
  } = usePurposes({
    keepPreviousData: true,
    onError: () => {
      displaySnackbar('Purpose list can not be loaded', {
        variant: 'error',
        permanent: true,
        title: 'An error occurred:',
        action: {
          action: /* istanbul ignore next */ () => {
            setReloadingPurposesFromFailure(true);
            refetchPurposes().finally(() => {
              setReloadingPurposesFromFailure(false);
            });
          },
          name: 'Reload',
        },
        secondaryAction: {
          action: /* istanbul ignore next */ () => {},
          name: 'Close',
          closeInAction: true,
        },
      });
    },
  });

  const isLoadingPurposes = isFetchingPurposes || isRefetchingPurposes;

  useEffect(() => {
    if (isLoadingPurposes) {
      // Scroll to top when re-loading purposes
      // as we still show the table with old data
      // and a loading bar at the top.
      purposeCountRef.current?.scrollIntoView?.();
    }
  }, [isLoadingPurposes]);

  const { isLoading: loadingConfTree, data: configTrees } = useConfigTrees();

  const { mutate: saveNewPurpose } = useCreatePurpose({
    onSuccess: purpose => {
      setCreatePurposeModalState(false);
      setIsSavingPurpose(false);
      refetchPurposes();
      displaySnackbar(`"${getTranslatedValue(purpose.data.description)}" is now available`, { icon: 'check' });
    },
    onError: () => {
      setIsSavingPurpose(false);
      displaySnackbar('There was an error saving the purpose', { icon: 'danger-light' });
    },
  });

  const { mutateAsync: addPurpose } = useCreateSelectedPurpose(configTrees?.data?.[0]?.id, {
    onError: () => {
      displaySnackbar('Purposes have not been added to your Configuration Tree', {
        icon: 'danger-light',
        variant: 'error',
        title: 'Sorry, an error occurred:',
        action: { name: 'OK', closeInAction: true },
      });
      setAddingPurposeIntoConfigTree(false);
    },
  });

  const selectedPurposes = configTrees && configTrees.data[0]?.selected_purposes;
  const purposesLength = purposes.length;
  const noPurposes = isLoadingPurposes || purposesLength === 0;
  const preselectedPurposeIds = selectedPurposes ? selectedPurposes.map(({ purpose }) => purpose.id) : [];

  /**
   * Appends each newly selected purpose to the Configuration Tree
   * The backend does not support batch selection so we need to do it one by one
   * It redirects back to the configuration page once the purposes have been added
   */
  const handlePurposesAppend = async () => {
    let currentOrder = selectedPurposes?.length;
    let currentPurpose = 1;

    try {
      setAddingPurposeIntoConfigTree(true);
      for (const newPurposeId of newlySelectedPurposeIds) {
        displaySnackbar(`Adding ${currentPurpose}/${newlySelectedPurposeIds.length} purpose in progress, it could take a while...`, {
          permanent: true,
        });

        await addPurpose({
          order: currentOrder,
          purpose_id: newPurposeId,
        });
        currentOrder++;
        currentPurpose++;
      }
    } catch (error) {
      // This is handled by onReactQueryError method in the root.component.
      return;
    }
    displaySnackbar('Purposes have been added to your Configuration Tree', { icon: 'check' });
    push(backPath, {
      scrollToBottom: true,
    });
  };

  /**
   * After an item has been selected in the table we display the bottom bar and add the selected item
   * to the list of purposes to be added to the Configuration Tree
   * @param {DragStartEvent} event The event that started the dragging
   */
  const selectItems = item => {
    const updated = item.detail.newSelectedItems || [];
    setNewlySelectedPurposeIds(updated);
    setDisplayBottomBar(true);
  };

  /**
   * Closes the modal if the modal has been exited by pressing escape or clicking outside
   * @param {CustomEvent} e The modal event - if false the modal was closed
   */
  // istanbul ignore next - Update if dialog closed from escape or outside click
  const handleDialogChange = (e: CustomEvent) => {
    if (isCreatePurposeModalOpen && !e.detail) {
      setCreatePurposeModalState(e.detail);
    }
  };

  /**
   * Triggers the process to create a new purpose and resets the form if the purpose was created successfully
   * @param {any} input The purpose submitted details
   * @param {FormikHelpers<any>} resetForm The form methods
   * TODO: UPDATE USER LANGUAGE TO USER SELECTED LANGUAGE - Hardcoded to English now
   */
  const onSaveNewPurpose = (input: NewPurposeInput, { resetForm }: FormikHelpers<any>) => {
    setIsSavingPurpose(true);
    saveNewPurpose(
      {
        description: {
          en: sanitizeHTML(input.name).result,
        },
        details: {
          en: sanitizeHTML(input.description).result,
        },
      },
      {
        onSuccess: () => resetForm(),
      },
    );
  };

  /**
   * Updates the table sorting (column and directions) and fetches the new items from the server
   * @param {CustomEvent} e The table sort event
   */
  // TODO: Add sorting when admin api allows sorting by more fields
  // const updateSorting = (e: CustomEvent) => {
  //   const newSortDir = e.detail.direction;
  //   const newSortBy = e.detail.sortId;
  //   const notSameValue = newSortDir !== sortBy || newSortBy !== sortBy;

  //   if (newSortDir) {
  //     setReloadingPurposes(true);
  //     refetchPurposes({ limit, skip: limit * (currPage - 1), sortDir: newSortDir, sort: newSortBy }).then(() => {
  //       if (notSameValue) {
  //         setSortBy(newSortBy);
  //       }
  //       setSortDirection(newSortDir);
  //       setReloadingPurposes(false);
  //     });
  //   }
  // };

  // purpose list filtering logic
  const filteredPurposes = useMemo(() => {
    if (search) {
      return purposes.filter(purpose => substrIncludes(getTranslatedValue(purpose.description), search));
    }
    return purposes;
  }, [purposes, search]);

  const filteredLength = filteredPurposes.length;
  const isLoading = isLoadingPurposes || reloadingPurposesFromFailure || loadingConfTree;

  // return the adequate purposes count message
  const getPurposesCountMessage = () => {
    if (isLoading) {
      return 'Loading purposes';
    }
    const availablePurposesMessage = (count: number, fallback: string) => (count ? `Available purpose${count > 1 ? 's' : ''}: ${count}` : fallback);

    if (search) {
      return availablePurposesMessage(filteredLength, 'No purpose found');
    }

    return availablePurposesMessage(purposesLength, 'No available purposes yet');
  };

  const countText = getPurposesCountMessage();

  return (
    <main className="h-full">
      <section className="p-12 pb-7 h-full box-border !flex flex-col">
        <div className="flex justify-between">
          <DidomiSecondaryHeader titleText={titleText}>
            <div slot="back-button">
              <DidomiBackButtonLink
                className="cursor-pointer"
                onClick={() => {
                  if (backPath) {
                    push(backPath);
                  }
                }}
                text="Back to Configuration"
              />
            </div>
            <div slot="description">
              <strong className="font-bold text-base">{subtitle}</strong>
              <br />
              <p className="text-base">{description}</p>
            </div>
          </DidomiSecondaryHeader>
          <AddPurposeHelper />
        </div>
        <div className="flex-1 flex flex-col">
          <div className="mt-l mb-s flex flex-col flex-1">
            <div className="flex justify-between items-end mb-xs">
              <h4 ref={purposeCountRef} className="h4 font-bold text-primary-blue-6">
                {countText}
              </h4>
              <div className="flex flex-nowrap items-center w-1/2 justify-end">
                <div className="mr-4">
                  <DidomiSearchBar data-testid="search-input" value={search} onValueChange={setSearch} placeholder="Search a purpose by name..." variant="secondary" />
                </div>
                <DidomiButton data-tracking="pmp-start-create-new-purpose" data-cy="create-purpose-cta" onClick={() => setCreatePurposeModalState(true)}>
                  Create a new purpose
                </DidomiButton>
              </div>
            </div>
            {isFetchingPurposes ? (
              <div className="w-full mt-64 flex flex-col justify-center items-center">
                <DidomiLoader />
                <div data-testid="purpose-loading-text" className="text-body-normal font-sans text-primary-blue-6 mt-m mb-xxs">
                  Loading purposes
                </div>
              </div>
            ) : errorLoadingPurposes ? (
              <GenericEmptyState illustration="list-cannot-be-loaded" className="border-1 border-dashed border-neutral-gray-5 rounded-lg flex-1" />
            ) : !isLoading && preselectedPurposeIds && noPurposes ? (
              <GenericEmptyState
                title="You have no purposes yet in your list"
                helperText="You can create purposes directly in your data manager"
                illustration="no-purposes-yet"
                className="border-1 border-dashed border-neutral-gray-5 rounded-lg flex-1"
              />
            ) : (
              <DidomiTable
                loading={isRefetchingPurposes}
                className="mb-s"
                fixedLayout
                selectable
                sortable
                sortBy={sortBy}
                sortDirection={sortDir}
                preSelectedItems={preselectedPurposeIds}
                selectedItems={newlySelectedPurposeIds}
                onRowSelectionChange={selectItems}
                onToggleAllRowsSelectedChange={selectItems}
              >
                <DidomiTableHeading>
                  <DidomiTableHeaderRow>
                    <DidomiTableTh>NAME</DidomiTableTh>
                    <DidomiTableTh>DESCRIPTION</DidomiTableTh>
                    <DidomiTableTh></DidomiTableTh>
                  </DidomiTableHeaderRow>
                </DidomiTableHeading>
                <DidomiTableBody>
                  {filteredPurposes.map(({ id, description, details }) => (
                    <DidomiTableRow key={id} selectionValue={id} data-testid={id} data-cy={id}>
                      <DidomiTableTd>
                        <TruncatedTextWithTooltip value={getTranslatedValue(description)} data-testid={`table-description-${id}`}></TruncatedTextWithTooltip>
                      </DidomiTableTd>
                      <DidomiTableTd>
                        <TruncatedTextWithTooltip value={getTranslatedValue(details)} data-testid={`table-details-${id}`}></TruncatedTextWithTooltip>
                      </DidomiTableTd>
                      <DidomiTableTd cellAlign="right">
                        <div className="flex">
                          <DidomiButton
                            data-tracking="pmp-start-edit-purpose-from-purpose-page"
                            className="mr-xxs"
                            variant="secondary"
                            size="small"
                            onClick={() => setPurposeToEditDelete(id)}
                          >
                            Edit / Delete
                          </DidomiButton>
                          <DidomiButton
                            variant="secondary"
                            size="small"
                            onClick={() => setDisplayPurposePreview({ name: getTranslatedValue(description), description: getTranslatedValue(details) })}
                          >
                            Preview
                          </DidomiButton>
                        </div>
                      </DidomiTableTd>
                    </DidomiTableRow>
                  ))}
                </DidomiTableBody>
              </DidomiTable>
            )}
            <div className="flex justify-end">
              {!!purposes?.length && !isLoadingPurposes && !loadingConfTree ? (
                <DidomiPaginator
                  data-testid="purpose-paginator"
                  className="self-end"
                  page={currPage}
                  itemCount={totalPurposes}
                  size={limit}
                  onPageSizeChange={setLimit}
                  onPageChange={setCurrPage}
                />
              ) : null}
            </div>
          </div>
        </div>
        <ChatBubbleSpacer />
      </section>
      <DidomiBottomBar
        className={tx('!fixed w-full', {
          hidden: addingPurposeIntoConfigTree,
        })}
        data-tracking="pmp-add-purpose-to-configuration-tree"
        data-cy="confirmation-bar"
        isOpen={newlySelectedLength > 0 && displayBottomBar}
        text={`${newlySelectedLength} ${newlySelectedLength > 1 ? 'purposes' : 'purpose'} selected`}
        closable={true}
        icon="step-success"
        onApply={handlePurposesAppend}
        onClose={() => setDisplayBottomBar(false)}
        buttonText="Add to my Configuration Tree"
      />
      <CreatePurposeModal isOpen={isCreatePurposeModalOpen} onChange={handleDialogChange} onClose={() => setCreatePurposeModalState(false)} onSave={onSaveNewPurpose} />
      <PreviewPurposeModal
        isOpen={!!displayPurposePreview}
        purposeName={displayPurposePreview?.name}
        purposeDescription={displayPurposePreview?.description !== DEFAULT_EMPTY_TRANSLATION ? displayPurposePreview?.description : null}
        onCancel={() => setDisplayPurposePreview(null)}
        onProceed={() => navigateTo('data-manager/purposes')}
        canEdit={true}
      />
      <EditDeletePurposeConfirmationModal
        isOpen={!!purposeToEditDelete}
        purposeId={purposeToEditDelete}
        onCancel={() => setPurposeToEditDelete(null)}
        onProceed={() => navigateTo('data-manager')}
      />
      <LoadingModal isOpen={isSavingPurpose} title="Saving purpose..." />
    </main>
  );
};

export { AddPurpose };
