import React, { useCallback } from 'react';
import { DidomiTextInput } from '@didomi/ui-atoms-react';
import { ContentEditorHintboxWrapper, ContentEditorHintboxWrapperProps } from './ContentEditorHintboxWrapper';
import { ContentEditorTextInputProps } from './ContentEditorTextInput';
import { DidomiImageOptions } from '@didomi/pmp-generator';
import { sanitizeHTML } from '@didomi/xss';

interface ContentEditorFileInputProps extends Omit<ContentEditorTextInputProps, 'value' | 'onChange'>, ContentEditorHintboxWrapperProps {
  'data-testid'?: string;
  value?: DidomiImageOptions;
  onChange(newValue?: DidomiImageOptions): void;
}

export const ContentEditorFileInput = ({ 'data-testid': dataTestId, hint, hasBottomMargin, value, onChange, ...restProps }: ContentEditorFileInputProps) => {
  const handleValueChange = useCallback(
    (ev: CustomEvent<string>) => {
      onChange({
        src: ev.detail,
      });
    },
    [onChange],
  );

  const handleFocusChange = useCallback(
    (ev: CustomEvent<boolean>) => {
      if (!ev.detail) {
        onChange({
          src: sanitizeHTML(value?.src).result,
        });
      }
    },
    [value, onChange],
  );

  return (
    <ContentEditorHintboxWrapper hint={hint} hasBottomMargin={hasBottomMargin}>
      <DidomiTextInput
        data-testid={`${dataTestId}-input`}
        hideErrorMessage
        {...restProps}
        value={value?.src ?? ''}
        onValueChange={handleValueChange}
        onFocusChange={handleFocusChange}
        data-skeleton
        className="w-full"
      />
    </ContentEditorHintboxWrapper>
  );
};
