import { AxiosError } from 'axios';
import { PaginatedResponse } from '@types';
import { useQuery, UseQueryOptions, useQueryClient } from '@tanstack/react-query';
import { useActiveOrganization } from '@didomi/utility-react';
import { DEFAULT_LIMIT, apiRoutes } from '@constants';
import { useAxiosWithLogOut } from '../useAxiosWithInterceptors.hook';
import { Layout, ErrorResponse } from '@interfaces';
import { useCallback } from 'react';

export interface UseLayoutsQuery {
  widget_id?: string;
  limit?: number;
}

export type FetchLayoutsAsyncFnType = (widgetId: string) => Promise<PaginatedResponse<Layout>>;

export const useLayouts = ({ widget_id, limit = DEFAULT_LIMIT }: UseLayoutsQuery = {}, options: UseQueryOptions<PaginatedResponse<Layout>> = {}) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();
  const queryClient = useQueryClient();

  const fetchParams = { organization_id: organizationId, $limit: limit, widget_id };

  const fetch = useCallback(
    async (params: any) => {
      const response = await axiosWithInterceptors.get<PaginatedResponse<Layout>>(apiRoutes.layouts, {
        params,
      });
      return response.data;
    },
    [axiosWithInterceptors],
  );

  const fetchAsync: FetchLayoutsAsyncFnType = useCallback(
    (widgetId: string) => {
      const params = { organization_id: organizationId, $limit: limit, widget_id: widgetId };
      return queryClient.fetchQuery({
        queryKey: ['layouts', params],
        queryFn: () => fetch(params),
      });
    },
    [queryClient, limit, organizationId, fetch],
  );

  return {
    ...useQuery<PaginatedResponse<Layout>, AxiosError<ErrorResponse>>(['layouts', fetchParams], () => fetch(fetchParams), options),
    fetchAsync,
  };
};
