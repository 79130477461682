import React from 'react';
import { DidomiButton, DidomiModal, DidomiModalHeader, DidomiModalActions, DidomiIcon, DidomiModalContent, DidomiChipList, DidomiChip } from '@didomi/ui-atoms-react';
import { PreferenceType } from '@enums';
import { PreferenceValueContentAsString } from '@types';

export interface PreferencePreviewData {
  id: string;
  name: string;
  description: string;
  type: string;
  values: Array<PreferenceValueContentAsString>;
}

interface PreviewPreferenceModalProps extends PreferencePreviewData {
  isOpen?: boolean;
  onCancel?: () => void;
  onProceed?: (id: string) => void;
  canEdit?: boolean;
}

/**
 * Preview Preference
 */
const PreviewPreferenceModal = ({ id, name, description, type, values, isOpen, onCancel, onProceed, canEdit }: PreviewPreferenceModalProps): JSX.Element => {
  const preferenceValues = values;
  return (
    <DidomiModal isOpen={isOpen} permanent="true">
      <DidomiIcon name="preference-shadow" style={{ marginBottom: 'var(--space-xs)' }} />
      <DidomiModalHeader modalTitle={name} modalDescription={description} />
      <DidomiModalContent
        class="w-full border-1 border-dashed border-neutral-gray-5 rounded-lg text-primary-blue-6 preview-preference-modal"
        style={{ paddingTop: 16, paddingBottom: 16, marginBottom: 32 }}
      >
        <h4 style={{ fontWeight: 600 }}>Preference values</h4>
        <p style={{ fontSize: 14, marginBottom: 10, marginTop: 4 }}>{type === PreferenceType.MULTI ? 'Multiselection' : 'Singleselection'}</p>
        {preferenceValues?.length && (
          <DidomiChipList className="px-xs" alignment={'center'}>
            {preferenceValues.map(value => (
              <DidomiChip key={value.id} label={value.name} variation="basic-outline" basicType="neutral" />
            ))}
          </DidomiChipList>
        )}
      </DidomiModalContent>

      <DidomiModalActions>
        <DidomiButton data-testid="close-preference-preview" variant="secondary" onClick={onCancel}>
          Close
        </DidomiButton>
        {canEdit && (
          <DidomiButton data-tracking="pmp-start-edit-preference-from-preview" data-testid="edit-preference-preview" onClick={() => onProceed(id)}>
            Edit
          </DidomiButton>
        )}
      </DidomiModalActions>
    </DidomiModal>
  );
};

export { PreviewPreferenceModal };
