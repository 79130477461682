import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export interface UseUrlQueryParamOptions {
  pushHistory?: boolean;
}

export const useQueryParams = (options?: UseUrlQueryParamOptions) => {
  const { push, replace } = useHistory();
  const location = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const historyMethod = options?.pushHistory ? push : replace;

  const getQueryParam = useCallback((key: string) => queryParams.get(key), [queryParams]);
  const setQueryParam = useCallback(
    (key: string, newValue: any, setOpts?: { resetValue?: any; clearParams?: boolean }) => {
      const newParams = new URLSearchParams(setOpts?.clearParams ? '' : queryParams);
      if (String(newValue) === String(setOpts?.resetValue)) {
        newParams.delete(key);
      } else {
        newParams.set(key, newValue);
      }

      historyMethod(`${location.pathname}?${newParams}`);
    },
    [queryParams, historyMethod, location.pathname],
  );

  return {
    getQueryParam,
    setQueryParam,
  };
};
