/**
 * Private function to help snakeToCamelCase recursion
 * * Input: {
 *            snake_case_number : 1,
 *            snake_case_object: { snake_case_number: 1 },
 *            snake_case_array: [{
 *              snake_case_number: 1,
 *            }]
 *        }
 * Output: {
 *            snakeCaseNumber : 1,
 *            snakeCaseObject: { snakeCaseNumber: 1 },
 *            snakeCaseArray: [{
 *              snakeCaseNumber: 1,
 *            }]
 *        }
 * @param obj Object to be converted.
 * @returns
 */
const transformSnakeToCamelCase = (obj: Record<string, unknown>) =>
  Object.fromEntries(Object.entries(obj).map(([key, val]) => [key.replace(/_(.)/g, g => g[1].toUpperCase()), snakeToCamelCase(val)]));

/**
 * Converts an object from snake case to camel case
 * Input: {
 *            snake_case_number : 1,
 *            snake_case_object: { snake_case_number: 1 },
 *            snake_case_array: [{
 *              snake_case_number: 1,
 *            }]
 *        }
 * Output: {
 *            snakeCaseNumber : 1,
 *            snakeCaseObject: { snakeCaseNumber: 1 },
 *            snakeCaseArray: [{
 *              snakeCaseNumber: 1,
 *            }]
 *        }
 * @param value Object or array to be converted. It doesn't accept Map or Set
 * @returns
 */
export const snakeToCamelCase = (value: any) =>
  typeof value !== 'object' || value === null || value instanceof Date ? value : Array.isArray(value) ? value.map(snakeToCamelCase) : transformSnakeToCamelCase(value);
