import { AxiosError } from 'axios';
import { PaginatedResponse } from '@types';
import { useQuery, UseQueryOptions, useQueryClient } from '@tanstack/react-query';
import { useActiveOrganization } from '@didomi/utility-react';
import { apiRoutes, DEFAULT_LIMIT } from '@constants';
import { useAxiosWithLogOut } from '../useAxiosWithInterceptors.hook';
import { WidgetDeployment, ErrorResponse } from '@interfaces';

export interface UseWidgetDeploymentQuery {
  limit?: number;
  skip?: number;
  widget_id?: string;
  createdAtRange?: {
    from: string;
    to: string;
  };
}

export const useWidgetDeployments = (
  { limit = DEFAULT_LIMIT, skip = 0, widget_id, createdAtRange }: UseWidgetDeploymentQuery = {},
  options: UseQueryOptions<PaginatedResponse<WidgetDeployment>> = {},
) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();
  const queryClient = useQueryClient();

  const fetchParams = { organization_id: organizationId, $limit: limit, $skip: skip, widget_id };

  if (createdAtRange) {
    fetchParams['created_at[$gte]'] = createdAtRange.from;
    fetchParams['created_at[$lte]'] = createdAtRange.to;
  }

  const fetch = async (params: any) => {
    const response = await axiosWithInterceptors.get<PaginatedResponse<WidgetDeployment>>(apiRoutes.widgetDeployments, {
      params,
    });
    return response.data;
  };

  const fetchAsync = (widgetId: string) => {
    return queryClient.fetchQuery({
      queryKey: ['widget-deployments', { ...fetchParams, widget_id: widgetId }],
      queryFn: () => fetch({ ...fetchParams, widget_id: widgetId }),
    });
  };

  return {
    ...useQuery<PaginatedResponse<WidgetDeployment>, AxiosError<ErrorResponse>>(['widget-deployments', fetchParams], () => fetch(fetchParams), options),
    fetchAsync,
  };
};
