import React from 'react';
import { generateUniqueId, escapeRegexCharacters } from '@utils';

type TextHighlightPropsType = {
  // original text
  text: string;
  // search or match term
  highlight: string;
};

// This component will highlight any text that matches `highlight` string
export const TextHighlight = ({ text, highlight }: TextHighlightPropsType) => {
  const escapedHighlight = escapeRegexCharacters(highlight);
  const parts = text.split(new RegExp(`(${escapedHighlight})`, 'gi'));
  return (
    <span>
      {parts.map(part => {
        const id = generateUniqueId('hl');
        return part.toLowerCase() === highlight.toLowerCase() ? (
          <span key={id} className="text-primary-pink-4">
            {part}
          </span>
        ) : (
          <span key={id}>{part}</span>
        );
      })}
    </span>
  );
};
