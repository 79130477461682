import { useWidgetPreview } from '@hooks';
import { WidgetLayoutShape, WidgetThemeName } from '@enums';
import { ComponentsListInput, LayoutEntityInput } from '@didomi/pmp-generator';

interface PreviewProps {
  themeId?: string;
  layoutShape?: WidgetLayoutShape;
  customTheme?: WidgetThemeName;
  updatedComponents?: ComponentsListInput;
  layoutEntities?: LayoutEntityInput[];
}

interface WidgetPreviewChildrenProps {
  isLoading: boolean;
  preview: (props?: PreviewProps) => void;
}

type WidgetPreviewChildren = (props: WidgetPreviewChildrenProps) => JSX.Element;

interface WidgetPreviewProps {
  children: WidgetPreviewChildren;
  widgetId: string;
}

/**
 * This component is a wrapper over useWidgetPreview hook allowing to use the hook multiples time in a same component.
 * An example of it can be in a widget list that has a button to preview the widget. With this wrapper each button
 * can preview the widget and has a context for controlling the loading.
 * Ex:
 * <ul>
 *  <li>
 *    <WidgetPreview widgetId="123">
 *      {({ isLoading, preview }) => (<button onClick={preview} isLoading={isLoading})>Preview Widget 123</button>)}
 *    </WidgetPreview>
 *  </li>
 *  <li>
 *    <WidgetPreview widgetId="456">
 *      {({ isLoading, preview }) => (<button onClick={preview} isLoading={isLoading})>Preview Widget 456</button>)}
 *    </WidgetPreview>
 *  </li>
 * </ul>
 * @param param0
 * @returns
 */
const WidgetPreview = ({ children, widgetId }: WidgetPreviewProps): JSX.Element => {
  const { isLoading, preview } = useWidgetPreview();

  const handlePreview = () => preview(widgetId);

  return children({ isLoading, preview: handlePreview });
};

export { WidgetPreview };
