import React, { useEffect, useState } from 'react';
import { PublishedWidgetModal, PublishedWidgetModalVariant } from '@didomi/widgets-utilities';
import { PUBLISH_EMBEDDABLE_WIDGET_MODAL_TITLE } from '@constants';
import { useHistory } from 'react-router-dom';
import { getWidgetDomainURL } from '@utils';
import { WidgetDomain, WidgetFormat, WidgetPublicationStatus, WidgetTemplateId } from '@enums';

interface Props {
  isModalOpen: boolean;
  widgetFormat: WidgetFormat;
  initialWidgetPublicationStatus: WidgetPublicationStatus;
  widgetId?: string;
  widgetTemplateId?: WidgetTemplateId;
  widgetDomain?: WidgetDomain;
  widgetSubdomainId?: string;
  widgetCustomDomainId?: string;
  onModalClosed?: () => void;
}

function EditWidgetPublishedModal({
  isModalOpen,
  widgetId,
  widgetFormat,
  widgetTemplateId,
  widgetDomain,
  widgetSubdomainId,
  widgetCustomDomainId,
  onModalClosed,
  initialWidgetPublicationStatus,
}: Props) {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(isModalOpen);
  }, [isModalOpen, setIsOpen]);

  return (
    <>
      {widgetFormat === WidgetFormat.HOSTED && (
        <PublishedWidgetModal
          variant={PublishedWidgetModalVariant.Hosting}
          isOpen={isOpen}
          showDomainAvailabilityWarning={widgetDomain === WidgetDomain.SUB && initialWidgetPublicationStatus === WidgetPublicationStatus.DRAFT}
          domainUrl={getWidgetDomainURL({
            domain: widgetDomain,
            subdomainId: widgetSubdomainId,
            customDomainId: widgetCustomDomainId,
          })}
          onCancel={() => {
            setIsOpen(false);
            onModalClosed && onModalClosed();
          }}
          onProceed={() =>
            window
              .open(
                getWidgetDomainURL({
                  domain: widgetDomain,
                  subdomainId: widgetSubdomainId,
                  customDomainId: widgetCustomDomainId,
                }),
                '_blank',
              )
              .focus()
          }
        />
      )}

      {widgetFormat === WidgetFormat.EMBEDDABLE && (
        <PublishedWidgetModal
          variant={PublishedWidgetModalVariant.Embedding}
          isOpen={isOpen}
          modalTitle={PUBLISH_EMBEDDABLE_WIDGET_MODAL_TITLE[widgetTemplateId]}
          onProceed={() => {
            setIsOpen(false);
            history.push(`/widgets/edit/${widgetId}/embedding`);
          }}
        />
      )}
    </>
  );
}

export default EditWidgetPublishedModal;
