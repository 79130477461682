import React, { useState } from 'react';
import { ValueOutput } from '@didomi/pmp-generator/dist/types/entities/models/entities.model';
import { OverflowWithTooltip } from '@components';
import { Direction } from '@enums';
import { EditWidgetPreferencesBaseProps, ExtendedComponentsListInput } from '@types';
import { getComponentTranslatedValue } from '@utils';
import { useTranslationContext } from '@didomi/widgets-utilities';
import { EditWidgetHeaderActions } from './EditWidgetHeaderActions';
import { useReleaseFlag } from '@didomi/utility-react';
import { DidomiSwitch } from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';

interface EditPreferenceValueControlProps extends EditWidgetPreferencesBaseProps {
  preferenceId: string;
  preferenceValue: ValueOutput;
  children?: React.ReactNode;
  selectionType: 'single' | 'multi';
  components?: ExtendedComponentsListInput;
}

export function EditPreferenceValueControl({
  preferenceValue,
  preferenceId,
  selectionType,
  widgetPreferences,
  setWidgetLayoutEntityEnabled,
  children,
  components,
  preferenceOrderingControls,
}: EditPreferenceValueControlProps) {
  const [isHover, setIsHover] = useState(false);
  const [hasLayoutReorderingFeature] = useReleaseFlag('pmp_layout_reordering');
  const { defaultLanguage } = useTranslationContext();

  const updatedValue = widgetPreferences?.[preferenceValue.layoutEntityId]?.enabled;
  const enabled = typeof updatedValue === 'boolean' ? updatedValue : preferenceValue.enabled !== false;
  const translatedPreferenceValue = getComponentTranslatedValue(preferenceValue?.component?.id, 'label.content', preferenceValue.name, components, defaultLanguage);
  const showHeaderActions = selectionType === 'multi' && isHover;
  const shouldHaveOpacity = !enabled && hasLayoutReorderingFeature;

  const headerActionDisabled = preferenceOrderingControls?.getDisabledActionControls(preferenceValue.layoutEntityId);

  return (
    <section key={preferenceValue.id}>
      <header
        className="flex justify-between items-center"
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        data-testid="edit-preference-value-control-header"
      >
        <OverflowWithTooltip
          className={tx('text-body-normal min-w-0 text-primary-blue-6', {
            'opacity-30': shouldHaveOpacity,
          })}
          value={translatedPreferenceValue}
        />
        <div className="flex pl-xs">
          {hasLayoutReorderingFeature && showHeaderActions && (
            <EditWidgetHeaderActions
              enabled={enabled}
              onValueChange={value => {
                setWidgetLayoutEntityEnabled(preferenceValue.layoutEntityId, value === 'selected');
              }}
              moveDownDisabled={headerActionDisabled?.downOptionDisabled}
              moveUpDisabled={headerActionDisabled?.upOptionDisabled}
              onMoveDown={() => preferenceOrderingControls?.move(preferenceValue.layoutEntityId, Direction.DOWN)}
              onMoveUp={() => preferenceOrderingControls?.move(preferenceValue.layoutEntityId, Direction.UP)}
            />
          )}
          {!hasLayoutReorderingFeature && selectionType === 'multi' && (
            <DidomiSwitch
              data-testid={`edit-pref-value-switch-${preferenceId}-${preferenceValue.id}`}
              hideErrorMessage
              valueSelected="selected"
              valueNotSelected="not-selected"
              value={enabled ? 'selected' : 'not-selected'}
              onValueChange={ev => {
                setWidgetLayoutEntityEnabled(preferenceValue.layoutEntityId, ev.detail === 'selected');
              }}
            />
          )}
        </div>
      </header>
      {children}
    </section>
  );
}
