import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { AxiosResponse, AxiosError } from 'axios';
import { useActiveOrganization } from '@didomi/utility-react';
import { apiRoutes } from '@constants';
import { ErrorResponse, SelectedPreference } from '@interfaces';
import { useAxiosWithLogOut } from '../useAxiosWithInterceptors.hook';

export const useDeleteSelectedPreferences = (configTreeId: string, options?: UseMutationOptions<AxiosResponse<SelectedPreference[]>, AxiosError, string[]>) => {
  const { organizationId } = useActiveOrganization();
  const axiosWithInterceptors = useAxiosWithLogOut();
  const queryClient = useQueryClient();

  const mutationFn = async (selectedPreferenceIds: string[]) =>
    axiosWithInterceptors.delete(apiRoutes.selectedPreferences(configTreeId), { params: { organization_id: organizationId, id: selectedPreferenceIds } });

  return useMutation<AxiosResponse<SelectedPreference[]>, AxiosError<ErrorResponse>, string[]>(mutationFn, {
    ...options,
    onSuccess: (deletedSelectedPreference: AxiosResponse<SelectedPreference[]>, variables, context) => {
      queryClient.invalidateQueries(['config-trees']);

      if (options.onSuccess) {
        options.onSuccess(deletedSelectedPreference, variables, context);
      }
    },
  });
};
