import { Icons } from '@didomi/ui-foundation';
import { WidgetLayoutShape, WidgetTemplateId } from '@enums';
import { WidgetTheme } from '@interfaces';
export const COLOR_ITEM_DEBOUNCE_DELAY = 500;
type ColorInput = {
  label: string;
  name: string;
  themeKey: keyof WidgetTheme;
  hasTooltip?: boolean;
};
export const THEME_LAYOUT_OPTIONS: Array<{ label: string; value: WidgetLayoutShape; iconDefault: Icons; iconSelected: Icons }> = [
  {
    label: 'Smoothed',
    value: WidgetLayoutShape.SMOOTHED,
    iconDefault: 'smoothed-default' as Icons,
    iconSelected: 'smoothed-selected' as Icons,
  },
  {
    label: 'Rounded',
    value: WidgetLayoutShape.ROUNDED,
    iconDefault: 'rounded-default' as Icons,
    iconSelected: 'rounded-selected' as Icons,
  },
  {
    label: 'Squared',
    value: WidgetLayoutShape.SQUARED,
    iconDefault: 'squared-default' as Icons,
    iconSelected: 'squared-selected' as Icons,
  },
];

export const CUSTOM_COLORS_INPUTS: ColorInput[] = [
  {
    label: 'Header',
    name: 'header',
    themeKey: 'color_1',
    hasTooltip: true,
  },
  {
    label: 'Section title',
    name: 'sectionTitle',
    themeKey: 'color_2',
    hasTooltip: true,
  },
  {
    label: 'Card title',
    name: 'cardTitle',
    themeKey: 'color_3',
  },
  {
    label: 'Card body copy',
    name: 'cardBodyCopy',
    themeKey: 'color_4',
  },
  {
    label: 'Selection buttons',
    name: 'selectionButtons',
    themeKey: 'color_5',
    hasTooltip: true,
  },
  {
    label: 'Save button',
    name: 'saveButton',
    themeKey: 'color_6',
  },
  {
    label: 'Footer',
    name: 'footer',
    themeKey: 'color_7',
    hasTooltip: true,
  },
  {
    label: 'Background',
    name: 'background',
    themeKey: 'color_8',
  },
];

export const CUSTOM_MPW_COLORS_INPUTS: ColorInput[] = [
  {
    label: 'Section title',
    name: 'sectionTitle',
    themeKey: 'color_2',
    hasTooltip: true,
  },
  {
    label: 'Card title',
    name: 'cardTitle',
    themeKey: 'color_3',
  },
  {
    label: 'Card body copy',
    name: 'cardBodyCopy',
    themeKey: 'color_4',
  },
  {
    label: 'Selection buttons',
    name: 'selectionButtons',
    themeKey: 'color_5',
    hasTooltip: true,
  },
  {
    label: 'Save button',
    name: 'saveButton',
    themeKey: 'color_6',
  },
];

export const CUSTOM_COLORS_INPUTS_MAP = {
  [WidgetTemplateId.FULL_PREFERENCE_CENTER]: CUSTOM_COLORS_INPUTS,
  [WidgetTemplateId.SECTIONS]: CUSTOM_COLORS_INPUTS,
  [WidgetTemplateId.SECTIONS_AND_SAVE]: CUSTOM_MPW_COLORS_INPUTS,
};
