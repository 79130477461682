import { devOrganizationId } from '@constants';

export const createDefaultPurposes = (server, defaultOrganization = devOrganizationId) => {
  server.create('purpose', {
    created_at: '2021-10-19T15:28:46.493Z',
    updated_at: '2021-10-20T15:18:16.293Z',
    id: 'purpose-cv3fw4df5s',
    description: {
      en: 'Communicate about new product release',
    },
    details: {
      en: 'To communicate about product releases',
    },
    organizationId: defaultOrganization,
  });

  server.create('purpose', {
    created_at: '2021-10-19T15:28:46.493Z',
    updated_at: '2021-10-20T15:18:16.293Z',
    id: 'purpose-bvdfw43f1z',
    description: {
      en: 'Communicate about brand news',
    },
    details: {
      en: 'To communicate about product releases',
    },
    organizationId: defaultOrganization,
  });

  server.create('purpose', {
    created_at: '2021-10-19T15:28:46.493Z',
    updated_at: '2021-10-20T15:18:16.293Z',
    id: 'purpose-ifetw4345a',
    description: {
      en: 'Third purpose',
    },
    details: {
      en: 'Third purpose description',
    },
    organizationId: defaultOrganization,
  });

  server.create('purpose', {
    created_at: '2021-10-19T15:28:46.493Z',
    updated_at: '2021-10-20T15:18:16.293Z',
    id: 'purpose-dsfe45c6a',
    description: {
      en: 'Fourth purpose',
    },
    details: {
      en: 'Fourth purpose description',
    },
    organizationId: defaultOrganization,
  });
};
