import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { AxiosResponse, AxiosError } from 'axios';
import { useActiveOrganization } from '@didomi/utility-react';
import { apiRoutes } from '@constants';
import { ErrorResponse, LayoutEntity } from '@interfaces';
import { useAxiosWithLogOut } from '../useAxiosWithInterceptors.hook';

export type PatchLayoutEntitiesPayload = {
  id: string;
  order?: number;
  enabled?: boolean;
};

export const usePatchLayoutEntities = (options: UseMutationOptions<AxiosResponse<LayoutEntity[]>, AxiosError, PatchLayoutEntitiesPayload[]> = {}) => {
  const { organizationId } = useActiveOrganization();
  const axiosWithInterceptors = useAxiosWithLogOut();
  const queryClient = useQueryClient();

  const patch = async (payload: PatchLayoutEntitiesPayload[]) => axiosWithInterceptors.patch(apiRoutes.layoutEntities, payload, { params: { organization_id: organizationId } });

  return useMutation<AxiosResponse<LayoutEntity[]>, AxiosError<ErrorResponse>, PatchLayoutEntitiesPayload[]>(patch, {
    ...options,
    onSuccess: (updatedLayoutEntities: AxiosResponse<LayoutEntity[]>, variables, context) => {
      queryClient.invalidateQueries(['layout-entities']);

      if (options.onSuccess) {
        options.onSuccess(updatedLayoutEntities, variables, context);
      }
    },
  });
};
