import React from 'react';
import { DidomiCollapsibleCard, DidomiColorItem } from '@didomi/ui-atoms-react';
import debounce from 'lodash.debounce';
import { tx } from '@twind/core';
import SelectionButtonTypeSVG from '../../../assets/svg/selection-button-type.svg';
import { COLOR_ITEM_DEBOUNCE_DELAY, CUSTOM_COLORS_INPUTS_MAP } from './widgetThemeConstants';
import { WidgetTemplateId } from '@enums';
import { WidgetTheme } from '@interfaces';
import { useWidgetPreviewData } from '@contexts';

interface WidgetThemeCustomColorProps {
  isCollapsed: boolean;
  widgetTemplateId: WidgetTemplateId;
  onThemeChange: (newTheme: WidgetTheme) => void;
  onCardToggle: (event: CustomEvent<boolean>) => void;
}

const WidgetThemeCustomColor = ({ onThemeChange, isCollapsed = true, onCardToggle: onCardToggleProp, widgetTemplateId }: WidgetThemeCustomColorProps): JSX.Element => {
  const { customTheme, selectedThemeId, setCustomTheme } = useWidgetPreviewData();

  const onColorChange = themeKey =>
    debounce(newColor => {
      const newTheme = {
        ...customTheme,
        [themeKey]: newColor?.detail,
      };
      setCustomTheme(newTheme);
      onThemeChange(newTheme);
    }, COLOR_ITEM_DEBOUNCE_DELAY);

  const onCardToggle = (event: CustomEvent<boolean>) => {
    if (!event.detail) {
      onThemeChange(customTheme);
    }
    onCardToggleProp(event);
  };

  const isCustomThemeSelected = customTheme?.id === selectedThemeId;
  const customColorInputs = CUSTOM_COLORS_INPUTS_MAP[widgetTemplateId];

  return (
    <div data-skeleton className="w-full">
      <DidomiCollapsibleCard
        label="Customized colors"
        data-tracking="pmp-look-feel-custom-colors"
        icon="color-theme-empty"
        data-testid="widget-theme-custom-colors"
        isCollapsed={isCollapsed}
        onCardToggle={onCardToggle}
        isSelected={isCustomThemeSelected}
      >
        <div>
          {customColorInputs.map(({ label, name, themeKey, hasTooltip }, index) => (
            <DidomiColorItem
              key={name}
              label={label}
              name={name}
              value={customTheme?.[themeKey]}
              onValueChange={onColorChange(themeKey)}
              className={tx('block mr-xxxs', {
                'mb-4': index < customColorInputs.length - 1,
                'mb-xxxs': index === customColorInputs.length - 1,
              })}
              tooltipElementId={hasTooltip && `color-item-tooltip-${name}`}
              data-testid={`look-and-feel-color-item-${name}`}
            />
          ))}
        </div>
      </DidomiCollapsibleCard>
      <div className="hidden">
        <div id="color-item-tooltip-header">
          <div className="text-body-small font-normal">
            <strong>Header color is applied on:</strong>
            <br />
            Main title
            <br />
            Description text
            <br />
            User e-mail
            <br />
            User history
          </div>
        </div>
        <div id="color-item-tooltip-sectionTitle">
          <div className="text-body-small font-normal">
            <strong>Section titles</strong> are titles <br />
            between cards
          </div>
        </div>
        <div id="color-item-tooltip-selectionButtons">
          <div className="text-body-small font-normal">
            <img src={SelectionButtonTypeSVG} alt="Selection buttons tooltip" className="mb-2" />
            <strong>Selection buttons</strong> <br />
            are radio buttons and <br />
            checkboxes cards
          </div>
        </div>
        <div id="color-item-tooltip-footer">
          <div className="text-body-small font-normal">
            <strong>Footer color is applied on:</strong>
            <br />
            Success icon
            <br />
            Thanks message
            <br />
            Manage cookie button
          </div>
        </div>
      </div>
    </div>
  );
};

export { WidgetThemeCustomColor };
