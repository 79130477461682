import { useCallback } from 'react';
import { AxiosError } from 'axios';
import { PaginatedResponse } from '@types';
import { useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import { useActiveOrganization } from '@didomi/utility-react';
import { apiRoutes } from '@constants';
import { useAxiosWithLogOut } from '../useAxiosWithInterceptors.hook';
import { ConfigTree, ErrorResponse } from '@interfaces';

export type FetchConfigTreeAsyncFnType = () => Promise<PaginatedResponse<ConfigTree>>;

export const useConfigTrees = (options?: UseQueryOptions<PaginatedResponse<ConfigTree>>) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();
  const queryClient = useQueryClient();

  const params = { organization_id: organizationId, $with: ['preference', 'purpose'] };

  const fetch = useCallback(
    async fetchParams => {
      const response = await axiosWithInterceptors.get<PaginatedResponse<ConfigTree>>(apiRoutes.configTrees, {
        params: fetchParams,
      });
      return response.data;
    },
    [axiosWithInterceptors],
  );

  const fetchAsync: FetchConfigTreeAsyncFnType = useCallback(() => {
    const params = { organization_id: organizationId, $with: ['preference', 'purpose'] };
    return queryClient.fetchQuery({
      queryKey: ['config-trees', params],
      queryFn: () => fetch(params),
    });
  }, [queryClient, organizationId, fetch]);

  return {
    ...useQuery<PaginatedResponse<ConfigTree>, AxiosError<ErrorResponse>>(['config-trees', params], () => fetch(params), {
      ...options,
    }),
    fetchAsync,
  };
};
