import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { AxiosResponse, AxiosError } from 'axios';
import { useActiveOrganization } from '@didomi/utility-react';
import { apiRoutes } from '@constants';
import { ErrorResponse, Widget } from '@interfaces';
import { useAxiosWithLogOut } from '../useAxiosWithInterceptors.hook';

export const useDeleteWidget = (options?: UseMutationOptions<AxiosResponse<Widget>, AxiosError, string>) => {
  const { organizationId } = useActiveOrganization();
  const axiosWithInterceptors = useAxiosWithLogOut();
  const queryClient = useQueryClient();

  const mutationFn = async (id: string) => axiosWithInterceptors.delete(`${apiRoutes.widgets}/${id}`, { params: { organization_id: organizationId } });

  return useMutation<AxiosResponse<Widget>, AxiosError<ErrorResponse>, string>(mutationFn, {
    ...options,
    onSuccess: (deletedWidget: AxiosResponse<Widget>, variables, context) => {
      queryClient.invalidateQueries(['widgets']);
      queryClient.invalidateQueries(['archived-widgets']);

      if (options.onSuccess) {
        options.onSuccess(deletedWidget, variables, context);
      }
    },
  });
};
