import React from 'react';
import { DidomiModal, DidomiModalHeader, DidomiButton, DidomiModalActions } from '@didomi/ui-atoms-react';

type ResetPurposeModalProps = {
  isOpen: boolean;
  onOpenChange: () => void;
  onProceed: () => void;
};

export const ResetPurposeModal = ({ isOpen, onOpenChange, onProceed }: ResetPurposeModalProps) => (
  <DidomiModal className="relative" isOpen={isOpen} onOpenChange={onOpenChange}>
    <DidomiModalHeader
      illustrationName="delete-pref-tree"
      modalTitle={`All the nested preferences\nwill be removed`}
      modalSubTitle="Please pay attention"
      modalDescription="Are you sure you want to remove all the nested preferences for this purpose?"
      modalDescriptionSpace="none"
      style={{ whiteSpace: 'pre' }}
    />
    <DidomiModalActions className="pt-5!">
      <DidomiButton variant="secondary" onClick={onOpenChange} data-cy="cancel-reset">
        Oops, no thanks
      </DidomiButton>
      <DidomiButton data-tracking="pmp-confirm-reset-purpose" onClick={onProceed} data-cy="confirm-reset">
        <div className="w-full">Yes, delete!</div>
      </DidomiButton>
    </DidomiModalActions>
  </DidomiModal>
);
