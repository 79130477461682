export const layoutsRoutes = {
  '/widgets/layouts': {
    get: (schema: any, request) => {
      const { widget_id, organization_id } = request.queryParams;
      const layout = schema.layouts.findBy({ widget_id, organization_id }).attrs;

      const selectedLayoutComponents = schema.selectedLayoutComponents.where({ layout_id: layout.id }).models.map(({ attrs }) => ({
        ...attrs,
        layout_id: layout.id,
      }));

      const selectedLayoutComponentsById = new Map();
      selectedLayoutComponents.forEach(selectedLayoutComponent => {
        selectedLayoutComponentsById.set(selectedLayoutComponent.id, selectedLayoutComponent);
      });

      layout.selected_layout_components = [];

      selectedLayoutComponents.forEach(selectedLayoutComponent => {
        selectedLayoutComponent.selectedLayoutComponents = [];
        if (selectedLayoutComponent.parent_id) {
          const parentSelectedLayoutComponent = selectedLayoutComponentsById.get(selectedLayoutComponent.parent_id);
          parentSelectedLayoutComponent.selectedLayoutComponents.push(selectedLayoutComponent);
        } else {
          layout.selected_layout_components.push(selectedLayoutComponent);
        }
      });

      return {
        total: 1,
        data: [layout],
      };
    },
  },
};
