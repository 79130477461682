import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { AxiosResponse, AxiosError } from 'axios';
import { useActiveOrganization } from '@didomi/utility-react';
import { apiRoutes } from '@constants';
import { WidgetDeployment } from '@interfaces';
import { updateQueryClientCache } from '@utils';
import { useAxiosWithLogOut } from '../useAxiosWithInterceptors.hook';

export type CreateWidgetDeploymentPayload = {
  widget_id: string;
};

export const useCreateWidgetDeployment = (options?: UseMutationOptions<AxiosResponse<WidgetDeployment>, AxiosError, CreateWidgetDeploymentPayload>) => {
  const { organizationId } = useActiveOrganization();
  const axiosWithInterceptors = useAxiosWithLogOut();
  const queryClient = useQueryClient();

  const post = async (payload: CreateWidgetDeploymentPayload) =>
    axiosWithInterceptors.post(
      apiRoutes.widgetDeployments,
      {
        ...payload,
        organization_id: organizationId,
      },
      { params: { organization_id: organizationId } },
    );

  return useMutation<AxiosResponse<WidgetDeployment>, AxiosError, CreateWidgetDeploymentPayload>(post, {
    ...options,
    onSuccess: (createdWidgetDeployment: AxiosResponse<WidgetDeployment>, variables, context) => {
      updateQueryClientCache(queryClient, createdWidgetDeployment, ['widget-deployments']);

      if (options.onSuccess) {
        options.onSuccess(createdWidgetDeployment, variables, context);
      }
    },
  });
};
