import { Factory } from 'miragejs';
import { devOrganizationId } from '@constants';
import { generateRandomId } from '@utils';

const selectedPreference = Factory.extend({
  id() {
    return generateRandomId('selected-preference');
  },
  updated_at: new Date().toISOString(),
  created_at: new Date().toISOString(),
  organization_id: devOrganizationId,
  order(index) {
    return index;
  },
});

const selectedPurpose = Factory.extend({
  id() {
    return generateRandomId('selected-purpose');
  },
  updated_at: new Date().toISOString(),
  created_at: new Date().toISOString(),
  organization_id: devOrganizationId,
  order(index) {
    return index;
  },
});

const configTree = Factory.extend({
  id() {
    return generateRandomId('config-tree');
  },
  updated_at: new Date().toISOString(),
  created_at: new Date().toISOString(),
  organization_id: devOrganizationId,
  order(index) {
    return index;
  },
});

export default { selectedPreference, selectedPurpose, configTree };
