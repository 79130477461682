import { DidomiChip } from '@didomi/ui-atoms-react';
import { WidgetDisplay, WidgetPublicationStatus, WidgetTemplateId } from '@enums';
import React from 'react';

export const UPDATE_IFRAME_DELAY = 250;

export const WIDGET_TEMPLATE_ID_LABELS = {
  [WidgetTemplateId.FULL_PREFERENCE_CENTER]: 'Preference Center',
  [WidgetTemplateId.SECTIONS]: 'Single purpose widget',
  [WidgetTemplateId.SECTIONS_AND_SAVE]: 'Multi purpose widget',
};

export const WIDGET_STATUS_LABELS = {
  [WidgetPublicationStatus.DRAFT]: 'Draft',
  [WidgetPublicationStatus.UNPUBLISHED_CHANGES]: 'Unpublished Changes',
  [WidgetPublicationStatus.PUBLISHED]: 'Published',
};

export const WIDGET_STATUS_CHIP_TYPE: { [key in WidgetPublicationStatus]: React.ComponentProps<typeof DidomiChip>['basicType'] } = {
  [WidgetPublicationStatus.DRAFT]: 'default',
  [WidgetPublicationStatus.UNPUBLISHED_CHANGES]: 'warning',
  [WidgetPublicationStatus.PUBLISHED]: 'success',
};

export const WIDGET_TEMPLATE_ID_TO_WIDGET_DISPLAY = {
  [WidgetTemplateId.FULL_PREFERENCE_CENTER]: WidgetDisplay.FullPage,
  [WidgetTemplateId.SECTIONS]: WidgetDisplay.Wrapped,
  [WidgetTemplateId.SECTIONS_AND_SAVE]: WidgetDisplay.Wrapped,
};

export const PUBLISH_EMBEDDABLE_WIDGET_MODAL_TITLE = {
  [WidgetTemplateId.SECTIONS]: 'Your Single purpose widget is ready to be implemented',
  [WidgetTemplateId.SECTIONS_AND_SAVE]: 'Your Multi purpose widget is ready to be implemented',
};
