import { formatPaginationResponse, sort } from '../utils';

export const layoutEntitiesRoutes = {
  '/widgets/preferences-centers/layout-entities': {
    get: (schema: any, request) => {
      const { organization_id, widget_id } = request.queryParams;
      const layoutEntities = schema.layoutEntities.where({ organization_id, widget_id });
      const data = layoutEntities.models.map(({ attrs }) => attrs);
      const sortedData = sort({ queryParams: request.queryParams, data });

      return formatPaginationResponse(sortedData, request);
    },

    patch: (schema: any, request) => {
      const { organization_id } = request.queryParams;
      const newLayoutEntities = JSON.parse(request.requestBody);
      return newLayoutEntities.map(layoutEntity => {
        const layoutEntityToUpdate = schema.layoutEntities.findBy({
          id: layoutEntity.id,
          organization_id,
        });
        layoutEntityToUpdate.update(layoutEntity);
        return layoutEntityToUpdate.attrs;
      });
    },
  },
};
