import React from 'react';
import { DidomiButton, DidomiIllustration, DidomiModal, DidomiModalHeader, DidomiModalActions } from '@didomi/ui-atoms-react';

type RemovePreferenceFromTreeModalProps = {
  isOpen?: boolean;
  onCancel?: () => void;
  onProceed?: () => void;
};

/**
 * Remove preference from tree Modal
 */
const RemovePreferenceFromTreeModal = ({ isOpen, onCancel, onProceed }: RemovePreferenceFromTreeModalProps): JSX.Element => {
  return (
    <DidomiModal isOpen={isOpen} permanent="true">
      <DidomiIllustration name="all-widget-update" style={{ marginBottom: 'var(--space-m)' }} />
      <DidomiModalHeader
        data-testid="remove-preference-modal-header"
        modalSubTitle="Please be careful"
        variant="warning"
        modalTitle={`Removing this preference will have\nan impact on all your widgets`}
        style={{ whiteSpace: 'pre' }}
      />

      <DidomiModalActions className="mt-2">
        <DidomiButton variant="secondary" onClick={onCancel}>
          Oops, no thanks
        </DidomiButton>
        <DidomiButton data-tracking="pmp-confirm-remove-preference-from-tree" onClick={onProceed}>
          Got it, save!
        </DidomiButton>
      </DidomiModalActions>
    </DidomiModal>
  );
};

export { RemovePreferenceFromTreeModal };
