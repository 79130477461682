import { devOrganizationId } from '@constants';
import { WidgetThemeName } from '@enums';

export function createDefaultWidgetThemes(server, defaultOrganization = devOrganizationId) {
  server.create('widgetTheme', {
    id: 'custom_theme_1',
    name: WidgetThemeName.CUSTOM,
    organization_id: defaultOrganization,
    created_at: '2021-10-24T12:00:21.145Z',
    updated_at: '2021-10-24T12:00:21.145Z',
    color_1: '#FFFFFF',
    color_2: '#FFFFFF',
    color_3: '#68374A',
    color_4: '#33454C',
    color_5: '#9F4F56',
    color_6: '#9F4F56',
    color_7: '#FFFFFF',
    color_8: 'linear-gradient(314.71deg, #9F4F56 63.02%, #FFEFD7 100%)',
  });
  server.create('widgetTheme', {
    id: 'custom_theme_2',
    name: WidgetThemeName.CUSTOM,
    organization_id: defaultOrganization,
    created_at: '2021-10-24T12:00:21.145Z',
    updated_at: '2021-10-24T12:00:21.145Z',
    color_1: '#FFFFFF',
    color_2: '#FFFFFF',
    color_3: '#68374A',
    color_4: '#33454C',
    color_5: '#9F4F56',
    color_6: '#9F4F56',
    color_7: '#FFFFFF',
    color_8: 'linear-gradient(314.71deg, #9F4F56 63.02%, #FFEFD7 100%)',
  });
  server.create('widgetTheme', {
    id: 'custom_theme_3',
    name: WidgetThemeName.CUSTOM,
    organization_id: defaultOrganization,
    created_at: '2021-10-24T12:00:21.145Z',
    updated_at: '2021-10-24T12:00:21.145Z',
    color_1: '#FFFFFF',
    color_2: '#FFFFFF',
    color_3: '#68374A',
    color_4: '#33454C',
    color_5: '#9F4F56',
    color_6: '#9F4F56',
    color_7: '#FFFFFF',
    color_8: 'linear-gradient(314.71deg, #9F4F56 63.02%, #FFEFD7 100%)',
  });

  server.create('widgetTheme', {
    id: 'default_theme_id_0',
    name: WidgetThemeName.DEFAULT,
    organization_id: null,
    created_at: '2021-10-24T12:00:21.145Z',
    updated_at: '2021-10-24T12:00:21.145Z',
    color_1: '#FFFFFF',
    color_2: '#FFFFFF',
    color_3: '#68374A',
    color_4: '#33454C',
    color_5: '#9F4F56',
    color_6: '#9F4F56',
    color_7: '#FFFFFF',
    color_8: 'linear-gradient(314.71deg, #9F4F56 63.02%, #FFEFD7 100%)',
  });

  server.create('widgetTheme', {
    id: 'default_theme_id_1',
    name: WidgetThemeName.OCEAN,
    organization_id: null,
    created_at: '2021-10-24T12:00:21.145Z',
    updated_at: '2021-10-24T12:00:21.145Z',
    color_1: '#FFFFFF',
    color_2: '#FFFFFF',
    color_3: '#68374A',
    color_4: '#33454C',
    color_5: '#9F4F56',
    color_6: '#9F4F56',
    color_7: '#FFFFFF',
    color_8: 'linear-gradient(314.71deg, #9F4F56 63.02%, #FFEFD7 100%)',
  });

  server.create('widgetTheme', {
    id: 'default_theme_id_2',
    name: WidgetThemeName.LAGOON,
    organization_id: null,
    created_at: '2021-10-24T12:00:21.145Z',
    updated_at: '2021-10-24T12:00:21.145Z',
    color_1: '#FFFFFF',
    color_2: '#FFFFFF',
    color_3: '#147278',
    color_4: '#33454C',
    color_5: '#3C9AA0',
    color_6: '#147278',
    color_7: '#FFFFFF',
    color_8: 'linear-gradient(314.71deg, #4F9A9F 63.02%, #CBFBD8 100%)',
  });

  server.create('widgetTheme', {
    id: 'default_theme_id_3',
    name: WidgetThemeName.SUNSET,
    organization_id: null,
    created_at: '2021-10-24T12:00:21.145Z',
    updated_at: '2021-10-24T12:00:21.145Z',
    color_1: '#FFFFFF',
    color_2: '#FFFFFF',
    color_3: '#68374A',
    color_4: '#33454C',
    color_5: '#9F4F56',
    color_6: '#9F4F56',
    color_7: '#FFFFFF',
    color_8: 'linear-gradient(314.71deg, #9F4F56 63.02%, #FFEFD7 100%)',
  });

  server.create('widgetTheme', {
    id: 'default_theme_id_4',
    name: WidgetThemeName.ABYSS,
    organization_id: null,
    created_at: '2021-10-24T12:00:21.145Z',
    updated_at: '2021-10-24T12:00:21.145Z',
    color_1: '#FFFFFF',
    color_2: '#FFFFFF',
    color_3: '#68374A',
    color_4: '#33454C',
    color_5: '#9F4F56',
    color_6: '#9F4F56',
    color_7: '#FFFFFF',
    color_8: 'linear-gradient(314.71deg, #9F4F56 63.02%, #FFEFD7 100%)',
  });
}
