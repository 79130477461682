import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useActiveOrganization } from '@didomi/utility-react';
import { apiRoutes } from '@constants';
import { useAxiosWithLogOut } from '@hooks';
import { Preference, ErrorResponse } from '@interfaces';

export const usePreference = (id: string, options?: UseQueryOptions<Preference, AxiosError<ErrorResponse>>) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();

  const fetch = async () => {
    const response = await axiosWithInterceptors.get<Preference>(`${apiRoutes.preferences}/${id}`, {
      params: { organization_id: organizationId },
    });
    return response.data;
  };

  return useQuery<Preference, AxiosError<ErrorResponse>>(['preferences', { id, organization_id: organizationId }], fetch, {
    ...options,
  });
};
