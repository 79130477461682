import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSnackbar } from '@didomi/utility-react';
import { PreferenceType } from '@enums';
import { getTranslatedValue, createSanitizedTranslationFromValue } from '@utils';
import { DidomiModal, DidomiModalContent, DidomiLoader } from '@didomi/ui-atoms-react';
import { PreferenceForm } from '@components';
import { CreatePreferencePayload, useCreatePreference } from '../../hooks/data/preferences/useCreatePreference.hook';
import { CreatePreferenceFormData } from '../../components/PreferenceForm/PreferenceForm';
import { Preference } from '@interfaces';

/**
 * Add Preference Page
 */
const AddPreference = (): JSX.Element => {
  const { push } = useHistory();
  const { state: preSelectedPreference } = useLocation<Preference | null>();
  const { displaySnackbar } = useSnackbar();

  // Transform the preference before saving it
  const transformedPreference = (preference: CreatePreferenceFormData): CreatePreferencePayload => ({
    ...preference,
    name: createSanitizedTranslationFromValue(preference.name),
    description: createSanitizedTranslationFromValue(preference.description),
    values: preference.values.map(value => ({ ...value, name: createSanitizedTranslationFromValue(value.name) })),
  });

  const { mutate: savePreference, isLoading: savingPreference } = useCreatePreference({
    onSuccess: () => {
      displaySnackbar('1 preference added to the Preferences Library', { icon: 'check' });
      push('/configuration');
    },
    onError: () => {
      displaySnackbar('There was an error saving the preference', { icon: 'danger-light' });
    },
  });

  return (
    <main className="pb-10">
      <div className="mt-xs flex flex-col pb-6">
        <h2 className="h2 text-primary-blue-6">Preference content</h2>
        <PreferenceForm
          initialValues={{
            name: getTranslatedValue(preSelectedPreference?.name, ''),
            description: getTranslatedValue(preSelectedPreference?.description, ''),
            values: preSelectedPreference?.values.map(value => ({ ...value, name: getTranslatedValue(value.name, '') })) ?? [],
            type: preSelectedPreference?.type ?? PreferenceType.MULTI,
          }}
          onCancel={() => {
            push('/configuration');
          }}
          onSubmit={newPreference => {
            savePreference({
              ...transformedPreference(newPreference),
            });
          }}
          autoFocusNameInput
        />
        <DidomiModal isOpen={savingPreference} permanent="true">
          <DidomiModalContent className="flex flex-col items-center">
            <DidomiLoader />
            <div className="text-body-big font-semibold font-sans text-primary-blue-4 mt-m mb-xxs">Please wait,</div>
            <div className="text-body-big font-sans text-primary-blue-6">We are saving your preference...</div>
          </DidomiModalContent>
        </DidomiModal>
      </div>
    </main>
  );
};

export { AddPreference };
