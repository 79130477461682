import { useMemo } from 'react';
import { useWidgetThemes } from '@hooks';
import { WidgetThemeName } from '@enums';
import { WidgetTheme } from '@interfaces';

const processThemes = (data?: Array<WidgetTheme>) => {
  if (data) {
    // we create a shallow copy as the data is read only
    const themes = [...data];
    themes.sort((theme1, theme2) => {
      // Keep default theme at the first position always
      if (theme1.name === WidgetThemeName.DEFAULT) {
        return -1;
      }

      if (theme2.name === WidgetThemeName.DEFAULT) {
        return 1;
      }

      return theme1.name.localeCompare(theme2.name);
    });

    return themes;
  }

  return [];
};

export const useDefaultThemes = () => {
  const {
    isLoading: loading,
    isError: error,
    data,
    refetch,
    fetchAsync,
  } = useWidgetThemes({
    /*
     * This serves as a workaround due to Axios converting null to an empty string.
     * Within our API, the presence of a valid value for "organization" is a mandatory requirement,
     * failure to provide one will result in an error.
     */
    organization_id: 'null',
  });
  const defaultThemes = useMemo(() => processThemes(data?.data), [data?.data]);

  return {
    loading,
    error,
    defaultThemes,
    refetch,
    fetchAsync,
  };
};
