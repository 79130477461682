import React from 'react';
import { DidomiButton, DidomiIllustration, DidomiModal, DidomiModalHeader, DidomiModalActions } from '@didomi/ui-atoms-react';

type ConfirmAfterEditPreferenceModalProps = {
  isOpen?: boolean;
  onCancel?: () => void;
  onProceed?: () => void;
};

/**
 * Confirm After Edit Preference Modal
 */
const ConfirmAfterEditPreferenceModal = ({ isOpen, onCancel, onProceed }: ConfirmAfterEditPreferenceModalProps): JSX.Element => {
  return (
    <DidomiModal isOpen={isOpen} permanent="true">
      <DidomiIllustration name="delete-pref-tree" style={{ marginBottom: 'var(--space-m)' }} />
      <DidomiModalHeader
        variant="warning"
        modalSubTitle="Please pay attention"
        modalTitle="Saving your changes will have an impact on all your widgets."
        modalDescription=""
      ></DidomiModalHeader>

      <DidomiModalActions className="pt-5!">
        <DidomiButton variant="secondary" onClick={onCancel}>
          Oops, no thanks
        </DidomiButton>
        <DidomiButton onClick={onProceed}>Yes, save</DidomiButton>
      </DidomiModalActions>
    </DidomiModal>
  );
};

export { ConfirmAfterEditPreferenceModal };
