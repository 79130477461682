import React from 'react';
import { NavLink } from 'react-router-dom';
import { DidomiNavTabs, DidomiNavTabHeading, DidomiNavTabHeader, DidomiNavTabHeaders, DidomiSkeleton, DidomiHintbox } from '@didomi/ui-atoms-react';
import { useReleaseFlag } from '@didomi/utility-react';
import { WidgetPreviewIframe } from '@didomi/widgets-utilities';
import { WidgetFormat } from '@enums';

interface WidgetLayoutProps {
  widgetId: string;
  widgetName?: string;
  widgetFormat: WidgetFormat;
  children: React.ReactChild;
  header?: React.ReactChild;
  isLoading: boolean;
  isWidgetHeadless: boolean;
  setIframe: (iframe: HTMLIFrameElement) => void;
}

/**
 * Widget Preview Layout
 * Layout with header and tab navs for widget editing tabs
 */
const WidgetPreviewLayout = ({ children, widgetId: id, widgetName, widgetFormat: format, header, isLoading, isWidgetHeadless, setIframe }: WidgetLayoutProps): JSX.Element => {
  const [hasPreferencesRelease, isLoadingPreferencesRelease] = useReleaseFlag('pmp_widget_preferences');
  const previewWrapperStyle = isWidgetHeadless
    ? {
        height: 'calc(100% - 88px)',
      }
    : {};

  const NavTabs: JSX.Element = (
    <DidomiNavTabs data-testid={'widget-nav-tabs'}>
      <DidomiNavTabHeading variant="flow" className="mb-m">
        <DidomiNavTabHeaders>
          {!isLoadingPreferencesRelease && hasPreferencesRelease ? (
            <DidomiNavTabHeader>
              <NavLink data-testid="nav-link-preferences" activeClassName="didomi-active-tab" data-cy="preferences" to={`/widgets/edit/${id}/purposes-and-preferences`}>
                Purposes &#38; preferences
              </NavLink>
            </DidomiNavTabHeader>
          ) : null}

          <DidomiNavTabHeader>
            <NavLink data-testid="nav-link-content-editor" activeClassName="didomi-active-tab" data-cy="content-editor" to={`/widgets/edit/${id}/content-editor`}>
              Content editor
            </NavLink>
          </DidomiNavTabHeader>

          <DidomiNavTabHeader>
            <NavLink data-testid="nav-link-look-and-feel" activeClassName="didomi-active-tab" data-cy="look-and-feel" to={`/widgets/edit/${id}/look-and-feel`}>
              Look & Feel
            </NavLink>
          </DidomiNavTabHeader>

          {format === WidgetFormat.HOSTED && (
            <DidomiNavTabHeader>
              <NavLink data-testid="nav-link-hosting" activeClassName="didomi-active-tab" data-cy="content-editor" to={`/widgets/edit/${id}/hosting`}>
                Hosting
              </NavLink>
            </DidomiNavTabHeader>
          )}
          {format === WidgetFormat.EMBEDDABLE && (
            <DidomiNavTabHeader>
              <NavLink data-testid="nav-link-embedding" activeClassName="didomi-active-tab" data-cy="content-editor" to={`/widgets/edit/${id}/embedding`}>
                Embedding
              </NavLink>
            </DidomiNavTabHeader>
          )}
        </DidomiNavTabHeaders>
      </DidomiNavTabHeading>
    </DidomiNavTabs>
  );

  return (
    <div className="h-full flex flex-col">
      {isLoading ? (
        <div className="flex gap-x-6 mb-m">
          <DidomiSkeleton is-loading="true" className="w-full h-l rounded-3xl"></DidomiSkeleton>
          <DidomiSkeleton is-loading="true" className="w-full h-l rounded-3xl"></DidomiSkeleton>
          <DidomiSkeleton is-loading="true" className="w-full h-l rounded-3xl"></DidomiSkeleton>
          <DidomiSkeleton is-loading="true" className="w-full h-l rounded-3xl"></DidomiSkeleton>
        </div>
      ) : (
        NavTabs
      )}

      <div className="flex flex-1 w-full">
        <div className="flex-1 mr-14 h-full">
          {isWidgetHeadless && (
            <DidomiHintbox data-testid="hintbox-headless" className="mb-2" iconName="warning" variant="warning">
              The preview of your widget might not accurately reflect the configuration of the headless widget as implemented by your technical team.
            </DidomiHintbox>
          )}
          <div className="flex flex-1 flex-col h-full" style={previewWrapperStyle}>
            {header}
            <WidgetPreviewIframe
              isLoading={isLoading || isLoadingPreferencesRelease}
              widgetName={widgetName}
              previewURL={`${CONFIG.previewURL}/assets/preview.html`}
              setIframe={setIframe}
            />
          </div>
        </div>
        {children}
      </div>
    </div>
  );
};

export { WidgetPreviewLayout };
