import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { useAxiosWithLogOut } from '@hooks';
import { apiRoutes } from '@constants';
import { AxiosResponse, AxiosError } from 'axios';
import { useActiveOrganization } from '@didomi/utility-react';
import { Widget } from '@interfaces';

export const useDuplicateWidget = (options?: UseMutationOptions<AxiosResponse<Widget>, AxiosError, {}>) => {
  const { organizationId } = useActiveOrganization();
  const axiosWithInterceptors = useAxiosWithLogOut();
  const queryClient = useQueryClient();

  const duplicate = (id: string) => axiosWithInterceptors.post(apiRoutes.widgetsDuplicate(id), {}, { params: { organization_id: organizationId } });

  return useMutation<AxiosResponse<Widget>, AxiosError, {}>(duplicate, {
    ...options,
    onSuccess: (duplicatedWidgetRes: AxiosResponse<Widget>, variables, context) => {
      // this will update all queries/caches that start with 'widgets'
      const dataKey = ['widgets'];

      queryClient.invalidateQueries(dataKey);

      if (options?.onSuccess) {
        options.onSuccess(duplicatedWidgetRes, variables, context);
      }
    },
  });
};
