import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { DidomiTooltip } from '@didomi/ui-atoms-react';
import { useIsOverflowing } from '@didomi/helpers-react';
import { tx } from '@twind/core';

interface WidgetTitleProps {
  title: string;
  id: string;
  width?: number;
  isLink?: boolean;
}

export const WidgetTitle = ({ title, id, width, isLink }: WidgetTitleProps) => {
  const [linkRef, setLinkRef] = useState<HTMLAnchorElement>();
  const isOverflowing = useIsOverflowing(linkRef);
  const titleClass = tx(
    'rounded-md box-border block truncate pt-1 outline-offset-1 border-[3px] border-solid border-transparent rounded focus:(outline-none border-primary-blue-2)',
    {
      'border-b border-solid border-primary-blue-6 mb-1': isOverflowing,
      'underline pb-1': isLink,
    },
  );

  return (
    <DidomiTooltip data-testid={`widget-tooltip-${id}`} content={isOverflowing ? title : ''} placement="right">
      {isLink ? (
        <NavLink ref={setLinkRef} style={width ? { width } : undefined} data-testid={`widget-title-${id}-${isOverflowing}`} className={titleClass} to={`/widgets/edit/${id}`}>
          {title}
        </NavLink>
      ) : (
        <div style={width ? { width } : undefined} data-testid={`widget-title-${id}-${isOverflowing}`} className={titleClass}>
          {title}
        </div>
      )}
    </DidomiTooltip>
  );
};
