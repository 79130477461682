import React from 'react';
import { DidomiButton, DidomiIllustration } from '@didomi/ui-atoms-react';

interface PreferenceLibraryErrorStateProps {
  refetch: () => void;
}

/**
 * PreferenceLibraryErrorState Component
 */
export const PreferenceLibraryErrorState = ({ refetch }: PreferenceLibraryErrorStateProps): JSX.Element => (
  <div className="border-1 border-dashed border-neutral-gray-5 rounded-lg flex-1 flex flex-col items-center justify-center overflow-x-hidden">
    <DidomiIllustration name="preferences-loading-error" className="mb-xs" />
    <div className="font-sans text-body-big font-semibold text-primary-pink-4">There seems to be an error</div>
    <div className="font-sans text-body-big text-primary-blue-6">Preferences cards has not loaded</div>
    <DidomiButton className="mt-s" size="small" data-testid="reload-preferences" onClick={() => refetch()} iconLeft="reset">
      Reload
    </DidomiButton>
  </div>
);
