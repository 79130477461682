import React from 'react';
import { DidomiTextSearchInput } from '@didomi/ui-atoms-react';

interface PreferenceLibrarySearchInputProps {
  value: string;
  onSearch: (newVal: string) => void;
  className?: string;
  onFocusChange?: (event: CustomEvent<boolean>) => void;
  'data-testid'?: string;
}

const PreferenceLibrarySearchInput = React.forwardRef<HTMLDidomiTextSearchInputElement, PreferenceLibrarySearchInputProps>(
  ({ value, className, onSearch, onFocusChange, 'data-testid': dataTestId = '' }: PreferenceLibrarySearchInputProps, ref) => (
    <div data-testid={`preference-lib-search${dataTestId}`} className={className}>
      <DidomiTextSearchInput
        ref={ref}
        variant="secondary"
        value={value}
        placeholder={'Search preference by name...'}
        onValueChange={ev => onSearch(ev.detail)}
        onFocusChange={onFocusChange}
        hideErrorMessage
      />
    </div>
  ),
);

export { PreferenceLibrarySearchInput };
