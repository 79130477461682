import React, { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useHistory, useLocation } from 'react-router-dom';
import { tx } from '@twind/core';
import { useSnackbar, useSPARouter, useHasAccessPolicies } from '@didomi/utility-react';
import { PreferenceCard, PreferenceLibrary, LoadingPurposeCard, PurposesList, GenericEmptyState } from '@components';
import { DidomiButton, DidomiIllustration, DidomiSearchBar, DidomiSkeleton, DidomiSwitch } from '@didomi/ui-atoms-react';
import { DndContext, DragEndEvent, DragOverlay, DragStartEvent } from '@dnd-kit/core';
import { RemovePurposeFromTreeModal, RemovePreferenceFromTreeModal, ResetPurposeModal, PreviewPurposeModal, PreviewPreferenceModal } from '@modals';
import { DEFAULT_EMPTY_TRANSLATION, substrIncludes, CustomConfigurationTreeCollision, configTreeMap, isPreferenceSelfNesting, mergePurposesWithSelectedPreference } from '@utils';
import { DropData } from '@types';
import type { PreferencePreviewData } from '../../modals/PreviewPreferenceModal/PreviewPreferenceModal.modal';
import { usePaginationQueryParams, useConfigTrees, useCreateSelectedPreference, useDeleteSelectedPurpose, useDeleteSelectedPreferences, useDeleteSelectedPreference } from '@hooks';
import { SelectedPreference, SelectedPurpose } from '@interfaces';
import { ACCESS_POLICIES_CONFIG } from '../../constants/access-policies';

/**
 * Configuration Default Page
 * This page uses @dnd-kit library for drag and dropping the preference into the Configuration Tree.
 * For more information read on: https://docs.dndkit.com/api-documentation/context-provider
 */
const Configuration = (): JSX.Element => {
  const { hasAccess: canEditConfigurationTree } = useHasAccessPolicies(ACCESS_POLICIES_CONFIG.CONFIGURATION_TREE_EDITOR);
  const { push } = useHistory();
  const location = useLocation<{ scrollToBottom: boolean }>();
  const scrollToBottomRef = useRef<HTMLDivElement | null>(null);

  const { search, setSearch } = usePaginationQueryParams({
    debounceSearch: true,
  });
  const { navigateTo } = useSPARouter();
  const { displaySnackbar } = useSnackbar();
  const [isResetPurposeModalOpen, setResetPurposeModalState] = useState(false);

  const [showAllIds, setShowAllIds] = useState(false);
  const [resetCandidateId, setResetCandidateId] = useState(null);
  const [draggingPreference, setDraggingPreference] = useState(null);
  const [deletingPurposeId, setDeletingPurposeId] = useState<string>();
  const [deletingSelectedPreferenceId, setDeletingSelectedPreferenceId] = useState<string>();
  const [displayPurposePreview, setDisplayPurposePreview] = useState(null);
  const [displayPreferencePreview, setDisplayPreferencePreview] = useState<PreferencePreviewData | null>(null);
  const [entityIDsToBeDeleted, setEntityIDsToBeDeleted] = useState<string[]>([]);
  const [selectedPurposeIDsToBeReset, setSelectedPurposeIDsToBeReset] = useState<string[]>([]);
  const [selectedPreferenceToBeAdded, setSelectedPreferenceToBeAdded] = useState<SelectedPreference>(null);
  const hasSetInitialShouldScrollToBottom = useRef<boolean>(false);

  const {
    isLoading: loadingConfTree,
    isFetching: isFetchingConfigTree,
    error: errorLoadingConfTree,
    data: configTrees,
    refetch: refetchConfTree,
  } = useConfigTrees({
    keepPreviousData: true,
  });

  const configTree = configTrees?.data?.[0];
  const existingPurposes = useMemo(
    () => mergePurposesWithSelectedPreference(configTree?.selected_purposes, selectedPreferenceToBeAdded),
    [configTree, selectedPreferenceToBeAdded],
  );
  const hasPurposes = existingPurposes?.length;

  // Helper mappings used to check if a preference can be included in the config tree.
  const configurationMap = useMemo(() => configTreeMap(configTree), [configTree]);

  // istanbul ignore next - drag&drop code can't be unit tested
  const { mutate: createSelectedPreference } = useCreateSelectedPreference(configTree?.id, {
    onSuccess: async () => {
      await refetchConfTree();
      displaySnackbar('Configuration saved with success', { icon: 'check' });
      setSelectedPreferenceToBeAdded(null);
    },
    onError: () => {
      displaySnackbar('There was an error updating the configuration', { icon: 'danger-light', variant: 'error' });
      setSelectedPreferenceToBeAdded(null);
    },
  });

  const { mutate: removePurpose } = useDeleteSelectedPurpose(configTree?.id, {
    onSuccess: async data => {
      displaySnackbar('Configuration saved with success', { icon: 'check' });
      await refetchConfTree();
      setEntityIDsToBeDeleted(entityIDsToBeDeleted.filter(id => id !== data.data.id));
    },
    onError: (_, selectedPurposeId) => {
      displaySnackbar('There was an error updating the configuration', { icon: 'danger-light', variant: 'error' });
      setEntityIDsToBeDeleted(entityIDsToBeDeleted.filter(id => id !== selectedPurposeId));
    },
  });

  const { mutate: resetSelectedPreference } = useDeleteSelectedPreferences(configTree?.id, {
    onSuccess: async data => {
      await refetchConfTree();
      displaySnackbar('Configuration saved with success', { icon: 'check' });
      const selectedPreferenceIds = data.data.map(selectedPreference => selectedPreference.id);
      setSelectedPurposeIDsToBeReset(selectedPurposeIDsToBeReset.filter(id => !selectedPreferenceIds.includes(id)));
    },
    onError: (_, selectedPreferenceIds) => {
      displaySnackbar('There was an error updating the configuration', { icon: 'danger-light', variant: 'error' });
      setSelectedPurposeIDsToBeReset(selectedPurposeIDsToBeReset.filter(id => !selectedPreferenceIds.includes(id)));
    },
  });

  const { mutate: deleteSelectedPreference } = useDeleteSelectedPreference(configTree?.id, {
    onSuccess: async data => {
      displaySnackbar('Configuration saved with success', { icon: 'check' });
      await refetchConfTree();
      setEntityIDsToBeDeleted(entityIDsToBeDeleted.filter(id => id !== data.data.id));
    },
    onError: (_, selectedPreferenceId) => {
      displaySnackbar('There was an error updating the configuration', { icon: 'danger-light', variant: 'error' });
      setEntityIDsToBeDeleted(entityIDsToBeDeleted.filter(id => id !== selectedPreferenceId));
    },
  });

  /**
   * Handles the start of a dragging preference, this event triggers as soon as a user picks up a preference card.
   * For more details on this event please check: https://docs.dndkit.com/api-documentation/context-provider#ondragstart
   * Note: This method is ignored for unit testing. At the moment of writing JSDOM does not support Pointer events so testing the
   * dragging using RTL is not possible
   * @param {DragStartEvent} event The event that started the dragging
   */
  // istanbul ignore next - drag&drop code can't be unit tested
  const handleDragStart = (event: DragStartEvent) => {
    setDraggingPreference(event.active);
  };

  /**
   * Handles the end of a dragging preference, this event triggers as soon as a user drops a preference card in a purpose.
   * For more details on this event please check: https://docs.dndkit.com/api-documentation/context-provider#ondragend
   * Note: This method is ignored for unit testing. At the moment of writing JSDOM does not support Pointer events so testing the
   * dropping using RTL is not possible. We have moved the logic into a separate util file so that we can test the function separately.
   * @param {DragEndEvent} event The event that started the dragging
   */
  // istanbul ignore next - drag&drop code can't be unit tested
  const handleDragEnd = (event: DragEndEvent) => {
    if (!event?.over) {
      return;
    }

    const preferenceData = event?.active?.data?.current;
    const dropData = event?.over?.data?.current as DropData;

    const isValid = canPreferenceBeIncludedInConfigTree(preferenceData.id, dropData);

    if (isValid) {
      displaySnackbar('Saving...');

      setSelectedPreferenceToBeAdded({
        id: null,
        parent_id: dropData.parentId,
        parent_preference_value_id: dropData.parentPreferenceValueId,
        parent_selected_purpose_id: dropData.parentSelectedPurposeId,
        order: dropData.order,
        preference: preferenceData as any,
        selected_preferences: [],
      } as SelectedPreference);

      createSelectedPreference({
        parent_id: dropData.parentId,
        parent_preference_value_id: dropData.parentPreferenceValueId,
        parent_selected_purpose_id: dropData.parentSelectedPurposeId,
        preference_id: preferenceData.id,
        order: dropData.order,
      });
    }

    setDraggingPreference(null);
  };

  /**
   * Validates that the dropped preference can be included inside the dropped place
   * A preference cannot be dropped if the preference is already at the save level of the tree or
   * if the same preference is a parent (at any level)
   * To do this we grab te selected preference at the same level and the parent id
   * @param {string} preferenceId The dropped preference id
   * @param {DropData} dropData The information of the dragged and dropped preference
   */
  // istanbul ignore next - drag&drop code can't be unit tested
  const canPreferenceBeIncludedInConfigTree = (preferenceId: string, { parentId, parentSelectedPurposeId, parentPreferenceValueId, isParentPurpose }: DropData): boolean => {
    // Checks if self-nesting is occurring by checking for the inclusion of the same preference within the preference
    if (isPreferenceSelfNesting(preferenceId, parentId, configurationMap)) {
      displaySnackbar('Preference can not be nested in itself');
      return false;
    }

    if (isParentPurpose) {
      // Checks if the preference is already added in the parent purpose
      if (configurationMap.preferencesByParentId.get(parentSelectedPurposeId)?.includes(preferenceId)) {
        displaySnackbar('Preference has already been added');
        return false;
      }
    } else {
      // Checks if the preference is already added in the parent value
      if (configurationMap.preferencesByParentId.get(`${parentId}-${parentPreferenceValueId}`)?.includes(preferenceId)) {
        displaySnackbar('Preference has already been added');
        return false;
      }
    }

    return true;
  };

  /**
   * Deletes a purpose from the three.
   * @param {string} purposeId The purpose id to delete
   */
  const processDeletePurposeFromTree = () => {
    displaySnackbar('Saving...');
    removePurpose(deletingPurposeId);
    setEntityIDsToBeDeleted([...entityIDsToBeDeleted, deletingPurposeId]);
    setDeletingPurposeId(undefined);
  };

  /**
   * Deletes a preference from the three.
   * @param {string} purposeId The preference id to delete
   */
  const processDeletePreferenceFromTree = () => {
    displaySnackbar('Saving...');
    deleteSelectedPreference(deletingSelectedPreferenceId);
    setEntityIDsToBeDeleted([...entityIDsToBeDeleted, deletingSelectedPreferenceId]);
    setDeletingSelectedPreferenceId(undefined);
  };

  /**
   * Deletes the selected preferences from a particular purpose
   * @param {string[]} selectedPreferencesIds The list of selected preferences to delete
   */
  const handlePurposeReset = (selectedPreferencesIds: string[]) => {
    displaySnackbar('Saving...');
    resetSelectedPreference(selectedPreferencesIds);
    setSelectedPurposeIDsToBeReset([...selectedPurposeIDsToBeReset, ...selectedPreferencesIds]);
    // we reset the id variable after finishing the operation
    setResetPurposeModalState(false);
  };

  /**
   * Navigates to the purpose page
   */
  const moveToPurposesPage = () => {
    push('/purposes');
  };

  /**
   * This is the modal button handler for purpose reset confirmation
   */
  const confirmResetPurposeAction = () => {
    if (resetCandidateId.length) {
      // we reset the purpose with id `resetCandidateId`
      handlePurposeReset(resetCandidateId);
      toggleModalState();
    }
  };

  /**
   * Toggles the purpose reset modal
   */
  const toggleModalState = () => {
    setResetPurposeModalState(!isResetPurposeModalOpen);
  };

  // purpose list filtering logic
  const purposeSearchFilter = useCallback((purposes: SelectedPurpose[], search: string) => {
    if (search) {
      return purposes.filter(({ purpose }) => {
        return substrIncludes(purpose.description.en, search);
      });
    }
    return purposes;
  }, []);

  const isPreferenceInTree = useCallback(
    preferenceId => {
      return Array.from(configurationMap.selectedPreferenceToPreference.values()).includes(preferenceId);
    },
    [configurationMap],
  );
  useLayoutEffect(() => {
    if (!hasSetInitialShouldScrollToBottom.current && configTree && !isFetchingConfigTree && location?.state?.scrollToBottom === true) {
      scrollToBottomRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
      hasSetInitialShouldScrollToBottom.current = true;
    }
  }, [location?.state?.scrollToBottom, configTree, isFetchingConfigTree, hasSetInitialShouldScrollToBottom]);

  return (
    <>
      <DndContext onDragStart={handleDragStart} onDragEnd={handleDragEnd} collisionDetection={CustomConfigurationTreeCollision}>
        <ResetPurposeModal isOpen={isResetPurposeModalOpen} onOpenChange={toggleModalState} onProceed={confirmResetPurposeAction} />
        <div className="mt-8 mb-s w-full flex">
          <div className="pr-l flex flex-col" style={{ width: 'calc(100% - 430px)' }}>
            {errorLoadingConfTree ? (
              <div className="border-1 border-dashed border-neutral-gray-5 rounded-lg flex-1 flex flex-col items-center justify-center overflow-x-hidden">
                <DidomiIllustration name="preferences-loading-error" className="mb-xs" />
                <div className="font-sans text-body-big font-semibold text-primary-pink-4">There seems to be an error</div>
                <div className="font-sans text-body-big text-primary-blue-6">The Configuration Tree has not been loaded</div>
                <DidomiButton className="mt-s" size="small" data-testid="reload-purposes" onClick={() => refetchConfTree()} iconLeft="reset">
                  Reload
                </DidomiButton>
              </div>
            ) : loadingConfTree ? (
              <>
                <div className="flex mb-7">
                  <div className="flex-1 flex flex-col justify-center ml-xxs max-w-xs">
                    <DidomiSkeleton isLoading={true} className="w-72 h-14" />
                  </div>
                  <div className="flex-1 flex flex-col">
                    <DidomiSkeleton isLoading={true} className="w-56 h-16 ml-auto" />
                  </div>
                </div>
                <LoadingPurposeCard data-testid="loading-purpose-card-0" />
                <LoadingPurposeCard data-testid="loading-purpose-card-1" />
                <LoadingPurposeCard data-testid="loading-purpose-card-2" />
                <LoadingPurposeCard data-testid="loading-purpose-card-3" />
                <LoadingPurposeCard data-testid="loading-purpose-card-4" />
                <LoadingPurposeCard data-testid="loading-purpose-card-5" />
                <LoadingPurposeCard data-testid="loading-purpose-card-6" />
              </>
            ) : hasPurposes ? (
              <>
                <div className="flex mb-7 flex items-center justify-center">
                  <div className="flex flex-col justify-center ml-xxs max-w-xs">
                    <DidomiSearchBar
                      data-testid="config-purpose-search"
                      autoCollapse={false}
                      initialCollapsed={false}
                      value={search}
                      placeholder="Search a purpose by name..."
                      variant="secondary"
                      onValueChange={setSearch}
                    />
                  </div>

                  <div className="flex flex-grow" />

                  <div className="flex">
                    <DidomiSwitch
                      labelVariant="no-box"
                      className="mt-[22px] mr-6"
                      valueSelected={true}
                      valueNotSelected={false}
                      onValueChange={({ detail }) => setShowAllIds(detail)}
                    >
                      Show all IDs
                    </DidomiSwitch>
                  </div>

                  <div className="flex">
                    {!!hasPurposes && canEditConfigurationTree && (
                      <DidomiButton
                        disabled={showAllIds}
                        data-tracking="pmp-start-add-purpose"
                        iconRight="new-create"
                        className="ml-auto"
                        data-cy="add-purpose"
                        onClick={moveToPurposesPage}
                      >
                        Add a purpose
                      </DidomiButton>
                    )}
                  </div>
                </div>
                <PurposesList
                  search={search}
                  purposes={existingPurposes}
                  showAllIds={showAllIds}
                  canEditConfigurationTree={canEditConfigurationTree}
                  entityIDsToBeDeleted={entityIDsToBeDeleted}
                  selectedPurposeIDsToBeReset={selectedPurposeIDsToBeReset}
                  onRemovePurpose={setDeletingPurposeId}
                  onRemovePreference={setDeletingSelectedPreferenceId}
                  onDisplayPreview={setDisplayPurposePreview}
                  onDisplayPreferencePreview={d => setDisplayPreferencePreview(d)}
                  searchFilter={purposeSearchFilter}
                  onReset={id => {
                    setResetCandidateId(id);
                    toggleModalState();
                  }}
                />
              </>
            ) : (
              <GenericEmptyState
                title="Start adding a purpose"
                illustration="start-add-purpose"
                ctaText="Add a purpose"
                ctaDataCy="add-purpose"
                ctaIcon={{
                  position: 'right',
                  icon: 'new-create',
                }}
                className="border-1 border-dashed border-neutral-gray-5 rounded-lg"
                onCtaClick={moveToPurposesPage}
              />
            )}
            <div data-testid="scroll-to-bottom-ref" className="absolute bottom-0" ref={scrollToBottomRef}></div>
          </div>
          <div className={tx`relative w-[430px] opacity-${showAllIds ? '40' : '100'} `}>
            <PreferenceLibrary
              onPreferencePreview={setDisplayPreferencePreview}
              existingPurposes={existingPurposes}
              canEditConfigurationTree={canEditConfigurationTree}
              isPreferenceInTree={isPreferenceInTree}
              disablePreferences={!!selectedPreferenceToBeAdded}
            />
          </div>
        </div>
        {createPortal(
          <DragOverlay
            dropAnimation={{
              duration: 300,
              easing: 'ease-in-out',
            }}
          >
            {
              /* istanbul ignore next */ draggingPreference ? (
                <PreferenceCard
                  data-testid={draggingPreference.id + '-dragging'}
                  id={draggingPreference.id}
                  expanded={draggingPreference?.data?.current?.expanded}
                  expandable={false}
                  isFloatingDrag
                  name={draggingPreference?.data?.current?.name}
                  values={draggingPreference?.data?.current?.values}
                />
              ) : null
            }
          </DragOverlay>,
          document.querySelector('.Didomioverlay-container') || document.body,
        )}
      </DndContext>
      <RemovePurposeFromTreeModal isOpen={!!deletingPurposeId} onCancel={() => setDeletingPurposeId(undefined)} onProceed={() => processDeletePurposeFromTree()} />
      <RemovePreferenceFromTreeModal
        isOpen={!!deletingSelectedPreferenceId}
        onCancel={() => setDeletingSelectedPreferenceId(undefined)}
        onProceed={() => processDeletePreferenceFromTree()}
      />
      <PreviewPurposeModal
        isOpen={!!displayPurposePreview}
        purposeName={displayPurposePreview?.name}
        purposeDescription={displayPurposePreview?.description !== DEFAULT_EMPTY_TRANSLATION ? displayPurposePreview?.description : null}
        onCancel={() => setDisplayPurposePreview(null)}
        onProceed={() => navigateTo('data-manager/purposes')}
        canEdit={canEditConfigurationTree}
      />
      <PreviewPreferenceModal
        isOpen={!!displayPreferencePreview}
        id={displayPreferencePreview?.id}
        name={displayPreferencePreview?.name}
        description={displayPreferencePreview?.description}
        type={displayPreferencePreview?.type}
        values={displayPreferencePreview?.values}
        onCancel={() => setDisplayPreferencePreview(null)}
        onProceed={id => navigateTo(`preference-management/edit-preference/${id}`)}
        canEdit={canEditConfigurationTree}
      />
    </>
  );
};

export { Configuration };
