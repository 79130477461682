import { useCallback, useMemo } from 'react';
import dlv from 'dlv';
import cleanSet from 'clean-set';
import type { ContentEditorFormData, ContentEditorFormInput, ContentEditorForm as ContentEditorFormProps } from '@types';

export const useContentEditorForm = <T extends ContentEditorFormData>(data: T, setData: ContentEditorFormProps<T>['setData'], overrideComponentId?: string) => {
  const handlerCache = useMemo(() => {
    // This is a cache maintained for a form (eg header/footer). Each key corresponds to the field in the data
    // (eg, "logo" in header) and the corresponding value in the cache is the change handler to update the value there.
    // This returns the same instance of onChange handler for the same field. So even across multiple re-renders,
    // the handler returned will be same even when some other input changed in the same form.
    const handleChangeCache: Record<string, (newData: ContentEditorFormInput) => void> = {};
    return (key: string, debounce: boolean = true) => {
      if (!(key in handleChangeCache)) {
        handleChangeCache[key] = (newData: ContentEditorFormInput) => {
          setData(
            currentData => {
              const data = {
                ...currentData,
              };
              if (typeof newData === 'undefined') {
                delete data[key];
                return data;
              }
              return cleanSet(data, key, newData);
            },
            debounce,
            overrideComponentId,
          );
        };
      }
      return handleChangeCache[key];
    };
  }, [setData, overrideComponentId]);

  const getValueAndChangeHandlerFor = useCallback(
    (key: string, debounce: boolean = true) => ({
      value: dlv(data, key),
      onChange: handlerCache(key, debounce),
    }),
    [data, handlerCache],
  );

  return { getValueAndChangeHandlerFor };
};
