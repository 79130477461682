import { sortByText } from '@utils';

type SortOptions = {
  queryParams: Record<string, string>;
  data: any[];
  defaultSort?: string;
};

export const sort = ({ queryParams = {}, data = [], defaultSort = 'created_at' }: SortOptions): any[] => {
  const { sort } = queryParams;
  const sortKeys = sort ? Object.keys(sort) : [];
  const sortBy = sort ? sortKeys[0] : defaultSort;
  const sortDir = sort && sort[sortKeys[0]][0] === 1 ? 'asc' : 'desc';

  return sortByText(data, sortBy, sortDir);
};
