import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useActiveOrganization, useSnackbar } from '@didomi/utility-react';
import { getUpdatedLayoutEntities, snakeToCamelCase } from '@utils';
import { WIDGET_TEMPLATE_ID_TO_WIDGET_DISPLAY } from '@constants';
import { ComponentInput, Languages, ShapesInput } from '@didomi/pmp-generator';
import {
  useConfigTrees,
  useLayoutComponents,
  useLayoutEntities,
  FetchLayoutComponentsAsyncFnType,
  FetchLayoutEntitiesAsyncFnType,
  FetchConfigTreeAsyncFnType,
  useDefaultThemes,
  useLayouts,
  FetchLayoutsAsyncFnType,
  FetchWidgetThemesAsyncFnType,
  FetchWidgetAsyncFnType,
  useWidget,
} from '@hooks';
import { ExtendedComponentsListInput, PaginatedResponse, UpdatedLayoutEntityData } from '@types';
import { useWidgetPreview as useWidgetPreviewShared, UseWidgetPreviewFetchFnResult, UseWidgetPreviewFetchFn } from '@didomi/widgets-utilities';
import { WidgetFormat, WidgetLayoutShape } from '@enums';
import { ConfigTree, Layout, LayoutComponent, LayoutEntity, Widget, WidgetTheme } from '@interfaces';

interface PreviewProps {
  themeId?: string;
  layoutShape?: WidgetLayoutShape;
  customTheme?: WidgetTheme;
  updatedComponents?: ExtendedComponentsListInput;
  widgetPreferences?: UpdatedLayoutEntityData;
  languages?: Languages;
}

const getTheme = (widget: Widget, customTheme: WidgetTheme, defaultThemes: Array<WidgetTheme>, themeId: string) => {
  themeId = themeId || widget?.theme_id;
  customTheme = customTheme || widget?.custom_theme;
  if (customTheme?.id === themeId) {
    return customTheme;
  }

  return defaultThemes.find(({ id }) => id === themeId);
};

/**
 * Fetch the widget infos and layout / components attached to it.
 * @param widgetId
 * @returns
 */
const fetchWidgetInfos = async (
  widgetId: string,
  skipComponentLoad: boolean,
  fetchLayoutComponents: FetchLayoutComponentsAsyncFnType,
  fetchLayoutEntities: FetchLayoutEntitiesAsyncFnType,
  fetchConfigTree: FetchConfigTreeAsyncFnType,
  fetchLayouts: FetchLayoutsAsyncFnType,
  fetchDefaultThemes: FetchWidgetThemesAsyncFnType,
  fetchWidget: FetchWidgetAsyncFnType,
): Promise<{
  configTree: ConfigTree;
  widget: Widget;
  layout: Layout;
  components: LayoutComponent[];
  layoutEntities: LayoutEntity[];
  defaultThemes: WidgetTheme[];
}> => {
  const widgetQuery = fetchWidget(widgetId);
  const layoutQuery = fetchLayouts(widgetId);
  const configTreeQuery = fetchConfigTree();
  const layoutEntitiesQuery = fetchLayoutEntities(widgetId);
  const defaultThemesQuery = fetchDefaultThemes();
  const promises: [
    Promise<PaginatedResponse<ConfigTree>>,
    Promise<Widget>,
    Promise<PaginatedResponse<Layout>>,
    Promise<PaginatedResponse<LayoutEntity>>,
    Promise<PaginatedResponse<WidgetTheme>>,
    Promise<PaginatedResponse<LayoutComponent>>?,
  ] = [configTreeQuery, widgetQuery, layoutQuery, layoutEntitiesQuery, defaultThemesQuery];
  if (!skipComponentLoad) {
    const componentsQuery = fetchLayoutComponents(widgetId);
    promises.push(componentsQuery);
  }

  const [configTreeResult, widgetResult, layoutResult, layoutEntitiesResult, defaultThemesResult, componentsResult] = await Promise.all(promises);

  const configTree = configTreeResult?.data?.[0];
  const widget = widgetResult;
  const layout = layoutResult.data?.[0];
  const layoutEntities = layoutEntitiesResult.data || [];
  const defaultThemes = defaultThemesResult.data || [];
  const components = componentsResult?.data || [];
  return { configTree, widget, layout, components, layoutEntities, defaultThemes };
};

/**
 * Custom hook for preview a widget.
 * @returns An object that contains two functions
 * preview: Open a new tab for previewing a widget.
 *  params:
 *    - widgetId: Widget to preview
 *    - themeId(optional): Selected custom theme / default theme id
 *    - layoutShape(optional): Selected layout shape
 *    - customTheme: Customized theme changed by the user
 *  How it works: This function select which theme will be applied to the widget (see getTheme)
 *  and save into local storage a processed payload that will be used by WidgetPreview page
 *  for rendering the skd iframe. It's the same process used by useIframePreview but
 *  instead of calling directly the iframe message, it saves the data on the local storage.
 *
 * loadPreview: Load the data saved on preview function and call iframe message to render the widget.
 *  params:
 *    - widgetId: Widget id used on preview function. This is used as the sessionStorage key
 *    - iframe: Iframe ref that contains the loaded sdk.
 */
const useWidgetPreview = ({ themeId, layoutShape, customTheme, updatedComponents, widgetPreferences, languages }: PreviewProps = {}) => {
  const { organization } = useActiveOrganization();
  const { fetchAsync: fetchDefaultThemes } = useDefaultThemes();
  const { fetchAsync: fetchConfigTree } = useConfigTrees({ enabled: false });
  const { fetchAsync: fetchWidget } = useWidget(null, { enabled: false });
  const { displaySnackbar } = useSnackbar();
  const history = useHistory();
  const { fetchAsync: fetchLayoutComponents } = useLayoutComponents({ limit: 1000 }, { enabled: false });
  const { fetchAsync: fetchLayoutEntities } = useLayoutEntities({ limit: 1000 }, { enabled: false });
  const { fetchAsync: fetchLayouts } = useLayouts({ limit: 1 }, { enabled: false });

  const fetchFn: UseWidgetPreviewFetchFn = useCallback(
    async (widgetId: string): Promise<UseWidgetPreviewFetchFnResult> => {
      const skipComponentLoad = !!updatedComponents;

      const { configTree, widget, layout, components, layoutEntities, defaultThemes } = await fetchWidgetInfos(
        widgetId,
        skipComponentLoad,
        fetchLayoutComponents,
        fetchLayoutEntities,
        fetchConfigTree,
        fetchLayouts,
        fetchDefaultThemes,
        fetchWidget,
      );

      const theme = getTheme(widget, customTheme, defaultThemes, themeId);

      const authMethod = widget.format === WidgetFormat.HOSTED ? 'email' : null;
      const list = updatedComponents ? Object.values(updatedComponents) : components;
      const previewLanguages = languages || {
        enabled: widget.enabled_languages,
        default: widget.default_language,
      };
      return {
        widget: {
          name: widget.name,
        },
        languages: previewLanguages,
        publicApiKey: organization.public_api_key,
        display: WIDGET_TEMPLATE_ID_TO_WIDGET_DISPLAY[widget.template_id],
        authMethod,
        colors: snakeToCamelCase(theme),
        layoutShape: (layoutShape || widget.layout_shape) as unknown as ShapesInput,
        layout: snakeToCamelCase(layout),
        layoutComponents: list as ComponentInput[],
        layoutEntities: snakeToCamelCase(getUpdatedLayoutEntities(layoutEntities, widgetPreferences)),
        configTree: snakeToCamelCase(configTree),
      };
    },
    [
      updatedComponents,
      fetchLayoutComponents,
      fetchLayoutEntities,
      fetchConfigTree,
      fetchLayouts,
      fetchWidget,
      customTheme,
      themeId,
      languages,
      organization.public_api_key,
      layoutShape,
      widgetPreferences,
      fetchDefaultThemes,
    ],
  );
  return useWidgetPreviewShared(fetchFn, {
    onError: () => {
      displaySnackbar('Preview has not loaded', { title: 'Sorry, an error occurred:', icon: 'danger-light', variant: 'error' });
    },
    redirectToPreview: widgetId => {
      const previewPage = history.createHref({ pathname: `/widgets/${widgetId}/preview` });
      window.open(previewPage, '_blank');
    },
  });
};

export { useWidgetPreview };
