import React from 'react';
import { DidomiIllustration } from '@didomi/ui-atoms-react';

/**
 * PreferenceLibraryEmptyState Component
 */
export const PreferenceLibraryEmptyState = (): JSX.Element => (
  <div className="border-1 border-dashed border-neutral-gray-5 rounded-lg flex-1 flex flex-col items-center justify-center overflow-x-hidden">
    <DidomiIllustration name="empty-preference-library" className="mb-xs" />
    <div className="font-sans text-body-big font-semibold text-primary-blue-4">It looks like</div>
    <div className="font-sans text-body-big text-primary-blue-6">you have no preference</div>
    <div className="font-sans text-body-big text-primary-blue-6">in your library</div>
  </div>
);
