import React, { createContext, useContext, ReactNode, useState, useMemo } from 'react';
import { WidgetLayoutShape } from '@enums';
import { WidgetTheme } from '@interfaces';

type Props = {
  children: ReactNode;
};

export type WidgetPreviewDataContextType = ReturnType<typeof useInit>;

export const WidgetPreviewDataContext = createContext<WidgetPreviewDataContextType>({} as any);

const useInit = () => {
  const [selectedThemeId, setSelectedThemeId] = useState<string | null>(null);
  const [selectedLayoutShape, setSelectedLayoutShape] = useState<WidgetLayoutShape | null>(null);
  const [customTheme, setCustomTheme] = useState<WidgetTheme | null>(null);

  const values = useMemo(
    () => ({
      selectedThemeId,
      setSelectedThemeId,
      selectedLayoutShape,
      setSelectedLayoutShape,
      customTheme,
      setCustomTheme,
    }),
    [selectedThemeId, selectedLayoutShape, customTheme],
  );

  return values;
};

export const WidgetPreviewDataProvider = ({ children }: Props) => {
  const context = useInit();
  return <WidgetPreviewDataContext.Provider value={context}>{children}</WidgetPreviewDataContext.Provider>;
};

export const useWidgetPreviewData = () => {
  return useContext(WidgetPreviewDataContext);
};
