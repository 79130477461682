import React from 'react';
import { DidomiLink, DidomiSwitch } from '@didomi/ui-atoms-react';

interface EditWidgetHeadlessSectionProps {
  widgetHeadless: boolean;
  onWidgetHeadlessChanged: (widgetHeadless: boolean) => void;
}

const EditWidgetHeadlessSection = ({ widgetHeadless, onWidgetHeadlessChanged }: EditWidgetHeadlessSectionProps) => {
  return (
    <div data-testid="edit-widget-headless-section">
      <DidomiSwitch
        value={widgetHeadless ? 'selected' : 'not-selected'}
        onValueChange={e => onWidgetHeadlessChanged(e.detail === 'selected')}
        data-testid="widget-headless"
        verticalAlign="top"
      >
        <div className="mb-xxs">
          <b className="h4">Headless</b>
        </div>
        <span>Build fully customizable widgets with flexible design and behavior settings, while managing content and configuration from the Didomi Console.</span>
        <div className="mt-s">
          <DidomiLink text="Read the developer documentation" href="https://developers.didomi.io/api/widgets/privacy-widgets/headless-widgets" target="_blank" />
        </div>
      </DidomiSwitch>
    </div>
  );
};

export default EditWidgetHeadlessSection;
