import React from 'react';
import { DidomiToggleButton, DidomiToggleButtonOption } from '@didomi/ui-atoms-react';
import { WIDGET_THEME_CONFIG_CONDITIONS } from '../EditWidgetTheme';
import { WidgetFormat, WidgetTemplateId, WidgetThemeConfig } from '@enums';

interface EditWidgetThemeConfigToggleOptionsProps {
  activeThemeConfig: WidgetThemeConfig;
  widgetTemplateId: WidgetTemplateId;
  widgetFormat: WidgetFormat;
  onValueChange: (value: WidgetThemeConfig) => void;
}

const EditWidgetThemeConfigToggleOptions = ({ activeThemeConfig, widgetTemplateId, widgetFormat, onValueChange }: EditWidgetThemeConfigToggleOptionsProps) => {
  return (
    <DidomiToggleButton value={activeThemeConfig} onValueChange={e => onValueChange(e.detail as WidgetThemeConfig)} data-testid="edit-widget-options">
      {WIDGET_THEME_CONFIG_CONDITIONS.basic({ widgetTemplateId, widgetFormat }) && (
        <DidomiToggleButtonOption value={WidgetThemeConfig.BASIC} data-testid="edit-widget-option-basic">
          Basic
        </DidomiToggleButtonOption>
      )}
      {WIDGET_THEME_CONFIG_CONDITIONS.advanced({ widgetTemplateId, widgetFormat }) && (
        <DidomiToggleButtonOption value={WidgetThemeConfig.ADVANCED} data-testid="edit-widget-option-advanced">
          Advanced
        </DidomiToggleButtonOption>
      )}
      {WIDGET_THEME_CONFIG_CONDITIONS.headless({ widgetTemplateId, widgetFormat }) && (
        <DidomiToggleButtonOption value={WidgetThemeConfig.HEADLESS} data-testid="edit-widget-option-headless">
          Headless
        </DidomiToggleButtonOption>
      )}
    </DidomiToggleButton>
  );
};

export default EditWidgetThemeConfigToggleOptions;
