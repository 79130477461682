import React from 'react';
import { DidomiButton, DidomiModal, DidomiModalHeader, DidomiModalActions } from '@didomi/ui-atoms-react';

type RemovePurposeFromTreeModalProps = {
  isOpen?: boolean;
  onCancel?: () => void;
  onProceed?: () => void;
};

/**
 * Remove purpose from tree Modal
 */
const RemovePurposeFromTreeModal = ({ isOpen, onCancel, onProceed }: RemovePurposeFromTreeModalProps): JSX.Element => {
  return (
    <DidomiModal isOpen={isOpen} permanent="true">
      <DidomiModalHeader
        illustrationName="all-widget-update"
        data-testid="remove-purpose-modal-header"
        style={{ whiteSpace: 'pre' }}
        modalSubTitle="Please be careful"
        variant="warning"
        modalTitle={`Removing this purpose will have\nan impact on all your widgets`}
      />
      <DidomiModalActions>
        <DidomiButton variant="secondary" onClick={onCancel}>
          Oops, no thanks
        </DidomiButton>
        <DidomiButton data-tracking="pmp-confirm-remove-purpose-from-tree" onClick={onProceed}>
          Got it, save!
        </DidomiButton>
      </DidomiModalActions>
    </DidomiModal>
  );
};

export { RemovePurposeFromTreeModal };
