import { ACCESS_POLICIES_CONFIG, WIDGET_TEMPLATE_ID_TO_WIDGET_DISPLAY } from '@constants';
import { DidomiDatepicker, DidomiErrorState, DidomiHintbox, DidomiSkeleton } from '@didomi/ui-atoms-react';
import { useActiveOrganization, useHasAccessPolicies } from '@didomi/utility-react';
import { WidgetConfigPreview, WidgetHistory, WidgetHistoryToolbar, WidgetPreviewMode, useWidgetHistory } from '@didomi/widgets-utilities';
import { useWidget, useWidgetDeployments } from '@hooks';
import { SecondaryLayout } from '@layouts';
import React from 'react';
import { useParams } from 'react-router-dom';

const ITEMS_PER_PAGE = 5;

const VersionsHistory = (): JSX.Element => {
  const { hasAccess: canEditWidgets } = useHasAccessPolicies(ACCESS_POLICIES_CONFIG.PMP_WIDGETS_EDITOR);

  const { id: widgetId } = useParams<{ id: string }>();
  const { organization } = useActiveOrganization();

  const { data: widget, error: errorLoadingWidget, isLoading: isLoadingWidget, refetch: refetchWidget } = useWidget(widgetId);
  const {
    widgetDeployments,
    addWidgetDeployments,
    selectedWidgetDeploymentId,
    setSelectedWidgetDeploymentId,
    currentWidgetDeploymentId,
    language,
    setLanguage,
    inspectionMode,
    setInspectionMode,
    dateRange,
    setDateRange,
    config,
    limit,
    skip,
    loadMore,
  } = useWidgetHistory({
    itemsPerPage: ITEMS_PER_PAGE,
  });

  const {
    data: pagedWidgetDeployments,
    error: errorLoadingWidgetDeployments,
    isLoading: isLoadingWidgetDeployments,
    refetch: refetchWidgetDeployments,
  } = useWidgetDeployments(
    { widget_id: widgetId, limit, skip, createdAtRange: dateRange },
    {
      onSuccess: widgetDeploymentsResponse => {
        if (widgetDeploymentsResponse.data?.length > 0) {
          addWidgetDeployments(widgetDeploymentsResponse.data);

          if (!selectedWidgetDeploymentId) {
            setSelectedWidgetDeploymentId(widgetDeploymentsResponse?.data[0]?.id);
          }
        }
      },
    },
  );

  const refetchData = () => {
    if (errorLoadingWidget) {
      refetchWidget();
    }

    if (errorLoadingWidgetDeployments) {
      refetchWidgetDeployments();
    }
  };

  const hasError = errorLoadingWidget || errorLoadingWidgetDeployments;
  if (hasError) {
    return (
      <DidomiErrorState className="h-full" illustrationName="content-loading-error" actionName="Reload" onActionClick={() => refetchData()} actionIcon="reset">
        <div slot="title">It seems to be an error</div>
        <div slot="description">The widget cannot be loaded</div>
      </DidomiErrorState>
    );
  }

  const Header = () => (
    <div slot="header-title" className="flex items-center">
      <DidomiSkeleton className="w-[220px] h-[51px]" isLoading={isLoadingWidget} data-testid={`widget-history-header-loading-${isLoadingWidget}`}>
        <h3 className="font-serif text-h3 font-h3 leading-h3 font-bold text-primary-blue-6 whitespace-nowrap" data-testid="widget-history-name">
          {widget?.name}
        </h3>
        <div className="flex">
          <span className="text-[10px] font-semibold text-primary-blue-6">Widget ID&nbsp;</span>
          <span className="text-[10px] text-secondary-cobalt-blue-3" data-testid="widget-history-id">
            {widgetId}
          </span>
        </div>
      </DidomiSkeleton>
    </div>
  );

  return (
    <SecondaryLayout
      backPath={canEditWidgets ? `/widgets/edit/${widgetId}` : '/widgets'}
      backText={canEditWidgets ? 'Back to widget' : 'Back to widgets'}
      fullWidth
      headerChildren={<Header />}
      headerRightChildren={
        <div className="w-max-[174px]">
          <DidomiDatepicker variant="modern" hideErrorMessage={true} range={true} showClearButton={true} onValueChange={value => setDateRange(value.detail)} />
        </div>
      }
    >
      <div className="w-full h-full relative">
        <div className="flex gap-xl absolute inset-0">
          <div className="flex flex-col gap-xs flex-1">
            <DidomiSkeleton isLoading={!config} className="rounded-[16px]">
              <WidgetHistoryToolbar languages={config?.languages?.enabled} activeLanguage={language} onLanguageChange={setLanguage} onInspectionModeChange={setInspectionMode} />
            </DidomiSkeleton>

            {config?.headless && (
              <DidomiHintbox data-testid="hintbox-headless" iconName="warning" variant="warning">
                The preview of your widget might not accurately reflect the configuration of the headless widget as implemented by your technical team.
              </DidomiHintbox>
            )}

            <WidgetConfigPreview
              key={selectedWidgetDeploymentId} // force the SDK to re-render the widget with the new config
              apiKey={organization.public_api_key}
              previewURL={`${CONFIG.previewURL}/assets/preview.html`}
              widgetId={'preview-container'}
              isLoading={!config}
              showEmptyState={!selectedWidgetDeploymentId && !isLoadingWidgetDeployments}
              components={config ? config.components : {}}
              mode={inspectionMode ? WidgetPreviewMode.Versioning : WidgetPreviewMode.NonInteractive}
              display={WIDGET_TEMPLATE_ID_TO_WIDGET_DISPLAY?.[widget?.template_id]}
              language={language}
            />
          </div>

          <div className="h-full w-[432px]">
            <WidgetHistory
              widgetDeployments={widgetDeployments}
              currentWidgetDeploymentId={currentWidgetDeploymentId}
              selectedWidgetDeploymentId={selectedWidgetDeploymentId!}
              isLoading={isLoadingWidgetDeployments && widgetDeployments.length === 0}
              isFetching={isLoadingWidgetDeployments && widgetDeployments.length !== 0}
              hideLoadButton={widgetDeployments?.length === pagedWidgetDeployments?.total}
              onLoad={loadMore}
              onSelectionChange={setSelectedWidgetDeploymentId}
            />
          </div>
        </div>
      </div>
    </SecondaryLayout>
  );
};

export { VersionsHistory };
