import { Server } from 'miragejs';
import { preferenceRoutes } from './preferences/routes';
import { domainsRoutes } from './domains/routes';
import { purposesRoutes } from './purposes/routes';
import { configTreeRoutes } from './configTree/routes';
import { widgetsRoutes } from './widgets/routes';
import { widgetDeploymentsRoutes } from './widgetsDeployments/routes';
import { archivedWidgetsRoutes } from './archivedWidgets/routes';
import { widgetThemesRoutes } from './widgetThemes/routes';
import { layoutComponentsRoutes } from './layoutComponents/routes';
import { layoutEntitiesRoutes } from './layoutEntities/routes';
import { layoutsRoutes } from './layouts/routes';

const allRoutes = {
  ...preferenceRoutes,
  ...domainsRoutes,
  ...purposesRoutes,
  ...configTreeRoutes,
  ...widgetsRoutes,
  ...widgetDeploymentsRoutes,
  ...archivedWidgetsRoutes,
  ...layoutComponentsRoutes,
  ...layoutEntitiesRoutes,
  ...layoutsRoutes,
  ...widgetThemesRoutes,
};

export const setupRoutes = (server: Server) => {
  Object.entries(allRoutes).forEach(([path, methods]) => {
    Object.entries(methods).forEach(([method, route]) => {
      server[method](path, route);
    });
  });
};
