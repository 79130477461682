import { Factory } from 'miragejs';
import { devOrganizationId } from '@constants';
import { generateRandomId } from '@utils';

const widgetDeployment = Factory.extend({
  id() {
    return generateRandomId('widget-deployment');
  },
  organization_id: devOrganizationId,
  config: { test: 1 },
});

export default { widgetDeployment };
