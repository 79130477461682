import React, { useState } from 'react';
import { PreferenceCard } from '../index';
import { PreferenceCardProps } from '../PreferenceCard/PreferenceCard';
import { useDraggable } from '@dnd-kit/core';

/**
 * Draggable Preference card component
 * This component allows the dragging of preference cards from the left panel into the Configuration Tree
 * More details on the use useDraggable on https://docs.dndkit.com/api-documentation/draggable
 */
const DraggablePreferenceCard = (props: PreferenceCardProps): JSX.Element => {
  const [expanded, setExpanded] = useState<boolean>(props.expanded || true);
  const { attributes, listeners, setNodeRef, isDragging } = useDraggable({
    id: props.id,
    data: {
      id: props.preferenceId,
      name: props.name,
      values: props.values,
      expanded,
    },
  });

  return props.canEditConfigurationTree ? (
    <PreferenceCard
      data-tracking="pmp-drag-and-drop-preference"
      ref={setNodeRef}
      {...props}
      {...attributes}
      {...listeners}
      isDragging={isDragging}
      onExpansionChange={e => setExpanded(e)}
    />
  ) : (
    <PreferenceCard data-tracking="pmp-drag-and-drop-preference" {...props} onExpansionChange={e => setExpanded(e)} />
  );
};

export { DraggablePreferenceCard };
