import React from 'react';
import { InAppHelper } from '@components';

import PurposeEndUserImage from '../../assets/illustrations/helpers/purpose-end-user.png';

export const AddPurposeHelper = () => {
  return (
    <InAppHelper title="What is a purpose?">
      <h3 className="h3 font-normal">
        A purpose is <strong className="text-primary-blue-4">WHY</strong> a company/organization processes personal data.
      </h3>
      <figure className="mt-s">
        <img src={PurposeEndUserImage} alt="Rendering of purpose to end user" />
      </figure>
      <p className="mt-s pb-xxs">
        Personal data a company/organization may process <strong>depends on the legal reason for processing a personal and the intended use.</strong>
      </p>
      <h3 className="h3 mt-m">
        When a company obtains its clients’ personal data, <strong className="text-primary-blue-4">it should explain in clear and plain language:</strong>
      </h3>
      <ul className="list-disc mt-s pl-s">
        <li className="pb-xxs">Why it needs the data</li>
        <li className="pb-xxs">How it will be using it</li>
        <li>How long it intends to keep it</li>
      </ul>
      <p className="mt-m font-normal">
        The processing should be tailored in a way <strong>that respects the key data protection principles</strong>.
      </p>
    </InAppHelper>
  );
};
