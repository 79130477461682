import { WidgetPreviewMode, WidgetTemplateId } from '@enums';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

const UrlFragmentToWidgetPreviewMode: { [key: string]: WidgetPreviewMode | ((widgetTemplateId: WidgetTemplateId) => WidgetPreviewMode) } = {
  'look-and-feel': WidgetPreviewMode.LookAndFeel,
  'content-editor': (widgetTemplateId: WidgetTemplateId) => {
    /**
     * The `content-editor-no-selection` is used when we only have one component selectable in the widget.
     * Currently it is the case for our `sections` template which contains by default one section when created via the SPA.
     */
    if (widgetTemplateId === WidgetTemplateId.SECTIONS) {
      return WidgetPreviewMode.ContentEditorNoSelection;
    }
    return WidgetPreviewMode.ContentEditor;
  },
  hosting: WidgetPreviewMode.Hosting,
  'purposes-and-preferences': WidgetPreviewMode.Preferences,
  embedding: WidgetPreviewMode.Embedding,
};

export const useGetWidgetMode: (widgetTemplateId: WidgetTemplateId) => WidgetPreviewMode | undefined = (widgetTemplateId: WidgetTemplateId) => {
  const location = useLocation();
  const mode = useMemo(() => {
    const fragment = location.pathname.split('/').pop();
    const mode = UrlFragmentToWidgetPreviewMode[fragment];
    return typeof mode === 'function' ? mode(widgetTemplateId) : mode;
  }, [location.pathname, widgetTemplateId]);
  return mode;
};
