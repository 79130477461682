import React, { useCallback } from 'react';
import { DidomiSwitch } from '@didomi/ui-atoms-react';
import { DidomiElementOptions } from '@didomi/pmp-generator';

type DidomiSwitchProps = React.ComponentProps<typeof DidomiSwitch>;

interface ContentEditorSwitchProps extends Omit<DidomiSwitchProps, 'onChange' | 'onValueChange'> {
  'data-testid'?: string;
  value?: DidomiElementOptions;
  onChange(newValue?: DidomiElementOptions): void;
  children?: React.ReactNode;
}

export const ContentEditorSwitch = ({ 'data-testid': dataTestid, value, onChange, children, ...restProps }: ContentEditorSwitchProps) => {
  const handleValueChange = useCallback(
    (ev: CustomEvent<boolean>) => {
      onChange({
        isShown: ev.detail,
      });
    },
    [onChange],
  );
  return (
    <DidomiSwitch
      data-testid={dataTestid}
      hideErrorMessage
      labelVariant="no-box"
      value={!!value?.isShown}
      valueSelected
      valueNotSelected={false}
      onValueChange={handleValueChange}
      data-skeleton
      {...restProps}
    >
      {children}
    </DidomiSwitch>
  );
};
