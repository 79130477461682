import { selectedPreferences } from './selectedPreferencesMocks';

export const configTreeRoutes = {
  '/config-trees': {
    get: (schema: any, request) => {
      const organizationId = request.queryParams.organization_id;
      const limit = parseInt(request.queryParams?.$limit || 100);
      const skip = parseInt(request.queryParams?.$skip || 0);
      const total = schema.configTrees.all().models.length;

      const configTree = schema.configTrees.findBy({ organization_id: organizationId });

      const purposesModels = schema.selectedPurposes.find(configTree.selectedPurposeIds).models;

      const selectedPurposes = purposesModels.map(pM => ({
        ...pM.attrs,
        purpose: schema.purposes.findBy({ id: pM.purpose_id })?.attrs,
        selected_preferences: selectedPreferences(schema, pM.attrs.selectedPreferenceIds).map(sP => ({ ...sP, parent_selected_purpose_id: pM.attrs.id })),
      }));

      return {
        data: [
          {
            organization_id: organizationId,
            id: configTree.id,
            selected_purposes: selectedPurposes,
          },
        ],
        limit,
        skip,
        total,
      };
    },
  },
  '/config-trees/:config_tree_id/selected-purposes': {
    post: (schema: any, request) => {
      const organizationId = request.queryParams.organization_id;
      const configTreeId = request.params.id;
      const purposeId = request.params.purpose_id;
      const order = request.params.order;
      const parsedBody = JSON.parse(request.requestBody);
      return schema.selectedPurposes.create({
        ...parsedBody,
        config_tree_id: configTreeId,
        organization_id: organizationId,
        purpose_id: purposeId,
        order: order,
      }).attrs;
    },
  },
  '/config-trees/:config_tree_id/selected-purposes/:id': {
    delete: (schema: any, request) => {
      const entity = schema.selectedPurposes.find(request.params.id);
      entity.destroy();

      return entity.attrs;
    },
  },
  '/config-trees/:config_tree_id/selected-preferences': {
    post: (schema: any, request) => {
      const organizationId = request.queryParams.organization_id;
      const configTreeId = request.params.config_tree_id;
      const parsedBody = JSON.parse(request.requestBody);
      return schema.selectedPreferences.create({
        ...parsedBody,
        config_tree_id: configTreeId,
        organization_id: organizationId,
      }).attrs;
    },
    delete: (schema: any, request) => {
      const selectedPreferenceIds = Object.entries(request.queryParams)
        .filter(([key]) => key.startsWith('id'))
        .map(([, value]) => value);
      const selectedPreferences = schema.selectedPreferences.find(selectedPreferenceIds);
      selectedPreferences.models.forEach(selectedPreference => selectedPreference.destroy());
      return selectedPreferences.models.map(selectedPreference => selectedPreference.attrs);
    },
  },
  '/config-trees/:config_tree_id/selected-preferences/:id': {
    delete: (schema: any, request) => {
      const selectedPreferenceId = request.params.id;
      const selectedPreference = schema.selectedPreferences.findBy({ id: selectedPreferenceId });
      selectedPreference.destroy();
      return selectedPreference.attrs;
    },
  },
};
