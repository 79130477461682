import React from 'react';
import { DidomiDrawer, DidomiDrawerContent, DidomiHintbox } from '@didomi/ui-atoms-react';
import { useTranslationContext } from '@didomi/widgets-utilities';
import { MissingTranslationLanguageCard } from './components/MissingTranslationLanguageCard';
import { Language } from '@didomi/pmp-generator';

export const MissingTranslationDrawer = (): JSX.Element | null => {
  const { missingTranslations, isMissingTranslationDrawerOpen, setMissingTranslationDrawerOpen } = useTranslationContext();

  if (!missingTranslations || Object.keys(missingTranslations).length === 0) {
    return null;
  }

  const missingLanguages = Object.entries(missingTranslations);

  return (
    <DidomiDrawer variant="action-required" data-testid="missing-translation-drawer" isOpen={isMissingTranslationDrawerOpen} onClose={() => setMissingTranslationDrawerOpen(false)}>
      <DidomiDrawerContent>
        <h2 className="h2 font-semibold text-primary-blue-6 mt-m mb-10">Missing languages</h2>
        <DidomiHintbox contentAlign="start" variant="neutral" headerIconName="speaker-half">
          To add the missing translations in your widget, click on the Add translation button
        </DidomiHintbox>
        <div className="mt-10">
          {missingLanguages.map(([missingLanguage, missingTranslations]) => (
            <MissingTranslationLanguageCard key={`missing-translation-card-${missingLanguage}`} language={missingLanguage as Language} missingTranslations={missingTranslations} />
          ))}
        </div>
      </DidomiDrawerContent>
    </DidomiDrawer>
  );
};
