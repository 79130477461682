import { Factory } from 'miragejs';
import { generateRandomId } from '@utils';
import { devOrganizationId } from '@constants';
import { WidgetFormat, WidgetLayoutShape, WidgetPublicationStatus, WidgetTemplateId } from '@enums';
import { Language } from '@didomi/pmp-generator';

const widget = Factory.extend({
  id() {
    return generateRandomId('widget');
  },
  name(i) {
    return `Widget ${i}`;
  },
  organization_id: devOrganizationId,
  publication_status: WidgetPublicationStatus.DRAFT,
  custom_theme_id: 'custom',
  template_id: WidgetTemplateId.FULL_PREFERENCE_CENTER,
  layout_shape: WidgetLayoutShape.ROUNDED,
  format: WidgetFormat.EMBEDDABLE,
  theme_id: 'custom',
  enabled_languages: [Language.EN],
  default_language: Language.EN,
  headless: false,
  created_at: new Date().toISOString(),
  updated_at: new Date().toISOString(),
});

export default { widget };
