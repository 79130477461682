import { AxiosError } from 'axios';
import { PaginatedResponse } from '@types';
import { useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import { apiRoutes } from '@constants';
import { useAxiosWithLogOut } from '../useAxiosWithInterceptors.hook';
import { ErrorResponse, WidgetTheme } from '@interfaces';
import { useCallback, useMemo } from 'react';

export interface WidgetThemesParams {
  organization_id: string | null;
}

export type FetchWidgetThemesAsyncFnType = () => Promise<PaginatedResponse<WidgetTheme>>;

export const useWidgetThemes = ({ organization_id }: WidgetThemesParams, options?: UseQueryOptions<PaginatedResponse<WidgetTheme>>) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const queryClient = useQueryClient();
  const queryKey = 'widgetThemes';

  const params = useMemo(() => ({ organization_id }), [organization_id]);

  const fetch = useCallback(async () => {
    const response = await axiosWithInterceptors.get<PaginatedResponse<WidgetTheme>>(apiRoutes.widgetThemes, {
      params,
    });
    return response.data;
  }, [axiosWithInterceptors, params]);

  const fetchAsync: FetchWidgetThemesAsyncFnType = useCallback(() => {
    return queryClient.fetchQuery({
      queryKey: [queryKey, params],
      queryFn: () => fetch(),
    });
  }, [queryClient, params, fetch]);

  return {
    ...useQuery<PaginatedResponse<WidgetTheme>, AxiosError<ErrorResponse>>([queryKey, params], () => fetch(), options),
    fetchAsync,
  };
};
