import { keyframes, animation } from '@twind/core';

export const createFadeInAnimation = () => {
  const fadeInAnimation = keyframes({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  });

  return animation('400ms ease in', fadeInAnimation);
};

export const createSlideToLeftAnimation = () => {
  const slideToLeftAnimation = keyframes({
    from: {
      opacity: 0,
      transform: 'translate3d(1rem, 0, 0)',
    },
    to: {
      opacity: 1,
      transform: 'translate3d(0, 0, 0)',
    },
  });

  return animation('300ms ease in', slideToLeftAnimation);
};

export const createSlideToDownAnimation = () => {
  const keyframe = keyframes({
    from: {
      opacity: 0,
      transform: 'translate3d(0, -100%, 0)',
    },
    to: {
      opacity: 1,
      transform: 'translate3d(0, 0, 0)',
    },
  });

  return animation('600ms ease in forwards', keyframe);
};

export const createSlideToUpAnimation = () => {
  const keyframe = keyframes({
    from: {
      opacity: 0,
      transform: 'translate3d(0, 100%, 0)',
    },
    to: {
      opacity: 1,
      transform: 'translate3d(0, 0, 0)',
    },
  });

  return animation('600ms ease in forwards', keyframe);
};
