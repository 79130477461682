import React from 'react';
import { DidomiTextInput } from '@didomi/ui-atoms-react';
import { ContentEditorHintboxWrapper, ContentEditorHintboxWrapperProps } from './ContentEditorHintboxWrapper';
import { ContentEditorTextInputProps } from './ContentEditorTextInput';
import { Translations } from '@didomi/pmp-generator';
import { useContentEditorTranslatedTextInput } from '@hooks';

interface ContentEditorTranslatedInputProps extends Omit<ContentEditorTextInputProps, 'value' | 'onChange'>, ContentEditorHintboxWrapperProps {
  'data-testid'?: string;
  value?: Translations;
  onChange?: (newValue?: Translations | string) => void;
}

export const ContentEditorTranslatedInput = ({ 'data-testid': dataTestId, hint, hasBottomMargin, value, onChange, ...restProps }: ContentEditorTranslatedInputProps) => {
  const {
    value: uiValue,
    onChange: handleValueChange,
    onFocusChange: handleValueOnFocusChange,
  } = useContentEditorTranslatedTextInput({ content: value }, newValue => {
    onChange(newValue?.content);
  });

  return (
    <ContentEditorHintboxWrapper hint={hint} hasBottomMargin={hasBottomMargin}>
      <DidomiTextInput
        data-testid={dataTestId}
        hideErrorMessage
        {...restProps}
        value={uiValue}
        onValueChange={handleValueChange}
        onFocusChange={handleValueOnFocusChange}
        data-skeleton
        className="w-full"
      />
    </ContentEditorHintboxWrapper>
  );
};
