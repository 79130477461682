import React from 'react';
import { Language } from '@didomi/pmp-generator';
import { supportedLanguages } from '@didomi/helpers';
import { MissingTranslation } from '@types';
import { MissingTranslationCardComponent } from './MissingTranslationCardComponent';

interface MissingTranslationCardProps {
  language: Language;
  missingTranslations: MissingTranslation[];
}

export const MissingTranslationLanguageCard = ({ language, missingTranslations }: MissingTranslationCardProps): JSX.Element => {
  const supportedLanguage = supportedLanguages.find(supportedLanguage => supportedLanguage.code === language.toString());
  return (
    <div className="mb-11">
      <h4 className="text-body-normal font-semibold text-primary-blue-6 mb-3">{supportedLanguage.longName}</h4>
      {missingTranslations.map((missingTranslation, index) => (
        <MissingTranslationCardComponent key={`${missingTranslation.componentId}-${index}`} missingTranslation={missingTranslation} />
      ))}
    </div>
  );
};
