import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { AxiosResponse, AxiosError } from 'axios';
import { useActiveOrganization } from '@didomi/utility-react';
import { apiRoutes } from '@constants';
import { SelectedPurpose } from '@interfaces';
import { useAxiosWithLogOut } from '../useAxiosWithInterceptors.hook';

export type CreateSelectedPurpose = {
  purpose_id: string;
  order: number;
};

export const useCreateSelectedPurpose = (configTreeId: string, options?: UseMutationOptions<AxiosResponse<SelectedPurpose>, AxiosError, CreateSelectedPurpose>) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();
  const queryClient = useQueryClient();

  const post = async (payload: CreateSelectedPurpose) =>
    axiosWithInterceptors.post(
      apiRoutes.selectedPurposes(configTreeId),
      {
        purpose_id: payload.purpose_id,
        order: payload.order,
      },
      { params: { organization_id: organizationId } },
    );

  return useMutation<AxiosResponse<SelectedPurpose>, AxiosError, CreateSelectedPurpose>(post, {
    ...options,
    onSuccess: (createSelectedPurpose: AxiosResponse<SelectedPurpose>, variables, context) => {
      queryClient.invalidateQueries(['config-trees']);

      if (options.onSuccess) {
        options.onSuccess(createSelectedPurpose, variables, context);
      }
    },
  });
};
