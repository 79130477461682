import React from 'react';
import { DidomiSaveOptions } from '@didomi/pmp-generator';
import { Icons } from '@didomi/ui-foundation';
import { ContentEditorSection } from './ContentEditorSection';
import { ContentEditorTextarea, ContentEditorButtonInput } from './components';
import { ContentEditorForm as ContentEditorFormProps } from '@types';
import { useContentEditorForm } from '@hooks';

export const ContentEditorSaveForm = ({ data, setData }: ContentEditorFormProps<DidomiSaveOptions>) => {
  const { getValueAndChangeHandlerFor } = useContentEditorForm<DidomiSaveOptions>(data, setData);

  return (
    <ContentEditorSection data-testid="content-editor-save" title="Save card">
      <ContentEditorTextarea
        hint={{
          icon: 'speaker-half' as Icons,
          title: 'Pending changes',
          description: 'This message will appear when a user has made changes and need to save them.',
        }}
        label="Save card title"
        placeholder="You have {numberOfPendingConsent} changes pending, do you want to save?"
        rows={3}
        data-testid="content-editor-save-title"
        {...getValueAndChangeHandlerFor('guidelineText')}
      />
      <ContentEditorButtonInput data-testid="content-editor-save-button" label="Button Label" placeholder="Save my changes" {...getValueAndChangeHandlerFor('saveButton')} />
    </ContentEditorSection>
  );
};
