import { DidomiHintbox, DidomiIcon, DidomiSelectionCardItem, DidomiThemingCardOption } from '@didomi/ui-atoms-react';
import React, { useRef, useState } from 'react';
import { THEME_LAYOUT_OPTIONS } from './widgetThemeConstants';
import { CollapsibleCard } from '@components';
import { useDefaultThemes } from '@hooks';
import { WidgetThemeCustomColor } from './WidgetThemeCustomColor';
import { WidgetLayoutShape, WidgetTemplateId } from '@enums';
import { WidgetTheme } from '@interfaces';
import { useWidgetPreviewData } from '@contexts';
import { DidomiThemingCardOptionCustomEvent } from '@didomi/ui-atoms';

type AllowedColorThemeIcons = 'color-theme-default' | 'color-theme-lagoon' | 'color-theme-sunset' | 'color-theme-ocean' | 'color-theme-abyss';

interface EditWidgetBasicSectionProps {
  widgetTemplateId: WidgetTemplateId;
}

const EditWidgetBasicSection = ({ widgetTemplateId }: EditWidgetBasicSectionProps) => {
  const { defaultThemes } = useDefaultThemes();
  const bottomDiv = useRef(null);

  const { setSelectedLayoutShape, customTheme, selectedThemeId, setSelectedThemeId, selectedLayoutShape } = useWidgetPreviewData();

  const isCustomThemeSelected = customTheme?.id === selectedThemeId;
  const [isCustomThemePanelCollapsed, setCustomThemePanelCollapsed] = useState<boolean>(!isCustomThemeSelected);

  const handleLayoutSelect = (ev: DidomiThemingCardOptionCustomEvent<WidgetLayoutShape>) => {
    setSelectedLayoutShape(ev.detail);
  };

  const handleThemeSelect = (theme: WidgetTheme, fromGlobalThemeCard = false) => {
    setSelectedThemeId(theme.id);
    if (fromGlobalThemeCard) {
      setCustomThemePanelCollapsed(true);
    }
  };

  const scrollToBottom = () => {
    setTimeout(() => {
      if (bottomDiv?.current?.scrollIntoView) {
        bottomDiv.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, 200);
  };

  return (
    <div data-testid="edit-widget-basic-section">
      <DidomiHintbox className="flex" titleText="Keep close to your brand identity!" headerIconName="edit-half" contentAlign="start" variant="info">
        Easily customize your widget with shapes and color themes.
      </DidomiHintbox>

      <CollapsibleCard
        contentId="layout-shape"
        data-testid="layout-shape"
        title="Layout shape"
        description="Choose the type of shape you want to apply to the content of your Preference Center. Layout shapes are applied to cards, containers and buttons."
      >
        <div className="grid grid-cols-3 gap-xxs mt-xs" role="radiogroup" aria-describedby="theme-layout-label">
          <span className="sr-only" id="theme-layout-label">
            Theme layout options
          </span>
          {THEME_LAYOUT_OPTIONS.map(option => (
            <DidomiThemingCardOption
              data-tracking={`pmp-look-feel-shape-${option.value}`}
              data-skeleton
              data-testid={`layout-card-${option.value}`}
              key={option.value}
              selected={selectedLayoutShape === option.value}
              {...option}
              value={`${option.value}`}
              onSelectOption={handleLayoutSelect}
            />
          ))}
        </div>
      </CollapsibleCard>

      <CollapsibleCard
        contentId="layout-colors"
        data-testid="layout-colors"
        title="Layout colors"
        description="Choose a color theme close to your brand identity or customize your own theme."
      >
        <div className="mt-xs mb-s" role="radiogroup" aria-describedby="theme-color-label">
          <span className="sr-only" id="theme-color-label">
            Choose preset themes
          </span>
          <div data-skeleton className="grid grid-cols-2 gap-xxs w-full">
            {defaultThemes.map(theme => (
              <DidomiSelectionCardItem
                data-tracking={`pmp-look-feel-theme-${theme?.name}`}
                data-testid={`theme-card-${theme?.name}`}
                key={theme?.id}
                value={theme?.name}
                selected={theme?.id === selectedThemeId}
                onSelectCard={() => handleThemeSelect(theme, true)}
              >
                <div className="flex justify-between items-center">
                  <span className="capitalize">{theme?.name}</span>
                  <DidomiIcon name={`color-theme-${theme?.name}` as AllowedColorThemeIcons} />
                </div>
              </DidomiSelectionCardItem>
            ))}
          </div>
        </div>
        <div className="border-t border-dashed border-neutral-gray-5 pt-s mb-1">
          <WidgetThemeCustomColor
            onThemeChange={handleThemeSelect}
            widgetTemplateId={widgetTemplateId}
            onCardToggle={(event: CustomEvent<boolean>) => {
              setCustomThemePanelCollapsed(event.detail);
              if (!event.detail) {
                scrollToBottom();
              }
            }}
            isCollapsed={isCustomThemePanelCollapsed}
          />
        </div>
      </CollapsibleCard>
      <div ref={bottomDiv}></div>
    </div>
  );
};

export default EditWidgetBasicSection;
