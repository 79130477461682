import React, { useState, useEffect } from 'react';
import { WidgetFormat, WidgetTemplateId, WidgetThemeConfig } from '@enums';
import { DidomiRightPanel } from '@didomi/ui-atoms-react';
import EditWidgetThemeConfig from './components/EditWidgetThemeConfig';
import EditWidgetThemeConfigToggleOptions from './components/EditWidgetThemeConfigToggleOptions';

interface EditWidgetThemeProps {
  widgetTemplateId: WidgetTemplateId;
  widgetFormat: WidgetFormat;
  widgetHeadless: boolean;
  onWidgetHeadlessChanged: (widgetHeadless: boolean) => void;
}

export const WIDGET_THEME_CONFIG_CONDITIONS: Record<WidgetThemeConfig, ({ widgetTemplateId, widgetFormat }) => boolean> = {
  [WidgetThemeConfig.BASIC]: ({ widgetTemplateId }) => [WidgetTemplateId.FULL_PREFERENCE_CENTER, WidgetTemplateId.SECTIONS_AND_SAVE].includes(widgetTemplateId),
  [WidgetThemeConfig.ADVANCED]: ({ widgetTemplateId }) => [WidgetTemplateId.SECTIONS].includes(widgetTemplateId),
  [WidgetThemeConfig.HEADLESS]: ({ widgetFormat }) => widgetFormat === WidgetFormat.EMBEDDABLE,
};

const getActiveThemeConfig = (widgetTemplateId: WidgetTemplateId, widgetFormat: WidgetFormat) => {
  for (const key of Object.keys(WIDGET_THEME_CONFIG_CONDITIONS)) {
    if (WIDGET_THEME_CONFIG_CONDITIONS[key]({ widgetTemplateId, widgetFormat })) {
      return key as WidgetThemeConfig;
    }
  }
};

const totalWidgetThemeConfigTabs = (widgetTemplateId: WidgetTemplateId, widgetFormat: WidgetFormat) => {
  return Object.keys(WIDGET_THEME_CONFIG_CONDITIONS).filter(key => WIDGET_THEME_CONFIG_CONDITIONS[key]({ widgetTemplateId, widgetFormat })).length;
};

/**
 * Edit Widget Theme on Look and Feel Page
 */
const EditWidgetTheme = ({ widgetTemplateId, widgetFormat, widgetHeadless, onWidgetHeadlessChanged }: EditWidgetThemeProps): JSX.Element => {
  const [activeThemeConfig, setActiveThemeConfig] = useState<WidgetThemeConfig | null>(null);
  useEffect(() => {
    if (widgetTemplateId !== null && widgetFormat !== null && widgetHeadless !== null && !activeThemeConfig) {
      if (widgetHeadless) {
        setActiveThemeConfig(WidgetThemeConfig.HEADLESS);
      } else {
        setActiveThemeConfig(getActiveThemeConfig(widgetTemplateId, widgetFormat));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgetTemplateId, widgetFormat, widgetHeadless]);

  const shouldRenderWidgetTabs = totalWidgetThemeConfigTabs(widgetTemplateId, widgetFormat) > 1;

  return (
    <DidomiRightPanel titleText="Look &#38; Feel" showCreateButton={false} className="h-full w-[430px]">
      <div slot="header-content">
        {shouldRenderWidgetTabs && (
          <EditWidgetThemeConfigToggleOptions
            activeThemeConfig={activeThemeConfig}
            widgetTemplateId={widgetTemplateId}
            widgetFormat={widgetFormat}
            onValueChange={setActiveThemeConfig}
          />
        )}
      </div>

      <div slot="content" className="h-full">
        <div data-testid="widget-theme-content" className="flex flex-col h-full">
          <EditWidgetThemeConfig
            activeThemeConfig={activeThemeConfig}
            widgetTemplateId={widgetTemplateId}
            widgetHeadless={widgetHeadless}
            onWidgetHeadlessChanged={onWidgetHeadlessChanged}
          />
        </div>
      </div>
    </DidomiRightPanel>
  );
};

export { EditWidgetTheme };
