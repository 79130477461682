import * as Yup from 'yup';

const SUBDOMAIN_MAX_LENGTH = 63;
const DOMAIN_MAX_LENGTH = 255;

const isAlphanumeric = (letter: string): boolean => {
  const isNumber = new RegExp('[0-9]');
  const isLetter = new RegExp('[a-zA-Z]');
  return isNumber.test(letter) || isLetter.test(letter);
};

export const SUBDOMAIN_VALIDATOR = Yup.string()
  .required('Oops, subdomain cannot be empty.')
  .max(SUBDOMAIN_MAX_LENGTH, `Oops, subdomain cannot exceed ${SUBDOMAIN_MAX_LENGTH} characters.`)
  .test('length-with-domain', `Oops, entire hostname cannot exceed ${DOMAIN_MAX_LENGTH} characters.`, (value: string) => {
    if (CONFIG.hostingDomain.length + value.length > DOMAIN_MAX_LENGTH) {
      return false;
    }
    return true;
  })
  .test('start-end-with-alphanum', 'Oops, subdomain must start and end with a letter or number.', (value: string) => {
    return isAlphanumeric(value.charAt(0)) && isAlphanumeric(value.charAt(value.length - 1));
  })
  .test('consecutive-hyphens', 'Oops, subdomain cannot contain two consecutive hyphens.', (value: string) => {
    if (value.includes('--')) {
      return false;
    }
    return true;
  })
  // With the below regex, we do not need the above two tests but they are there because we want to
  // show a different error message for both the cases.
  .matches(new RegExp('^[a-z0-9]+(-[a-z0-9]+)*$'), {
    message: 'Oops, subdomain can only contain lowercase letters (a-z), numbers (0-9) and hyphen/minus sign (-).',
  });
