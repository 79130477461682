import { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import { useActiveOrganization } from '@didomi/utility-react';
import { apiRoutes } from '@constants';
import { Widget, ErrorResponse } from '@interfaces';
import { useCallback, useMemo } from 'react';
import { useAxiosWithLogOut } from '@hooks';

export type FetchWidgetAsyncFnType = (widgetId: string) => Promise<Widget>;

export const useWidget = (id?: string, options?: UseQueryOptions<Widget, AxiosError<ErrorResponse>>) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();
  const queryClient = useQueryClient();

  const params = useMemo(() => {
    return { organization_id: organizationId, $with: ['custom_theme', 'meta_tags'] };
  }, [organizationId]);
  const queryKey = useMemo(() => {
    return ['widgets', { ...params, id }];
  }, [id, params]);

  const fetch = useCallback(
    async (id: string) => {
      const response = await axiosWithInterceptors.get<Widget>(`${apiRoutes.widgets}/${id}`, {
        params,
      });
      return response.data;
    },
    [axiosWithInterceptors, params],
  );

  const fetchAsync: (widgetId: string) => Promise<Widget> = useCallback(
    (widgetId: string) => {
      return queryClient.fetchQuery({
        queryKey,
        queryFn: () => fetch(widgetId),
      });
    },
    [queryClient, queryKey, fetch],
  );

  return {
    ...useQuery<Widget, AxiosError<ErrorResponse>>(queryKey, () => fetch(id), {
      ...options,
    }),
    fetchAsync,
  };
};
