import { formatPaginationResponse } from '../utils';

export const purposesRoutes = {
  '/metadata/purposes': {
    get: (schema: any, request) => {
      const purposes = schema.purposes
        .where({
          organization_id: request.queryParams.organization_id,
        })
        .models.map(purpose => purpose.attrs);

      return formatPaginationResponse(purposes, request);
    },
    post: (schema: any, request) => {
      const purpose = JSON.parse(request.requestBody);
      const newPurpose = schema.purposes.create({
        ...purpose,
        organization_id: request.queryParams.organization_id,
      });

      return newPurpose.attrs;
    },
  },
};
