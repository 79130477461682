import React from 'react';
import { RadioCard } from '@components';

interface CreateWidgetTemplatesProps {
  templates: any[];
  selectedTemplateId: string;
  onSelectedTemplateChange: (string) => void;
}

const CreateWidgetTemplates = ({ templates, selectedTemplateId, onSelectedTemplateChange }: CreateWidgetTemplatesProps): JSX.Element => {
  return (
    <div className="pl-12 pr-12 flex flex-col items-center mt-[6vh] 2xl:mt-[13vh]">
      <span className="mb-2 text-body-small text-primary-blue-5 font-semibold leading-[20px] uppercase 2xl:text-body-normal 2xl:leading-6 ">Create a widget</span>
      <span className="font-semibold text-primary-blue-6 text-[32px] leading-[40px] mb-8 font-serif 2xl:text-[42px] 2xl:leading-[56px] 2xl:font-bold 2xl:mb-10">
        Choose a widget format
      </span>
      <div data-testid="widgets-container" className="flex flex-row gap-x-4">
        {templates.map(widget => (
          <RadioCard
            key={widget.id}
            id={widget.id}
            name={widget.name}
            description={widget.description}
            selected={selectedTemplateId}
            illustration={widget.illustration}
            disabled={widget.disabled}
            onChange={onSelectedTemplateChange}
          />
        ))}
      </div>
    </div>
  );
};

export { CreateWidgetTemplates };
