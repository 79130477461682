import { formatPaginationResponse } from '../utils';

export const archivedWidgetsRoutes = {
  '/widgets/archived': {
    get: (schema: any, request) => {
      const archivedWidgets = schema.archivedWidgets
        .where({
          organization_id: request.queryParams.organization_id,
          usage: request.queryParams.usage,
        })
        .models.map(({ attrs }) => attrs);

      return formatPaginationResponse(archivedWidgets, request);
    },
  },
};
