import React from 'react';
import { DidomiPreferenceValueOptions } from '@didomi/pmp-generator';
import { ContentEditorForm as ContentEditorFormProps, PreferenceValueMetadata } from '@types';
import { getTranslatedValue } from '@utils';
import { useContentEditorForm } from '@hooks';
import { useTranslationContext } from '@didomi/widgets-utilities';
import { ContentEditorFileInput, ContentEditorTextInput } from './components';
import { useReleaseFlag } from '@didomi/utility-react';

interface ContentEditorPreferenceValueFormProps extends ContentEditorFormProps<Record<string, DidomiPreferenceValueOptions>, PreferenceValueMetadata> {
  componentId: string;
  hasBottomMargin?: boolean;
}

export function ContentEditorPreferenceValueForm({ data, metadata, hasBottomMargin, setData, componentId }: ContentEditorPreferenceValueFormProps) {
  const { getValueAndChangeHandlerFor } = useContentEditorForm(data, setData, componentId);
  const { activeEditorLanguage } = useTranslationContext();
  const { preferenceValue } = metadata;
  const placeholder = getTranslatedValue(preferenceValue.name, 'You can add a preference value label here...', activeEditorLanguage);
  const [hasLanguagesFeature] = useReleaseFlag('widget_translation_management');

  return (
    <div className={hasBottomMargin ? 'mb-m' : undefined}>
      <ContentEditorTextInput
        data-testid={`preference-value-${componentId}`}
        id={preferenceValue?.id}
        label="Value content"
        placeholder={placeholder}
        hideErrorMessage={!hasLanguagesFeature || !!preferenceValue.name?.[activeEditorLanguage]}
        {...getValueAndChangeHandlerFor(`${componentId}.label`)}
      />
      <ContentEditorFileInput label="Value icon" description="15x15 pixels maximum" placeholder="https://www...." {...getValueAndChangeHandlerFor(`${componentId}.icon`)} />
    </div>
  );
}
