import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { MainLayout, SecondaryLayout } from '@layouts';
import { Widgets, Configuration, AddPurpose, AddPreference, EditPreference, EditWidget, WidgetPreview, CreateWidget, VersionsHistory } from '../index';
import { ConfigurationTreeHelpAction } from '../Configuration/ConfigurationTreeHelpAction';
import { useHasAccessPolicies, useReleaseFlag } from '@didomi/utility-react';
import { TranslationContextProvider } from '@didomi/widgets-utilities';
import { NavigateToDashboard } from '@components';
import { useConfigTrees } from '@hooks';
import { PreferenceHelp } from '../AddPreference/PreferenceHelp';
import { ACCESS_POLICIES_CONFIG } from '../../constants/access-policies';
import { WidgetHelperAction } from '../Widgets/WidgetHelperAction';
import { WidgetPreviewDataProvider } from '@contexts';

/**
 * App Component
 * Takes care of the routing
 */
const App = (): JSX.Element => {
  const { hasAccess: canViewWidgets } = useHasAccessPolicies(ACCESS_POLICIES_CONFIG.PMP_WIDGETS_VIEWER);
  const { hasAccess: canEditWidgets } = useHasAccessPolicies(ACCESS_POLICIES_CONFIG.PMP_WIDGETS_EDITOR);
  const { hasAccess: canViewConfigurationTree } = useHasAccessPolicies(ACCESS_POLICIES_CONFIG.CONFIGURATION_TREE_VIEWER);
  const { hasAccess: canEditConfigurationTree } = useHasAccessPolicies(ACCESS_POLICIES_CONFIG.CONFIGURATION_TREE_EDITOR);

  const [hasVersioningRelease, isLoadingVersioningRelease] = useReleaseFlag('pmp_versioning');

  const { isLoading: loadingConfTree, data: configTrees } = useConfigTrees();

  const loadingAccessPolicies = !(canViewWidgets || canViewConfigurationTree);
  const loading = loadingConfTree || loadingAccessPolicies || isLoadingVersioningRelease;

  const hasTree = !!configTrees?.data?.length;
  const hasPurposes = hasTree && configTrees.data[0]?.selected_purposes?.length;
  const disableWidgetsTab = !hasPurposes || !canViewWidgets;
  const preferenceHelpEl = <PreferenceHelp />;

  return (
    <div className="h-full" data-testid={`router-loading-${loading}`}>
      {!loading && (
        <Switch>
          <Redirect exact from="/widgets/edit/:id" to="/widgets/edit/:id/purposes-and-preferences" />
          <Redirect exact from="/widgets/edit/:id/content-manager" to="/widgets/edit/:id/purposes-and-preferences" />
          <Redirect exact from="/widgets/edit/:id/preferences" to="/widgets/edit/:id/purposes-and-preferences" />

          {canEditWidgets && (
            <Route path="/widgets/create">
              <CreateWidget />
            </Route>
          )}

          {canEditWidgets && (
            <Route path="/widgets/edit/:id">
              <TranslationContextProvider>
                <WidgetPreviewDataProvider>
                  <EditWidget />
                </WidgetPreviewDataProvider>
              </TranslationContextProvider>
            </Route>
          )}
          <Route exact path="/widgets/:id/preview">
            <WidgetPreview />
          </Route>
          {canViewWidgets && hasVersioningRelease && (
            <Route path="/widgets/versions-history/:id">
              <VersionsHistory />
            </Route>
          )}
          {!disableWidgetsTab && (
            <Route exact path="/widgets">
              <MainLayout loading={loading} disableConfigurationTreeTab={!canViewConfigurationTree} Action={WidgetHelperAction}>
                <Widgets />
              </MainLayout>
            </Route>
          )}
          {canViewConfigurationTree && (
            <Route path="/configuration">
              <MainLayout loading={loading} disableWidgetsTab={disableWidgetsTab} Action={ConfigurationTreeHelpAction} disableChatBubbleSpacer>
                <Configuration />
              </MainLayout>
            </Route>
          )}
          {canEditConfigurationTree && (
            <Route path="/add-preference">
              <SecondaryLayout
                illustration="create-a-preference"
                titleText="Create a preference"
                backPath="/configuration"
                backText="Back to Configuration"
                actionChildren={preferenceHelpEl}
              >
                <AddPreference />
              </SecondaryLayout>
            </Route>
          )}
          {canEditConfigurationTree && (
            <Route path="/edit-preference/:id">
              <SecondaryLayout
                illustration="create-a-preference"
                titleText="Edit a preference"
                backPath="/configuration"
                backText="Back to Configuration"
                actionChildren={preferenceHelpEl}
              >
                <EditPreference />
              </SecondaryLayout>
            </Route>
          )}
          {canEditConfigurationTree && (
            <Route exact path="/purposes">
              <AddPurpose
                titleText="Add a purpose"
                subtitle="Here is the list of all your available purposes"
                description="You can easily manage the purposes you want to display or to hide inside your Configuration Tree."
                backPath="/configuration"
              />
            </Route>
          )}
          <Route exact path="/">
            <Redirect to={!disableWidgetsTab ? '/widgets' : '/configuration'} />
          </Route>
          <Route path="*">
            <NavigateToDashboard />
          </Route>
        </Switch>
      )}
    </div>
  );
};

export { App };
