import React from 'react';
import { DidomiCopyText, DidomiIcon } from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';
import { useSnackbar } from '@didomi/utility-react';
import { OverflowWithTooltip, SelectedPreferenceCardOptions, ValuePreferenceDrop, ValuePreferenceDropArea, ValuePreferenceDropPlaceholder } from '../index';
import { generateUniqueId, getTranslatedValue } from '@utils';
import type { PreferencePreviewData } from '../../modals/PreviewPreferenceModal/PreviewPreferenceModal.modal';
import { PreferenceValueContentAsString } from '@types';
import { ConfigTreeEntityStateCard } from '../ConfigTreeEntityStateCard/ConfigTreeEntityStateCard';
import { ConfigTreeEntityState } from '@enums';

type SelectedPreferenceCardProps = {
  id?: string;
  'data-testid'?: string;
  label: string;
  description?: string;
  type?: string;
  level?: number;
  expanded?: boolean;
  showAllIds?: boolean;
  canEditConfigurationTree?: boolean;
  parentSelectedPurposeId: string;
  selectedPreferenceId?: string;
  entityIDsToBeDeleted?: string[];
  values: PreferenceValueContentAsString[];
  onPreferencePreview?: (previewData: PreferencePreviewData) => void;
  onRemove?: (id: string) => void;
  onEdit?: () => void;
};

/**
 * Selected Preference card component
 * This component is the preference card that lives inside of the Configuration Tree (as opposed to the right panel)
 * The card is in charge of rendering not only its details but its children details as well.
 * The card uses the ValuePreferenceDrop component to allow the dropping of new cards (note it's limited at level 3 of deepness)
 */
const SelectedPreferenceCard = ({
  id = generateUniqueId('selected-preference-card'),
  'data-testid': dataTestid,
  level = 1,
  parentSelectedPurposeId,
  label,
  description,
  expanded = true,
  type,
  selectedPreferenceId,
  entityIDsToBeDeleted = [],
  onPreferencePreview,
  showAllIds = false,
  canEditConfigurationTree,
  values,
  onRemove,
  onEdit,
}: SelectedPreferenceCardProps): JSX.Element => {
  const { displaySnackbar } = useSnackbar();
  return (
    <div data-testid={dataTestid || 'selected-preference-card'} className="w-full">
      {/* This block is shown when a preference is getting deleted */}
      {entityIDsToBeDeleted.includes(selectedPreferenceId) && <ConfigTreeEntityStateCard label={`${label} preference`} state={ConfigTreeEntityState.DELETING} />}
      {/* This block is shown when a preference is getting created */}
      {selectedPreferenceId === null && <div>{<ConfigTreeEntityStateCard label={`${label} preference`} state={ConfigTreeEntityState.ADDING} />}</div>}
      {/* This block is shown when a preference is already created */}
      {!entityIDsToBeDeleted.includes(selectedPreferenceId) && selectedPreferenceId !== null && (
        <div id={id} className={tx('w-full rounded-lg box-border border-neutral-gray-4 border-1 border-solid rounded-t-lg', level % 2 === 0 ? 'bg-white' : 'bg-neutral-gray-1')}>
          <div className="py-m pl-6 pr-4 h-8 flex justify-between items-center" data-testid={dataTestid ? dataTestid + '-header' : 'preference-card-header'}>
            <div className="flex-grow flex items-center min-w-0">
              <DidomiIcon name="preference" aria-hidden className="pr-2 min-w-[15px]" />
              <OverflowWithTooltip data-testid="selected-preference-card-title" className=" min-w-0 font-sans text-body-normal font-semibold text-primary-blue-6" value={label} />
              {showAllIds && (
                <DidomiCopyText
                  text={selectedPreferenceId}
                  onTextCopied={() => displaySnackbar('Preference ID copied!', { icon: 'check' })}
                  className="text-[10px] mt-[1px] ml-0.5 text-primary-blue-6 font-extralight flex align-middle"
                  variation="basic-clear"
                >
                  {selectedPreferenceId}
                </DidomiCopyText>
              )}
            </div>
            <div className="flex">
              <SelectedPreferenceCardOptions
                onPreview={() =>
                  onPreferencePreview({
                    id,
                    name: label,
                    description,
                    type,
                    values,
                  })
                }
                onRemove={() => onRemove(selectedPreferenceId)}
                onEdit={onEdit}
                showEdit={canEditConfigurationTree}
                showRemove={canEditConfigurationTree}
              />
            </div>
          </div>

          <div id={id + '-card-values'} role="region" data-testid="selected-preference-card-values" className="border-neutral-gray-4 border-t-1 border-solid">
            <ul className="px-6 py-5 m-0 list-none">
              {values.map((value, i) => (
                <li key={value + '-' + i} className="not-last-child:mb-5">
                  <ValuePreferenceDropArea
                    order={0} // The first drop area on the value list needs to be 0 as it will be added at the top of the preference list.
                    parentId={selectedPreferenceId}
                    parentPreferenceValueId={value.id}
                    parentSelectedPurposeId={parentSelectedPurposeId}
                    isParentPurpose={false}
                    disabled={!expanded || level >= 3}
                  >
                    <div className="flex flex-row">
                      <OverflowWithTooltip
                        data-testid={'selected-preference-card-value-' + i}
                        className="font-sans text-body-normal text-primary-blue-6 truncate"
                        value={getTranslatedValue(value.name)}
                      />
                      {showAllIds && (
                        <DidomiCopyText
                          text={value.id}
                          onTextCopied={() => displaySnackbar('Preference value ID copied!', { icon: 'check' })}
                          className="text-[10px] mt-[1px] ml-0.5 text-primary-blue-6 font-extralight flex align-middle"
                          variation="basic-clear"
                        >
                          {value.id}
                        </DidomiCopyText>
                      )}
                    </div>
                    <ValuePreferenceDropPlaceholder />
                  </ValuePreferenceDropArea>

                  {value.selected_preferences?.length > 0 &&
                    value.selected_preferences.map((p, j) => (
                      <div key={'selected-preference-' + j}>
                        <div className="children:(relative pl-xxl my-xs before::(content-no-open-quote absolute top-0 left-xs border-l-1 border-b-1 rounded-bl-2xl border-neutral-gray-5 w-s h-s p-xxxs))">
                          <SelectedPreferenceCard
                            id={p.preference.id}
                            label={getTranslatedValue(p.preference.name)}
                            description={getTranslatedValue(p.preference.description)}
                            type={p.preference.type}
                            showAllIds={showAllIds}
                            canEditConfigurationTree={canEditConfigurationTree}
                            parentSelectedPurposeId={parentSelectedPurposeId}
                            selectedPreferenceId={p.id}
                            entityIDsToBeDeleted={entityIDsToBeDeleted}
                            data-testid={'selected-preference-card-' + p.id}
                            level={level + 1}
                            onPreferencePreview={onPreferencePreview}
                            onRemove={prefId => onRemove(prefId || p.id)}
                            values={p.preference.values.map(value => ({ ...value, name: getTranslatedValue(value.name) })) as unknown as PreferenceValueContentAsString[]}
                          />
                        </div>
                        {expanded && level < 3 && value.selected_preferences?.length > 0 && (
                          <ValuePreferenceDrop
                            order={p.order + 1}
                            parentId={selectedPreferenceId}
                            parentPreferenceValueId={value.id}
                            parentSelectedPurposeId={parentSelectedPurposeId}
                            isParentPurpose={false}
                          />
                        )}
                      </div>
                    ))}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export { SelectedPreferenceCard };
