import React from 'react';
import { DidomiButton, DidomiModal, DidomiModalHeader, DidomiModalActions } from '@didomi/ui-atoms-react';

type ConfirmBeforeEditPreferenceModalProps = {
  isOpen?: boolean;
  onCancel?: () => void;
  onProceed?: () => void;
};

/**
 * Remove preference
 */
const ConfirmBeforeEditPreferenceModal = ({ isOpen, onCancel, onProceed }: ConfirmBeforeEditPreferenceModalProps): JSX.Element => {
  return (
    <DidomiModal isOpen={isOpen} permanent="true">
      <DidomiModalHeader
        illustrationName="delete-pref-tree"
        style={{ whiteSpace: 'pre' }}
        variant="warning"
        modalSubTitle="Please be careful"
        modalTitle={`Editing this preference will have\nan impact on all your widgets.`}
      />

      <DidomiModalActions className="pt-5!">
        <DidomiButton variant="secondary" onClick={onCancel}>
          Oops, no thanks
        </DidomiButton>
        <DidomiButton data-tracking="pmp-start-edit-preference-in-tree" onClick={onProceed}>
          Edit preference
        </DidomiButton>
      </DidomiModalActions>
    </DidomiModal>
  );
};

export { ConfirmBeforeEditPreferenceModal };
