import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { AxiosResponse, AxiosError } from 'axios';
import { useActiveOrganization } from '@didomi/utility-react';
import { apiRoutes } from '@constants';
import { ErrorResponse, LayoutComponent } from '@interfaces';
import { useAxiosWithLogOut } from '../useAxiosWithInterceptors.hook';
import { ComponentType } from '@didomi/pmp-generator';

export type PatchLayoutComponentPayload = {
  id: string;
  options?: Record<string, any>;
  theme?: Record<string, any>;
  type?: ComponentType;
};

export const usePatchLayoutComponents = (options: UseMutationOptions<AxiosResponse<LayoutComponent[]>, AxiosError, PatchLayoutComponentPayload[]> = {}) => {
  const { organizationId } = useActiveOrganization();
  const axiosWithInterceptors = useAxiosWithLogOut();
  const queryClient = useQueryClient();

  const patch = async (payload: PatchLayoutComponentPayload[]) => axiosWithInterceptors.patch(apiRoutes.layoutComponents, payload, { params: { organization_id: organizationId } });

  return useMutation<AxiosResponse<LayoutComponent[]>, AxiosError<ErrorResponse>, PatchLayoutComponentPayload[]>(patch, {
    ...options,
    onSuccess: (updatedLayoutComponents: AxiosResponse<LayoutComponent[]>, variables, context) => {
      queryClient.invalidateQueries(['layout-components']);

      if (options.onSuccess) {
        options.onSuccess(updatedLayoutComponents, variables, context);
      }
    },
  });
};
