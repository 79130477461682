import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams, Route, useHistory } from 'react-router-dom';
import { useActiveOrganization, useReleaseFlag, useSnackbar } from '@didomi/utility-react';
import { tx } from '@twind/core';
import DidomiLogo from '../../assets/svg/didomi-logo.svg';
import {
  useConnectContentEditorWithPreview,
  useDefaultThemes,
  useWidgetDomain,
  useWidgetPreview,
  useCreateWidgetDeployment,
  usePatchLayoutComponents,
  PatchLayoutComponentPayload,
  useUpdateWidget,
  UpdateWidgetPayload,
  useUpdateCustomTheme,
} from '@hooks';
import { DidomiButton, DidomiIconButton, DidomiIllustration, DidomiHeaderInput, DidomiIcon, DidomiSkeleton, DidomiChip, DidomiTooltip } from '@didomi/ui-atoms-react';
import { EditWidgetContentEditor, EditWidgetTheme, EditWidgetPreferences, MissingTranslationDrawer } from '@components';
import { getMissingTranslation, snakeToCamelCase } from '@utils';
import { SecondaryLayout, WidgetPreviewLayout } from '@layouts';
import { LoadingModal } from '@modals';
import { CustomComponentType, WidgetDomain, WidgetFormat, WidgetPreviewSelectionType, WidgetPublicationStatus, WidgetTemplateId } from '@enums';
import { UPDATE_IFRAME_DELAY, WIDGET_STATUS_CHIP_TYPE, WIDGET_STATUS_LABELS } from '@constants';
import {
  useTranslationContext,
  EmbeddingRightPanel,
  HostingRightPanel,
  NavigationPrompt,
  WidgetDomain as WidgetUtilitiesDomain,
  WidgetPublicationStatus as WidgetUtilitiesPublicationStatus,
  WidgetHostingHeader,
  WidgetPreviewMode,
} from '@didomi/widgets-utilities';
import { sanitizeHTML } from '@didomi/xss';
import debounce from 'lodash.debounce';
import { MissingTranslation, WidgetMetaTagsOptions } from '@types';
import EditWidgetPublishedModal from './components/EditWidgetPublishedModal';
import { useWidget } from '../../hooks/data/widgets/useWidget.hook';
import isEqual from 'lodash.isequal';
import { ComponentType } from '@didomi/pmp-generator';
import { useWidgetPreviewData } from '@contexts';
import { sanitizeHTMLObject } from '../../utils/sanitizeObject';

const TEMPLATE_CONFIG = {
  [WidgetTemplateId.FULL_PREFERENCE_CENTER]: {
    renderSinglePreference: false,
    initialComponentType: ComponentType.HEADER,
    selectionType: WidgetPreviewSelectionType.SelectByComponentType,
    hideSectionFields: false,
    preventDisablingAllPreferences: false,
  },
  [WidgetTemplateId.SECTIONS]: {
    renderSinglePreference: true,
    initialComponentType: ComponentType.SECTION,
    selectionType: WidgetPreviewSelectionType.SelectByComponentType,
    hideSectionFields: true,
    preventDisablingAllPreferences: true,
  },
  [WidgetTemplateId.SECTIONS_AND_SAVE]: {
    renderSinglePreference: false,
    initialComponentType: ComponentType.SAVE, // When all the entities are disabled only the save button is enabled
    selectionType: WidgetPreviewSelectionType.SelectFirstSection,
    hideSectionFields: false,
    preventDisablingAllPreferences: false,
  },
};

const getSelectedComponentId = (missingTranslation: MissingTranslation) => {
  if (missingTranslation.componentType === ComponentType.PREFERENCE_VALUE) {
    return missingTranslation.parentComponentId;
  }
  return missingTranslation.componentId;
};

/**
 * Edit Widget Page
 */
// TODO: Uncomment when tabs are added
// eslint-disable-next-line
const EditWidget = (): JSX.Element => {
  const { id: widgetId } = useParams<{ id: string }>();
  const { organization } = useActiveOrganization();
  const [hasVersioningRelease] = useReleaseFlag('pmp_versioning');
  const history = useHistory();
  const { displaySnackbar } = useSnackbar();
  const [widgetName, setWidgetName] = useState('');
  const [widgetHeadless, setWidgetHeadless] = useState(null);
  const [widgetTemplateId, setWidgetTemplateId] = useState(WidgetTemplateId.FULL_PREFERENCE_CENTER);
  const [widgetFormat, setWidgetFormat] = useState<WidgetFormat>(null);
  const [displayPublishModal, setDisplayPublishModal] = useState(false);
  const { setSelectedLayoutShape, setCustomTheme, customTheme, selectedThemeId, setSelectedThemeId, selectedLayoutShape } = useWidgetPreviewData();
  const [isSaving, setSaving] = useState<boolean>(false);
  const [hasPreferencesRelease, isLoadingPreferencesRelease] = useReleaseFlag('pmp_widget_preferences');
  const templateConfig = TEMPLATE_CONFIG[widgetTemplateId];
  const [showMissingTranslationTooltip, setShowMissingTranslationTooltip] = useState(false);
  // We only want the status when the deployment was initiated, since after publishing the status changes due to widget refresh
  const initialPublicationStatus = useRef<WidgetPublicationStatus | null>(null);

  const {
    enabledLanguages,
    defaultLanguage,
    missingTranslationsFlat,
    setDefaultLanguage,
    setEnabledLanguages,
    setMissingTranslations,
    setIsLoading: setIsLanguagesLoading,
    hasLanguageChanged,
    setMissingTranslationDrawerOpen,
    selectedMissingTranslation,
    setActiveEditorLanguage,
    activeEditorLanguage,
  } = useTranslationContext();

  const {
    selectedComponent,
    setComponentData,
    setSelectedComponentById,
    scrollTo,
    isSdkReady,
    setIframe,
    currentComponents,
    componentsDiff,
    refetchLayoutComponents,
    refetchLayoutEntities,
    loadingPreferencesData,
    loading: isLoadingContentEditorPreview,
    entities,
    enabledEntities,
    widgetPreferences,
    setWidgetLayoutEntityEnabled,
    hasEnabledLayoutEntity,
    saveUpdatedLayoutEntities,
    getBreadcrumb,
    layoutEntities,
    preferenceOrderingControls,
    mode,
    includeCustomComponentInput,
    selectCustomComponent,
  } = useConnectContentEditorWithPreview({
    initialComponentType: templateConfig?.initialComponentType,
    selectionType: templateConfig?.selectionType,
    organization,
    widgetId,
    widgetTemplateId,
    widgetFormat,
  });

  const setMissingTranslationDebounce = useMemo(
    () =>
      debounce((purposes, currentComponents, enabledLanguages) => {
        const missingTranslations = getMissingTranslation(purposes, currentComponents, enabledLanguages);
        setMissingTranslations(missingTranslations);
      }, UPDATE_IFRAME_DELAY),
    [setMissingTranslations],
  );

  useEffect(() => {
    setMissingTranslationDebounce(enabledEntities?.purposes, currentComponents, enabledLanguages);
  }, [enabledEntities, currentComponents, componentsDiff, enabledLanguages, setMissingTranslationDebounce]);

  useEffect(() => {
    if (selectedMissingTranslation) {
      setActiveEditorLanguage(selectedMissingTranslation.language);
      const selectedComponentId = getSelectedComponentId(selectedMissingTranslation);
      scrollTo(selectedComponentId);
      setSelectedComponentById(selectedComponentId);
    }
  }, [selectedMissingTranslation, setActiveEditorLanguage, setSelectedComponentById, scrollTo]);
  const {
    data: widget,
    refetch: refetchWidget,
    error: errorLoadingWidget,
    isLoading: isLoadingWidget,
  } = useWidget(widgetId, {
    // this is need to force the widget to be refetched when we come back from the versions history page
    // otherwise the onSuccess callback is not called
    refetchOnMount: true,
    staleTime: 0,
    onSuccess: widget => {
      if (widget?.name) {
        setWidgetName(widget.name);
        setWidgetTemplateId(widget.template_id);
        setWidgetFormat(widget.format);
        setWidgetHeadless(widget.headless);
        setSelectedLayoutShape(widget.layout_shape);
        setCustomTheme(widget.custom_theme);
        setSelectedThemeId(widget.theme_id);
        setDefaultLanguage(widget.default_language);
        setEnabledLanguages(widget.enabled_languages);
        setIsLanguagesLoading(false);
        if (widget.meta_tags) {
          includeCustomComponentInput({
            id: widget.meta_tags.id,
            type: CustomComponentType.META_TAGS,
            options: {
              title: widget.meta_tags.title,
              favicon: widget.meta_tags.favicon,
            },
          });
        }
      }
    },
  });

  const initialSubDomain = useMemo(() => widget?.subdomain_id?.split('.')[0], [widget?.subdomain_id]);
  const { hasError: hasErrorOnWidgetDomain, widgetDomainInput, isDomainBooked } = useWidgetDomain({ initialSubDomain, widgetId });

  const { loading: isLoadingDefaultThemes, error: errorLoadingDefaultThemes, refetch: refetchDefaultThemes } = useDefaultThemes();

  const { mutateAsync: saveWidget } = useUpdateWidget(widgetId, {
    onSuccess: () => {
      displaySnackbar('Your widget is saved', { icon: 'check' });
      refetchWidget();
    },
    onError: () => {
      displaySnackbar('Widget can not be saved', { title: 'Sorry, an error occurred:', icon: 'danger-light', variant: 'error' });
    },
  });

  const { mutateAsync: patchCustomTheme } = useUpdateCustomTheme({
    onError: () => {
      displaySnackbar('Widget can not be saved', { title: 'Sorry, an error occurred:', icon: 'danger-light', variant: 'error' });
    },
  });

  const { mutateAsync: updateComponents } = usePatchLayoutComponents({
    onError: () => {
      displaySnackbar('Widget can not be saved', { title: 'Sorry, an error occurred:', icon: 'danger-light', variant: 'error' });
    },
  });

  const { mutate: deployWidget, isLoading: deployingWidget } = useCreateWidgetDeployment({
    onSuccess: () => {
      refetchWidget();
      setDisplayPublishModal(true);
      widgetDomainInput.handleReset();
    },
    onError: error => {
      displaySnackbar('Widget can not be published', {
        title: 'Sorry, an error occurred:',
        icon: 'danger-light',
        variant: 'error',
        permanent: true,
        action: {
          action: /* istanbul ignore next */ () => {},
          name: 'Ok',
          closeInAction: true,
        },
      });

      if (isDomainBooked(error.response.status)) {
        history.push(`/widgets/edit/${widgetId}/hosting`);
      }
    },
  });

  const shouldAllowRouteChange = useCallback((newPath: string) => newPath.startsWith(`/widgets/edit/${widgetId}/`), [widgetId]);

  const refetch = () => {
    if (errorLoadingWidget) {
      refetchWidget();
    }
    if (errorLoadingDefaultThemes) {
      refetchDefaultThemes();
    }
  };

  useEffect(() => {
    // widget.customTheme object reference is changed after patchTheme mutation
    // directly by apollo. So sync the changes to the reactive theme var.
    if (widget?.custom_theme) {
      setCustomTheme(widget.custom_theme);
    }
  }, [setCustomTheme, widget?.custom_theme]);
  const { isLoading: isLoadingPreview, preview: previewWidget } = useWidgetPreview({
    widgetPreferences,
    themeId: selectedThemeId,
    layoutShape: selectedLayoutShape,
    customTheme,
    updatedComponents: currentComponents,
    languages: {
      enabled: enabledLanguages,
      default: defaultLanguage,
    },
  });

  const isLoading = isLoadingWidget || isLoadingDefaultThemes || isLoadingContentEditorPreview || !isSdkReady || isLoadingPreferencesRelease;
  const haveValuesChanged =
    widgetName !== widget?.name || widgetHeadless !== widget?.headless || widget?.layout_shape !== selectedLayoutShape || widget?.theme_id !== selectedThemeId;
  const hasCustomThemeChanged = !isEqual(widget?.custom_theme || {}, customTheme || {});
  const hasLayoutComponentsChanged = Object.keys(componentsDiff).length > 0;
  const hasWidgetPreferenceChanged = Object.keys(widgetPreferences).length > 0;
  const hasSubdomainChanged = !!initialSubDomain && widgetDomainInput.value !== initialSubDomain && !hasErrorOnWidgetDomain;
  const hasWidgetLanguageChanged = hasLanguageChanged(widget?.default_language, widget?.enabled_languages);
  const hasDataChanged = haveValuesChanged || hasCustomThemeChanged || hasLayoutComponentsChanged || hasWidgetPreferenceChanged || hasSubdomainChanged || hasWidgetLanguageChanged;
  const hasMissingTranslations = missingTranslationsFlat.length > 0;
  const isWidgetPublished = widget?.publication_status === WidgetPublicationStatus.PUBLISHED;
  const isPublishButtonDisabled = isSaving || deployingWidget || (isWidgetPublished && !hasDataChanged) || hasErrorOnWidgetDomain;
  const isSaveButtonDisabled = isSaving || !hasDataChanged || hasErrorOnWidgetDomain;
  const allowDisableSetWidgetPreferenceValue = templateConfig?.preventDisablingAllPreferences;
  const statusChipVariation = widget?.publication_status === WidgetPublicationStatus.DRAFT ? 'basic-outline' : 'basic';
  const handleSave = async () => {
    setSaving(true);
    try {
      if (hasWidgetPreferenceChanged) {
        await saveUpdatedLayoutEntities();
        await refetchLayoutEntities();
      }

      if (hasCustomThemeChanged && widget.custom_theme) {
        await patchCustomTheme({
          id: widget.custom_theme.id,
          color_1: customTheme.color_1,
          color_2: customTheme.color_2,
          color_3: customTheme.color_3,
          color_4: customTheme.color_4,
          color_5: customTheme.color_5,
          color_6: customTheme.color_6,
          color_7: customTheme.color_7,
          color_8: customTheme.color_8,
        });
      }

      let metaTags = null;

      if (hasLayoutComponentsChanged) {
        const patchLayoutComponentsDtoInput: PatchLayoutComponentPayload[] = [];
        const componentsDiffClone = { ...componentsDiff };
        metaTags = snakeToCamelCase(componentsDiff[widget.meta_tags?.id]);
        if (metaTags) {
          // Removing meta tags from the components list as it should be part of the widget patch payload.
          delete componentsDiffClone[widget.meta_tags.id];
        }
        for (const componentId in componentsDiffClone) {
          const component = componentsDiffClone[componentId];
          patchLayoutComponentsDtoInput.push({
            id: componentId,
            options: component.options,
            type: component.type as ComponentType,
          });
        }
        if (patchLayoutComponentsDtoInput.length) {
          await updateComponents(patchLayoutComponentsDtoInput);
          await refetchLayoutComponents();
        }
      }

      const patchWidgetDtoInput: UpdateWidgetPayload = {
        name: widgetName,
        layout_shape: selectedLayoutShape !== widget.layout_shape.toLocaleUpperCase() ? selectedLayoutShape : undefined,
        theme_id: selectedThemeId !== widget?.theme_id ? selectedThemeId : undefined,
        default_language: defaultLanguage,
        enabled_languages: enabledLanguages,
        headless: widgetHeadless,
      };

      if (metaTags) {
        // Includes the meta tags on the widget patch payload
        patchWidgetDtoInput.meta_tags = metaTags.options;
      }

      if (widget.domain === WidgetDomain.SUB && hasSubdomainChanged) {
        patchWidgetDtoInput.domain = WidgetDomain.SUB;
        patchWidgetDtoInput.subdomain_id = `${widgetDomainInput.value}.${CONFIG.hostingDomain}`;
      }

      const sanitizedPatchWidgetDtoInput: UpdateWidgetPayload = sanitizeHTMLObject<UpdateWidgetPayload>(patchWidgetDtoInput);

      return await saveWidget(sanitizedPatchWidgetDtoInput);
    } catch (error) {
      console.error(error);
    } finally {
      setSaving(false);
    }
  };

  /**
   * if the widget has meta tags, return the edited custom meta tag component.
   * @returns
   */
  const getMetaTagsOptions = () => {
    if (widget?.meta_tags) {
      return currentComponents[widget.meta_tags.id]?.options as WidgetMetaTagsOptions;
    }

    return null;
  };

  /**
   * Return the logo that will be displayed on the WidgetHostingHeader
   * @returns
   */
  const getWidgetHeaderLogo = () => {
    return getMetaTagsOptions()?.favicon || DidomiLogo;
  };

  /**
   * Return the title that will be displayed on the WidgetHostingHeader
   * @returns
   */
  const getWidgetTitle = () => {
    return getMetaTagsOptions()?.title?.[activeEditorLanguage];
  };

  /**
   * Handles a click event on element by `data-id`. This element is only visible when, we hover the publish button.
   * Once the element is clicked, it opens the missing translation drawer.
   *
   * Note: We need this method because the we can't bind elements that live in the tooltip content.
   */
  useEffect(() => {
    const handleClick = () => {
      setMissingTranslationDrawerOpen(true);
    };

    const element = document.querySelector('.didomi-overlay-container [data-id="edit-widget-publish-button"]');
    element?.addEventListener('click', handleClick);

    return () => {
      element?.removeEventListener('click', handleClick);
    };
  }, [showMissingTranslationTooltip, setMissingTranslationDrawerOpen]);

  const showVersionsHistoryLink = hasVersioningRelease && [WidgetPublicationStatus.PUBLISHED, WidgetPublicationStatus.UNPUBLISHED_CHANGES].includes(widget?.publication_status);

  const hasError = errorLoadingWidget || errorLoadingDefaultThemes;
  if (hasError) {
    return (
      <SecondaryLayout backPath="/widgets" backText="Back to widgets" fullWidth>
        <div className="h-full border-1 border-dashed border-neutral-gray-5 rounded-lg flex-1 flex flex-col items-center justify-center overflow-x-hidden">
          <DidomiIllustration name="preferences-loading-error" className="mb-xs" />
          <div className="font-sans text-body-big font-semibold text-primary-pink-4">There seems to be an error</div>
          <div className="font-sans text-body-big text-primary-blue-6">The widget has not been loaded</div>
          <DidomiButton className="mt-s" size="small" data-testid="reload-purposes" onClick={refetch} iconLeft="reset">
            Reload
          </DidomiButton>
        </div>
      </SecondaryLayout>
    );
  }

  return (
    <DidomiSkeleton variant="layout" isLoading={isLoading} data-testid={`edit-widget-loading-${isLoading}`}>
      <SecondaryLayout
        backPath="/widgets"
        backText="Back to widgets"
        fullWidth
        headerChildren={
          <div slot="header-title" data-testid="widget-tilte-section" className="flex items-center">
            <DidomiHeaderInput
              data-cy="widget-title"
              value={sanitizeHTML(widgetName).result}
              onValueChange={ev => {
                setWidgetName(ev.detail);
              }}
              icon="edit"
              tooltipContent="Edit title"
              className={tx({ 'min-w-[250px] h-10': isLoading })}
              data-skeleton
            />

            <DidomiChip
              data-testid={`widget-status-${widget?.publication_status}`}
              variation={statusChipVariation}
              label={WIDGET_STATUS_LABELS[widget?.publication_status]}
              basicType={WIDGET_STATUS_CHIP_TYPE[widget?.publication_status]}
              data-skeleton
            />
          </div>
        }
        actionChildren={
          <div className="flex items-center h-full">
            {showVersionsHistoryLink && (
              <>
                <button
                  className="flex items-center cursor-pointer text-body-extra-small font-semibold text-primary-blue-6 rounded-lg outline-offset-4 focus:(outline outline-[3px] outline-primary-blue-2)"
                  data-testid="widget-versions-history-button"
                  onClick={() => {
                    history.push(`/widgets/versions-history/${widgetId}`);
                  }}
                >
                  <span>See version history</span>
                  <DidomiIcon name="timer-thin" className="ml-xxxs" />
                </button>
                <div className="mx-xs border-l border-solid border-neutral-gray-4 self-stretch"></div>
              </>
            )}

            <DidomiButton
              variant="secondary"
              data-tracking="pmp-preview-widget"
              data-testid="widget-preview-button"
              className="mr-xs"
              onClick={() => previewWidget(widget!.id)}
              disabled={isLoadingPreview}
              data-skeleton
            >
              <DidomiIcon
                name={isLoadingPreview ? 'reset' : 'preview'}
                className={tx('align-middle', {
                  'animate-spin': isLoadingPreview,
                })}
              />
            </DidomiButton>
            <DidomiIconButton
              data-tracking="pmp-save-widget"
              data-cy="widget-save-button"
              data-testid="widget-save-button"
              disabled={isSaveButtonDisabled}
              icon="save"
              className="mr-xs"
              onClick={handleSave}
              data-skeleton
            />
            {hasMissingTranslations ? (
              <>
                <div className="hidden" id="edit-widget-publish-button-tooltip">
                  <p className="text-body-small font-normal">
                    Some translations are missing.&nbsp;
                    <button
                      data-id="edit-widget-publish-button"
                      data-testid="edit-widget-publish-button"
                      className="rounded-sm focus:(outline outline-[3px] outline-offset-2 outline-primary-blue-2)"
                    >
                      <strong>Click here</strong>
                    </button>
                    &nbsp;to see all missing translations. Add the translations or disabled incomplete languages to publish your widget.
                  </p>
                </div>
                <DidomiTooltip placement="left" variant="helper" interactive={true} contentElementId="edit-widget-publish-button-tooltip">
                  <div onMouseEnter={() => setShowMissingTranslationTooltip(true)} onMouseLeave={() => setShowMissingTranslationTooltip(false)}>
                    <DidomiButton
                      data-tracking="pmp-publish-widget"
                      disabled={true}
                      variant="top"
                      data-testid="widget-publish-button"
                      iconRight="save-publish"
                      data-skeleton
                      onClick={() => {
                        handleSave().then(data => {
                          if (data) {
                            initialPublicationStatus.current = widget?.publication_status;

                            deployWidget({
                              widget_id: widget.id,
                            });
                          }
                        });
                      }}
                    >
                      Publish
                    </DidomiButton>
                  </div>
                </DidomiTooltip>
              </>
            ) : (
              <DidomiButton
                data-tracking="pmp-publish-widget"
                disabled={isPublishButtonDisabled}
                variant="top"
                data-testid="widget-publish-button"
                iconRight="save-publish"
                data-skeleton
                onClick={() =>
                  handleSave().then(data => {
                    if (data) {
                      initialPublicationStatus.current = widget?.publication_status;

                      deployWidget({
                        widget_id: widget.id,
                      });
                    }
                  })
                }
              >
                Publish
              </DidomiButton>
            )}
          </div>
        }
      >
        <WidgetPreviewLayout
          widgetId={widgetId}
          widgetFormat={widget?.format}
          isWidgetHeadless={widgetHeadless}
          widgetName={widgetName}
          isLoading={isLoading}
          setIframe={setIframe}
          header={
            <Route exact path={['/widgets/edit/:id/content-editor', '/widgets/edit/:id/hosting']}>
              {widget?.format === WidgetFormat.HOSTED && (
                <>
                  <WidgetHostingHeader
                    customTitle={getWidgetTitle()}
                    didomiLogo={getWidgetHeaderLogo()}
                    domain={widget?.domain?.toLocaleUpperCase() as unknown as WidgetUtilitiesDomain}
                    publicationStatus={widget?.publication_status.toLocaleUpperCase() as unknown as WidgetUtilitiesPublicationStatus}
                    customDomainId={widget?.custom_domain_id}
                    subdomainId={widget?.subdomain_id}
                    selectable={mode === WidgetPreviewMode.ContentEditor}
                    disableActions={[WidgetPreviewMode.ContentEditor, WidgetPreviewMode.ContentEditorNoSelection].includes(mode)}
                    isSelected={mode === WidgetPreviewMode.ContentEditor && selectedComponent?.id === widget.meta_tags?.id}
                    onClick={() => mode === WidgetPreviewMode.ContentEditor && selectCustomComponent(widget.meta_tags?.id)}
                  />
                  <div className="w-full border-t border-solid border-neutral-gray-4" />
                </>
              )}
            </Route>
          }
        >
          <>
            {hasPreferencesRelease ? (
              <Route exact path="/widgets/edit/:id/purposes-and-preferences">
                <EditWidgetPreferences
                  preventDisableAllPreferenceValues={allowDisableSetWidgetPreferenceValue}
                  isLoading={loadingPreferencesData}
                  entities={entities}
                  layoutEntities={layoutEntities}
                  widgetPreferences={widgetPreferences}
                  setWidgetLayoutEntityEnabled={setWidgetLayoutEntityEnabled}
                  hasEnabledLayoutEntity={hasEnabledLayoutEntity}
                  renderSinglePreference={templateConfig.renderSinglePreference}
                  components={currentComponents}
                  getBreadcrumb={getBreadcrumb}
                  preferenceOrderingControls={preferenceOrderingControls}
                />
              </Route>
            ) : null}

            <Route exact path="/widgets/edit/:id/look-and-feel">
              <EditWidgetTheme
                widgetTemplateId={widgetTemplateId}
                widgetFormat={widgetFormat}
                widgetHeadless={widgetHeadless}
                onWidgetHeadlessChanged={headless => setWidgetHeadless(headless)}
              />
            </Route>
            <Route exact path="/widgets/edit/:id/content-editor">
              <EditWidgetContentEditor
                componentId={selectedComponent.id}
                metadata={selectedComponent.metadata}
                selectedComponentType={selectedComponent.type}
                selectedComponentData={selectedComponent.data}
                setComponentData={setComponentData}
                hideSectionFields={templateConfig.hideSectionFields}
                isLoading={isLoading}
              />
            </Route>
            {widget?.format === WidgetFormat.HOSTED && (
              <Route exact path="/widgets/edit/:id/hosting">
                <HostingRightPanel
                  useYourDomainCardTitle={'Use your own Preference Center domain.'}
                  useYourDomainCardDescription={'We recommend using your own domain name instead of the one assigned to your Preference Center.'}
                  isLoading={isLoadingWidget}
                  widgetDomainInputProps={widgetDomainInput}
                  widgetDomain={widget?.domain?.toLocaleUpperCase()}
                  widgetCustomDomainId={widget?.custom_domain_id}
                />
              </Route>
            )}
            {widget?.format === WidgetFormat.EMBEDDABLE && (
              <Route exact path="/widgets/edit/:id/embedding">
                <EmbeddingRightPanel widgetId={widgetId} apiKey={organization.public_api_key} isLoading={isLoadingWidget} sdkLoader={CONFIG.sdkLoader} headless={widgetHeadless} />
              </Route>
            )}
          </>
        </WidgetPreviewLayout>
      </SecondaryLayout>
      <NavigationPrompt
        data-testid="edit-widget-navigation-prompt"
        when={!isLoading && hasDataChanged}
        title="Your changes will be lost"
        subTitle="Please pay attention"
        description="Are you sure you want to leave your Widget configuration without saving?"
        okText="Keep on editing"
        cancelText="Don’t save"
        actionText="Save"
        illustration="widget-lost"
        onOK={() => true}
        onIntermediateAction={async () => {
          try {
            await handleSave();
            return true;
          } catch (error) {
            return false;
          }
        }}
        onCancel={() => true}
        shouldAllowRouteChange={shouldAllowRouteChange}
      />
      <LoadingModal data-cy="edit-widget-saving" isOpen={isSaving} title="We are saving the widget..." />
      <EditWidgetPublishedModal
        isModalOpen={displayPublishModal}
        initialWidgetPublicationStatus={initialPublicationStatus.current}
        widgetId={widgetId}
        widgetFormat={widgetFormat}
        widgetTemplateId={widgetTemplateId}
        widgetDomain={widget?.domain}
        widgetSubdomainId={widget?.subdomain_id}
        widgetCustomDomainId={widget?.custom_domain_id}
        onModalClosed={() => {
          setDisplayPublishModal(false);
        }}
      />
      <MissingTranslationDrawer />
    </DidomiSkeleton>
  );
};

export { EditWidget };
