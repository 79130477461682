import { AxiosError } from 'axios';
import { PaginatedResponse } from '@types';
import { useQuery, UseQueryOptions, useQueryClient } from '@tanstack/react-query';
import { useActiveOrganization } from '@didomi/utility-react';
import { DEFAULT_LIMIT, apiRoutes } from '@constants';
import { useAxiosWithLogOut } from '../useAxiosWithInterceptors.hook';
import { LayoutEntity, ErrorResponse } from '@interfaces';
import { useCallback } from 'react';

export interface UseLayoutEntitiesQuery {
  widget_id?: string;
  limit?: number;
}

export type FetchLayoutEntitiesAsyncFnType = (widgetId: string) => Promise<PaginatedResponse<LayoutEntity>>;

export const useLayoutEntities = ({ widget_id, limit = DEFAULT_LIMIT }: UseLayoutEntitiesQuery = {}, options: UseQueryOptions<PaginatedResponse<LayoutEntity>> = {}) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();
  const queryClient = useQueryClient();

  const fetchParams = { organization_id: organizationId, $limit: limit, widget_id };

  const fetch = useCallback(
    async (params: any) => {
      const response = await axiosWithInterceptors.get<PaginatedResponse<LayoutEntity>>(apiRoutes.layoutEntities, {
        params,
      });
      return response.data;
    },
    [axiosWithInterceptors],
  );

  const fetchAsync: FetchLayoutEntitiesAsyncFnType = useCallback(
    (widgetId: string) => {
      const params = { organization_id: organizationId, $limit: limit, widget_id: widgetId };
      return queryClient.fetchQuery({
        queryKey: ['layout-entities', params],
        queryFn: () => fetch(params),
      });
    },
    [queryClient, limit, organizationId, fetch],
  );

  return {
    ...useQuery<PaginatedResponse<LayoutEntity>, AxiosError<ErrorResponse>>(['layout-entities', fetchParams], () => fetch(fetchParams), options),
    fetchAsync,
  };
};
