import React from 'react';
import { DidomiButton, DidomiIllustration } from '@didomi/ui-atoms-react';
import { Illustrations } from '@didomi/ui-foundation';

type GenericEmptyStateProps = {
  title?: string;
  helperText?: string;
  illustration?: Illustrations;
  ctaText?: React.ReactNode;
  ctaDataCy?: string;
  ctaIcon?: {
    position: 'left' | 'right';
    icon: string;
  };
  onCtaClick?: () => void;
  className?: string;
};

/**
 * Generic empty state component with slot for title, description, illustration and configurable container
 * TODO: discuss about expanding existing DidomiEmptyState with this variation
 */
const GenericEmptyState = ({ title, className, illustration, helperText, ctaDataCy, ctaText, ctaIcon, onCtaClick }: GenericEmptyStateProps) => (
  <div className={`w-full flex-1 flex flex-col items-center justify-center ${className ? className : ''}`}>
    <DidomiIllustration name={illustration} className="mb-xs" />
    <h2 className="h1 text-primary-blue-6">{title}</h2>
    <p className="mt-xxs">{helperText}</p>
    {ctaText && (
      <DidomiButton
        className="w-80 my-6"
        iconRight={ctaIcon?.position === 'right' && ctaIcon?.icon}
        iconLeft={ctaIcon?.position === 'left' && ctaIcon?.icon}
        data-tracking="pmp-create-widget"
        data-cy={ctaDataCy}
        onClick={() => {
          if (onCtaClick) {
            onCtaClick();
          }
        }}
      >
        {ctaText}
      </DidomiButton>
    )}
  </div>
);

export { GenericEmptyState };
