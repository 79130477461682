import { Configuration, ShapesInput, ComponentsListInput, ComponentType, Languages, WidgetInput } from '@didomi/pmp-generator';
import { ConfigTree, LayoutComponent, LayoutEntity, Layout, WidgetTheme } from '@interfaces';
import { snakeToCamelCase } from '@utils';
import { WidgetFormat, WidgetLayoutShape } from '@enums';

type buildWidgetPreviewPayloadProps = {
  layout?: Layout;
  components: LayoutComponent[];
  organization: {
    public_api_key?: string;
  };
  theme: WidgetTheme;
  layoutShape: WidgetLayoutShape;
  mode: string;
  display: string;
  format: WidgetFormat;
  configurationTree: ConfigTree;
  updatedComponents?: ComponentsListInput;
  flags?: WidgetInput['flags'];
  layoutEntities: LayoutEntity[];
  languages: Languages;
};

export const buildComponentList = (components: LayoutComponent[]): ComponentsListInput => {
  if (!components) {
    return {};
  }

  return components.reduce((acc, { id, options, type, theme }) => {
    acc[id] = { id, options, type, theme };
    return acc;
  }, {});
};

/**
 * Currently the generator is accepting the component type as a lower case enum (header, save, footer, etc)
 * and the backend is returning this enum as upper case (HEADER, SAVE, FOOTER).
 * @param components
 * @returns
 */
export const fixComponentType = (components?: LayoutComponent[]): LayoutComponent[] => {
  if (!components) {
    return [];
  }
  return components.map(({ type, ...props }) => ({
    ...props,
    type: ComponentType[type] || type,
  }));
};

export const buildWidgetPreviewPayload = ({
  configurationTree,
  mode,
  display,
  format,
  theme,
  layoutShape,
  organization,
  layout,
  components,
  updatedComponents,
  flags,
  layoutEntities,
  languages,
}: buildWidgetPreviewPayloadProps) => {
  const list = updatedComponents ? Object.values(updatedComponents) : components;
  const authMethod = format === WidgetFormat.HOSTED ? 'email' : null;

  return Configuration.generateConfiguration({
    apiKey: organization.public_api_key,
    widgets: [
      {
        // For the preview, we don't really need to have the proper widget ID.
        // Since it is easier to keep a static one in the iframe, we use it here as well
        id: 'preview-container',
        mode,
        display,
        options: {
          auth: {
            method: authMethod,
            componentOptions: {},
            hideIfNotAuthenticated: false,
          },
        },
        theme: {
          colors: snakeToCamelCase(theme),
          layoutShape: ShapesInput[layoutShape.toString()?.toUpperCase()],
        },
        layout: snakeToCamelCase(layout),
        layoutComponents: list,
        configTree: snakeToCamelCase(configurationTree),
        flags,
        layoutEntities: snakeToCamelCase(layoutEntities),
        languages,
        headless: false,
      },
    ],
  });
};
