import { Factory } from 'miragejs';
import { generateUniqueId } from '@utils';

const widgetMetaTag = Factory.extend({
  id() {
    return generateUniqueId('widgetMetaTag');
  },
  title(i) {
    return { en: `Meta tag title ${i}` };
  },
  favicon(i) {
    return `https://didomi.io/asset/favicon${i}.png`;
  },
  created_at: '2021-11-10 15:00:00.000',
  updated_at: '2021-11-10 15:00:00.000',
});

export default { widgetMetaTag };
