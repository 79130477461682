import { BaseModel } from '@interfaces';
import { QueryClient } from '@tanstack/react-query';
import { PaginatedResponse } from '@types';
import { AxiosResponse } from 'axios';

/**
 * Updates the cache of a given query client based on the provided response.
 *
 * @template T
 *   Type that extends from BaseModel.
 *
 * @param {QueryClient} queryClient
 *   An instance of the react-query's QueryClient.
 *
 * @param {AxiosResponse<T>} response
 *   The Axios response containing the data to be updated in the cache.
 *
 * @param {string[]} dataKey
 *   An array of strings representing the keys that identify the data in the cache.
 *
 * @returns {void}
 *
 * @description
 *   The function takes in an instance of react-query's QueryClient, an Axios response,
 *   and the cache data key as parameters. It checks if the data inside the cache is
 *   paginated (contains an array of data) or a single item.
 *
 *   If it's paginated, it maps through the data array and replaces the entity with
 *   a matching ID from the Axios response. If it's a single item and the ID matches,
 *   it replaces the entire item with the data from the Axios response.
 *
 * @example
 *   const queryClient = new QueryClient();
 *   const axiosResponse = { data: { id: 1, name: "Updated Name" } };
 *   updateQueryClientCache(queryClient, axiosResponse, ['dataKey']);
 *
 */
export function updateQueryClientCache<T extends BaseModel>(queryClient: QueryClient, response: AxiosResponse<T>, dataKey: string[]) {
  queryClient.setQueriesData(dataKey, (data: PaginatedResponse<T> | T) => {
    if ('data' in data && Array.isArray(data.data)) {
      return {
        ...data,
        data: data.data.map(entity => (entity.id === response.data.id ? { ...entity, ...response.data } : entity)),
      };
    } else if ('id' in data && data.id === response.data.id) {
      return { ...data, ...response.data };
    }
  });
}

/**
 * Updates the cache of a given query client by deleting an item based on the provided response.
 *
 * @template T
 *   Type that extends from BaseModel.
 *
 * @param {QueryClient} queryClient
 *   An instance of the react-query's QueryClient.
 *
 * @param {AxiosResponse<T>} response
 *   The Axios response containing the data to be deleted from the cache.
 *
 * @param {string[]} dataKey
 *   An array of strings representing the keys that identify the data in the cache.
 *
 * @returns {void}
 *
 * @description
 *   The function takes in an instance of react-query's QueryClient, an Axios response,
 *   and the cache data key as parameters. It checks if the data inside the cache is
 *   paginated (contains an array of data) or a single item.
 *
 *   If it's paginated, it maps through the data array and removes the entity with
 *   a matching ID from the Axios response. If it's a single item and the ID matches,
 *   it removes the entire item from the cache.
 *
 * @example
 *   const queryClient = new QueryClient();
 *   const axiosResponse = { data: { id: 1, name: "Updated Name" } };
 *   updateQueryClientCacheDeleteItem(queryClient, axiosResponse, ['dataKey']);
 *
 */
export function updateQueryClientCacheDeleteItem<T extends BaseModel>(queryClient: QueryClient, response: AxiosResponse<T>, dataKey: string[]) {
  queryClient.setQueriesData(dataKey, (data: PaginatedResponse<T> | T) => {
    if ('data' in data && Array.isArray(data.data)) {
      const filteredData = data.data.filter(entity => entity.id !== response.data.id);

      return {
        ...data,
        total: filteredData.length,
        data: filteredData,
      };
    }
  });
}

/**
 * Updates the cache of a given query client by adding an item based on the provided response.
 *
 * @template T
 *   Type that extends from BaseModel.
 *
 * @param {QueryClient} queryClient
 *   An instance of the react-query's QueryClient.
 *
 * @param {AxiosResponse<T>} response
 *   The Axios response containing the data to be added to the cache.
 *
 * @param {string[]} dataKey
 *   An array of strings representing the keys that identify the data in the cache.
 *
 * @returns {void}
 *
 * @description
 *   The function takes in an instance of react-query's QueryClient, an Axios response,
 *   and the cache data key as parameters. It checks if the data inside the cache is
 *   paginated (contains an array of data) or a single item.
 *
 *   If it's paginated, it adds the entity from the Axios response to the end of the data array.
 *
 * @example
 *   const queryClient = new QueryClient();
 *   const axiosResponse = { data: { id: 1, name: "Updated Name" } };
 *   updateQueryClientCacheAddItem(queryClient, axiosResponse, ['dataKey']);
 *
 */
export function updateQueryClientCacheAddItem<T extends BaseModel>(queryClient: QueryClient, response: AxiosResponse<T>, dataKey: any[]) {
  queryClient.setQueriesData(dataKey, (data: PaginatedResponse<T> | T) => {
    // Data is undefined when enabled option is set to false in the useQuery.
    if (!data) {
      return;
    }

    if ('data' in data && Array.isArray(data.data)) {
      const allData = [response.data, ...data.data];

      return {
        ...data,
        total: allData.length,
        data: allData,
      };
    }
  });
}
