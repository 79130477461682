type Args = { queryParams?: { skip?: number; $skip?: number; limit?: number; $limit?: number } };

export const formatPaginationResponse = <T>(allData: T[], args: Args): { data: T[]; total: number; limit: number; skip: number } => {
  const skip = +args?.queryParams?.skip || +args?.queryParams?.$skip || 0;
  const limit = +args?.queryParams?.limit || +args?.queryParams?.$limit || 100;

  return {
    data: allData.slice(skip, limit + skip),
    total: allData.length,
    limit,
    skip,
  };
};
