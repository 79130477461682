import { ComponentOutput, ComponentsListInput, DidomiPreferenceOptions, DidomiPreferenceValueOptions, DidomiSectionOptions, Language, Translations } from '@didomi/pmp-generator';
import { PreferenceOutput, PurposeOutput, ValueOutput } from '@didomi/pmp-generator/dist/types/entities/models/entities.model';
import { MissingTranslations } from '@types';

/**
 * Recursively goes trough the purposes and returns all the missing translations for the enabled languages.
 */
export const getMissingTranslation = (purposes: PurposeOutput[], components: ComponentsListInput, enabledLanguages: Language[]): MissingTranslations => {
  const hasPurposes = purposes && purposes.length > 0;
  const hasComponents = components && Object.keys(components).length > 0;
  const hasEnabledLanguages = enabledLanguages && enabledLanguages.length > 0;
  if (!hasPurposes || !hasComponents || !hasEnabledLanguages) {
    return {} as MissingTranslations;
  }

  return getPurposeMissingTranslation(purposes, components, enabledLanguages);
};

/**
 * Returns the missing translations for the purposes.
 */
export const getPurposeMissingTranslation = (
  purposes: PurposeOutput[],
  components: ComponentsListInput,
  enabledLanguages: Language[],
  missingTranslationRef = {} as MissingTranslations,
) => {
  purposes.forEach(purpose => {
    const component = components[purpose?.component?.id] as ComponentOutput;
    /**
     * Single preference widget does not contain the component attached to the purpose as
     * the purpose is not displayed.
     */
    if (component && purpose.enabled) {
      const titleContent = (component.options as DidomiSectionOptions)?.purposeOptions?.title?.content;
      const titleMissingLanguages = getMissingLanguages(purpose.name, titleContent, enabledLanguages);

      insertMissingTranslation(titleMissingLanguages, purpose.name, purpose.id, component, null, missingTranslationRef);
    }

    // if the purpose has preferences we return the preferences translations as well
    if (purpose.preferences) {
      return getPreferencesMissingTranslation(purpose.preferences, components, enabledLanguages, missingTranslationRef);
    }
  });

  return missingTranslationRef;
};

/**
 * Returns the missing translations for the preferences.
 */
export const getPreferencesMissingTranslation = (
  preferences: PreferenceOutput[],
  components: ComponentsListInput,
  enabledLanguages: Language[],
  missingTranslationRef = {} as MissingTranslations,
) => {
  preferences.forEach(preference => {
    const id = preference.component.id;
    const component = components[id] as ComponentOutput;
    const titleContent = (component.options as DidomiPreferenceOptions)?.title?.content;
    const titleMissingLanguages = getMissingLanguages(preference.name, titleContent, enabledLanguages);

    insertMissingTranslation(titleMissingLanguages, preference.name, preference.id, component, null, missingTranslationRef);

    // if the preference has values we return the values translations as well
    if (preference.values && preference.values.length > 0) {
      return getPreferenceValuesMissingTranslation(preference.values, components, id, enabledLanguages, missingTranslationRef);
    }
  });

  return missingTranslationRef;
};

/**
 * Returns the missing translations for the preference values.
 */
export const getPreferenceValuesMissingTranslation = (
  values: ValueOutput[],
  components: ComponentsListInput,
  parentComponentId: string,
  enabledLanguages: Language[],
  missingTranslationRef = {} as MissingTranslations,
) => {
  values.forEach(value => {
    const component = components[value.component.id] as ComponentOutput;
    const labelContent = (component.options as DidomiPreferenceValueOptions)?.label?.content;
    const labelMissingLanguages = getMissingLanguages(value.name, labelContent, enabledLanguages);

    insertMissingTranslation(labelMissingLanguages, value.name, value.id, component, parentComponentId, missingTranslationRef);

    // if the value has preferences we return the preferences translations as well
    if (value.preferences && value.preferences.length > 0) {
      return getPreferencesMissingTranslation(value.preferences, components, enabledLanguages, missingTranslationRef);
    }
  });

  return missingTranslationRef;
};

/**
 * For every missing language, inserts the corresponding missing translations by reference.
 */
export const insertMissingTranslation = (
  missingLanguages: Language[],
  content: Translations,
  id: string,
  component: ComponentOutput,
  parentComponentId: string,
  missingTranslationRef,
) => {
  if (missingLanguages.length === 0) {
    return;
  }

  missingLanguages.forEach(language => {
    const translations = missingTranslationRef[language] || [];
    translations.push({
      content: structuredClone(content), // deep clone of the content, to avoid holding references
      componentId: component.id,
      componentType: component.type,
      parentComponentId,
      language,
      id,
    });
    missingTranslationRef[language] = translations;
  });
};

/**
 * Returns a list of all the missing enabled languages for the given content (union of entity content and component content).
 */
export const getMissingLanguages = (entityContent: string | Translations, componentContent: string | Translations, enabledLanguages: Language[]) => {
  const languages = [];

  enabledLanguages.forEach(lang => {
    const hasEntityContent = typeof entityContent === 'string' || (entityContent && entityContent[lang]);
    const hasComponentContent = typeof componentContent === 'string' || (componentContent && componentContent[lang]);
    if (!hasEntityContent && !hasComponentContent) {
      languages.push(lang);
    }
  });

  return languages;
};
