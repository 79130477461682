import React from 'react';

import { InAppHelper } from '@components';
import WidgetHeroImage from '../../assets/illustrations/helpers/widget-hero.png';
import ColorsCustomizationImage from '../../assets/illustrations/helpers/colours-customization.png';

export const WidgetHelperAction = () => {
  return (
    <InAppHelper data-tracking="pmp-open-in-app-help" title="What is a widget?">
      <h3 className="h3 font-normal">
        A widget is an <strong className="text-primary-blue-4">end-user facing form</strong> used to collect their preferences.
      </h3>
      <figure className="mt-s">
        <img src={WidgetHeroImage} alt="Shows widget form UI" />
      </figure>
      <p className="mt-s pb-xxxs">
        A widget is based on the unique Configuration Tree defined at the organization level and exposes to end user{' '}
        <strong>only a part or all the purposes and preferences</strong> defined in this Configuration Tree.
      </p>
      <figure className="mt-m">
        <img src={ColorsCustomizationImage} alt="Customize theme colors" />
      </figure>
      <p className="mt-s">
        For each widget, you can customize <strong>the color theme, the layout shapes</strong> and <strong>the content</strong> to make it fit with your business and design needs.
      </p>
    </InAppHelper>
  );
};
