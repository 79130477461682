import { formatPaginationResponse, sort } from '../utils';

export const widgetDeploymentsRoutes = {
  '/widgets/deployments': {
    get: (schema: any, request) => {
      const organizationId = request.queryParams.organization_id;
      const widgetId = request.queryParams?.widget_id;
      const widgetDeploymentsByOrg = schema.widgetDeployments.where({ organization_id: organizationId, widget_id: widgetId }).models.map(w => w.attrs);
      const data: any[] = sort({ queryParams: request.queryParams, data: widgetDeploymentsByOrg });
      return formatPaginationResponse(data, {
        ...request,
        queryParams: request.queryParams,
      });
    },
    post: (schema: any, request) => {
      const widgetDeployment = { ...JSON.parse(request.requestBody), config: { test: 1 } };
      const newWidgetDeployment = schema.widgetDeployments.create(widgetDeployment);
      return newWidgetDeployment.attrs;
    },
  },
};
