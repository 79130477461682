import { WidgetFormat, WidgetLayoutShape, WidgetPublicationStatus, WidgetTemplateId, WidgetDomain } from '@enums';
import { devOrganizationId } from '@constants';
import { Language } from '@didomi/pmp-generator';
import { createDefaultLayout } from '../layouts/seeds';
import { createDefaultLayoutEntities } from '../layoutEntities/seeds';
import { createDefaultPreferences } from '../preferences/seeds';
import { createDefaultPurposes } from '../purposes/seeds';
import { createDefaultConfigTree } from '../configTree/seeds';
import { createDefaultWidgetThemes } from '../widgetThemes/seeds';

export const createDefaultWidgets = (server, defaultOrganization = devOrganizationId) => {
  server.create('widget', {
    custom_domain_id: '',
    domain: WidgetDomain.SUB,
    format: WidgetFormat.HOSTED,
    id: 'widget-1',
    layout_shape: WidgetLayoutShape.ROUNDED,
    name: 'My first widget',
    subdomain_id: 'subdomain-id',
    organization_id: defaultOrganization,
    theme_id: 'default_theme_id_2',
    custom_theme_id: 'custom_theme_1',
    publication_status: WidgetPublicationStatus.DRAFT,
    enabled_languages: [Language.EN],
    default_language: Language.EN,
    template_id: WidgetTemplateId.SECTIONS_AND_SAVE,
  });

  server.create('widget', {
    custom_domain_id: 'customdomain-id',
    domain: WidgetDomain.CUSTOM,
    format: WidgetFormat.HOSTED,
    id: 'widget-2',
    layout_shape: WidgetLayoutShape.ROUNDED,
    name: 'Archived Widget 02',
    subdomain_id: '',
    organization_id: defaultOrganization,
    theme_id: 'default_theme_id_3',
    custom_theme_id: 'custom_theme_1',
    publication_status: WidgetPublicationStatus.DRAFT,
    enabled_languages: [Language.EN],
    default_language: Language.EN,
  });

  server.create('widget', {
    custom_domain_id: 'customdomain-id',
    domain: WidgetDomain.CUSTOM,
    format: WidgetFormat.EMBEDDABLE,
    id: 'widget-3',
    layout_shape: WidgetLayoutShape.ROUNDED,
    name: 'Widget 03',
    subdomain_id: '',
    template_id: WidgetTemplateId.SECTIONS,
    organization_id: defaultOrganization,
    theme_id: 'default_theme_id_2',
    custom_theme_id: 'custom_theme_1',
    publication_status: WidgetPublicationStatus.UNPUBLISHED_CHANGES,
    enabled_languages: [Language.EN],
    default_language: Language.EN,
  });

  server.create('widget', {
    custom_domain_id: '',
    domain: WidgetDomain.SUB,
    format: WidgetFormat.HOSTED,
    id: 'widget-4',
    layout_shape: WidgetLayoutShape.ROUNDED,
    name: 'Archived Widget 04',
    subdomain_id: 'subdomain-id',
    organization_id: defaultOrganization,
    theme_id: 'custom_theme_1',
    custom_theme_id: 'custom_theme_1',
    enabled_languages: [Language.EN],
    default_language: Language.EN,
  });

  server.create('widgetMetaTag', {
    id: 'widget_5_meta_tags_1',
  });

  server.create('widget', {
    custom_domain_id: '',
    domain: WidgetDomain.SUB,
    format: WidgetFormat.HOSTED,
    id: 'widget-5',
    layout_shape: WidgetLayoutShape.ROUNDED,
    name: 'Widget 05',
    subdomain_id: 'subdomain-id',
    organization_id: defaultOrganization,
    theme_id: 'default_theme_id_1',
    custom_theme_id: 'custom_theme_1',
    publication_status: WidgetPublicationStatus.PUBLISHED,
    enabled_languages: [Language.EN],
    default_language: Language.EN,
    meta_tags_id: 'widget_5_meta_tags_1',
    template_id: WidgetTemplateId.FULL_PREFERENCE_CENTER,
  });

  server.create('widget', {
    custom_domain_id: '',
    domain: WidgetDomain.SUB,
    format: WidgetFormat.EMBEDDABLE,
    id: 'widget-6',
    layout_shape: WidgetLayoutShape.ROUNDED,
    name: 'Widget 06',
    template_id: WidgetTemplateId.SECTIONS,
    subdomain_id: 'subdomain-id',
    organization_id: defaultOrganization,
    theme_id: 'default_theme_id_1',
    custom_theme_id: 'custom_theme_3',
    publication_status: WidgetPublicationStatus.UNPUBLISHED_CHANGES,
    enabled_languages: [Language.EN],
    default_language: Language.EN,
  });

  server.create('widget', {
    custom_domain_id: '',
    domain: WidgetDomain.SUB,
    format: WidgetFormat.EMBEDDABLE,
    id: 'widget-7',
    layout_shape: WidgetLayoutShape.ROUNDED,
    name: 'Widget 07',
    template_id: WidgetTemplateId.SECTIONS,
    subdomain_id: 'subdomain-id',
    organization_id: defaultOrganization,
    theme_id: 'default_theme_id_1',
    custom_theme_id: 'custom_theme_1',
    publication_status: WidgetPublicationStatus.UNPUBLISHED_CHANGES,
    enabled_languages: [Language.EN, Language.FR],
    default_language: Language.EN,
  });

  server.create('widget', {
    custom_domain_id: '',
    domain: WidgetDomain.SUB,
    format: WidgetFormat.HOSTED,
    id: 'widget-8',
    layout_shape: WidgetLayoutShape.ROUNDED,
    name: 'Widget 08',
    template_id: WidgetTemplateId.FULL_PREFERENCE_CENTER,
    subdomain_id: 'subdomain-id',
    organization_id: defaultOrganization,
    theme_id: 'default_theme_id_1',
    custom_theme_id: 'custom_theme_1',
    publication_status: WidgetPublicationStatus.UNPUBLISHED_CHANGES,
    enabled_languages: [Language.EN, Language.FR],
    default_language: Language.EN,
  });

  createDefaultPreferences(server, defaultOrganization);
  createDefaultPurposes(server, defaultOrganization);
  createDefaultConfigTree(server, defaultOrganization);
  createDefaultWidgetThemes(server, defaultOrganization);
  createDefaultLayout(server, defaultOrganization, 'widget-1');
  createDefaultLayout(server, defaultOrganization, 'widget-2');
  createDefaultLayout(server, defaultOrganization, 'widget-3');
  createDefaultLayout(server, defaultOrganization, 'widget-4');
  createDefaultLayout(server, defaultOrganization, 'widget-5');
  createDefaultLayout(server, defaultOrganization, 'widget-6');
  createDefaultLayout(server, defaultOrganization, 'widget-7');
  createDefaultLayout(server, defaultOrganization, 'widget-8');
  createDefaultLayoutEntities(server, defaultOrganization, 'widget-1');
  createDefaultLayoutEntities(server, defaultOrganization, 'widget-2');
  createDefaultLayoutEntities(server, defaultOrganization, 'widget-3');
  createDefaultLayoutEntities(server, defaultOrganization, 'widget-4');
  createDefaultLayoutEntities(server, defaultOrganization, 'widget-5');
  createDefaultLayoutEntities(server, defaultOrganization, 'widget-6');
  createDefaultLayoutEntities(server, defaultOrganization, 'widget-7');
  createDefaultLayoutEntities(server, defaultOrganization, 'widget-8');
};
