import { useCallback, useState, useMemo, useRef, useEffect } from 'react';
import { Organization } from '@didomi/utility';
import debounce from 'lodash.debounce';
import isEqual from 'lodash.isequal';
import { ComponentType } from '@didomi/pmp-generator';
import {
  buildComponentList,
  createEntityMappingFromConfigTree,
  createSelectedComponentState,
  createEntityToComponentIdMapping,
  getBreadcrumb as getBreadcrumbUtil,
  removeEmptyOptions,
} from '@utils';
import { WidgetFormat, WidgetPreviewMode, WidgetPreviewSelectionType, WidgetTemplateId } from '@enums';
import { UPDATE_IFRAME_DELAY, WIDGET_TEMPLATE_ID_TO_WIDGET_DISPLAY } from '@constants';
import { useLayoutComponents, useLayoutEntities } from '@hooks';
import { useGetWidgetMode } from './useGetWidgetMode.hook';
import type {
  ContentEditorFormData,
  ContentEditorFormDataSetter,
  SelectedComponent,
  Breadcrumb,
  GetBreadcrumb,
  ComponentChangePreferenceValuePayload,
  ExtendedComponentsListInput,
  ExtendedComponentInput,
} from '@types';
import { useWidgetPreviewIframe } from './useWidgetPreviewIframe.hook';
import { useEditWidgetPreferences } from './useEditWidgetPreferences.hook';
import { useConfigTrees } from './data';

interface Options {
  initialComponentType: ComponentType;
  selectionType: WidgetPreviewSelectionType;
  organization: Organization;
  widgetId: string;
  widgetTemplateId?: WidgetTemplateId;
  widgetFormat: WidgetFormat;
}

const MODE_FIRE_SELECT_COMPONENT = [WidgetPreviewMode.ContentEditor, WidgetPreviewMode.ContentEditorNoSelection];

export const useConnectContentEditorWithPreview = ({
  organization,
  widgetId,
  widgetFormat,
  widgetTemplateId = WidgetTemplateId.FULL_PREFERENCE_CENTER,
  initialComponentType,
  selectionType,
}: Options) => {
  const mode = useGetWidgetMode(widgetTemplateId);
  const [componentsDiff, setComponentsDiff] = useState<ExtendedComponentsListInput>({});
  const currentComponents = useRef<ExtendedComponentsListInput>({});
  const savedComponents = useRef<ExtendedComponentsListInput>({});
  const lastSelectedMode = useRef<WidgetPreviewMode>(null);
  const display = WIDGET_TEMPLATE_ID_TO_WIDGET_DISPLAY[widgetTemplateId];

  const [selectedComponent, setSelectedComponent] = useState<SelectedComponent>({
    id: null,
    type: null,
    data: {},
  });

  const { data: configTrees, isLoading: isLoadingConfigurationTree } = useConfigTrees();

  const {
    data: layoutEntitiesData,
    isLoading: isLoadingLayoutEntities,
    refetch: refetchLayoutEntities,
  } = useLayoutEntities({
    widget_id: widgetId,
    limit: 1000,
  });

  const { isLoading: isLoadingLayoutComponents, refetch: refetchLayoutComponents, data: dataLayoutComponents } = useLayoutComponents({ widget_id: widgetId, limit: 1000 });
  const layoutComponents = useMemo(() => dataLayoutComponents?.data || [], [dataLayoutComponents]);
  const layoutEntities = useMemo(() => layoutEntitiesData?.data || [], [layoutEntitiesData]);

  const layoutEntitiesMap = useMemo(() => createEntityToComponentIdMapping(layoutEntities), [layoutEntities]);
  const configurationTree = configTrees?.data?.[0];
  const entityMapping = useMemo(() => createEntityMappingFromConfigTree(configurationTree), [configurationTree]);

  useEffect(() => {
    currentComponents.current = {
      ...currentComponents.current,
      ...buildComponentList(layoutComponents),
    };
    savedComponents.current = {
      ...savedComponents.current,
      ...removeEmptyOptions(buildComponentList(layoutComponents)), // It needs to reprocess to get a new instance of the components to compare
    };
    setComponentsDiff({});
  }, [layoutComponents]);

  /**
   * Include a custom component that on the editable component list.
   * Current is used to simulate a component for the widget meta tags.
   * @param component
   */
  const includeCustomComponentInput = (component: ExtendedComponentInput) => {
    currentComponents.current = {
      ...currentComponents.current,
      [component.id]: component,
    };
    savedComponents.current = {
      ...savedComponents.current,
      [component.id]: removeEmptyOptions(component), // It needs to reprocess to get a new instance of the components to compare
    };
    setComponentsDiff({});
  };

  const isPreferencesDataLoading = isLoadingConfigurationTree || isLoadingLayoutEntities || isLoadingLayoutComponents;

  const {
    widgetPreferences,
    setWidgetLayoutEntityEnabled,
    saveUpdatedLayoutEntities,
    enabledEntities,
    entities,
    preferenceOrderingControls,
    hasEnabledLayoutEntity,
    scrollToComponent,
  } = useEditWidgetPreferences({
    layoutEntities,
    configurationTree,
  });

  const iframeMessageCallbacks = useMemo(
    () => ({
      onComponentChange: ({ componentId, componentType, entityId, children }: ComponentChangePreferenceValuePayload) => {
        setSelectedComponent(
          createSelectedComponentState({
            currentComponents: currentComponents.current,
            componentType,
            componentId,
            entityMapping,
            entityId,
            childrenEntities: children,
            layoutEntitiesMap,
            widgetPreferences,
            layoutEntities,
          }),
        );
      },
    }),
    [entityMapping, layoutEntitiesMap, widgetPreferences, layoutEntities],
  );

  const {
    isSdkReady,
    setIframe,
    updateComponent,
    setSelectedComponentById,
    setSelectedComponentByType,
    updateWidgetEntities,
    scrollTo,
    loading: isLoadingWidgetPreviewIframe,
    setSelectedComponent: setSelectedComponentOnSDK,
  } = useWidgetPreviewIframe({
    organization,
    mode,
    display,
    widgetId,
    widgetFormat,
    listeners: iframeMessageCallbacks,
    configurationTree,
    layoutComponents,
    layoutEntities,
  });

  /**
   * This effect moved the sdk to the selected component that the user is moving on the Preferences Panel
   * Implemented using useEffect to avoid circular dependency between useWidgetPreviewIframe and useEditWidgetPreferences
   */
  useEffect(() => {
    if (scrollToComponent) {
      scrollTo(scrollToComponent.id);
    }
  }, [scrollToComponent, scrollTo]);

  const selectComponentOnSDK = useCallback(() => {
    const hasPurposeEnabled = !!enabledEntities?.purposes?.length; // Case when the user disable all purposes and preferences
    if (selectionType === WidgetPreviewSelectionType.SelectByComponentType || !hasPurposeEnabled) {
      setSelectedComponentByType(initialComponentType);
    } else {
      setSelectedComponentById(enabledEntities.purposes[0].component.id);
    }
  }, [selectionType, initialComponentType, setSelectedComponentByType, enabledEntities, setSelectedComponentById]);

  useEffect(() => {
    if (!layoutComponents.length || !layoutEntities.length || !isSdkReady) {
      return;
    }

    if (MODE_FIRE_SELECT_COMPONENT.includes(mode)) {
      if (mode !== lastSelectedMode.current) {
        lastSelectedMode.current = mode;
        selectComponentOnSDK();
      }
    } else {
      lastSelectedMode.current = mode;
    }
  }, [isSdkReady, mode, selectComponentOnSDK, initialComponentType, layoutComponents, layoutEntities]);

  const updateComponentDataDebounced = useMemo(() => debounce((componentId, data) => updateComponent(componentId, data), UPDATE_IFRAME_DELAY), [updateComponent]);

  const updateComponentData = useCallback(
    (componentId, data, debounce) => {
      if (debounce) {
        updateComponentDataDebounced(componentId, data);
      } else {
        updateComponent(componentId, data);
      }
    },
    [updateComponentDataDebounced, updateComponent],
  );

  const updateComponentsDiff = useCallback(
    componentId => {
      const currentComponentOptions = removeEmptyOptions(currentComponents.current[componentId]?.options);
      const savedComponentsOptions = savedComponents.current[componentId]?.options;

      if (isEqual(currentComponentOptions, savedComponentsOptions)) {
        setComponentsDiff(currentComponentsDiff => {
          const newComponentsDiff = { ...currentComponentsDiff };
          delete newComponentsDiff[componentId];
          return newComponentsDiff;
        });
      } else {
        setComponentsDiff(currentComponentsDiff => ({
          ...currentComponentsDiff,
          [componentId]: currentComponents.current[componentId],
        }));
      }
    },
    [setComponentsDiff],
  );

  const setComponentData = useCallback(
    (dataOrSetter: ContentEditorFormDataSetter<ContentEditorFormData>, debounce: boolean = true, overrideId?: string) => {
      // if overrideId has a value, it means that, even though we are updating the whole state that has data for
      // multiple forms, we only want to postMesssage the data specific to the component that has the same id as
      // overrideId.
      setSelectedComponent(currentValue => {
        const componentId = overrideId ?? currentValue.id;
        const isFunction = typeof dataOrSetter === 'function';
        const newData = isFunction ? dataOrSetter(currentValue.data) : dataOrSetter;
        const dataToSend = overrideId ? newData[overrideId] : newData;

        try {
          updateComponentData(componentId, dataToSend, debounce);
          currentComponents.current[componentId].options = dataToSend;
          updateComponentsDiff(componentId);
        } catch (error) {
          const detail = { dataToSend: dataToSend, componentId, error, message: 'Error setting component options', dsn: CONFIG.sentryDsn, release: CONFIG.commitSha };
          const errorEvent = new CustomEvent('on-spa-error', { detail });

          window.dispatchEvent(errorEvent);

          throw error;
        }

        return {
          ...currentValue,
          data: newData,
        };
      });
    },
    [updateComponentData, updateComponentsDiff],
  );

  const getBreadcrumb: GetBreadcrumb = (entityId: string): Breadcrumb[] => {
    return getBreadcrumbUtil({
      entityId,
      currentComponents: currentComponents.current,
      mapping: entityMapping,
      layoutEntitiesMap,
    });
  };

  useEffect(() => {
    if (enabledEntities) {
      updateWidgetEntities?.(enabledEntities);
    }
  }, [enabledEntities, updateWidgetEntities]);

  /**
   * Select a custom component in the content editor tab and forces the SDK to unselect the current component.
   */
  const selectCustomComponent = useCallback(
    (componentId: string) => {
      const customComponent = currentComponents.current[componentId];
      if (customComponent) {
        setSelectedComponent({
          id: customComponent.id,
          type: customComponent.type,
          data: customComponent.options as ContentEditorFormData,
        });
        setSelectedComponentOnSDK(null, null); // It forces the SDK to unselect the current component
      }
    },
    [setSelectedComponent, setSelectedComponentOnSDK],
  );

  return {
    mode,
    selectedComponent,
    isSdkReady,
    setIframe,
    setComponentData,
    setSelectedComponentById,
    scrollTo,
    loadingPreferencesData: isPreferencesDataLoading,
    loading: isLoadingLayoutComponents || isLoadingWidgetPreviewIframe || isPreferencesDataLoading,
    currentComponents: currentComponents.current,
    componentsDiff,
    refetchLayoutComponents,
    refetchLayoutEntities,
    entities,
    enabledEntities,
    widgetPreferences,
    layoutEntities,
    saveUpdatedLayoutEntities,
    setWidgetLayoutEntityEnabled,
    getBreadcrumb,
    hasEnabledLayoutEntity,
    preferenceOrderingControls,
    includeCustomComponentInput,
    selectCustomComponent,
  };
};
