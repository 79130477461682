export const sortByText = <T>(arr: T[], sort: string, dir: 'asc' | 'desc'): T[] => {
  const sorted = (a, b) => {
    if (a[sort] > b[sort]) {
      return dir === 'asc' ? 1 : -1;
    } else if (a[sort] < b[sort]) {
      return dir === 'asc' ? -1 : 1;
    }
    return 0;
  };

  return arr.sort(sorted);
};
