import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';
import { Root } from './root.component';
import { setUpDidomiUIAtoms } from '@didomi/ui-atoms-react';
import { Standalone } from '@didomi/utility';

// Entry point for Single SPA
const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  errorBoundary() {
    // Customize the root error boundary for your microfrontend here.
    return null;
  },
});

const { bootstrap, mount: mountOriginal, unmount: unmountOriginal } = lifecycles;

const setStylesForStandalone = () => {
  document.body.style.height = '100vh';
  document.body.style.margin = '0';
  setTimeout(() => {
    const spaDiv = document.getElementById('single-spa-application:@didomi-spa/pmp');
    spaDiv.style.height = '100%';
  }, 0);
};

async function mount(opts) {
  if (CONFIG.standalone) {
    await Standalone.initApp();
    setStylesForStandalone();
  }

  await setUpDidomiUIAtoms('pmp');
  mountOriginal(opts);
}

async function unmount(opts) {
  unmountOriginal(opts);
}

export { bootstrap, mount, unmount };
