import React, { useState } from 'react';
import { DidomiDrawer, DidomiDrawerContent, DidomiSmallPush } from '@didomi/ui-atoms-react';

interface InAppHelperProps {
  title: string;
  subtitle?: string;
  drawerContentTitle?: string;
  children?: React.ReactNode;
}

export const InAppHelper = ({ subtitle = 'Good to know!', title, drawerContentTitle, children }: InAppHelperProps) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  return (
    <>
      <DidomiSmallPush
        data-tracking="pmp-open-in-app-help"
        data-testid="in-app-helper-action"
        highlightedText={title}
        text={subtitle}
        smallIcon="right-on"
        onClick={() => setIsDrawerOpen(true)}
      />
      <DidomiDrawer data-testid="in-app-helper-drawer" isOpen={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
        <DidomiDrawerContent>
          <h2 className="h2 mb-s text-primary-blue-6">{drawerContentTitle ?? title}</h2>
          {children && <div className="text-body-normal text-primary-blue-6">{children}</div>}
        </DidomiDrawerContent>
      </DidomiDrawer>
    </>
  );
};
