import { Model, hasMany, belongsTo } from 'miragejs';

const selectedPreference = Model.extend({
  selected_preferences: hasMany('selectedPreference', { inverse: 'selectedPreference' }),
  preference: belongsTo('preference'),
});

const selectedPurpose = Model.extend({
  selected_preferences: hasMany('selectedPreference'),
  purpose: belongsTo('purpose'),
});

const configTree = Model.extend({
  selected_purposes: hasMany('selectedPurpose'),
});

export default { configTree, selectedPurpose, selectedPreference };
