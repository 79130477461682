import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  DidomiFeatureHeader,
  DidomiNavTabs,
  DidomiNavTabHeading,
  DidomiNavTabHeader,
  DidomiNavTabHeaders,
  DidomiNavTabContents,
  DidomiNavTabContent,
  DidomiTooltip,
  DidomiIcon,
  DidomiSkeleton,
} from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';
import { ChatBubbleSpacer } from '@components';

interface IMainLayoutProps {
  children: React.ReactChild;
  loading?: boolean;
  disableWidgetsTab?: boolean;
  disableConfigurationTreeTab?: boolean;
  disableChatBubbleSpacer?: boolean;
  Action?: React.ComponentType<any>;
}

/**
 * Main Layout
 * Default layout with header and tab navs for second level pages
 */
const MainLayout = ({ children, loading, disableWidgetsTab, disableConfigurationTreeTab, Action, disableChatBubbleSpacer = false }: IMainLayoutProps): JSX.Element => {
  const WidgetNavTabHeader = () => (
    <DidomiNavTabHeader
      disabled={disableWidgetsTab}
      iconRight={disableWidgetsTab ? 'lock' : ''}
      disabledTooltip="Sorry you don’t have access to this content, please contact your administrator"
    >
      <NavLink data-testid="nav-link-widgets" activeClassName="didomi-active-tab" data-cy="widgets" to="widgets">
        Widgets
      </NavLink>
    </DidomiNavTabHeader>
  );

  const ConfigurationTreeNavTabHeader = () => (
    <DidomiNavTabHeader
      disabled={disableConfigurationTreeTab}
      iconRight={disableConfigurationTreeTab ? 'lock' : ''}
      disabledTooltip="Sorry you don’t have access to this content, please contact your administrator"
    >
      <NavLink data-testid="nav-link-configuration" activeClassName="didomi-active-tab" data-cy="configuration" to="configuration">
        Configuration Tree
        <div className="hidden" id="config-tree-tab-tooltip">
          <p className="text-body-small font-normal">
            The Configuration Tree defines the <strong className="font-bold">data structure of the collected consent</strong>. It defines{' '}
            <strong className="font-bold">which data you can collect</strong> in your widgets and, very important,{' '}
            <strong className="font-bold">for which purpose you are collecting it</strong>.
          </p>
        </div>
        <DidomiTooltip placement="top" content-element-id="config-tree-tab-tooltip" trigger="mouseenter focus" variant="helper" distance="xs">
          <DidomiIcon name="helper-text" className="ml-xxs" />
        </DidomiTooltip>
      </NavLink>
    </DidomiNavTabHeader>
  );

  return (
    <section className="p-12 pb-7 h-full box-border !flex flex-col">
      <DidomiSkeleton isLoading={loading} variant="layout" className="flex-1 flex flex-col">
        <div className="flex mb-8">
          <DidomiFeatureHeader titleText="Preference Management" name="preference-management" className="w-full" style={{ display: 'block' }}>
            <div slot="description" data-cy="pmp-header--description">
              The Preference Management Platform allows you to collect user preferences and store them in a single configuration model.
            </div>
            <div slot="actions" data-skeleton>
              {Action && <Action />}
            </div>
          </DidomiFeatureHeader>
        </div>
        <DidomiNavTabs className="flex-1 flex flex-col">
          <DidomiNavTabHeading data-skeleton className={tx({ 'border-0!': loading })}>
            <DidomiNavTabHeaders>
              <WidgetNavTabHeader />
              <ConfigurationTreeNavTabHeader />
            </DidomiNavTabHeaders>
          </DidomiNavTabHeading>
          <DidomiNavTabContents data-skeleton className={tx('flex-1 flex flex-col', { 'mt-s': loading })}>
            {!loading && <DidomiNavTabContent className="flex-1">{children}</DidomiNavTabContent>}
          </DidomiNavTabContents>
        </DidomiNavTabs>
      </DidomiSkeleton>
      {!disableChatBubbleSpacer && <ChatBubbleSpacer />}
    </section>
  );
};

export { MainLayout };
