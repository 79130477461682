import { formatPaginationResponse, sort } from '../utils';

export const layoutComponentsRoutes = {
  '/widgets/layout-components': {
    get: (schema: any, request) => {
      const { widget_id, organization_id } = request.queryParams;
      const layoutComponents = schema.layoutComponents.where({ organization_id, widget_id });
      const data = layoutComponents.models.map(({ attrs }) => attrs);
      const sortedData = sort({ queryParams: request.queryParams, data });

      return formatPaginationResponse(sortedData, request);
    },
    patch: (schema: any, request) => {
      const { organization_id } = request.queryParams;
      const newLayoutComponents = JSON.parse(request.requestBody);
      return newLayoutComponents.map(newLayoutComponent => {
        const layoutComponentToUpdate = schema.layoutComponents.findBy({ id: newLayoutComponent.id, organization_id });
        layoutComponentToUpdate.update(newLayoutComponent);
        return layoutComponentToUpdate.attrs;
      });
    },
  },
};
