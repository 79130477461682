import React from 'react';

import { InAppHelper } from '@components';
import PreferenceEndUserImage from '../../assets/illustrations/helpers/preference-end-user.png';
import PreferenceCardsImage from '../../assets/illustrations/helpers/preference-cards.svg';

export const PreferenceHelp = () => {
  return (
    <InAppHelper title="What is a preference?">
      <h3 className="h3 font-normal">
        A preference is an opportunity <strong className="text-primary-blue-4">to collect information about user&apos;s</strong> intentions, motivations and interests.
      </h3>
      <p className="mt-xs">
        Each preference has several choices <strong>(values)</strong> and can be added to <strong>one or multiple purpose(s)</strong> to offer a more granular choice to the user.
      </p>
      <figure className="mt-s mb-xxs">
        <img src={PreferenceEndUserImage} alt="End-user preference form" />
      </figure>
      <h3 className="mt-s h3">
        Preferences <strong className="text-primary-blue-4">should match with different marketing use cases</strong>. Here are some examples:
      </h3>
      <ul className="list-disc mt-s pl-s">
        <li className="mt-xxs">Topic and content choices </li>
        <li className="mt-xxs">Frequency options</li>
        <li className="mt-xxs">Custom customer data questions</li>
        <li className="mt-xxs">Privacy policies, programmes and certifications</li>
        <li className="mt-xxs">Access to privacy rights information</li>
      </ul>
      <figure className="mt-s">
        <img src={PreferenceCardsImage} alt="Preference cards" />
      </figure>
    </InAppHelper>
  );
};
