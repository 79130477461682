import React from 'react';
import { DidomiFooterOptions } from '@didomi/pmp-generator';
import { ContentEditorSection } from './ContentEditorSection';
import { ContentEditorTextarea, ContentEditorFileInput } from './components';
import { ContentEditorForm as ContentEditorFormProps } from '@types';
import { useContentEditorForm } from '@hooks';

export const ContentEditorFooterForm = ({ data, setData }: ContentEditorFormProps<DidomiFooterOptions>) => {
  const { getValueAndChangeHandlerFor } = useContentEditorForm<DidomiFooterOptions>(data, setData);
  return (
    <ContentEditorSection data-testid="content-editor-footer" title="Footer">
      <ContentEditorFileInput
        label="Thanks Icon"
        description="Recommended size is 48 x 48 pixels"
        placeholder="Paste your icon image URL..."
        data-testid="content-editor-footer-icon"
        {...getValueAndChangeHandlerFor('icon', false)}
      />
      <ContentEditorTextarea
        label="Thank you message"
        placeholder="Thanks! You can of course change your mind at any time."
        rows={3}
        data-testid="content-editor-footer-text"
        {...getValueAndChangeHandlerFor('text')}
      />
    </ContentEditorSection>
  );
};
