import React, { useMemo, useState } from 'react';
import { DidomiIcon, DidomiRadio } from '@didomi/ui-atoms-react';
import { PreferenceOutput } from '@didomi/pmp-generator/dist/types/entities/models/entities.model';
import { tx } from '@twind/core';
import { getComponentTranslatedValue } from '@utils';
import { OverflowWithTooltip } from '@components';
import { SelectedEntityType, Direction } from '@enums';
import { EditWidgetPreferencesBaseProps, ExtendedComponentsListInput } from '@types';
import { useTranslationContext } from '@didomi/widgets-utilities';
import { EditPreferenceValueControl } from './EditPreferenceValueControl';
import EditWidgetPreferencesControlTooltip from './EditWidgetPreferencesControlTooltip';
import { EditWidgetHeaderActions } from './EditWidgetHeaderActions';
import { useReleaseFlag } from '@didomi/utility-react';

interface EditPreferenceControlProps extends EditWidgetPreferencesBaseProps {
  isNested?: boolean;
  selectedPreference: PreferenceOutput;
  isSelectable?: boolean;
  selectedEntityId?: string;
  titleHighlightText?: string;
  selectionType: 'single' | 'multi';
  components?: ExtendedComponentsListInput;
  headerActionsEnabled?: boolean;
}

export function EditPreferenceControl({
  isNested,
  selectedPreference,
  selectionType,
  isSelectable,
  selectedEntityId,
  widgetPreferences,
  setWidgetLayoutEntityEnabled,
  setSelectedEntity,
  components,
  titleHighlightText,
  preferenceOrderingControls,
  headerActionsEnabled = true,
}: EditPreferenceControlProps) {
  const [isHover, setIsHover] = useState(false);
  const [hasLayoutReorderingFeature] = useReleaseFlag('pmp_layout_reordering');
  const { defaultLanguage } = useTranslationContext();

  const preferenceValues = selectedPreference.values;
  const preferenceValuesCount = preferenceValues.length;
  const contentClass = tx({
    'pt-xs': isNested,
    'pb-xs mb-xxs border-b border-dashed border-neutral-gray-4': isNested && !!preferenceValuesCount,
  });
  const translatedPreferenceName = getComponentTranslatedValue(selectedPreference.component?.id, 'title.content', selectedPreference.name, components, defaultLanguage);
  const preferenceValuesNames = useMemo(
    () => preferenceValues.map(preferenceValue => getComponentTranslatedValue(preferenceValue.component?.id, 'label.content', preferenceValue.name, components, defaultLanguage)),
    [preferenceValues, components, defaultLanguage],
  );

  const isCurrentPurposeSelected = selectedPreference.id === selectedEntityId;
  const selectableClass = tx('flex items-center w-full', {
    'cursor-pointer rounded-[8px]': isSelectable,
    'hover:bg-neutral-gray-1': isSelectable && !isCurrentPurposeSelected,
    'bg-neutral-gray-2': isCurrentPurposeSelected,
  });

  const showHeaderActions = headerActionsEnabled && selectionType === 'multi' && isHover;

  // This memoizes a boolean value indicating if all of the preference values passed in have their "enabled" properties set to `false`.
  const areAllValuesDisabled = useMemo(
    () =>
      // Use the `every` function on the preferenceValues array and return true if all elements meet the following condition.
      preferenceValues.every(value => {
        const widgetValue = widgetPreferences?.[value.layoutEntityId];
        // Check if the widget value is truthy. If yes, retrieve the enabled property from it. Otherwise, use the enabled property from the current preference value.
        const enabled = widgetValue ? widgetValue.enabled : value.enabled;
        return enabled === false;
      }),
    [preferenceValues, widgetPreferences],
  );

  const hasValueWithChildrenPreference = useMemo(() => preferenceValues.some(value => !!value.preferences?.length), [preferenceValues]);

  const headerActionDisabled = preferenceOrderingControls?.getDisabledActionControls(selectedPreference.layoutEntityId);

  return (
    <div className={contentClass}>
      <div className="flex justify-between items-center" onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)} data-testid="edit-preference-control-header">
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div
          className={selectableClass}
          onClick={() => setSelectedEntity?.(SelectedEntityType.Preference, selectedPreference.id)}
          data-testid={`edit-preference-control-${selectedPreference.id}`}
        >
          <div className="w-s">
            <DidomiIcon name="preference" className="pr-xxs" />
          </div>
          <OverflowWithTooltip
            highlight={titleHighlightText}
            className={tx('w-full flex items-center justify-between text-body-normal font-semibold min-w-0 text-primary-blue-6 pr-2', {
              'opacity-30': hasLayoutReorderingFeature && areAllValuesDisabled,
            })}
            value={translatedPreferenceName}
          ></OverflowWithTooltip>
          {selectionType === 'single' && (
            <div className="flex items-center text-primary-blue-6">
              <EditWidgetPreferencesControlTooltip id={selectedPreference.id} icon="preference" title={translatedPreferenceName} values={preferenceValuesNames} />
              <DidomiRadio data-testid={`edit-preference-radio-${selectedPreference.id}`} className="-mr-xxs" value={selectedPreference.id}></DidomiRadio>
            </div>
          )}

          {hasLayoutReorderingFeature && showHeaderActions && (
            <EditWidgetHeaderActions
              hideSwitch={true}
              moveUpDisabled={headerActionDisabled?.upOptionDisabled}
              moveDownDisabled={headerActionDisabled?.downOptionDisabled}
              onMoveDown={() => preferenceOrderingControls?.move(selectedPreference.layoutEntityId, Direction.DOWN)}
              onMoveUp={() => preferenceOrderingControls?.move(selectedPreference.layoutEntityId, Direction.UP)}
            />
          )}
        </div>
      </div>

      {!!preferenceValuesCount &&
        preferenceValues.map((preferenceValue, index) => {
          const isFirstItem = index === 0;
          const isLastItem = index === preferenceValuesCount - 1;
          const preferencesCount = preferenceValue.preferences.length;
          const wrapperClass = tx('ml-xxs pl-xs border-l border-solid border-primary-pink-3', {
            'pt-xs': isFirstItem,
            'pb-xxs': !isLastItem,
          });

          return (
            <div key={`${preferenceValue.id}-${preferenceValue.order}`} className={wrapperClass}>
              <div
                className={preferenceOrderingControls?.getAnimation(preferenceValue.layoutEntityId)}
                onAnimationEnd={hasValueWithChildrenPreference ? preferenceOrderingControls?.getScrollHandler(preferenceValue.layoutEntityId) : undefined}
              >
                <EditPreferenceValueControl
                  selectionType={selectionType}
                  widgetPreferences={widgetPreferences}
                  setWidgetLayoutEntityEnabled={setWidgetLayoutEntityEnabled}
                  setSelectedEntity={setSelectedEntity}
                  preferenceId={selectedPreference.id}
                  preferenceValue={preferenceValue}
                  components={components}
                  preferenceOrderingControls={preferenceOrderingControls}
                >
                  {!!preferencesCount &&
                    preferenceValue.preferences.map(selectedPreference => (
                      <div
                        key={`${selectedPreference.id}-${selectedPreference.order}`}
                        className={preferenceOrderingControls?.getAnimation(selectedPreference.layoutEntityId)}
                        onAnimationEnd={preferenceOrderingControls?.getScrollHandler(selectedPreference.layoutEntityId)}
                      >
                        <EditPreferenceControl
                          titleHighlightText={titleHighlightText}
                          selectionType={selectionType}
                          selectedEntityId={selectedEntityId}
                          isSelectable={isSelectable}
                          widgetPreferences={widgetPreferences}
                          setWidgetLayoutEntityEnabled={setWidgetLayoutEntityEnabled}
                          setSelectedEntity={setSelectedEntity}
                          selectedPreference={selectedPreference}
                          components={components}
                          isNested
                          preferenceOrderingControls={preferenceOrderingControls}
                        />
                      </div>
                    ))}
                </EditPreferenceValueControl>
              </div>
            </div>
          );
        })}
    </div>
  );
}
