import { sanitizeHTML } from '@didomi/xss';

/**
 * Sanitizes HTML content within an object using the @didomi/xss HTML sanitizer.
 *
 * @template T
 * @param {T} obj - The object containing HTML content to be sanitized.
 * @returns {T} - The sanitized object.
 */
export const sanitizeHTMLObject = <T>(obj: T): T => {
  const sanitizedHTML = sanitizeHTML(JSON.stringify(obj)).result;

  return JSON.parse(sanitizedHTML);
};
