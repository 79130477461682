import { getTranslatedValue, substrIncludes, snakeToCamelCase } from '@utils';
import React, { useMemo, useState } from 'react';
import { EntityOutput, PreferenceOutput, ValueOutput } from '@didomi/pmp-generator/dist/types/entities/models/entities.model';
import { Entities, ConfigTree } from '@didomi/pmp-generator';
import { DidomiIllustration, DidomiRadioGroup, DidomiSearchBar, DidomiSkeleton, DidomiBackToTopButton } from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';
import { EditPurposeControl } from '@components';
import { SelectedEntityType } from '@enums';
import { useEventListener } from '@didomi/helpers-react';
import { useConfigTrees } from '@hooks';

interface CreateWidgetSinglePurposeProps {
  selectedEntityId: null | string;
  setSelectedEntity?: (type: SelectedEntityType, id: string) => void;
}

const filterPreferenceValues = (preferenceValues: ValueOutput[], searchValue: string): ValueOutput[] => {
  return preferenceValues.filter(preferenceValue => {
    preferenceValue.preferences = filterPreferences(preferenceValue.preferences, searchValue);

    return preferenceValue.preferences.length > 0;
  });
};

const filterPreferences = (preferences: PreferenceOutput[], searchValue: string): PreferenceOutput[] => {
  if (!preferences || preferences.length === 0) {
    return [];
  }
  return preferences.filter(preference => {
    const preferenceNameMatchSearch = substrIncludes(getTranslatedValue(preference.name), searchValue);
    if (preferenceNameMatchSearch) {
      return true;
    }
    const filteredPreferenceValues = filterPreferenceValues(preference.values, searchValue);
    return filteredPreferenceValues.length > 0;
  });
};

const filterEntities = (entities: EntityOutput, searchValue: string): EntityOutput => {
  if (!searchValue) {
    return entities;
  }

  const lowerCaseSearchValue = searchValue.toLowerCase();

  const purposes = entities.purposes.filter(purpose => {
    const purposeNameMatchSearch = substrIncludes(getTranslatedValue(purpose.name), lowerCaseSearchValue);
    if (purposeNameMatchSearch) {
      return true;
    }

    purpose.preferences = filterPreferences(purpose.preferences, lowerCaseSearchValue);

    return purpose.preferences.length > 0;
  });

  return {
    ...entities,
    purposes,
  };
};

const CreateWidgetSinglePurpose = ({ selectedEntityId, setSelectedEntity }: CreateWidgetSinglePurposeProps): JSX.Element => {
  const scrollContainer = document.getElementsByTagName('main')[0] || window;
  const [searchValue, setSearchValue] = React.useState('');

  const { data: configTrees, isLoading } = useConfigTrees();

  const entities = useMemo(() => {
    if (!configTrees) {
      return {} as EntityOutput;
    }

    const configurationTree = snakeToCamelCase(configTrees?.data?.[0]);
    const entities = Entities.generateEntities({
      configTree: configurationTree as ConfigTree,
      layoutEntities: null,
      shouldOmitEntities: false,
      includeLayoutEntities: false,
    });

    return filterEntities(entities, searchValue);
  }, [configTrees, searchValue]);

  const [showBackToTopButton, setShowBackToTopButton] = useState(false);

  const didomiBackToTopWrapperClass = tx('!fixed transition-opacity bottom-[152px] ml-[600px] 2xl:ml-[766px]', {
    'opacity-0 pointer-events-none': !showBackToTopButton,
  });

  const handleScroll: EventListener = (e: Event) => {
    const mainTarget = e?.currentTarget as HTMLElement;
    const scrollTop = mainTarget?.scrollTop || window.pageYOffset;
    setShowBackToTopButton(scrollTop >= 900);
  };

  useEventListener('scroll', handleScroll, scrollContainer);

  return (
    <div className="w-[500px] h-full m-auto flex flex-col items-center flex-1 mt-[6vh] 2xl:mt-[13vh] 2xl:w-[667px]">
      <span className="mb-2 text-body-small text-primary-blue-5 font-semibold leading-[20px] uppercase 2xl:text-body-normal 2xl:leading-6 ">Create a widget</span>
      <span className="font-semibold text-center text-primary-blue-6 text-[32px] leading-[40px] mb-8 font-serif 2xl:text-[42px] 2xl:leading-[56px] 2xl:font-bold 2xl:mb-10">
        Select a purpose or a preference that you want to use
      </span>

      {/* TODO this will be implement in a separated MR */}
      {/* <PreferenceLibrarySearchInput className="w-full mb-6" value={''} onSearch={() => {}} onFocusChange={() => {}} /> */}

      {isLoading ? (
        <>
          <DidomiSkeleton className="w-full h-[140px] mb-6" isLoading={isLoading} variant="unit"></DidomiSkeleton>
          <DidomiSkeleton className="w-full h-[140px] mb-6" isLoading={isLoading} variant="unit"></DidomiSkeleton>
          <DidomiSkeleton className="w-full h-[140px] mb-6" isLoading={isLoading} variant="unit"></DidomiSkeleton>
        </>
      ) : (
        <div className="flex flex-col w-full mb-8 flex-1">
          <DidomiSearchBar
            className="mb-6"
            data-testid="create-spw-search"
            autoCollapse={false}
            initialCollapsed={false}
            value={searchValue}
            placeholder="Search a purpose or a preference by name..."
            onValueChange={ev => setSearchValue(ev.detail)}
          />
          {entities?.purposes?.length === 0 && (
            <div
              data-testid="create-spw-no-match-found"
              className="h-full w-full flex-1 flex flex-col items-center justify-center h-full border-1 border-dashed border-neutral-gray-5 rounded-lg"
            >
              <div className="font-sans text-body-big font-semibold text-primary-blue-4 mb-1">Sorry,</div>
              <h3 className="font-serif text-xl text-primary-blue-6 mb-4">No match found</h3>
              <DidomiIllustration name="traces-no-match-found" />
            </div>
          )}
          <DidomiRadioGroup data-testid={`single-purpose-radio-group`} value={selectedEntityId} hideErrorMessage={true}>
            <div className="w-full" data-testid="entities-container">
              {entities?.purposes?.map((selectedPurpose, index) => (
                <div
                  key={selectedPurpose.id}
                  className={tx({ 'mb-6': index < entities.purposes.length })}
                  style={{
                    filter:
                      'drop-shadow(0px 22.3363px 17.869px rgba(0, 0, 0, 0.0476886)) drop-shadow(0px 12.5216px 10.0172px rgba(0, 0, 0, 0.04)) drop-shadow(0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0323114)) drop-shadow(0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0224916))',
                  }}
                >
                  <EditPurposeControl
                    selectionType={'single'}
                    selectedPurpose={selectedPurpose}
                    selectedEntityId={selectedEntityId}
                    setSelectedEntity={setSelectedEntity}
                    titleHighlightText={searchValue}
                  />
                </div>
              ))}
            </div>
          </DidomiRadioGroup>
        </div>
      )}

      <div className={didomiBackToTopWrapperClass}>
        <DidomiBackToTopButton data-testid={`back-to-top-button`} targetRef={scrollContainer} behavior="smooth" />
      </div>
    </div>
  );
};

export { CreateWidgetSinglePurpose };
