import React, { useCallback } from 'react';
import { DidomiButtonOptions, DidomiTextOptions } from '@didomi/pmp-generator';
import { ContentEditorTextInput, ContentEditorTextInputProps } from './ContentEditorTextInput';

interface ContentEditorButtonTextInputProps extends Omit<ContentEditorTextInputProps, 'value' | 'onChange'> {
  'data-testid'?: string;
  value?: DidomiButtonOptions;
  onChange(newValue?: DidomiButtonOptions): void;
}

export const ContentEditorButtonInput = ({ value, onChange, ...restProps }: ContentEditorButtonTextInputProps) => {
  const handleValueChange = useCallback(
    (newValue: DidomiTextOptions) => {
      if (newValue) {
        onChange({
          text: newValue,
        });
      } else {
        onChange(undefined);
      }
    },
    [onChange],
  );
  return <ContentEditorTextInput {...restProps} value={value?.text} onChange={handleValueChange} />;
};
