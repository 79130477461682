import React from 'react';
import { DidomiHintbox } from '@didomi/ui-atoms-react';
import { Icons } from '@didomi/ui-foundation';

export interface ContentEditorHintboxWrapperProps {
  hint?: {
    icon: Icons;
    title: string;
    description: React.ReactNode;
  };
  hasBottomMargin?: boolean;
  children?: React.ReactNode;
}

export const ContentEditorHintboxWrapper = ({ hint, hasBottomMargin = true, children }: ContentEditorHintboxWrapperProps) => {
  return (
    <div className={hasBottomMargin ? 'mb-xs' : ''}>
      {hint && (
        <DidomiHintbox className="mb-xs" variant="info" contentAlign="start" headerIconName={hint.icon} titleText={hint.title}>
          {hint.description}
        </DidomiHintbox>
      )}
      {children}
    </div>
  );
};
