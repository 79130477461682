import { WidgetDomain } from '@enums';

type WidgetDomainProps = {
  domain: WidgetDomain;
  subdomainId: string;
  customDomainId: string;
};

/**
 * Returns the selected domain URL
 * @param {object} widget The widget object
 * @return {string} Selected domain URL
 */
export const getWidgetDomainURL = ({ domain, subdomainId, customDomainId }: WidgetDomainProps) => {
  return `https://${domain === WidgetDomain.SUB ? subdomainId : customDomainId}`;
};
