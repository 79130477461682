import React, { Fragment } from 'react';
import { getTranslatedValue } from '@utils';
import { PreferenceFormMetadata } from '@types';
import { EditWidgetBreadcrumbItem } from './EditWidgetBreadcrumbItem';

interface EditWidgetBreadcrumbProps {
  breadcrumbs: PreferenceFormMetadata['breadcrumbs'];
  className?: string;
}

const EditWidgetBreadcrumb = ({ breadcrumbs, className = '' }: EditWidgetBreadcrumbProps): JSX.Element | null => {
  if (!breadcrumbs.length) {
    return null;
  }

  return (
    <div>
      <h3 data-testid="breadcrumb-list" className={`text-body-small text-primary-blue-6 line-clamp-2 ${className}`}>
        {breadcrumbs.map(crumb => {
          return (
            <Fragment key={crumb.id}>
              <EditWidgetBreadcrumbItem text={getTranslatedValue(crumb.content, '')} />
              <span>{' / '}</span>
            </Fragment>
          );
        })}
      </h3>
    </div>
  );
};

export { EditWidgetBreadcrumb };
