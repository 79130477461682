import { Model, belongsTo } from 'miragejs';

const layoutEntity = Model.extend({
  enabled: true,
  selected_purpose: belongsTo('selectedPurpose'),
  selected_preference: belongsTo('selectedPreference'),
  preference_value: belongsTo('preferenceValue'),
  layout_component: belongsTo('layoutComponent'),
});

export default { layoutEntity };
