import { SUBDOMAIN_VALIDATOR } from '@utils';
import { ValidationError } from 'yup';
import { HTTP_STATUS_CODE } from '@constants';
import { Response } from 'miragejs';

export const domainsRoutes = {
  '/domains/:id': {
    get: (schema: any, request) => {
      const subdomain = request.params.id;
      const domain = schema.domains.findBy({ id: subdomain });
      if (domain) {
        return domain.attrs;
      }

      try {
        SUBDOMAIN_VALIDATOR.validateSync(subdomain.split('.')[0], { abortEarly: true });
        return new Response(HTTP_STATUS_CODE.NOT_FOUND, {}, { message: 'Domain does not exist' });
      } catch (error) {
        if (error.name === 'ValidationError') {
          const validationError = error as ValidationError;
          return new Response(HTTP_STATUS_CODE.UNPROCESSABLE_ENTITY, {}, { message: validationError.errors[0] });
        }
        throw error;
      }
    },
  },
};
