import React from 'react';
import { DidomiButton, DidomiModal, DidomiModalHeader, DidomiModalActions } from '@didomi/ui-atoms-react';

type RemovePreferenceModalProps = {
  isOpen?: boolean;
  onCancel?: () => void;
  onProceed?: () => void;
};

/**
 * Remove preference
 */
const RemovePreferenceModal = ({ isOpen, onCancel, onProceed }: RemovePreferenceModalProps): JSX.Element => {
  return (
    <DidomiModal isOpen={isOpen} permanent="true">
      <DidomiModalHeader
        illustrationName="delete-pref-tree"
        modalSubTitle="Please pay attention"
        modalTitle="This preference is used in your Configuration Tree"
        modalDescription="Deleting the preference from the library will impact your Configuration Tree and then, your widgets"
        modalDescriptionSpace="none"
      ></DidomiModalHeader>

      <DidomiModalActions>
        <DidomiButton variant="secondary" onClick={onCancel}>
          Oops, no thanks
        </DidomiButton>
        <DidomiButton data-tracking="pmp-delete-preference-from-pl-in-tree" onClick={onProceed}>
          Yes, delete
        </DidomiButton>
      </DidomiModalActions>
    </DidomiModal>
  );
};

export { RemovePreferenceModal };
