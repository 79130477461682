import React from 'react';
import { ValuePreferenceDropArea } from './ValuePreferenceDropArea';
import { ValuePreferenceDropPlaceholder } from './ValuePreferenceDropPlaceholder';

type ValuePreferenceDropProps = {
  order?: number;
  parentId?: string;
  parentPreferenceValueId?: string;
  parentSelectedPurposeId: string;
  isParentPurpose: boolean;
  'data-testid'?: string;
};

/**
 * Value Preference Drop component
 * Component used to display a droppable area to add a new preference to the Configuration Tree. The component is used inside
 * the preference card to allow adding a preference below a preference value or inside a purpose.
 * More details on this behavior on https://docs.dndkit.com/api-documentation/droppable
 */
const ValuePreferenceDrop = ({
  order = 0,
  parentId,
  parentSelectedPurposeId,
  parentPreferenceValueId,
  isParentPurpose,
  'data-testid': dataTestid,
}: ValuePreferenceDropProps): JSX.Element => {
  return (
    <ValuePreferenceDropArea
      order={order}
      parentId={parentId}
      parentSelectedPurposeId={parentSelectedPurposeId}
      parentPreferenceValueId={parentPreferenceValueId}
      isParentPurpose={isParentPurpose}
    >
      <ValuePreferenceDropPlaceholder data-testid={dataTestid} />
    </ValuePreferenceDropArea>
  );
};

export { ValuePreferenceDrop };
