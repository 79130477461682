import React, { useCallback } from 'react';
import { DidomiTextInput } from '@didomi/ui-atoms-react';
import { ContentEditorHintboxWrapper, ContentEditorHintboxWrapperProps } from './ContentEditorHintboxWrapper';
import { ContentEditorTextInputProps } from './ContentEditorTextInput';
import { sanitizeHTML } from '@didomi/xss';

interface ContentEditorInputProps extends Omit<ContentEditorTextInputProps, 'value' | 'onChange'>, ContentEditorHintboxWrapperProps {
  'data-testid'?: string;
  value?: any;
  onChange?: (newValue?: string) => void;
}

export const ContentEditorInput = ({ 'data-testid': dataTestId, hint, hasBottomMargin, value, onChange, ...restProps }: ContentEditorInputProps) => {
  const handleValueChange = useCallback(
    (ev: CustomEvent<string>) => {
      onChange(ev.detail);
    },
    [onChange],
  );

  const handleFocusChange = useCallback(
    (ev: CustomEvent<boolean>) => {
      if (!ev.detail) {
        onChange(sanitizeHTML(value).result);
      }
    },
    [value, onChange],
  );

  return (
    <ContentEditorHintboxWrapper hint={hint} hasBottomMargin={hasBottomMargin}>
      <DidomiTextInput
        data-testid={`${dataTestId}-input`}
        hideErrorMessage
        {...restProps}
        value={value ?? ''}
        onValueChange={handleValueChange}
        onFocusChange={handleFocusChange}
        data-skeleton
        className="w-full"
      />
    </ContentEditorHintboxWrapper>
  );
};
