import React, { useState, useMemo } from 'react';
import { tx } from '@twind/core';
import { DidomiCollapse } from '@didomi/ui-atoms-react';
import { PurposeCardHeader, EmptyPreferenceCard, SelectedPreferenceCard, ValuePreferenceDrop, ConfigTreeEntityStateCard } from '..';
import { SelectedPreference, FullPreferenceValue } from '@interfaces';
import { generateUniqueId, getTranslatedValue } from '@utils';
import type { PreferencePreviewData } from '../../modals/PreviewPreferenceModal/PreviewPreferenceModal.modal';
import { PreferenceValueContentAsString } from '@types';
import { ConfigTreeEntityState } from '@enums';

/**
 * Counts the amount of preferences under a preference array (and including sub values)
 * @param {FullSelectedPreference[]} selectedPreference List of preferences
 */
const countPreferences = (selectedPreferences: Array<SelectedPreference>): number => {
  let preferencesCount = 0;
  for (const selectedPreference of selectedPreferences) {
    if (selectedPreference.preference?.values?.length) {
      preferencesCount = preferencesCount + countValues(selectedPreference.preference.values);
    }
  }
  return preferencesCount + selectedPreferences.length;
};

/**
 * Counts the amount of preferences under a list of values
 * @param {PreferenceValue[]} values List of preference values
 */
const countValues = (values: FullPreferenceValue[]): number => {
  let preferencesCount = 0;
  for (const value of values) {
    if (value?.selected_preferences?.length) {
      preferencesCount = preferencesCount + countPreferences(value.selected_preferences);
    }
  }
  return preferencesCount;
};

type PurposeCardProps = {
  id?: string;
  name?: string;
  'data-testid'?: string;
  highlight?: string;
  expanded?: boolean;
  showPreferences?: boolean;
  selectedPurposeId: string;
  selectedPreferences?: SelectedPreference[];
  entityIDsToBeDeleted?: string[];
  selectedPurposeIDsToBeReset?: string[];
  children?: React.ReactChild;
  showAllIds?: boolean;
  canEditConfigurationTree?: boolean;
  onToggle?: () => void;
  onPreview?: () => void;
  onPreferencePreview?: (data: PreferencePreviewData) => void;
  onReset?: (selectedPreferenceId: string[]) => void;
  onRemove?: () => void;
  onRemovePreference?: (id: string) => void;
};

const PurposeCard = ({
  id = generateUniqueId('preference-card'),
  name,
  highlight,
  'data-testid': dataTestid,
  expanded = true,
  showPreferences = true,
  selectedPurposeId,
  selectedPreferences,
  entityIDsToBeDeleted = [],
  selectedPurposeIDsToBeReset = [],
  onToggle,
  onPreview,
  onPreferencePreview,
  onReset,
  onRemove,
  onRemovePreference,
  showAllIds = false,
  canEditConfigurationTree,
}: PurposeCardProps): JSX.Element => {
  const [isExpanded, setIsExpanded] = useState(expanded);
  const handleToggle = () => {
    setIsExpanded(e => !e);
    onToggle && onToggle();
  };

  const preferenceCount = useMemo(() => (selectedPreferences?.length ? countPreferences(selectedPreferences) : 0), [selectedPreferences]);
  const isPurposeBeenReset = useMemo(
    () => selectedPurposeIDsToBeReset.some(id => selectedPreferences.map(p => p.id).includes(id)),
    [selectedPurposeIDsToBeReset, selectedPreferences],
  );

  return (
    <>
      {/* This block is shown when a purpose is getting deleted */}
      {entityIDsToBeDeleted.includes(selectedPurposeId) && <ConfigTreeEntityStateCard label={`${name} purpose`} state={ConfigTreeEntityState.DELETING} />}

      {/* This block is shown when a purpose is already created */}
      {!entityIDsToBeDeleted.includes(selectedPurposeId) && (
        <div className="p-1 bg-neutral-gray-1 rounded-lg w-full" id={id} data-testid={dataTestid}>
          <div className={tx('p-6 bg-white rounded-lg', 'border-neutral-gray-4 border-1 border-solid', 'flex flex-col overflow-hidden')}>
            <PurposeCardHeader
              id={id + '-header'}
              selectedPurposeId={selectedPurposeId}
              data-testid={dataTestid + '-header'}
              name={name}
              highlight={highlight}
              expanded={isExpanded}
              showPreferences={showPreferences && !!preferenceCount}
              showAllIds={showAllIds}
              canEditConfigurationTree={canEditConfigurationTree}
              preferencesCount={preferenceCount}
              onToggle={handleToggle}
              onPreview={onPreview}
              onReset={() => onReset(selectedPreferences.map(p => p.id))}
              onRemove={onRemove}
            />
            <DidomiCollapse id={id + '-content'} isExpanded={isExpanded} style={{ '--collapse-duration': '500ms', '--collapse-easing': 'ease-in-out' }}>
              <div className="pt-xs">
                {/* This block is shown when a purpose is getting reset */}
                {isPurposeBeenReset && (
                  <div className="children:(relative pl-xxl before::(content-no-open-quote absolute top-0 left-xs border-l-1 border-b-1 rounded-bl-2xl border-neutral-gray-5 w-s h-s p-xxxs))">
                    <ConfigTreeEntityStateCard label={`${name} purpose`} state={ConfigTreeEntityState.RESETTING} />
                  </div>
                )}

                {!isPurposeBeenReset && (
                  <>
                    {isExpanded && !selectedPreferences?.length ? (
                      <EmptyPreferenceCard selectedPurposeId={selectedPurposeId} id={id + '-empty'} />
                    ) : (
                      <div className="w-full flex flex-col">
                        {isExpanded && <ValuePreferenceDrop parentSelectedPurposeId={selectedPurposeId} isParentPurpose={true} />}
                        {selectedPreferences?.map((p, i) => (
                          <div key={'selected-preference-' + i}>
                            <div className="pr-xxl children:(relative ml-xxl mr-l my-xxxs before::(content-no-open-quote absolute top-0 border-l-1 border-b-1 rounded-bl-2xl border-neutral-gray-5 w-s h-s p-xxxs -left-l))">
                              <SelectedPreferenceCard
                                parentSelectedPurposeId={selectedPurposeId}
                                selectedPreferenceId={p.id}
                                entityIDsToBeDeleted={entityIDsToBeDeleted}
                                data-testid={'selected-preference-card-' + p.id}
                                id={p.preference.id}
                                label={getTranslatedValue(p.preference.name)}
                                expanded={isExpanded}
                                description={getTranslatedValue(p.preference.description)}
                                showAllIds={showAllIds}
                                canEditConfigurationTree={canEditConfigurationTree}
                                type={p.preference.type}
                                values={p.preference.values.map(value => ({ ...value, name: getTranslatedValue(value.name) })) as unknown as PreferenceValueContentAsString[]}
                                onPreferencePreview={onPreferencePreview}
                                onRemove={prefId => onRemovePreference(prefId || p.id)}
                              />
                            </div>
                            {isExpanded && <ValuePreferenceDrop order={i + 1} parentSelectedPurposeId={selectedPurposeId} isParentPurpose={true} />}
                          </div>
                        ))}
                      </div>
                    )}
                  </>
                )}
              </div>
            </DidomiCollapse>
          </div>
        </div>
      )}
    </>
  );
};

export { PurposeCard };
