import React from 'react';
import { ComponentType } from '@didomi/pmp-generator';
import { MissingTranslationCard, useTranslationContext } from '@didomi/widgets-utilities';
import { MissingTranslation } from '@types';
import { getTranslatedValue } from '@utils';
import { useHistory } from 'react-router-dom';

interface MissingTranslationCardComponentProps {
  missingTranslation: MissingTranslation;
}

const COMPONENT_TYPE_TO_TRANSLATION_CARD_MAP = {
  [ComponentType.SECTION]: {
    title: 'Purpose',
    warningText: 'Purpose title is missing',
    action: 'Add translation',
  },
  [ComponentType.PREFERENCE]: {
    title: 'Preference',
    warningText: 'Preference title is missing',
    action: 'Add translation',
  },
  [ComponentType.PREFERENCE_VALUE]: {
    title: 'Preference value',
    warningText: 'Preference value is missing',
    action: 'Add translation',
  },
};

export const MissingTranslationCardComponent = ({ missingTranslation }: MissingTranslationCardComponentProps): JSX.Element => {
  const { push } = useHistory();
  const { setSelectedMissingTranslation, setMissingTranslationDrawerOpen, defaultLanguage } = useTranslationContext();
  const { title, warningText, action } = COMPONENT_TYPE_TO_TRANSLATION_CARD_MAP[missingTranslation.componentType];

  const handleAction = () => {
    push('./content-editor');
    setSelectedMissingTranslation(missingTranslation);
    setMissingTranslationDrawerOpen(false);
  };

  return (
    <MissingTranslationCard
      titleText={title}
      descriptionText={getTranslatedValue(missingTranslation.content, '-', defaultLanguage)}
      warningText={warningText}
      actionText={action}
      onAction={handleAction}
    />
  );
};
