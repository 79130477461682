import { AccessTypes, AvailableModules, modules } from '@didomi/authorization-metadata';

const PMP_WIDGETS_POLICIES_METADATA = modules[AvailableModules.PMPWidgets];
const CONFIGURATION_TREE_POLICIES_METADATA = modules[AvailableModules.ConfigurationTrees];

// PMP access policies config
export const ACCESS_POLICIES_CONFIG = {
  PMP_WIDGETS_VIEWER: PMP_WIDGETS_POLICIES_METADATA.accessTypeAllowedPolicies[AccessTypes.Viewer],
  PMP_WIDGETS_EDITOR: PMP_WIDGETS_POLICIES_METADATA.accessTypeAllowedPolicies[AccessTypes.Editor],
  CONFIGURATION_TREE_VIEWER: CONFIGURATION_TREE_POLICIES_METADATA.accessTypeAllowedPolicies[AccessTypes.Viewer],
  CONFIGURATION_TREE_EDITOR: CONFIGURATION_TREE_POLICIES_METADATA.accessTypeAllowedPolicies[AccessTypes.Editor],
};
