import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { AxiosResponse, AxiosError } from 'axios';
import { useActiveOrganization } from '@didomi/utility-react';
import { apiRoutes } from '@constants';
import { ErrorResponse, SelectedPreference } from '@interfaces';
import { useAxiosWithLogOut } from '../useAxiosWithInterceptors.hook';

export type CreateSelectedPreferencePayload = {
  preference_id: string;
  parent_selected_purpose_id: string;
  order: number;
  parent_id?: string;
  parent_preference_value_id?: string;
};

export const useCreateSelectedPreference = (configTreeId: string, options?: UseMutationOptions<AxiosResponse<SelectedPreference>, AxiosError, CreateSelectedPreferencePayload>) => {
  const { organizationId } = useActiveOrganization();
  const axiosWithInterceptors = useAxiosWithLogOut();
  const queryClient = useQueryClient();

  const post = async (payload: CreateSelectedPreferencePayload) =>
    axiosWithInterceptors.post(
      apiRoutes.selectedPreferences(configTreeId),
      {
        ...payload,
        organization_id: organizationId,
      },
      { params: { organization_id: organizationId } },
    );

  return useMutation<AxiosResponse<SelectedPreference>, AxiosError<ErrorResponse>, CreateSelectedPreferencePayload>(post, {
    ...options,
    onSuccess: (createdSelectedPreference: AxiosResponse<SelectedPreference>, variables, context) => {
      queryClient.invalidateQueries(['config-trees']);

      if (options.onSuccess) {
        options.onSuccess(createdSelectedPreference, variables, context);
      }
    },
  });
};
