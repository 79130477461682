import { apiRoutes } from '../../../constants/apiRoutes';
import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { useAxiosWithLogOut } from '../useAxiosWithInterceptors.hook';
import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosResponse, AxiosError } from 'axios';
import { Purpose } from '@interfaces';

export const useDeleteSelectedPurpose = (configTreeId: string, options?: UseMutationOptions<AxiosResponse<Purpose>, AxiosError, string>) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();
  const queryClient = useQueryClient();

  const deleteEntity = (id: string) => {
    return axiosWithInterceptors.delete<Purpose>(`${apiRoutes.selectedPurposes(configTreeId)}/${id}`, {
      params: { organization_id: organizationId },
    });
  };

  return useMutation<AxiosResponse<Purpose>, AxiosError, string>(deleteEntity, {
    ...options,
    onSuccess: async (response: AxiosResponse<Purpose>, variables, context) => {
      await queryClient.invalidateQueries(['config-trees']);

      if (options?.onSuccess) {
        options.onSuccess(response, variables, context);
      }
    },
  });
};
