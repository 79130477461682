import React, { Ref, useState, forwardRef } from 'react';
import { DidomiCollapse, DidomiIcon, DidomiIconButton } from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';
import { OverflowWithTooltip, PreferenceCardOptions } from '../index';
import { generateUniqueId } from '@utils';
import { PreferenceType } from '@enums';
import { PreferenceValueContentAsString } from '@types';
import type { PreferencePreviewData } from '../../modals/PreviewPreferenceModal/PreviewPreferenceModal.modal';

export type PreferenceCardProps = {
  id?: string;
  highlight?: string;
  expanded?: boolean;
  expandable?: boolean;
  'data-testid'?: string;
  name: string;
  description?: string;
  type?: PreferenceType;
  values: Array<PreferenceValueContentAsString>;
  preferenceId?: string;
  isFloatingDrag?: boolean;
  isDragging?: boolean;
  canEditConfigurationTree?: boolean;
  disabled?: boolean;
  onExpansionChange?: (expanded: boolean) => void;
  onPreview?: (data: PreferencePreviewData) => void;
  onDuplicate?: (id: string) => void;
  onEdit?: (preferenceId: string) => void;
  onDelete?: (preferenceId: string) => void;
  onCopy?: () => void;
};

/**
 * Preference card component
 */
const PreferenceCard = forwardRef(
  (
    {
      id = generateUniqueId('preference-card'),
      expanded = true,
      expandable = true,
      'data-testid': dataTestid,
      name,
      highlight,
      description,
      type,
      preferenceId,
      values,
      isFloatingDrag,
      isDragging,
      canEditConfigurationTree,
      disabled = false,
      onExpansionChange,
      onPreview,
      onEdit,
      onDelete,
      onCopy,
      ...props
    }: PreferenceCardProps,
    ref: Ref<HTMLDivElement>,
  ): JSX.Element => {
    const [isExpanded, setIsExpanded] = useState(expanded);

    const handleCardExpansion = () => {
      if (onExpansionChange) {
        onExpansionChange(!isExpanded);
      }
      setIsExpanded(e => !e);
    };

    return (
      <div
        data-testid={dataTestid || 'preference-card'}
        className={tx('w-[382px] bg-white transform border-dashed rounded-2xl box-border', {
          'border-1 border-neutral-gray-4 bg-neutral-gray-1': isDragging && !isFloatingDrag,
          'border-1 border-primary-blue-5 -rotate-2': isFloatingDrag,
        })}
      >
        <div
          data-testid={`${dataTestid}-wrapper`}
          className={tx('w-full border-4 border-solid border-neutral-gray-1 rounded-2xl box-border transition-opacity', {
            'opacity-40': isDragging && !isFloatingDrag,
            'border-neutral-gray-3': isFloatingDrag,
            'opacity-40 pointer-events-none': disabled,
          })}
        >
          <div className="pmp-border-neutral-gray-4 pmp-border-1 pmp-border-solid pmp-rounded-lg">
            <div className="pmp-py-m pmp-pl-6 pmp-pr-4 pmp-h-8 pmp-flex pmp-justify-between pmp-items-center">
              <div
                className="pmp-flex-grow pmp-flex pmp-items-center pmp-min-w-0 pmp-select-none pmp-rounded pmp-focus:(outline-none pmp-ring pmp-ring-primary-blue-2)"
                id={id}
                data-preferenceid={preferenceId}
                ref={ref}
                {...props}
              >
                <DidomiIcon name="preference" aria-hidden className="pmp-pr-2 pmp-min-w-[15px]" />
                <OverflowWithTooltip
                  data-testid="preference-card-title"
                  className="pmp-flex-grow pmp-min-w-0 pmp-font-sans pmp-text-body-normal pmp-font-semibold pmp-text-primary-blue-6 pmp-pr-2"
                  value={name}
                  highlight={highlight}
                />
              </div>
              <div className="flex">
                <PreferenceCardOptions
                  onPreview={() =>
                    onPreview({
                      id: preferenceId,
                      name,
                      description,
                      type,
                      values: values as any,
                    })
                  }
                  onCopy={onCopy}
                  onDelete={() => onDelete(preferenceId)}
                  onEdit={() => onEdit(preferenceId)}
                  showEdit={canEditConfigurationTree}
                  showCopy={canEditConfigurationTree}
                  showDelete={canEditConfigurationTree}
                />
                {expandable && (
                  <DidomiIconButton
                    data-testid="toggle-preference"
                    title={isExpanded ? 'Collapse preference' : 'Expand preference'}
                    variant="rounded-border"
                    aria-controls={id + '-card-values'}
                    aria-expanded={isExpanded ? 'true' : 'false'}
                    icon={isExpanded ? 'up-on-sm' : 'down-on-sm'}
                    onClick={handleCardExpansion}
                  />
                )}
              </div>
            </div>
            <DidomiCollapse
              id={id + '-card-values'}
              data-testid="preference-card-values"
              isExpanded={isExpanded}
              style={{ '--collapse-duration': '500ms', '--collapse-easing': 'ease-in-out' }}
              className="pmp-border-neutral-gray-4 pmp-border-t-1 pmp-border-solid pmp-rounded-b-lg"
            >
              <ul className="pmp-p-s pmp-m-0 pmp-list-none">
                {values.map((v, i) => (
                  <li key={v.name + '-' + i} className="pmp-not-last-child:mb-5">
                    <OverflowWithTooltip data-testid={'preference-card-value-' + i} className="pmp-font-sans pmp-text-body-normal pmp-text-primary-blue-6" value={v.name} />
                  </li>
                ))}
              </ul>
            </DidomiCollapse>
          </div>
        </div>
      </div>
    );
  },
);
export { PreferenceCard };
