import React from 'react';
import { DidomiIcon } from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';
import { generateUniqueId } from '@utils';
import { useDroppable } from '@dnd-kit/core';

type EmptyPreferenceCardProps = {
  id?: string;
  selectedPurposeId: string;
  'data-testid'?: string;
};

/**
 * Empty Preference card component
 * Component used to display a message in the purpose card if the purpose does not contain any preference
 * The component not only displays the message but acts as a droppable area to drag preferences into it.
 * More details on this behavior on https://docs.dndkit.com/api-documentation/droppable
 */
const EmptyPreferenceCard = ({ id = generateUniqueId('empty-preference-card'), selectedPurposeId, 'data-testid': dataTestid }: EmptyPreferenceCardProps): JSX.Element => {
  const { setNodeRef, isOver } = useDroppable({
    id,
    data: {
      isParentPurpose: true,
      order: 0,
      parentSelectedPurposeId: selectedPurposeId,
    },
  });

  return (
    <div
      ref={setNodeRef}
      data-testid={dataTestid || 'empty-preference-card'}
      id={id}
      className={tx('w-full border-1 border-dashed border-neutral-gray-4 rounded-lg box-border p-xs', {
        'py-5! bg-neutral-gray-1 border-primary-blue-6': isOver,
      })}
    >
      <div className="flex-grow flex items-center min-w-0">
        <DidomiIcon name="preference" aria-hidden className="pr-xs min-w-[15px]" />
        <div className="font-sans text-tooltip font-semibold text-primary-blue-6">{`No preference added yet`}</div>
      </div>
    </div>
  );
};

export { EmptyPreferenceCard };
