import React from 'react';
import { CollapsibleCard } from '@components';
import { ContentEditorSection } from './ContentEditorSection';
import { ContentEditorInput, ContentEditorTranslatedInput } from './components';
import { ContentEditorForm as ContentEditorFormProps, WidgetMetaTagsOptions } from '@types';
import { useContentEditorForm } from '@hooks';

export const ContentEditorMetaTagsForm = ({ data, setData }: ContentEditorFormProps<WidgetMetaTagsOptions>) => {
  const { getValueAndChangeHandlerFor } = useContentEditorForm<WidgetMetaTagsOptions>(data, setData);
  return (
    <ContentEditorSection data-testid="content-editor-meta-tags" title="Browser tab">
      <CollapsibleCard contentId="content-editor-meta-tags-favicon" title="Favicon" removeTopMargin isCollapsible={false}>
        <p className="text-body-small text-primary-blue-6 mb-3">A favicon is a graphical element designed to help identify your brand logo in browser tabs and bookmarks bars.</p>
        <ContentEditorInput
          label="Favicon"
          description="Required size 16x16px - PNG recommended"
          placeholder="Enter a URL"
          data-testid="content-editor-meta-tags-favicon-input"
          {...getValueAndChangeHandlerFor('favicon', false)}
        />
      </CollapsibleCard>
      <CollapsibleCard contentId="content-editor-meta-tags-title" title="Title" isCollapsible={false}>
        <p className="text-body-small text-primary-blue-6 mb-3">Specify the title to be shown on your browser tab.</p>
        <ContentEditorTranslatedInput
          data-testid="content-editor-meta-tags-title-input"
          label="Title"
          placeholder="Type your title here"
          {...getValueAndChangeHandlerFor('title')}
        />
      </CollapsibleCard>
    </ContentEditorSection>
  );
};
