import { Factory } from 'miragejs';
import { generateRandomId } from '@utils';
import { devOrganizationId } from '@constants';
import { WidgetThemeName } from '@enums';

const widgetTheme = Factory.extend({
  id() {
    return generateRandomId('widget-theme');
  },
  name: WidgetThemeName.CUSTOM,
  organization_id: devOrganizationId,
  created_at: new Date().toISOString(),
  updated_at: new Date().toISOString(),
  color_1: '#FFFFFF',
  color_2: '#FFFFFF',
  color_3: '#68374A',
  color_4: '#33454C',
  color_5: '#9F4F56',
  color_6: '#9F4F56',
  color_7: '#FFFFFF',
  color_8: 'linear-gradient(314.71deg, #9F4F56 63.02%, #FFEFD7 100%)',
});

export default { widgetTheme };
