import React from 'react';
import { StateIconButton } from '@components';
import { DidomiSwitch } from '@didomi/ui-atoms-react';
import { createSlideToLeftAnimation } from '@constants';
import { tw, tx } from '@twind/core';

const SLIDE_TO_LEFT_ANIMATION = createSlideToLeftAnimation();

interface Props {
  enabled?: boolean;
  dataTestId?: string;
  hideSwitch?: boolean;
  moveDownDisabled?: boolean;
  moveUpDisabled?: boolean;
  onMoveDown?: () => void;
  onMoveUp?: () => void;
  onValueChange?: (value: any) => void;
}

export const EditWidgetHeaderActions = ({
  enabled,
  hideSwitch,
  moveDownDisabled,
  moveUpDisabled,
  dataTestId = 'edit-widget-header-actions',
  onMoveDown,
  onMoveUp,
  onValueChange,
}: Props) => {
  if (moveUpDisabled && moveDownDisabled && hideSwitch) {
    return null;
  }

  const shouldShowOrderingActions = !moveUpDisabled || !moveDownDisabled;

  return (
    <div className={tw(SLIDE_TO_LEFT_ANIMATION.toString())}>
      <div className="flex items-center p-[2px] shadow-card rounded-3xl bg-neutral-grey-1" data-testid={dataTestId}>
        {shouldShowOrderingActions && (
          <div
            className={tx('flex items-center gap-xxxs', {
              'mr-xxs': !hideSwitch,
            })}
          >
            <StateIconButton
              icon="move-down"
              hoverIcon="move-down-hover"
              disabledIcon="move-down-disabled"
              onClick={onMoveDown}
              disabled={moveDownDisabled}
              dataTestId={`${dataTestId}-move-down`}
            />
            <StateIconButton
              icon="move-up"
              hoverIcon="move-up-hover"
              disabledIcon="move-up-disabled"
              onClick={onMoveUp}
              disabled={moveUpDisabled}
              dataTestId={`${dataTestId}-move-up`}
            />
          </div>
        )}

        {!hideSwitch && (
          <DidomiSwitch
            data-testid={`${dataTestId}-switch`}
            hideErrorMessage
            valueSelected="selected"
            valueNotSelected="not-selected"
            value={enabled ? 'selected' : 'not-selected'}
            onValueChange={ev => onValueChange && onValueChange(ev.detail)}
          />
        )}
      </div>
    </div>
  );
};
