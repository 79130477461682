import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { UtilityProvider, useActiveOrganization } from '@didomi/utility-react';
import { connect } from '@didomi/ui-foundation';
import * as utility from '@didomi/utility';
import { makeServer } from './__mocks__/server';
import { App } from './pages/App/App';
import { SentryBoundary } from '@components';
import presetLineClamp from '@twind/preset-line-clamp';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const queryClient = new QueryClient({
  logger: {
    error: () => {},
    log: () => {},
    warn: () => {},
  },
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      staleTime: Infinity,
      cacheTime: 0,
    },
  },
});

const isDevEnvironment = CONFIG.environment === 'development';

// Setup Twind for pmp only
connect('.pmp-root', {
  presets: [presetLineClamp()],
});

// @ts-ignore
if (window.Cypress) {
  makeServer({ defaultOrganization: 'pmp-uat' });
} else {
  // Uncomment next line if you want to run mirage
  // makeServer();
}

/**
 * Root Component
 * Takes care of initializing the utilities
 */
const Root = (): JSX.Element => {
  return (
    <QueryClientProvider client={queryClient}>
      <UtilityProvider utility={utility}>
        <SentryBoundary>
          <Router />
        </SentryBoundary>
      </UtilityProvider>

      <ReactQueryDevtools initialIsOpen={isDevEnvironment} />
    </QueryClientProvider>
  );
};
/**
 * Router Component
 * Takes care of initializing the router
 *
 * This component is separated from the Root component as the
 * useActiveOrganization hook needs the Utility povider to be loaded to work properly
 */
const Router = (): JSX.Element => {
  const { organization } = useActiveOrganization();

  return (
    <BrowserRouter
      basename={`/${organization.id}/preference-management`}
      getUserConfirmation={() => {
        /* Empty callback to block the default browser prompt */
      }}
    >
      <App />
    </BrowserRouter>
  );
};

export { Root };
