import React from 'react';
import { DidomiButton, DidomiIllustration, DidomiModal, DidomiModalHeader, DidomiModalActions } from '@didomi/ui-atoms-react';

type EditDeletePurposeConfirmationModalProps = {
  purposeId?: string;
  isOpen?: boolean;
  onCancel?: () => void;
  onProceed?: () => void;
};

/**
 * Remove preference from tree Modal
 */
const EditDeletePurposeConfirmationModal = ({ purposeId, isOpen, onCancel, onProceed }: EditDeletePurposeConfirmationModalProps): JSX.Element => {
  return (
    <DidomiModal data-testid={`edit-delete-purpose-modal-${purposeId}`} isOpen={isOpen} permanent="true">
      <DidomiIllustration name="land-to-data-manager" style={{ marginBottom: 'var(--space-m)' }} />
      <DidomiModalHeader modalDescription="Once everything is ok, just go back to your widget configuration" variant="warning">
        <span slot="modal-title">
          To edit/delete this purpose you will land to your <span style={{ color: 'rgb(47, 171, 213)' }}>Data Manager</span> section
        </span>
      </DidomiModalHeader>

      <DidomiModalActions className="mt-2">
        <DidomiButton variant="secondary" onClick={onCancel}>
          Cancel
        </DidomiButton>
        <DidomiButton data-tracking="pmp-confirm-start-edit-purpose-from-purpose-page" onClick={onProceed}>
          Go to my Data Manager
        </DidomiButton>
      </DidomiModalActions>
    </DidomiModal>
  );
};

export { EditDeletePurposeConfirmationModal };
