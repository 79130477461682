export const selectedPreferences = (schema, preferencesIds) => {
  const selectedPreferencesModels = schema.selectedPreferences.find(preferencesIds).models;

  const selectedPrefs = selectedPreferencesModels.map(pM => pM.attrs);

  return selectedPrefs.map(sP => {
    const preference = schema.preferences.findBy({ id: sP.preference_id }).attrs;

    return {
      id: sP.id,
      order: sP.order,
      parent_preference_value_id: sP.parent_preference_value_id,
      parent_selected_purpose_id: sP.parent_selected_purpose_id,
      parent_id: sP.parent_id,
      preference: {
        ...preference,
        values: preference.valueIds?.length
          ? schema.preferenceValues
              .find(preference.valueIds)
              .models?.map(v => v.attrs)
              .map(v => ({
                ...v,
                selected_preferences: sP.selectedPreferenceIds?.length
                  ? selectedPreferences(schema, sP.selectedPreferenceIds).filter(sP => sP.parent_preference_value_id === v.id)
                  : [],
              }))
          : [],
      },
    };
  });
};
