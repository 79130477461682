import { apiRoutes } from '../../../constants/apiRoutes';
import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { useAxiosWithLogOut } from '../useAxiosWithInterceptors.hook';
import { useActiveOrganization } from '@didomi/utility-react';
import { Preference } from '@interfaces';
import { AxiosResponse, AxiosError } from 'axios';
import { updateQueryClientCacheDeleteItem } from '@utils';

export const useDeletePreference = (options?: UseMutationOptions<AxiosResponse<Preference>, AxiosError, string>) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();
  const queryClient = useQueryClient();

  const deleteEntity = (id: string) => {
    return axiosWithInterceptors.delete<Preference>(`${apiRoutes.preferences}/${id}`, {
      params: { organization_id: organizationId },
    });
  };

  return useMutation<AxiosResponse<Preference>, AxiosError, string>(deleteEntity, {
    ...options,
    onSuccess: async (deletePreferenceRes: AxiosResponse<Preference>, variables, context) => {
      // this will update all queries/caches that start with 'preferences'
      const dataKey = ['preferences'];

      updateQueryClientCacheDeleteItem(queryClient, deletePreferenceRes, dataKey);
      await queryClient.invalidateQueries(['config-trees']);
      await queryClient.refetchQueries(['config-trees']);

      if (options?.onSuccess) {
        options.onSuccess(deletePreferenceRes, variables, context);
      }
    },
  });
};
