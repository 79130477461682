import React from 'react';
import { DidomiIconButton, DidomiMenu, DidomiMenuItem, DidomiTooltip, DidomiChip, DidomiCopyText } from '@didomi/ui-atoms-react';
import { useSnackbar } from '@didomi/utility-react';
import { TextHighlight } from '../TextHighlight/TextHighlight';
import PurposeCardIllustation from '../../assets/svg/illustration_cards_purpose-card.svg';

type PurposeCardHeaderProps = {
  id?: string;
  selectedPurposeId: string;
  name?: string;
  expanded?: boolean;
  showPreferences?: boolean;
  preferencesCount?: number;
  highlight?: string;
  showAllIds?: boolean;
  canEditConfigurationTree?: boolean;
  onToggle?: () => void;
  onPreview?: () => void;
  onReset?: () => void;
  onRemove?: () => void;
};

const PurposeCardHeader = ({
  id,
  selectedPurposeId,
  name,
  showPreferences,
  preferencesCount,
  highlight,
  expanded = true,
  showAllIds = false,
  canEditConfigurationTree,
  onToggle,
  onPreview,
  onReset,
  onRemove,
}: PurposeCardHeaderProps): JSX.Element => {
  const { displaySnackbar } = useSnackbar();
  return (
    <div className="flex justify-between">
      <div className="flex items-center">
        <img src={PurposeCardIllustation} alt="" className="mr-4" />
        <div className="flex flex-col">
          <div className="text-primary-blue-4 font-sans font-semibold text-xs">Purpose</div>
          <div className="flex flex-row align-middle">
            <div className="text-primary-blue-6 font-semibold text-body-big leading-6">{highlight ? <TextHighlight text={name} highlight={highlight} /> : name}</div>
            {showAllIds && (
              <DidomiCopyText
                text={selectedPurposeId}
                onTextCopied={() => displaySnackbar('Purpose ID copied!', { icon: 'check' })}
                className="text-[10px] ml-0.5 mt-[1px] text-primary-blue-6 font-extralight flex align-middle"
                variation="basic-clear"
              >
                {selectedPurposeId}
              </DidomiCopyText>
            )}
          </div>
        </div>
      </div>
      <div className="flex items-center">
        {showPreferences && <DidomiChip label={`${preferencesCount} ${preferencesCount === 1 ? 'preference' : 'preferences'}`} variation="basic-outline" />}
        {(onPreview || onReset || onRemove) && (
          <div>
            <DidomiTooltip content="Options" placement="top">
              <DidomiIconButton
                data-testid="purpose-card-actions"
                data-cy="purpose-options"
                className="mx-xxs"
                id={id ? id + '-options' : 'menu-button-variant'}
                variant="rounded"
                icon="submenu"
              />
            </DidomiTooltip>
            <DidomiMenu for={id ? id + '-options' : 'menu-button-variant'} variant="main" placement="left-start" distance="xxs">
              <div>
                {onPreview && (
                  <DidomiMenuItem data-tracking="pmp-preview-purpose" iconName="preview" data-testid="preview-item" onItemSelected={onPreview}>
                    Preview
                  </DidomiMenuItem>
                )}
                {onReset && !!preferencesCount && canEditConfigurationTree && (
                  <DidomiMenuItem data-tracking="pmp-start-reset-purpose" iconName="reset" data-testid="reset-item" data-cy="reset-item" onItemSelected={onReset}>
                    Reset
                  </DidomiMenuItem>
                )}
                {onRemove && canEditConfigurationTree && (
                  <DidomiMenuItem data-tracking="pmp-start-remove-purpose-from-tree" iconName="back" data-testid="remove-item" onItemSelected={onRemove}>
                    Remove
                  </DidomiMenuItem>
                )}
              </div>
            </DidomiMenu>
          </div>
        )}
        <DidomiIconButton
          data-testid="toggle-purposes-card"
          title={expanded ? 'Collapse purpose card' : 'Expand purpose card'}
          variant="rounded-border"
          aria-expanded={expanded ? 'true' : 'false'}
          aria-controls={id?.replace('header', 'content')}
          icon={expanded ? 'up-on-sm' : 'down-on-sm'}
          onClick={onToggle}
        />
      </div>
    </div>
  );
};

export { PurposeCardHeader };
