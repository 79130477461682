/* eslint-disable no-undef */
export const apiRoutes = {
  preferences: `${CONFIG.apiUrl}/preferences`,
  purposes: `${CONFIG.apiUrl}/metadata/purposes`,
  selectedPurposes: (configTreeId: string) => `${CONFIG.apiUrl}/config-trees/${configTreeId}/selected-purposes`,
  domains: `${CONFIG.apiUrl}/domains`,
  configTrees: `${CONFIG.apiUrl}/config-trees`,
  widgets: `${CONFIG.apiUrl}/widgets`,
  widgetsDuplicate: (widgetId: string) => `${CONFIG.apiUrl}/widgets/${widgetId}/duplicate`,
  widgetDeployments: `${CONFIG.apiUrl}/widgets/deployments`,
  archivedWidgets: `${CONFIG.apiUrl}/widgets/archived`,
  widgetThemes: `${CONFIG.apiUrl}/widgets/themes`,
  selectedPreferences: (configTreeId: string) => `${CONFIG.apiUrl}/config-trees/${configTreeId}/selected-preferences`,
  layoutComponents: `${CONFIG.apiUrl}/widgets/layout-components`,
  layoutEntities: `${CONFIG.apiUrl}/widgets/preferences-centers/layout-entities`,
  layouts: `${CONFIG.apiUrl}/widgets/layouts`,
};
