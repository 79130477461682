import React, { useCallback, useLayoutEffect, useRef } from 'react';
import { DidomiTextInput } from '@didomi/ui-atoms-react';
import { ContentEditorHintboxWrapper, ContentEditorHintboxWrapperProps } from './ContentEditorHintboxWrapper';
import type { DidomiTextOptions } from '@didomi/pmp-generator';
import { useContentEditorTranslatedTextInput } from '@hooks';
import { useTranslationContext } from '@didomi/widgets-utilities';

type DidomiTextInputProps = React.ComponentProps<typeof DidomiTextInput>;

export interface ContentEditorTextInputProps extends Omit<DidomiTextInputProps, 'value' | 'onChange' | 'onValueChange'>, ContentEditorHintboxWrapperProps {
  'data-testid'?: string;
  id?: string;
  value?: DidomiTextOptions;
  onChange?: (newValue?: DidomiTextOptions) => void;
  hideErrorMessage?: boolean;
}

export const ContentEditorTextInput = ({
  'data-testid': dataTestId,
  id,
  hint,
  hasBottomMargin,
  value,
  hideErrorMessage = true,
  onChange,
  ...restProps
}: ContentEditorTextInputProps) => {
  const { selectedMissingTranslation } = useTranslationContext();
  const { value: uiValue, onChange: handleValueChange, onFocusChange: handleFocusChange } = useContentEditorTranslatedTextInput(value, onChange);
  const errorMessage = uiValue ? null : 'Translation is missing';
  const textInputRef = useRef<HTMLDidomiTextInputElement>();

  const handleSelectedTranslationChange = useCallback(() => {
    if (selectedMissingTranslation && selectedMissingTranslation.id === id) {
      if (textInputRef.current) {
        const textInput = textInputRef.current;
        if (textInput) {
          setTimeout(() => {
            textInput.setFocus({ preventScroll: true });
            textInput.scrollToElement({ block: 'center' });
          }, 0);
        }
      }
    }
  }, [selectedMissingTranslation, id]);

  useLayoutEffect(() => handleSelectedTranslationChange(), [selectedMissingTranslation, id, handleSelectedTranslationChange]);

  return (
    <ContentEditorHintboxWrapper hint={hint} hasBottomMargin={hasBottomMargin}>
      <DidomiTextInput
        ref={textInputRef}
        data-testid={dataTestId}
        error={hideErrorMessage ? null : errorMessage}
        hideErrorMessage={hideErrorMessage}
        {...restProps}
        value={uiValue}
        onValueChange={handleValueChange}
        onFocusChange={handleFocusChange}
        data-skeleton
        className="w-full transition-all duration-[0s]"
        onTransitionEnd={handleSelectedTranslationChange}
      />
    </ContentEditorHintboxWrapper>
  );
};
