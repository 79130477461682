import { AxiosError } from 'axios';
import { PaginatedResponse } from '@types';
import { useQuery, UseQueryOptions, useQueryClient } from '@tanstack/react-query';
import { useActiveOrganization } from '@didomi/utility-react';
import { DEFAULT_LIMIT, apiRoutes } from '@constants';
import { useAxiosWithLogOut } from '../useAxiosWithInterceptors.hook';
import { LayoutComponent, ErrorResponse } from '@interfaces';
import { useCallback } from 'react';

export interface UseLayoutComponentsQuery {
  widget_id?: string;
  limit?: number;
}

export type FetchLayoutComponentsAsyncFnType = (widgetId: string) => Promise<PaginatedResponse<LayoutComponent>>;

export const useLayoutComponents = ({ widget_id, limit = DEFAULT_LIMIT }: UseLayoutComponentsQuery = {}, options: UseQueryOptions<PaginatedResponse<LayoutComponent>> = {}) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();
  const queryClient = useQueryClient();

  const fetchParams = { organization_id: organizationId, $limit: limit, widget_id };

  const fetch = useCallback(
    async (params: any) => {
      const response = await axiosWithInterceptors.get<PaginatedResponse<LayoutComponent>>(apiRoutes.layoutComponents, {
        params,
      });
      return response.data;
    },
    [axiosWithInterceptors],
  );

  const fetchAsync: FetchLayoutComponentsAsyncFnType = useCallback(
    (widgetId: string) => {
      const params = { $limit: limit, widget_id: widgetId, organization_id: organizationId };
      return queryClient.fetchQuery({
        queryKey: ['layout-components', params],
        queryFn: () => fetch(params),
      });
    },
    [queryClient, limit, organizationId, fetch],
  );

  return {
    ...useQuery<PaginatedResponse<LayoutComponent>, AxiosError<ErrorResponse>>(['layout-components', fetchParams], () => fetch(fetchParams), options),
    fetchAsync,
  };
};
