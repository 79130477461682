import { formatPaginationResponse } from '../utils';

const populateCustomTheme = (widget, schema) => {
  if (widget.custom_theme_id) {
    widget.custom_theme = { ...schema.widgetThemes.find(widget.custom_theme_id)?.attrs };
  }
};

const populateMetaTags = (widget, schema) => {
  if (widget.meta_tags_id) {
    widget.meta_tags = schema.widgetMetaTags.findBy({ id: widget.meta_tags_id })?.attrs;
  }
};

export const widgetsRoutes = {
  '/widgets': {
    get: (schema: any, request) => {
      const widgets = schema.widgets.all().models.map(model => ({
        ...model.attrs,
      }));
      widgets.forEach(widget => populateCustomTheme(widget, schema));
      widgets.forEach(widget => populateMetaTags(widget, schema));

      return formatPaginationResponse(widgets, request);
    },
    post: (schema: any, request) => {
      const entity = schema.widgets.create({ ...JSON.parse(request.requestBody) });

      return entity.attrs;
    },
  },
  '/widgets/:id': {
    delete: (schema: any, request) => {
      const entity = schema.widgets.find(request.params.id);
      entity.destroy();
      const widget = entity.attrs;

      schema.archivedWidgets.create({
        id: widget.id,
        name: widget.name,
        created_at: new Date(),
        widget_created_at: widget.created_at,
        organization_id: widget.organization_id,
        format: widget.format,
        template_id: widget.templateId,
        last_domain_used: widget.domain || widget.subdomainId,
      });

      return widget;
    },
    get: (schema: any, request) => {
      const widget = schema.widgets.find(request.params.id).attrs;
      populateCustomTheme(widget, schema);
      populateMetaTags(widget, schema);

      return widget;
    },
    patch: (schema: any, request) => {
      const body = JSON.parse(request.requestBody);
      const widget = schema.widgets.find(request.params.id);
      if (body.meta_tags && widget.attrs.meta_tags_id) {
        const metaTagToUpdate = schema.widgetMetaTags.findBy({ id: widget.attrs.meta_tags_id });
        metaTagToUpdate.update(body.meta_tags);
        delete body.meta_tags;
      }
      if (!body.layout_shape) {
        delete body.layout_shape;
      }
      if (!body.theme_id) {
        delete body.theme_id;
      }
      widget.update(body);
      const updatedWidget = widget.attrs;

      populateMetaTags(updatedWidget, schema);

      return updatedWidget;
    },
  },
  '/widgets/:id/duplicate': {
    post: (schema: any, request) => {
      const widget = schema.widgets.find(request.params.id).attrs;
      const entity = schema.widgets.create({ ...widget, id: `${widget.id}-copy`, name: `Copy of ${widget.name}` });

      return { ...entity.attrs };
    },
  },
};
