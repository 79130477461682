import { devOrganizationId } from '@constants';
import { createDefaultLayoutComponents } from '../layoutComponents/seeds';

export const createDefaultLayout = (server, defaultOrganization = devOrganizationId, widget_id = 'widget-1') => {
  createDefaultLayoutComponents(server, defaultOrganization, widget_id);
  server.create('layout', {
    id: `${widget_id}_layout_1`,
    created_at: '2021-12-17T11:29:11.399Z',
    updated_at: '2021-12-17T11:29:11.399Z',
    organization_id: defaultOrganization,
    widget_id,
  });
  server.create('selectedLayoutComponent', {
    id: `${widget_id}_full_layout_component_1`,
    created_at: '2021-12-17T11:29:11.399Z',
    updated_at: '2021-12-17T11:29:11.399Z',
    organization_id: defaultOrganization,
    parent_id: null,
    layout_component_id: `${widget_id}_header_1`,
    layout_id: `${widget_id}_layout_1`,
    order: 0,
    widget_id,
  });
  server.create('selectedLayoutComponent', {
    id: `${widget_id}_full_layout_component_2`,
    created_at: '2021-12-17T11:29:11.399Z',
    updated_at: '2021-12-17T11:29:11.399Z',
    organization_id: defaultOrganization,
    parent_id: null,
    layout_component_id: `${widget_id}_block_1`,
    layout_id: `${widget_id}_layout_1`,
    order: 1,
    widget_id,
  });
  server.create('selectedLayoutComponent', {
    id: `${widget_id}_full_layout_component_3`,
    created_at: '2021-12-17T11:29:11.399Z',
    updated_at: '2021-12-17T11:29:11.399Z',
    organization_id: defaultOrganization,
    parent_id: `${widget_id}_full_layout_component_2`,
    layout_component_id: `${widget_id}_sections_1`,
    layout_id: `${widget_id}_layout_1`,
    order: 0,
    widget_id,
  });
  server.create('selectedLayoutComponent', {
    id: `${widget_id}_full_layout_component_4`,
    created_at: '2021-12-17T11:29:11.399Z',
    updated_at: '2021-12-17T11:29:11.399Z',
    organization_id: defaultOrganization,
    parent_id: `${widget_id}_full_layout_component_2`,
    layout_component_id: `${widget_id}_save_1`,
    layout_id: `${widget_id}_layout_1`,
    order: 1,
    widget_id,
  });
  server.create('selectedLayoutComponent', {
    id: `${widget_id}_full_layout_component_5`,
    created_at: '2021-12-17T11:29:11.399Z',
    updated_at: '2021-12-17T11:29:11.399Z',
    organization_id: defaultOrganization,
    parent_id: null,
    layout_component_id: `${widget_id}_footer_1`,
    layout_id: `${widget_id}_layout_1`,
    order: 2,
    widget_id,
  });
};
