import { devOrganizationId } from '@constants';
import { PreferenceType } from '@enums';

export const createDefaultPreferences = (server, defaultOrganization = devOrganizationId) => {
  const PVEmail = server.create('preferenceValue', {
    id: 'preference-value-rtca7haj1',
    created_at: '2020-07-10 15:00:00.000',
    updated_at: '2020-07-10 15:00:00.000',
    order: 0,
    name: {
      en: 'Email',
    },
  });

  const PVText = server.create('preferenceValue', {
    id: 'preference-value-rtca7haj2',
    created_at: '2020-07-10 15:00:00.000',
    updated_at: '2020-07-10 15:00:00.000',
    order: 1,
    name: {
      en: 'Text Message',
    },
  });

  const PVPhone = server.create('preferenceValue', {
    id: 'preference-value-rtca7haj3',
    created_at: '2020-07-10 15:00:00.000',
    updated_at: '2020-07-10 15:00:00.000',
    order: 2,
    name: {
      en: 'Phone Call',
    },
  });

  server.create('preference', {
    id: 'preference-hdfy2vfd1',
    created_at: '2020-07-10 15:00:00.000',
    description: {
      en: 'Communication Channels',
    },
    name: {
      en: 'Communication Channels',
    },
    updated_at: '2020-07-10 15:00:00.000',
    type: PreferenceType.MULTI,
    organization_id: defaultOrganization,
    values: [PVEmail, PVText, PVPhone],
  });

  const PVDaily = server.create('preferenceValue', {
    id: 'preference-value-vkby7haj1',
    created_at: '2020-07-10 15:00:00.000',
    updated_at: '2020-07-10 15:00:00.000',
    order: 0,
    name: {
      en: 'Daily',
    },
  });

  const PVWeekly = server.create('preferenceValue', {
    id: 'preference-value-vkby7haj2',
    created_at: '2020-07-10 15:00:00.000',
    updated_at: '2020-07-10 15:00:00.000',
    order: 1,
    name: {
      en: 'Weekly',
    },
  });

  const PVMonthly = server.create('preferenceValue', {
    id: 'preference-value-vkby7haj3',
    created_at: '2020-07-10 15:00:00.000',
    updated_at: '2020-07-10 15:00:00.000',
    order: 2,
    name: {
      en: 'Monthly',
    },
  });

  server.create('preference', {
    id: 'preference-sdff43fd1',
    created_at: '2020-07-10 15:00:00.000',
    name: {
      en: 'Frequency',
    },
    description: {
      en: 'Frecuency',
    },
    updated_at: '2020-07-10 15:00:00.000',
    type: PreferenceType.MULTI,
    organization_id: defaultOrganization,
    values: [PVDaily, PVWeekly, PVMonthly],
  });

  const PVEmail2 = server.create('preferenceValue', {
    id: 'preference-value-sdc5haj1',
    created_at: '2020-07-10 15:00:00.000',
    updated_at: '2020-07-10 15:00:00.000',
    order: 0,
    name: {
      en: 'Email',
    },
  });

  const PVText2 = server.create('preferenceValue', {
    id: 'preference-value-sdc5haj2',
    created_at: '2020-07-10 15:00:00.000',
    updated_at: '2020-07-10 15:00:00.000',
    order: 1,
    name: {
      en: 'Text Message',
    },
  });

  const PVPhone2 = server.create('preferenceValue', {
    id: 'preference-value-sdc5haj3',
    created_at: '2020-07-10 15:00:00.000',
    updated_at: '2020-07-10 15:00:00.000',
    order: 2,
    name: {
      en: 'Phone Call',
    },
  });

  server.create('preference', {
    id: 'preference-bvfw443ds',
    created_at: '2020-07-10 15:00:00.000',
    name: {
      en: 'Communication type',
    },
    description: {
      en: 'Communication type',
    },
    updated_at: '2020-07-10 15:00:00.000',
    type: PreferenceType.MULTI,
    organization_id: defaultOrganization,
    values: [PVEmail2, PVText2, PVPhone2],
  });

  const MultiLanguageValue = server.create('preferenceValue', {
    id: 'preference-value-multi-language',
    created_at: '2020-07-10 15:00:00.000',
    updated_at: '2020-07-10 15:00:00.000',
    order: 0,
    name: {
      en: 'Value',
      pt: 'Valor',
    },
  });

  server.create('preference', {
    id: 'preference-with-multi-language',
    created_at: '2020-07-10 15:00:00.000',
    name: {
      en: 'Name',
      pt: 'Nome',
    },
    description: {
      en: 'Description',
      pt: 'Descricao',
    },
    updated_at: '2020-07-10 15:00:00.000',
    type: PreferenceType.MULTI,
    organization_id: defaultOrganization,
    values: [MultiLanguageValue],
  });
};
