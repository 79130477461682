import React, { useContext } from 'react';
import { DidomiIcon } from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';
import { ValuePreferenceDropAreaContext, ValuePreferenceDropAreaContextProps } from './ValuePreferenceDropArea';

type ValuePreferenceDropPlaceholderProps = {
  'data-testid'?: string;
};

const ValuePreferenceDropPlaceholder = ({ 'data-testid': dataTestid }: ValuePreferenceDropPlaceholderProps): JSX.Element => {
  const { isOver } = useContext<ValuePreferenceDropAreaContextProps>(ValuePreferenceDropAreaContext);

  return (
    <div>
      <div
        data-testid={dataTestid || 'empty-preference-card'}
        className={tx(
          'before::(content-no-open-quote absolute top-0 -left-l border-l-1 border-b-1 rounded-bl-2xl border-neutral-gray-5 w-s h-s p-xxxs)',
          'relative ml-xxl border-1 border-dashed border-neutral-gray-4 rounded-lg box-border ',
          {
            'p-xs my-xs': isOver,
            'invisible h-[1px] p-0 m-0': !isOver,
            'visible h-auto py-5! bg-neutral-gray-1 border-primary-blue-6': isOver,
          },
        )}
      >
        <div className="flex">
          <DidomiIcon name="preference" aria-hidden className="min-w-[15px]" />
        </div>
      </div>
    </div>
  );
};

export { ValuePreferenceDropPlaceholder };
