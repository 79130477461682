import { useEffect, useMemo, useRef } from 'react';
import { buildWidgetPreviewPayload, snakeToCamelCase } from '@utils';
import { ShapesInput, Theme } from '@didomi/pmp-generator';
import { useDefaultThemes } from './useDefaultThemes.hook';
import { IframeSdkListeners, useDidomiSdk, useTranslationContext } from '@didomi/widgets-utilities';
import { useReleaseFlag } from '@didomi/utility-react';
import { Organization } from '@didomi/utility/dist/typings/src/organizations/models/organizations.model';
import { WidgetFormat, WidgetPreviewMode } from '@enums';
import { ConfigTree, LayoutComponent, LayoutEntity, Layout, WidgetTheme } from '@interfaces';
import { useLayouts } from '@hooks';
import { useWidgetPreviewData } from '@contexts';

const MODE_FOR_USING_CURRENT_LANGUAGE = [WidgetPreviewMode.ContentEditor, WidgetPreviewMode.ContentEditorNoSelection];

interface UseWidgetPreviewIframeOptions {
  organization: Organization;
  mode: WidgetPreviewMode;
  display: string;
  widgetId: string;
  widgetFormat: WidgetFormat;
  listeners?: IframeSdkListeners;
  configurationTree: ConfigTree | null;
  layoutComponents: LayoutComponent[];
  layoutEntities: LayoutEntity[];
}

const getSelectedTheme = ({ selectedThemeId, defaultThemes, customTheme }): WidgetTheme => {
  if (!selectedThemeId || !defaultThemes || !customTheme) {
    return null;
  }

  if (customTheme.id === selectedThemeId) {
    return customTheme;
  }

  return defaultThemes.find(({ id }) => id === selectedThemeId);
};

export const useWidgetPreviewIframe = ({
  organization,
  mode,
  display,
  widgetId,
  widgetFormat,
  listeners,
  configurationTree,
  layoutComponents,
  layoutEntities,
}: UseWidgetPreviewIframeOptions) => {
  const {
    iframe,
    setIframe,
    isSdkReady,
    updateWidgetLayout,
    initializeSdk,
    setWidgetMode,
    setWidgetLocale,
    updateComponent,
    setSelectedComponent,
    updateWidgetEntities,
    setSelectedComponentById,
    setSelectedComponentByType,
    scrollTo,
  } = useDidomiSdk({ listeners });
  const { customTheme, selectedThemeId, selectedLayoutShape } = useWidgetPreviewData();

  const { defaultThemes } = useDefaultThemes();
  const iframeInitialized = useRef(false);
  const [hasSectionEditingEnabled, isLoadingSectionEditingEnabled] = useReleaseFlag('content-editor-sections');
  const { enabledLanguages, defaultLanguage, activeEditorLanguage } = useTranslationContext();

  const { data: dataLayout, isLoading: isLoadingLayout } = useLayouts({
    widget_id: widgetId,
    limit: 1,
  });

  const layout: Layout = dataLayout?.data?.[0];

  const selectedTheme = useMemo(() => getSelectedTheme({ selectedThemeId, customTheme, defaultThemes }), [selectedThemeId, customTheme, defaultThemes]);

  useEffect(() => {
    if (!iframeInitialized.current || !isSdkReady || !mode) {
      return;
    }
    setWidgetMode(mode);
  }, [mode, isSdkReady, setWidgetMode]);

  useEffect(() => {
    if (!iframeInitialized.current || !isSdkReady || !defaultLanguage || !activeEditorLanguage) {
      return;
    }
    if (MODE_FOR_USING_CURRENT_LANGUAGE.includes(mode)) {
      setWidgetLocale(activeEditorLanguage);
    } else {
      setWidgetLocale(defaultLanguage);
    }
  }, [mode, isSdkReady, activeEditorLanguage, defaultLanguage, setWidgetLocale]);

  useEffect(() => {
    if (!iframeInitialized.current) {
      return;
    }

    updateWidgetLayout(
      Theme.generateTheme({
        colors: snakeToCamelCase(selectedTheme),
        layoutShape: selectedLayoutShape as unknown as ShapesInput,
      }),
    );
  }, [updateWidgetLayout, selectedTheme, selectedLayoutShape]);

  useEffect(() => {
    if (iframeInitialized.current) {
      return;
    }
    if (
      iframe &&
      configurationTree &&
      organization &&
      selectedTheme &&
      selectedLayoutShape &&
      layoutComponents.length > 0 &&
      layout &&
      !isLoadingSectionEditingEnabled &&
      layoutEntities?.length > 0
    ) {
      const payload = buildWidgetPreviewPayload({
        configurationTree,
        theme: selectedTheme,
        mode,
        display,
        layout,
        format: widgetFormat,
        components: layoutComponents,
        organization,
        layoutShape: selectedLayoutShape,
        flags: {
          sectionEditingEnabled: hasSectionEditingEnabled,
        },
        layoutEntities,
        languages: {
          enabled: enabledLanguages,
          default: defaultLanguage,
        },
      });
      iframeInitialized.current = true;
      initializeSdk(payload);
    }
  }, [
    configurationTree,
    organization,
    iframe,
    mode,
    display,
    widgetFormat,
    selectedLayoutShape,
    selectedTheme,
    initializeSdk,
    layout,
    layoutComponents,
    hasSectionEditingEnabled,
    isLoadingSectionEditingEnabled,
    layoutEntities,
    enabledLanguages,
    defaultLanguage,
  ]);

  return {
    setIframe,
    isSdkReady,
    updateComponent,
    setSelectedComponent,
    setSelectedComponentById,
    setSelectedComponentByType,
    scrollTo,
    updateWidgetEntities,
    loading: isLoadingLayout || isLoadingSectionEditingEnabled,
  };
};
