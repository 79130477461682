import { Translations, Language } from '@didomi/pmp-generator';
import { Translation } from '@types';
import { sanitizeHTML } from '@didomi/xss';

/**
 * When the content is not translatable for the language,
 * The code tries to find this languages first
 */
const PREFERENTIAL_LANGUAGES = [Language.EN, Language.FR, Language.IT, Language.ES, Language.DE];

const OTHER_LANGUAGES = Object.values(Language).filter(language => !PREFERENTIAL_LANGUAGES.includes(language));

export const AVAILABLE_LANGUAGES = [...PREFERENTIAL_LANGUAGES, ...OTHER_LANGUAGES];

export const DEFAULT_EMPTY_TRANSLATION = '-';

/**
 * Returns the language code that is going to be selected from the translationContent
 * based on the string availability.
 *
 * @returns {(string|null)} -> null means the translation is directly a string or empty translation object.
 */
export const getSelectedLanguageCode = (translationContent: Translation, language: Language = Language.EN) => {
  if (typeof translationContent === 'string') {
    return null;
  }
  if (translationContent?.[language]) {
    return language;
  }
  // If there's no value in default language we return a value in any language
  const languageAvailable = AVAILABLE_LANGUAGES.find(language => !!translationContent?.[language]);
  return languageAvailable ?? null;
};

/**
 * Return the translated value for the translationContent.
 * Rules:
 *  1 - Return translationContent if it is a string
 *  2 - Return translationContent[language] if the language is present.
 *  3 - Return the first available language present on translationContent.
 *  4 - Return defaultValue if translationContent is null, undefined or empty object.
 */
export const getTranslatedValue = (translationContent: Translation, defaultValue: string = '-', language: Language = Language.EN) => {
  const languageCode = getSelectedLanguageCode(translationContent, language);
  if (languageCode === null) {
    if (typeof translationContent === 'string') {
      return translationContent;
    }
    // This case will be possible when the propertyObject is empty -> `{}`
    return defaultValue;
  }
  return translationContent[languageCode] || defaultValue;
};

/**
 * Return the translated value for the translationContent.
 * Rules:
 * 1 - Return translationContent if it is a string
 * 2 - Return translationContent[language] if it the language is present.
 * 3 - Otherwise returns defaultValue
 */
export const getTranslatedValueStrict = (translationContent: Translation, defaultValue: string = '-', language: Language = Language.EN) => {
  if (typeof translationContent === 'string') {
    return translationContent;
  }

  return translationContent?.[language] || defaultValue;
};

/**
 * Returns the translated value as a Translations object
 * based on 'en' being the default language.
 *
 * @returns Translations
 */
export const createSanitizedTranslationFromValue = (value: string, content: Translations = {}): Translations => {
  return {
    ...content,
    en: sanitizeHTML(value).result,
  };
};
