import React, { useMemo } from 'react';
import { DidomiSwitch, DidomiRadio } from '@didomi/ui-atoms-react';
import { PurposeOutput } from '@didomi/pmp-generator/dist/types/entities/models/entities.model';
import { CollapsibleCard } from '@components';
import { ComponentsListInput } from '@didomi/pmp-generator';
import { getComponentTranslatedValue } from '@utils';
import { Direction, SelectedEntityType } from '@enums';
import { EditWidgetPreferencesBaseProps } from '@types';
import { useTranslationContext } from '@didomi/widgets-utilities';
import { EditPreferenceControl } from './EditPreferenceControl';
import EditWidgetPreferencesControlTooltip from './EditWidgetPreferencesControlTooltip';
import { useReleaseFlag } from '@didomi/utility-react';
import { EditPurposeHeaderActions } from './EditPurposeHeaderActions';
interface EditPurposeControlProps extends EditWidgetPreferencesBaseProps {
  selectedPurpose: PurposeOutput;
  selectedEntityId?: string;
  selectionType: 'single' | 'multi';
  components?: ComponentsListInput;
  titleHighlightText?: string;
}

const handleStopPropagation = (ev: React.SyntheticEvent) => {
  ev.stopPropagation();
};

export function EditPurposeControl({
  selectedPurpose,
  selectionType,
  selectedEntityId,
  widgetPreferences,
  setWidgetLayoutEntityEnabled,
  setSelectedEntity,
  hasEnabledLayoutEntity,
  components,
  titleHighlightText,
  preferenceOrderingControls,
}: EditPurposeControlProps) {
  const [hasLayoutReorderingFeature] = useReleaseFlag('pmp_layout_reordering');
  const domId = `edit-pref-purpose-${selectedPurpose.id}`;
  const preferenceCount = selectedPurpose.preferences.length;
  const selectedPreferences = selectedPurpose.preferences;
  const updatedValue = widgetPreferences?.[selectedPurpose.layoutEntityId]?.enabled;
  const enabled = typeof updatedValue === 'boolean' ? updatedValue : selectedPurpose.enabled !== false;
  const headerDisplayDirection = selectionType === 'single' ? 'row' : 'column';
  const isSelectable = selectionType === 'single';
  const isCurrentPurposeSelected = selectedPurpose.id === selectedEntityId;
  const { defaultLanguage } = useTranslationContext();
  const translatedPurposeValue = getComponentTranslatedValue(selectedPurpose.component?.id, 'purposeOptions.title.content', selectedPurpose.name, components, defaultLanguage);
  const translatedPurposeYesLabel = getComponentTranslatedValue(selectedPurpose.component?.id, 'purposeOptions.yesLabel.content', 'Yes', components, defaultLanguage);
  const translatedPurposeNoLabel = getComponentTranslatedValue(selectedPurpose.component?.id, 'purposeOptions.noLabel.content', 'No', components, defaultLanguage);

  const hasEnabledLayoutEntityId = useMemo(() => hasEnabledLayoutEntity?.(selectedPurpose.layoutEntityId), [selectedPurpose, hasEnabledLayoutEntity]);

  const headerActionDisabled = preferenceOrderingControls?.getDisabledActionControls(selectedPurpose.layoutEntityId);
  return (
    <CollapsibleCard
      variant="bordered"
      data-testid={domId}
      icon="purpose"
      contentId={domId}
      title={translatedPurposeValue}
      isTitleDisabled={!enabled}
      removeTopMargin
      isCollapsible={!!preferenceCount && selectionType === 'multi'}
      initialCollapsed={!preferenceCount}
      allowToggleOnHeaderClick={false}
      animate
      isHeaderSelected={isCurrentPurposeSelected}
      isHeaderSelectable={isSelectable}
      headerDisplayDirection={headerDisplayDirection}
      titleHighlightText={titleHighlightText}
      onHeaderClick={() => setSelectedEntity?.(SelectedEntityType.Purpose, selectedPurpose.id)}
      inlineHeaderChildren={
        hasLayoutReorderingFeature &&
        selectionType === 'multi' && (
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
          <div role="region" onClick={handleStopPropagation} className="rounded-lg">
            <EditPurposeHeaderActions
              purposeId={selectedPurpose.id}
              isDisplaySectionEnabled={hasEnabledLayoutEntityId}
              isDisplayContentRequestEnabled={enabled}
              onDisplaySectionChange={enabled => setWidgetLayoutEntityEnabled(selectedPurpose.layoutEntityId, enabled, true)}
              onDisplayContentRequestChange={enabled => setWidgetLayoutEntityEnabled(selectedPurpose.layoutEntityId, enabled)}
              moveUpDisabled={headerActionDisabled?.upOptionDisabled}
              moveDownDisabled={headerActionDisabled?.downOptionDisabled}
              onMoveDown={() => preferenceOrderingControls?.move(selectedPurpose.layoutEntityId, Direction.DOWN)}
              onMoveUp={() => preferenceOrderingControls?.move(selectedPurpose.layoutEntityId, Direction.UP)}
            />
          </div>
        )
      }
      headerChildren={
        <>
          {!hasLayoutReorderingFeature && selectionType === 'multi' && (
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
            <div role="region" onClick={handleStopPropagation} className="rounded-lg">
              <DidomiSwitch
                data-testid={`edit-purpose-switch-${selectedPurpose.id}`}
                className="text-body-small font-italic p-xs pt-0"
                valueSelected="selected"
                valueNotSelected="not-selected"
                value={enabled ? 'selected' : 'not-selected'}
                hideErrorMessage
                onValueChange={ev => {
                  setWidgetLayoutEntityEnabled(selectedPurpose.layoutEntityId, ev.detail === 'selected');
                }}
              >
                Display the consent request
              </DidomiSwitch>
            </div>
          )}

          {selectionType === 'single' && (
            <div className="flex items-center text-primary-blue-6">
              <EditWidgetPreferencesControlTooltip
                id={selectedPurpose.id}
                title={translatedPurposeValue}
                icon="purpose"
                values={[translatedPurposeYesLabel, translatedPurposeNoLabel]}
              />
              <DidomiRadio data-testid={`edit-purpose-radio-${selectedPurpose.id}`} className="-mr-xxs" value={selectedPurpose.id}></DidomiRadio>
            </div>
          )}
        </>
      }
    >
      {!!preferenceCount && (
        <div className="border-t border-solid border-neutral-gray-4 p-xs pb-0">
          {selectedPreferences.map(selectedPreference => {
            return (
              <div className="pb-s" key={`${selectedPreference.id}-${selectedPreference.order}`}>
                <div
                  className={preferenceOrderingControls?.getAnimation(selectedPreference.layoutEntityId)}
                  onAnimationEnd={preferenceOrderingControls?.getScrollHandler(selectedPreference.layoutEntityId)}
                >
                  <EditPreferenceControl
                    preferenceOrderingControls={preferenceOrderingControls}
                    selectionType={selectionType}
                    selectedPreference={selectedPreference}
                    isSelectable={isSelectable}
                    titleHighlightText={titleHighlightText}
                    selectedEntityId={selectedEntityId}
                    widgetPreferences={widgetPreferences}
                    setWidgetLayoutEntityEnabled={setWidgetLayoutEntityEnabled}
                    setSelectedEntity={setSelectedEntity}
                    components={components}
                  />
                </div>
              </div>
            );
          })}
        </div>
      )}
    </CollapsibleCard>
  );
}
