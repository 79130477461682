import React from 'react';
import { LoadingPreferenceCard } from '@components';

/**
 * PreferenceLibraryLoadingState Component
 */
export const PreferenceLibraryLoadingState = (): JSX.Element => (
  <>
    <LoadingPreferenceCard data-testid="loading-preference-0" />
    <LoadingPreferenceCard data-testid="loading-preference-1" />
    <LoadingPreferenceCard data-testid="loading-preference-2" />
  </>
);
