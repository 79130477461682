import { devOrganizationId } from '@constants';

export const createDefaultDomains = (server, defaultOrganization = devOrganizationId, widgetId = 'widget-1') => {
  server.create('domain', {
    id: 'domain-exists.staging.preference-center.org',
    enabled: false,
    widget_id: widgetId,
    organization_id: defaultOrganization,
  });
};
