import { GenericEmptyState } from '@components';
import { WIDGET_TEMPLATE_ID_LABELS } from '@constants';
import {
  DidomiIconButton,
  DidomiPaginator,
  DidomiSkeleton,
  DidomiTable,
  DidomiTableBody,
  DidomiTableHeaderRow,
  DidomiTableHeading,
  DidomiTableRow,
  DidomiTableTd,
  DidomiTableTh,
  DidomiTooltip,
} from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';
import { useSnackbar } from '@didomi/utility-react';
import { useWidgetDeploymentPreview, useArchivedWidgets } from '@hooks';
import React from 'react';
import { useHistory } from 'react-router-dom';

const ArchivedWidgetTable = () => {
  const { displaySnackbar } = useSnackbar();
  const { preview, loading: loadingPreview } = useWidgetDeploymentPreview();
  const { push } = useHistory();
  const {
    isLoading,
    error,
    data: widgetsArchivedResult,
    refetch,
    paginator,
  } = useArchivedWidgets({
    onError: () => {
      displaySnackbar('Archived widget list can not be loaded', {
        variant: 'error',
        permanent: true,
        id: 'widget-archived-fetch-list-error',
        title: 'An error occurred:',
        action: {
          action: /* istanbul ignore next */ () => {
            refetch();
          },
          name: 'Reload',
        },
        secondaryAction: {
          action: /* istanbul ignore next */ () => {},
          name: 'Close',
          closeInAction: true,
        },
      });
    },
  });

  const widgetsArchivedCount = widgetsArchivedResult?.total || 0;
  const widgetsArchived = widgetsArchivedResult?.data || [];
  const showWidgetTable = isLoading || (widgetsArchivedCount > 0 && !error);
  const countText = widgetsArchivedCount ? `${widgetsArchivedCount} result${widgetsArchivedCount > 1 ? 's' : ''}` : '';

  return (
    <div className="flex flex-col">
      {showWidgetTable && (
        <>
          <DidomiSkeleton isLoading={isLoading} className="mb-xxs w-40">
            <h4 className="h4 font-bold text-primary-blue-6 h-5 mb-xxs">{countText}</h4>
          </DidomiSkeleton>
          <DidomiTable data-testid={isLoading ? 'loading-table' : 'widget-archived-table'} className="mb-4 w-auto">
            <DidomiTableHeading>
              <DidomiTableHeaderRow>
                <DidomiTableTh>NAME</DidomiTableTh>
                <DidomiTableTh>FORMAT</DidomiTableTh>
                <DidomiTableTh cellAlign="center" style={{ maxWidth: 350 }}></DidomiTableTh>
              </DidomiTableHeaderRow>
            </DidomiTableHeading>
            <DidomiTableBody>
              {isLoading &&
                Array.from({ length: paginator.limit }).map((_, i) => (
                  <DidomiTableRow key={'loading-widget-' + i}>
                    <DidomiTableTd class="children:(w-full)">
                      <DidomiSkeleton isLoading={isLoading} className="h-s w-full pr-s" />
                    </DidomiTableTd>
                    <DidomiTableTd class="children:(w-full)">
                      <DidomiSkeleton isLoading={isLoading} className="h-s w-full pr-s" />
                    </DidomiTableTd>
                    <DidomiTableTd cellAlign="right" style={{ maxWidth: 350 }}>
                      <div className="flex p-1">
                        <DidomiIconButton icon="preview" variant="rounded" aria-label="Preview widget" className="mr-xs" disabled />
                      </div>
                    </DidomiTableTd>
                  </DidomiTableRow>
                ))}
              {!isLoading &&
                widgetsArchived?.map(({ id, name, template_id }) => {
                  const isPreviewing = loadingPreview.includes(id);
                  return (
                    <DidomiTableRow key={id} selectionValue={id} data-testid={id} data-cy={id}>
                      <DidomiTableTd>
                        <div className="truncate w-full py-1">{name}</div>
                      </DidomiTableTd>
                      <DidomiTableTd>
                        <div className="truncate w-full py-1">{WIDGET_TEMPLATE_ID_LABELS[template_id]}</div>
                      </DidomiTableTd>
                      <DidomiTableTd cellAlign="right" style={{ maxWidth: 350 }}>
                        <div className="flex p-1">
                          <DidomiTooltip placement="top" distance="xxxs" content="Preview widget">
                            <DidomiIconButton
                              icon={isPreviewing ? 'reset' : 'preview'}
                              data-testid={`widget-preview-${id}-${isPreviewing}`}
                              variant="rounded"
                              aria-label="Preview widget"
                              data-tracking="pmp-preview-widget-from-list"
                              className={tx('mr-xs cursor-pointer', {
                                'animate-spin': isPreviewing,
                              })}
                              onClick={() => preview({ id, name, template_id })}
                            />
                          </DidomiTooltip>
                        </div>
                      </DidomiTableTd>
                    </DidomiTableRow>
                  );
                })}
            </DidomiTableBody>
          </DidomiTable>
          <DidomiPaginator
            data-testid="widget-paginator"
            className="self-end mb-s"
            page={paginator.page}
            itemCount={widgetsArchivedCount}
            size={paginator.limit}
            onPageSizeChange={paginator.setLimit}
            onPageChange={paginator.setPage}
          />
        </>
      )}
      {!showWidgetTable && error && (
        <div className="h-full">
          <GenericEmptyState illustration="list-cannot-be-loaded" className="h-full border-1 border-dashed border-neutral-gray-5 rounded-lg flex-1" />
        </div>
      )}
      {!showWidgetTable && !isLoading && !error && (
        <div className="h-full">
          <GenericEmptyState
            title="You have no widget yet"
            illustration="no-widget-yet"
            className="border-1 border-dashed border-neutral-gray-5 rounded-lg py-8"
            ctaText="Create a widget"
            onCtaClick={() => push(`/widgets/create`)}
            ctaIcon={{
              position: 'right',
              icon: 'new-create',
            }}
          />
        </div>
      )}
    </div>
  );
};

export { ArchivedWidgetTable };
