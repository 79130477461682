import React, { useRef } from 'react';
import { DidomiIconButton, DidomiMenu, DidomiMenuItem, DidomiSwitch, DidomiTooltip } from '@didomi/ui-atoms-react';
import { StateIconButton } from '@components';

const CLOSE_MENU_TIMEOUT = 500;

interface Props {
  purposeId: string;
  isDisplaySectionEnabled?: boolean;
  isDisplayContentRequestEnabled?: boolean;
  dataTestId?: string;
  moveDownDisabled?: boolean;
  moveUpDisabled?: boolean;
  onMoveDown?: () => void;
  onMoveUp?: () => void;
  onDisplaySectionChange?: (value: boolean) => void;
  onDisplayContentRequestChange?: (value: boolean) => void;
}

export const EditPurposeHeaderActions = ({
  purposeId,
  isDisplaySectionEnabled,
  isDisplayContentRequestEnabled,
  dataTestId = 'purpose-header-actions',
  moveDownDisabled,
  moveUpDisabled,
  onMoveDown,
  onMoveUp,
  onDisplaySectionChange,
  onDisplayContentRequestChange,
}: Props) => {
  const ref = useRef<HTMLDidomiMenuElement>();

  const closeMenu = () => ref.current.close();

  const handleDisplaySectionChange = () => {
    onDisplaySectionChange?.(!isDisplaySectionEnabled);
    setTimeout(() => closeMenu(), CLOSE_MENU_TIMEOUT);
  };

  const handleDisplayContentRequestChange = () => {
    onDisplayContentRequestChange?.(!isDisplayContentRequestEnabled);
    setTimeout(() => closeMenu(), CLOSE_MENU_TIMEOUT);
  };

  return (
    <>
      <DidomiTooltip placement="top" distance="xxxs" content="Options">
        <DidomiIconButton
          id={`purpose-header-options-${purposeId}`}
          data-testid={`${dataTestId}-submenu`}
          icon={'submenu'}
          variant="rounded"
          aria-label="Options"
          className={'mr-xxs cursor-pointer'}
        />
      </DidomiTooltip>

      <DidomiMenu ref={ref} for={`purpose-header-options-${purposeId}`} placement="left-start">
        <DidomiMenuItem closeOnSelect={false} onItemSelected={handleDisplaySectionChange}>
          <div className="flex justify-between items-center">
            <span className="mr-xs">Display section</span>
            <DidomiSwitch
              className="!w-auto"
              data-testid={`${dataTestId}-display-section-switch`}
              labelVariant="no-box"
              valueSelected="selected"
              valueNotSelected="not-selected"
              value={isDisplaySectionEnabled ? 'selected' : 'not-selected'}
              hideErrorMessage
            />
          </div>
        </DidomiMenuItem>
        <DidomiMenuItem closeOnSelect={false} onItemSelected={handleDisplayContentRequestChange}>
          <div className="flex justify-between items-center">
            <span className="mr-xs">Display the consent request</span>
            <DidomiSwitch
              className="w-auto"
              data-testid={`${dataTestId}-display-content-request-switch`}
              labelVariant="no-box"
              valueSelected="selected"
              valueNotSelected="not-selected"
              value={isDisplayContentRequestEnabled ? 'selected' : 'not-selected'}
              hideErrorMessage
            />
          </div>
        </DidomiMenuItem>
        <div className="h-[1px] bg-[#9EBDCB] my-[5px]" />
        <DidomiMenuItem disabled={moveUpDisabled} onItemSelected={!moveUpDisabled ? onMoveUp : undefined}>
          <StateIconButton
            icon="move-up"
            hoverIcon="move-up-hover"
            disabledIcon="move-up-disabled"
            disabled={moveUpDisabled}
            dataTestId={`${dataTestId}-move-up`}
            onClick={!moveUpDisabled ? onMoveUp : undefined}
            hideOutline
          >
            <span>Move up</span>
          </StateIconButton>
        </DidomiMenuItem>
        <DidomiMenuItem disabled={moveDownDisabled} onItemSelected={!moveDownDisabled ? onMoveDown : undefined}>
          <StateIconButton
            icon="move-down"
            hoverIcon="move-down-hover"
            disabledIcon="move-down-disabled"
            disabled={moveDownDisabled}
            dataTestId={`${dataTestId}-move-down`}
            onClick={!moveDownDisabled ? onMoveDown : undefined}
            hideOutline
          >
            <span>Move down</span>
          </StateIconButton>
        </DidomiMenuItem>
      </DidomiMenu>
    </>
  );
};
