import { Factory } from 'miragejs';
import { devOrganizationId } from '@constants';
import { generateRandomId } from '@utils';

const preference = Factory.extend({
  id() {
    return generateRandomId('preference');
  },
  description(index) {
    return {
      en: `Preference ${index}`,
    };
  },
  updated_at: new Date().toISOString(),
  created_at: new Date().toISOString(),
  organization_id: devOrganizationId,
});

const preferenceValue = Factory.extend({
  id() {
    return generateRandomId('preferenceValue');
  },
  name(index) {
    return {
      en: `Preference value ${index}`,
    };
  },
  updated_at: new Date().toISOString(),
  created_at: new Date().toISOString(),
  organization_id: devOrganizationId,
  order(index) {
    return index;
  },
});

export default { preference, preferenceValue };
