import React, { useMemo } from 'react';
import { DidomiTooltip } from '@didomi/ui-atoms-react';

interface EditWidgetBreadcrumbItemProps {
  'data-testid'?: string;
  text: string;
}

const TEXT_LIMIT_FOR_TOOLTIP = 30;
const ELLIPSIS = '...';

const EditWidgetBreadcrumbItem = ({ 'data-testid': dataTestId, text }: EditWidgetBreadcrumbItemProps): JSX.Element => {
  const tooltipData = useMemo(() => {
    const capitalizedText = text[0].toUpperCase() + text.slice(1);
    if (text.length > TEXT_LIMIT_FOR_TOOLTIP) {
      return {
        showTooltip: true,
        text: capitalizedText.slice(0, TEXT_LIMIT_FOR_TOOLTIP - ELLIPSIS.length) + ELLIPSIS,
      };
    }
    return {
      showTooltip: false,
      text: capitalizedText,
    };
  }, [text]);

  const contentElement = (
    <span data-testid={`${dataTestId}-text`} className="cursor-default">
      {tooltipData.text}
    </span>
  );

  if (!tooltipData.showTooltip) {
    return contentElement;
  }

  return (
    <DidomiTooltip data-testid={`${dataTestId}-tooltip`} placement="top" distance="xxxs" content={text}>
      {contentElement}
    </DidomiTooltip>
  );
};

export { EditWidgetBreadcrumbItem };
