import React from 'react';
import { DidomiButton } from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';

interface HorizontalStepProps {
  nextButtonText?: string;
  previousButtonText?: string;
  onNext?: () => void;
  onPrevious?: () => void;
  nextButtonDisplayed?: boolean;
  previousButtonDisplayed?: boolean;
  nextButtonDisabled?: boolean;
  previousButtonDisabled?: boolean;
  children?: React.ReactChild;
  position?: never;
}

const HorizontalStep = ({
  nextButtonText = 'Next',
  previousButtonText = 'Previous',
  nextButtonDisplayed = true,
  previousButtonDisplayed = true,
  onNext,
  onPrevious,
  nextButtonDisabled = false,
  previousButtonDisabled = false,
  children,
  position,
}: HorizontalStepProps) => {
  const PreviousButton = () => (
    <DidomiButton data-testid="previous-button" variant="secondary" disabled={previousButtonDisabled} onClick={onPrevious} className="min-w-[48px]">
      {previousButtonText}
    </DidomiButton>
  );

  const NextButton = () => (
    <DidomiButton data-testid="next-button" disabled={nextButtonDisabled} onClick={onNext} className="min-w-[48px]">
      {nextButtonText}
    </DidomiButton>
  );

  const isSticky = position === 'sticky';
  const buttonsWrapperClass = tx('p-4 flex items-center justify-center w-full bg-white border-t-1 border-neutral-gray-3 border-solid', {
    'sticky bottom-0': isSticky,
  });

  return (
    <div className="h-full flex flex-col justify-between flex-1">
      {children}

      <div className={buttonsWrapperClass}>
        <div className="flex gap-4">
          {previousButtonDisplayed && <PreviousButton />}
          {nextButtonDisplayed && <NextButton />}
        </div>
      </div>
    </div>
  );
};

export { HorizontalStep };
