import React from 'react';
import { tx } from '@twind/core';
import { ConfigTreeEntityState } from '@enums';
import { DidomiIcon } from '@didomi/ui-atoms-react';

const STATE_LABELS: Record<ConfigTreeEntityState, string> = {
  adding: 'added',
  resetting: 'reset',
  deleting: 'deleted',
};

interface ConfigTreeEntityStateCardProps {
  label: string;
  state: ConfigTreeEntityState;
  className?: string;
}

const ConfigTreeEntityStateCard = ({ label, state, className }: ConfigTreeEntityStateCardProps) => {
  return (
    <div data-testid="config-tree-entity-state-card">
      <div
        className={tx('flex flex-row justify-center items-center text-left px-s py-xs rounded-2xl', {
          'bg-neutral-gray-2': [ConfigTreeEntityState.ADDING].includes(state),
          'bg-primary-pink-2': [ConfigTreeEntityState.DELETING, ConfigTreeEntityState.RESETTING].includes(state),
          [className]: className,
        })}
      >
        <div className="flex flex-col flex-1">
          <span className="text-body-small font-semibold text-primary-blue-6">
            {label} will be {STATE_LABELS[state]} in few seconds
          </span>
          <span
            className={tx('text-[10px] font-bold', {
              'text-primary-blue-4': [ConfigTreeEntityState.ADDING].includes(state),
              'text-primary-pink-5': [ConfigTreeEntityState.DELETING, ConfigTreeEntityState.RESETTING].includes(state),
            })}
          >
            Usually less than 1 minute
          </span>
        </div>

        <div>
          <DidomiIcon name="reset" aria-label="Loading" className="text-primary-blue-6 ml-auto animate-spin" />
        </div>
      </div>
    </div>
  );
};

export { ConfigTreeEntityStateCard };
