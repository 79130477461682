import { SelectedPreference, SelectedPurpose } from '@interfaces';

/**
 * Returns true if a preferenceId is included inside the current Configuration Tree
 * @param {string} preferenceId The preference id
 * @return {boolean} True if the preference is on the tree
 */
export const isPreferenceInTree = (preferenceId: string, existingPurposes: SelectedPurpose[]): boolean => {
  for (const sPurpose of existingPurposes) {
    for (const sPref of sPurpose?.selected_preferences) {
      if (isPreferenceInSelectedPreference(preferenceId, sPref)) {
        return true;
      }
    }
  }
  return false;
};

/**
 * Returns true if a preferenceId is included inside a selected preference
 * @param {string} preferenceId The preference id
 * @param {SelectedPreference} selectedPreference The selected preference
 * @return {boolean} True if the preference is on the selected preference
 */
const isPreferenceInSelectedPreference = (preferenceId: string, selectedPreference: SelectedPreference): boolean => {
  if (selectedPreference.preference.id === preferenceId) {
    return true;
  }

  if (!selectedPreference?.preference?.values) {
    return false;
  }

  for (const value of selectedPreference.preference.values) {
    if (value?.selected_preferences) {
      for (const sPref of value.selected_preferences) {
        if (isPreferenceInSelectedPreference(preferenceId, sPref)) {
          return true;
        }
      }
    }
  }

  return false;
};
