import { AxiosError } from 'axios';
import { PaginatedResponse } from '@types';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useActiveOrganization } from '@didomi/utility-react';
import { apiRoutes } from '@constants';
import { useAxiosWithLogOut } from '../useAxiosWithInterceptors.hook';
import { Preference, ErrorResponse } from '@interfaces';

export const usePreferences = (options?: UseQueryOptions<PaginatedResponse<Preference>>) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();

  const fetch = async () => {
    const response = await axiosWithInterceptors.get<PaginatedResponse<Preference>>(apiRoutes.preferences, {
      params: { organization_id: organizationId },
    });
    return response.data;
  };

  return useQuery<PaginatedResponse<Preference>, AxiosError<ErrorResponse>>(['preferences', { organization_id: organizationId }], fetch, {
    ...options,
  });
};
