import { AxiosError, AxiosResponse } from 'axios';
import { UseMutationOptions, useQueryClient, useMutation } from '@tanstack/react-query';
import { useActiveOrganization } from '@didomi/utility-react';
import { apiRoutes } from '@constants';
import { useAxiosWithLogOut } from '../useAxiosWithInterceptors.hook';
import { Preference } from '@interfaces';
import { PreferenceType } from '@enums';
import { Translations } from '@didomi/pmp-generator';
import { ErrorResponse } from '../../../interfaces/error-response.interface';
import { updateQueryClientCache } from '@utils';

export type UpdatePreferenceValuePayload = {
  id?: string;
  metadata?: Record<string, string>;
  name?: Translations;
};

export interface UpdatePreferencePayload {
  id: string;
  description?: Translations;
  metadata?: Record<string, string>;
  name?: Translations;
  type?: PreferenceType;
  values?: UpdatePreferenceValuePayload[];
}

export const useUpdatePreference = (options?: UseMutationOptions<AxiosResponse<Preference>, AxiosError<ErrorResponse>, UpdatePreferencePayload>) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();
  const queryClient = useQueryClient();

  const patch = ({ id, ...payload }: UpdatePreferencePayload) => {
    return axiosWithInterceptors.patch<Preference>(`${apiRoutes.preferences}/${id}`, payload, {
      params: { organization_id: organizationId },
    });
  };

  return useMutation<AxiosResponse<Preference>, AxiosError<ErrorResponse>, UpdatePreferencePayload>(patch, {
    ...options,
    onSuccess: async (res: AxiosResponse<Preference>, variables: UpdatePreferencePayload, context) => {
      // this will update all queries/caches that start with 'preferences'
      const dataKey = ['preferences'];

      updateQueryClientCache(queryClient, res, dataKey);
      await queryClient.invalidateQueries(['config-trees']);

      if (options?.onSuccess) {
        options.onSuccess(res, variables, context);
      }
    },
  });
};
