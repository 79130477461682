import React, { useMemo } from 'react';
import { useDroppable } from '@dnd-kit/core';
import { generateUniqueId } from '@utils';

interface ValuePreferenceDropAreaProps {
  children: React.ReactNode;
  order?: number;
  parentId?: string;
  parentPreferenceValueId?: string;
  parentSelectedPurposeId: string;
  isParentPurpose: boolean;
  disabled?: boolean;
}

export interface ValuePreferenceDropAreaContextProps {
  isOver: boolean;
}

export const ValuePreferenceDropAreaContext = React.createContext<ValuePreferenceDropAreaContextProps>({ isOver: false });

/**
 * Value Preference Drop Area
 * Component used to define a droppable area for dnd-kit.
 * More details on this behavior on https://docs.dndkit.com/api-documentation/droppable
 */
export const ValuePreferenceDropArea = ({
  children,
  order = 0,
  parentId,
  parentSelectedPurposeId,
  parentPreferenceValueId,
  isParentPurpose,
  disabled = false,
}: ValuePreferenceDropAreaProps): JSX.Element => {
  const id = useMemo(() => generateUniqueId('value-preference-drop'), []);
  const { setNodeRef, isOver } = useDroppable({
    id,
    disabled,
    data: {
      order,
      parentId,
      parentPreferenceValueId,
      parentSelectedPurposeId,
      isParentPurpose,
    },
  });
  const contextValue = useMemo(() => ({ isOver }), [isOver]);
  return (
    <div ref={setNodeRef}>
      <ValuePreferenceDropAreaContext.Provider value={contextValue}>{children}</ValuePreferenceDropAreaContext.Provider>
    </div>
  );
};
