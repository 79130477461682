import { useCallback, useMemo } from 'react';
import { DidomiTextOptions, Translations } from '@didomi/pmp-generator';
import { useTranslationContext } from '@didomi/widgets-utilities';
import { sanitizeHTML } from '@didomi/xss';

export const useContentEditorTranslatedTextInput = (value?: DidomiTextOptions, onChange?: (newValue?: DidomiTextOptions) => void) => {
  const { activeEditorLanguage } = useTranslationContext();
  const selectedValue = useMemo(() => {
    return value?.content?.[activeEditorLanguage] || '';
  }, [value?.content, activeEditorLanguage]);

  const handleValueChange = useCallback(
    (ev: CustomEvent<string>) => {
      if (selectedValue !== ev.detail) {
        onChange({
          content: {
            ...(value?.content ? (value.content as Translations) : {}),
            [activeEditorLanguage]: ev.detail,
          },
        });
      }
    },
    [onChange, activeEditorLanguage, value?.content, selectedValue],
  );

  const handleFocusChange = useCallback(
    (ev: CustomEvent<boolean>) => {
      const hasLeftFocus = !ev.detail;
      if (selectedValue && hasLeftFocus) {
        onChange({
          content: {
            ...(value?.content ? (value.content as Translations) : {}),
            [activeEditorLanguage]: sanitizeHTML(selectedValue).result,
          },
        });
      }
    },
    [onChange, selectedValue, value, activeEditorLanguage],
  );

  return {
    value: selectedValue,
    onChange: handleValueChange,
    onFocusChange: handleFocusChange,
  };
};
