import { AxiosError, AxiosResponse } from 'axios';
import { UseMutationOptions, useQueryClient, useMutation } from '@tanstack/react-query';
import { useActiveOrganization } from '@didomi/utility-react';
import { apiRoutes } from '@constants';
import { useAxiosWithLogOut } from '../useAxiosWithInterceptors.hook';
import { WidgetTheme } from '@interfaces';
import { ErrorResponse } from '../../../interfaces/error-response.interface';

export interface UpdateCustomThemePayload {
  id: string;
  color_1?: string;
  color_2?: string;
  color_3?: string;
  color_4?: string;
  color_5?: string;
  color_6?: string;
  color_7?: string;
  color_8?: string;
}

export const useUpdateCustomTheme = (options?: UseMutationOptions<AxiosResponse<WidgetTheme>, AxiosError<ErrorResponse>, UpdateCustomThemePayload>) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();
  const queryClient = useQueryClient();

  const patch = ({ id, ...payload }: UpdateCustomThemePayload) => {
    return axiosWithInterceptors.patch<WidgetTheme>(`${apiRoutes.widgetThemes}/${id}`, payload, {
      params: { organization_id: organizationId },
    });
  };

  return useMutation<AxiosResponse<WidgetTheme>, AxiosError<ErrorResponse>, UpdateCustomThemePayload>(patch, {
    ...options,
    onSuccess: async (res: AxiosResponse<WidgetTheme>, variables: UpdateCustomThemePayload, context) => {
      // this will update all queries/caches that start with 'custom-themes', id
      const dataKey = ['custom-themes', res.data.id];
      await queryClient.invalidateQueries(dataKey);

      if (options?.onSuccess) {
        options.onSuccess(res, variables, context);
      }
    },
  });
};
