import React from 'react';
import { DidomiTextarea } from '@didomi/ui-atoms-react';
import { ContentEditorHintboxWrapper, ContentEditorHintboxWrapperProps } from './ContentEditorHintboxWrapper';
import type { DidomiTextOptions } from '@didomi/pmp-generator';
import { useContentEditorTranslatedTextInput } from '@hooks';

type DidomiTextareaProps = React.ComponentProps<typeof DidomiTextarea>;

export interface ContentEditorTextInputProps extends Omit<DidomiTextareaProps, 'value' | 'onChange' | 'onValueChange'>, ContentEditorHintboxWrapperProps {
  'data-testid'?: string;
  value?: DidomiTextOptions;
  onChange(newValue?: DidomiTextOptions): void;
}

export const ContentEditorTextarea = ({ 'data-testid': dataTestId, hint, hasBottomMargin, value, onChange, ...restProps }: ContentEditorTextInputProps) => {
  const { value: uiValue, onChange: handleValueChange, onFocusChange: handleFocusChange } = useContentEditorTranslatedTextInput(value, onChange);

  return (
    <ContentEditorHintboxWrapper hint={hint} hasBottomMargin={hasBottomMargin}>
      <DidomiTextarea
        data-testid={dataTestId}
        hideErrorMessage
        {...restProps}
        value={uiValue}
        onValueChange={handleValueChange}
        onFocusChange={handleFocusChange}
        data-skeleton
        className="w-full"
      />
    </ContentEditorHintboxWrapper>
  );
};
