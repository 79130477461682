import { useState } from 'react';
import { useActiveOrganization, useSnackbar } from '@didomi/utility-react';
import { useHistory } from 'react-router-dom';
import { ComponentsListInput, Language } from '@didomi/pmp-generator';
import { WIDGET_TEMPLATE_ID_TO_WIDGET_DISPLAY, LocalStorage } from '@constants';
import { WidgetPreviewMode } from '@enums';
import { Widget } from '@interfaces';
import { useWidgetDeployments } from '@hooks';

/**
 * When the mesh receive the property config.components.list, it's trying to
 * format the object to a graphql standard.
 * Objects that starts with a number, adds an _ in the beginning { '3abc': 1 } => { '_3abc': 1 }
 * This code remove this behavior
 * @param components
 * @returns
 */
const fixMeshComponentList = (components: ComponentsListInput): ComponentsListInput => {
  return Object.entries(components).reduce((acc, [, value]) => {
    return { ...acc, [value.id]: value };
  }, {});
};

const useWidgetDeploymentPreview = () => {
  const [loading, setLoading] = useState<string[]>([]);
  const { organization } = useActiveOrganization();
  const { displaySnackbar } = useSnackbar();
  const history = useHistory();
  const { fetchAsync } = useWidgetDeployments({ limit: 1 }, { enabled: false });

  const preview = async (widget: Pick<Widget, 'id' | 'name' | 'template_id'>): Promise<void> => {
    setLoading(previous => [...previous, widget.id]);
    try {
      const widgetDeployment = (await fetchAsync(widget.id)).data[0];

      const languages = widgetDeployment.config?.languages || { default: Language.EN, enabled: [Language.EN] };

      const payload = {
        apiKey: organization.public_api_key,
        app: { apikey: organization.public_api_key },
        components: { version: 2 },
        widgets: [
          {
            ...widgetDeployment.config,
            components: {
              ...widgetDeployment.config.components,
              list: fixMeshComponentList(widgetDeployment.config.components.list),
            },
            mode: WidgetPreviewMode.LookAndFeel,
            display: WIDGET_TEMPLATE_ID_TO_WIDGET_DISPLAY[widget.template_id],
            id: 'preview-container',
          },
        ],
      };
      sessionStorage.setItem(`${LocalStorage.KEYS.WIDGET_PREVIEW}_${widget.id}`, JSON.stringify({ payload, widget: { name: widget.name, languages } }));
      const previewPage = history.createHref({ pathname: `/widgets/${widget.id}/preview` });
      window.open(previewPage, '_blank');
    } catch (error) {
      displaySnackbar('Preview has not loaded', { title: 'Sorry, an error occurred:', icon: 'danger-light', variant: 'error' });
    } finally {
      setLoading(previous => previous.filter(item => item !== widget.id));
    }
  };

  return { preview, loading };
};

export { useWidgetDeploymentPreview };
