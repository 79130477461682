import React, { useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar, useGTM } from '@didomi/utility-react';
import { DidomiRightPanel } from '@didomi/ui-atoms-react';
import { RemovePreferenceModal, ConfirmBeforeEditPreferenceModal } from '@modals';
import { getTranslatedValue } from '@utils';
import { useEventListener } from '@didomi/helpers-react';
import { PreferencesList } from './PreferencesList';
import { PreferenceLibrarySearchInput } from './PreferenceLibrarySearchInput';
import { PreferenceLibraryLoadingState } from './PreferenceLibraryLoadingState';
import { PreferenceLibraryErrorState } from './PreferenceLibraryErrorState';
import { PreferenceLibraryEmptyState } from './PreferenceLibraryEmptyState';
import type { PreferencePreviewData } from '../../modals/PreviewPreferenceModal/PreviewPreferenceModal.modal';
import { usePreferences, useDeletePreference } from '@hooks';
import { SelectedPurpose } from '@interfaces';

const HEADING_SIZE = /* istanbul ignore next */ CONFIG?.standalone ? 220 : 299;
const PREFERENCE_LIBRARY_SPACE = 32;
const PREFERENCE_LIBRARY_MARGIN_TOP_SCROLLED = /* istanbul ignore next */ CONFIG?.standalone ? PREFERENCE_LIBRARY_SPACE * 2 : PREFERENCE_LIBRARY_SPACE * 4;
const PREFERENCE_LIBRARY_MARGIN_TOP = PREFERENCE_LIBRARY_SPACE * 2 - 30;

type PreferenceLibraryProps = {
  existingPurposes: SelectedPurpose[];
  canEditConfigurationTree?: boolean;
  onPreferencePreview?: (data: PreferencePreviewData) => void;
  isPreferenceInTree: (preferenceId: string) => boolean;
  disablePreferences?: boolean;
};

/**
 * PreferenceLibrary Component
 */
const PreferenceLibrary = ({ canEditConfigurationTree, onPreferencePreview, isPreferenceInTree, disablePreferences }: PreferenceLibraryProps): JSX.Element => {
  const { push } = useHistory();
  const { displaySnackbar } = useSnackbar();
  const { pushEventToDataLayer } = useGTM();
  const [offsetY, setOffsetY] = useState(HEADING_SIZE);
  const [deletingPreferenceId, setDeletingPreferenceId] = useState<string>();
  const [searchValue, setSearchValue] = useState('');
  const [preEditPreferenceModalId, setPreEditPreferenceModalId] = useState('');
  const stickyInputSearch = useRef<HTMLDidomiTextSearchInputElement>(null);

  const { data, isLoading, error: hasError, refetch: refetchPreferences } = usePreferences();

  const preferences = data?.data;
  const hasPreferences = preferences?.length > 0;

  const { mutate: deletePreferenceById } = useDeletePreference({
    onSuccess: async () => {
      displaySnackbar('Preference deleted with success', { icon: 'check' });
    },
    onError: () => {
      displaySnackbar('There was an error deleting the preference', { icon: 'danger-light', variant: 'error' });
    },
  });

  /**
   * Handles the scroll event of the main element on screen. We use this to update the height of the preference library
   * so that it grows shrinks with the screen size (as the screen size would change what's on top of it).
   * @param {React.UIEvent<HTMLElement>} event The scroll event (either on the main element or the window for standalone mode)
   */
  // istanbul ignore next - drag&drop code can't be unit tested
  const handleScroll: EventListener = (e: Event) => {
    const mainTarget = e?.currentTarget as HTMLElement;
    const scrollTop = mainTarget?.scrollTop || window.pageYOffset;
    setOffsetY(-scrollTop + HEADING_SIZE);
  };

  useEventListener('scroll', handleScroll, document.getElementsByTagName('main')[0] || window);

  /**
   * Navigates to the edit preference page
   */
  const editPreference = (preferenceId: string) => {
    push('/edit-preference/' + preferenceId);
  };

  /**
   * Confirms before taking users to edit preference page
   */
  const checkBeforeEditPreference = (preferenceId: string) => {
    if (isPreferenceInTree(preferenceId)) {
      setPreEditPreferenceModalId(preferenceId);
    } else {
      editPreference(preferenceId);
    }
  };

  /**
   * Starts deleting a preference, we validate if the preference is being used in the tree
   * in which case we display warning modal, otherwise we go ahead and delete it
   * @param {string} preferenceId The preference id to delete
   */
  const startDeletingPreference = (preferenceId: string): void => {
    const isInTree = isPreferenceInTree(preferenceId);
    if (isInTree) {
      setDeletingPreferenceId(preferenceId);
    } else {
      deletePreference(preferenceId);
    }
  };

  /**
   * Calls the server to delete a preference and cleans up the open warning modal
   * @param {string} preferenceId The preference id to delete
   */
  const deletePreference = (preferenceId: string) => {
    displaySnackbar('Deleting preference...');
    deletePreferenceById(preferenceId);
    setDeletingPreferenceId(undefined);
  };

  const onDuplicatePreference = preference => {
    push('/add-preference', {
      name: {
        en: `Copy ${getTranslatedValue(preference.name)}`,
      },
      description: preference.description,
      values: preference.values.map(value => ({
        name: value.name,
      })),
      type: preference.type,
    });
  };

  const handleAddPreference = () => {
    push('/add-preference');
    pushEventToDataLayer('pmp-start-create-preference', { product: 'pmp' });
  };

  return (
    <>
      <div className="hidden" id="preference-library-header-tooltip">
        <p className="text-body-small font-normal">
          The Library is the place <strong>where you can create and edit your preferences</strong>. Each preference contains a set of values: these are the checkboxes for your end
          users.
        </p>
      </div>
      <DidomiRightPanel
        data-testid="right-panel"
        className="!fixed"
        style={{
          bottom: `${PREFERENCE_LIBRARY_SPACE}px`,
          top: `${offsetY < PREFERENCE_LIBRARY_MARGIN_TOP ? PREFERENCE_LIBRARY_MARGIN_TOP_SCROLLED : offsetY + PREFERENCE_LIBRARY_MARGIN_TOP}px`,
        }}
        titleText={'Preferences Library'}
        icon={'helper-text'}
        iconTooltipContentElementId={'preference-library-header-tooltip'}
        description={'Drag preferences into the purposes'}
        createButtonTooltip={'Create preference'}
        showCreateButton={canEditConfigurationTree}
        onCreateClick={handleAddPreference}
      >
        <div slot="sticky-content">
          <PreferenceLibrarySearchInput className="pt-xxxs" value={searchValue} onSearch={setSearchValue} ref={stickyInputSearch} />
        </div>

        <div slot="content" className="h-full">
          <div className="h-full flex flex-col pt-1">
            {isLoading && <PreferenceLibraryLoadingState />}

            {hasError && <PreferenceLibraryErrorState refetch={refetchPreferences} />}

            {!hasError && !isLoading && (
              <>
                {!hasPreferences && <PreferenceLibraryEmptyState />}

                {hasPreferences && (
                  <PreferencesList
                    highlight={searchValue}
                    preferences={preferences}
                    onDelete={preferenceId => startDeletingPreference(preferenceId)}
                    onEdit={preferenceId => checkBeforeEditPreference(preferenceId)}
                    onCopy={onDuplicatePreference}
                    onPreview={onPreferencePreview}
                    canEditConfigurationTree={canEditConfigurationTree}
                    disablePreferences={disablePreferences}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </DidomiRightPanel>
      <RemovePreferenceModal isOpen={!!deletingPreferenceId} onCancel={() => setDeletingPreferenceId(undefined)} onProceed={() => deletePreference(deletingPreferenceId)} />
      <ConfirmBeforeEditPreferenceModal
        isOpen={!!preEditPreferenceModalId}
        onCancel={() => setPreEditPreferenceModalId('')}
        onProceed={() => editPreference(preEditPreferenceModalId)}
      />
    </>
  );
};

export { PreferenceLibrary };
