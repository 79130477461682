import { AxiosError, AxiosResponse } from 'axios';
import { UseMutationOptions, useQueryClient, useMutation } from '@tanstack/react-query';
import { useActiveOrganization } from '@didomi/utility-react';
import { apiRoutes } from '@constants';
import { useAxiosWithLogOut } from '../useAxiosWithInterceptors.hook';
import { Preference } from '@interfaces';
import { PreferenceType } from '@enums';
import { Translations } from '@didomi/pmp-generator';
import { ErrorResponse } from '../../../interfaces/error-response.interface';
import { updateQueryClientCache } from '@utils';

export type CreatePreferenceValuePayload = {
  metadata?: Record<string, string>;
  name: Translations;
};

export interface CreatePreferencePayload {
  description: Translations;
  metadata?: Record<string, string>;
  name: Translations;
  type: PreferenceType;
  values: CreatePreferenceValuePayload[];
}

export const useCreatePreference = (options?: UseMutationOptions<AxiosResponse<Preference>, AxiosError<ErrorResponse, any>, CreatePreferencePayload>) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();
  const queryClient = useQueryClient();

  const create = async (payload: CreatePreferencePayload) => {
    return await axiosWithInterceptors.post<Preference>(apiRoutes.preferences, payload, {
      params: { organization_id: organizationId },
    });
  };

  return useMutation<AxiosResponse<Preference>, AxiosError<ErrorResponse, any>, CreatePreferencePayload>(create, {
    ...options,
    onSuccess: (res: AxiosResponse<Preference>, variables: CreatePreferencePayload, context) => {
      // this will update all queries/caches that start with 'preferences'
      const dataKey = ['preferences'];

      updateQueryClientCache(queryClient, res, dataKey);

      if (options?.onSuccess) {
        options.onSuccess(res, variables, context);
      }
    },
  });
};
