import React from 'react';
import { DidomiIconButton } from '@didomi/ui-atoms-react';
import PurposeCardIllustation from '../../assets/svg/illustration_cards_purpose-card.svg';

import { generateUniqueId } from '@utils';

type LoadingPurposeCardProps = {
  id?: string;
  'data-testid'?: string;
};

const LoadingPurposeCard = ({ id = generateUniqueId('loading-purpose-card'), 'data-testid': dataTestid }: LoadingPurposeCardProps): JSX.Element => {
  return (
    <div className="p-1 bg-neutral-gray-1 rounded-lg w-full" id={id} data-testid={dataTestid}>
      <div className="p-6 bg-white rounded-lg border-neutral-gray-4 border-1 border-solid flex flex-col">
        <div className="flex justify-between items-center w-full">
          <div className="flex items-center w-full">
            <img src={PurposeCardIllustation} alt="" className="mr-4 opacity-40" />
            <div className="animate-pulse bg-neutral-gray-2 rounded-sm h-m w-full mr-xxl" />
          </div>
          <div className="flex">
            <DidomiIconButton disabled={true} title="Preference options" variant="rounded" icon="submenu" />
            <DidomiIconButton className="children:(border-neutral-gray-4!)" title="Collapse preference" variant="rounded-border" disabled={true} icon="down-on-sm" />
          </div>
        </div>
      </div>
    </div>
  );
};

export { LoadingPurposeCard };
