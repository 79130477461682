import { AxiosError } from 'axios';
import { PaginatedResponse } from '@types';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useActiveOrganization } from '@didomi/utility-react';
import { apiRoutes } from '@constants';
import { useAxiosWithLogOut } from '../useAxiosWithInterceptors.hook';
import { ArchivedWidget, ErrorResponse } from '@interfaces';
import { usePaginationQueryParams } from '../../usePaginationQueryParams.hook';
import { WidgetUsage } from '@enums';

export const useArchivedWidgets = (options: UseQueryOptions<PaginatedResponse<ArchivedWidget>> = {}) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();
  const { limit, page, skip, setLimit, setPage } = usePaginationQueryParams();

  const params = { organization_id: organizationId, $limit: limit, $skip: skip, usage: WidgetUsage.PMP };

  const fetch = async () => {
    const response = await axiosWithInterceptors.get<PaginatedResponse<ArchivedWidget>>(apiRoutes.archivedWidgets, {
      params,
    });
    return response.data;
  };

  return {
    ...useQuery<PaginatedResponse<ArchivedWidget>, AxiosError<ErrorResponse>>(['archived-widgets', params], fetch, {
      ...options,
    }),
    paginator: {
      limit,
      page,
      setPage,
      setLimit,
    },
  };
};
