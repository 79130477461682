import { ComponentType, DidomiPreferenceOptions, DidomiPreferenceValueOptions, DidomiSectionOptions, Language } from '@didomi/pmp-generator';
import dlv from 'dlv';
import {
  ComponentChangePreferenceValuePayload,
  EntityMapping,
  PreferenceFormMetadata,
  PreferenceValueMetadata,
  SectionFormMetadata,
  SelectedComponent,
  UpdatedLayoutEntityData,
  Translation,
  PreferenceEntityMapping,
  Breadcrumb,
  PreferenceAndValuesFormData,
  LayoutEntityData,
  ExtendedComponentsListInput,
} from '@types';
import { getTranslatedValue, getTranslatedValueStrict } from './translations';
import { PreferenceOutput, PurposeOutput, ValueOutput } from '@didomi/pmp-generator/dist/types/entities/models/entities.model';
import { ConfigTree, SelectedPreference, Purpose, LayoutComponent, LayoutEntity } from '@interfaces';

interface LayoutEntitiesMap {
  preferences: Record<string, string>;
  purposes: Record<string, string>;
  preferenceValues: Record<string, string>;
}

interface CreateSelectedComponentStateProps {
  currentComponents: ExtendedComponentsListInput;
  entityMapping: EntityMapping;
  componentType: ComponentType;
  entityId?: string;
  componentId?: string;
  components?: Array<LayoutComponent>;
  childrenEntities?: Array<ComponentChangePreferenceValuePayload>;
  layoutEntitiesMap?: LayoutEntitiesMap;
  layoutEntities?: LayoutEntity[];
  widgetPreferences?: UpdatedLayoutEntityData;
}

function iteratePreferences(selectedPreference: SelectedPreference, mapping: EntityMapping) {
  mapping.preferences[selectedPreference.id] = {
    parentId: selectedPreference.parent_id,
    parentPurposeId: selectedPreference.parent_selected_purpose_id,
    preference: selectedPreference.preference,
  };
  selectedPreference.preference.values.forEach(prefValue => {
    mapping.preferenceValues[prefValue.id] = prefValue;
    prefValue.selected_preferences?.forEach(selectedPreference => iteratePreferences(selectedPreference, mapping));
  });
}

export function createEntityMappingFromConfigTree(configurationTree: ConfigTree) {
  const mapping: EntityMapping = {
    preferences: {},
    purposes: {},
    preferenceValues: {},
  };
  if (!configurationTree) {
    return mapping;
  }

  configurationTree.selected_purposes.forEach(selectedPurpose => {
    mapping.purposes[selectedPurpose.id] = selectedPurpose.purpose;
    selectedPurpose.selected_preferences.forEach(selectedPreference => iteratePreferences(selectedPreference, mapping));
  });
  return mapping;
}

export function createEntityToComponentIdMapping(layoutEntities: LayoutEntity[]): LayoutEntitiesMap {
  const layoutEntitiesMap: LayoutEntitiesMap = {
    preferences: {},
    purposes: {},
    preferenceValues: {},
  };

  const layoutIdToSelectedPreferenceIdMap = layoutEntities
    .filter(layoutEntity => layoutEntity.selected_preference_id)
    .reduce((acc, layoutEntity) => {
      acc.set(layoutEntity.id, layoutEntity.selected_preference_id);
      return acc;
    }, new Map<string, string>());

  layoutEntities?.forEach((layoutEntity: LayoutEntity) => {
    if (layoutEntity.selected_preference_id) {
      layoutEntitiesMap.preferences[layoutEntity.selected_preference_id] = layoutEntity.layout_component_id;
    } else if (layoutEntity.selected_purpose_id) {
      layoutEntitiesMap.purposes[layoutEntity.selected_purpose_id] = layoutEntity.layout_component_id;
    } else if (layoutEntity.preference_value_id) {
      const parentSelectedPreference = layoutIdToSelectedPreferenceIdMap.get(layoutEntity.parent_id);
      layoutEntitiesMap.preferenceValues[`${parentSelectedPreference}-${layoutEntity.preference_value_id}`] = layoutEntity.layout_component_id;
    }
  });
  return layoutEntitiesMap;
}

export function getBreadcrumb({
  entityId,
  mapping,
  currentComponents,
  layoutEntitiesMap,
}: {
  entityId: string;
  mapping: EntityMapping;
  currentComponents: ExtendedComponentsListInput;
  layoutEntitiesMap?: LayoutEntitiesMap;
}): Breadcrumb[] {
  const breadcrumbs: Breadcrumb[] = [];

  let currentEntityId = entityId;
  let previousEntityId = null;
  while (currentEntityId) {
    const currentPreference = mapping.preferences[currentEntityId];
    if (!currentPreference) {
      break;
    }

    if (previousEntityId) {
      breadcrumbs.push(
        createBreadcrumbForPreferenceValue({
          mappedPreference: currentPreference,
          entityId: currentEntityId,
          previousEntityId,
          layoutEntitiesMap,
          currentComponents,
        }),
      );
    }
    // ignore the current preference being added in breadcrumb as it is displayed separately.
    if (currentEntityId !== entityId) {
      const componentId = layoutEntitiesMap.preferences[currentEntityId];
      breadcrumbs.push({
        content: getComponentTranslatedValue(componentId, 'title.content', currentPreference.preference.name, currentComponents),
        id: currentEntityId,
      });
    }

    if (!currentPreference.parentId && currentPreference.parentPurposeId) {
      const purpose = mapping.purposes[currentPreference.parentPurposeId];
      const componentId = layoutEntitiesMap.purposes[currentPreference.parentPurposeId];
      breadcrumbs.push({
        content: getComponentTranslatedValue(componentId, 'purposeOptions.title.content', purpose.description, currentComponents),
        id: currentPreference.parentPurposeId,
      });
    }
    previousEntityId = currentEntityId;
    currentEntityId = currentPreference.parentId;
  }

  return breadcrumbs.reverse();
}

function createBreadcrumbForPreferenceValue({
  mappedPreference,
  entityId,
  previousEntityId,
  layoutEntitiesMap,
  currentComponents,
}: {
  mappedPreference: PreferenceEntityMapping;
  entityId: string;
  previousEntityId: string;
  layoutEntitiesMap?: LayoutEntitiesMap;
  currentComponents: ExtendedComponentsListInput;
}): Breadcrumb {
  const preferenceValues = mappedPreference.preference.values;
  const previousPreferenceParentValue = preferenceValues.find(preferenceValue =>
    preferenceValue.selected_preferences.some(selectedPreference => selectedPreference.id === previousEntityId),
  );
  const componentId = layoutEntitiesMap.preferenceValues[`${entityId}-${previousPreferenceParentValue.id}`];
  return {
    id: previousPreferenceParentValue.id,
    content: getComponentTranslatedValue(componentId, 'label.content', previousPreferenceParentValue.name, currentComponents),
  };
}

function createPurposeMetadata(entityId: string, purpose: Purpose, updatedData: DidomiSectionOptions, enabled?: boolean): SectionFormMetadata {
  return {
    enabled,
    purpose,
    entityId,
    purposeOptions: {
      title: {
        content: {
          en: getTranslatedValue(updatedData.purposeOptions?.title?.content, '') || getTranslatedValue(purpose.description, ''),
        },
      },
      description: {
        content: {
          en: getTranslatedValue(updatedData.purposeOptions?.description?.content, '') || getTranslatedValue(purpose.details, ''),
        },
      },
    },
  };
}

function createPreferenceMetadata({
  componentId,
  entityId,
  preferenceValues,
  entityMapping,
  currentComponents,
  layoutEntitiesMap,
}: {
  componentId: string;
  entityId: string;
  preferenceValues: CreateSelectedComponentStateProps['childrenEntities'];
  entityMapping: EntityMapping;
  currentComponents: ExtendedComponentsListInput;
  layoutEntitiesMap?: LayoutEntitiesMap;
}): PreferenceFormMetadata {
  const preference = entityMapping.preferences[entityId];
  const componentOptions: DidomiPreferenceOptions = (currentComponents[componentId]?.options ?? {}) as DidomiPreferenceOptions;
  const metadata: PreferenceFormMetadata = {
    componentId,
    preferenceValues: preferenceValues || [],
    entityId,
    breadcrumbs: getBreadcrumb({ entityId, mapping: entityMapping, currentComponents, layoutEntitiesMap }),
    preferenceData: {
      [componentId]: {
        preference: preference.preference,
        title: {
          content: {
            en: getTranslatedValue(componentOptions.title?.content, '') || getTranslatedValue(preference.preference.name, ''),
          },
        },
        description: {
          content: {
            en: getTranslatedValue(componentOptions.description?.content, '') || getTranslatedValue(preference.preference.description, ''),
          },
        },
      },
    },
  };
  preferenceValues.forEach(preferenceValue => {
    const data: DidomiPreferenceValueOptions = (currentComponents[preferenceValue.componentId]?.options ?? {}) as DidomiPreferenceValueOptions;
    const preferenceValueData = entityMapping.preferenceValues[preferenceValue.entityId];
    metadata.preferenceData[preferenceValue.componentId] = {
      ...data,
      label: {
        content: {
          en: (getTranslatedValue(data.label?.content, '') as string) || (getTranslatedValue(preferenceValueData.name, '') as string),
        },
      },
      preferenceValue: preferenceValueData,
    } as PreferenceValueMetadata;
  });
  return metadata;
}

export function createSelectedComponentState({
  componentType,
  currentComponents,
  entityMapping,
  componentId: initialComponentId,
  entityId,
  childrenEntities,
  layoutEntitiesMap,
  layoutEntities,
  widgetPreferences,
}: CreateSelectedComponentStateProps): SelectedComponent {
  /**
   * Selecting a preference component in SPW.
   * In that case, the section component doesn't have the entity id, and we need to select the first child
   * that contains the preference
   */
  const hasComponentPopulated = initialComponentId && initialComponentId !== 'null';
  if (!hasComponentPopulated && childrenEntities.length) {
    const firstChildElement = childrenEntities[0];
    return createSelectedComponentState({
      componentType: firstChildElement.componentType,
      currentComponents,
      entityMapping,
      componentId: firstChildElement.componentId,
      entityId: firstChildElement.entityId,
      childrenEntities: firstChildElement.children,
      layoutEntitiesMap,
      layoutEntities,
      widgetPreferences,
    });
  }

  const componentId = initialComponentId ? initialComponentId : Object.values(currentComponents).find(a => a.type === componentType)?.id;
  let metadata: SelectedComponent['metadata'];
  let newData: DidomiSectionOptions | PreferenceAndValuesFormData = (currentComponents[componentId]?.options as DidomiSectionOptions | PreferenceAndValuesFormData) ?? {};
  if (componentType === ComponentType.SECTION && entityId) {
    const updatedLayoutEntities = getUpdatedLayoutEntities(layoutEntities || [], widgetPreferences);
    const entityData = updatedLayoutEntities.find(entity => entity.layout_component_id === componentId);
    const purpose = entityMapping.purposes[entityId];
    metadata = createPurposeMetadata(entityId, purpose, newData, entityData?.enabled);
  } else if (componentType === ComponentType.PREFERENCE) {
    metadata = createPreferenceMetadata({ componentId, entityId, entityMapping, currentComponents, preferenceValues: childrenEntities, layoutEntitiesMap });
    newData = {
      [componentId]: currentComponents[componentId]?.options ?? {},
    };
    childrenEntities.forEach(preferenceItem => {
      const data: DidomiPreferenceValueOptions = (currentComponents[preferenceItem.componentId]?.options ?? {}) as DidomiPreferenceValueOptions;
      newData[preferenceItem.componentId] = data;
    });
  }

  return {
    id: componentId,
    type: componentType,
    data: newData,
    metadata,
  };
}

export function getLayoutEntityToEnabledMapping(layoutEntities: LayoutEntity[]): Record<string, LayoutEntityData> {
  return layoutEntities.reduce<Record<string, LayoutEntityData>>((acc, entity) => {
    acc[entity.id] = { enabled: entity.enabled, order: entity.order, parentId: entity.parent_id, componentId: entity.layout_component_id };
    return acc;
  }, {});
}

export function getUpdatedLayoutEntities(layoutEntities: LayoutEntity[], widgetPreferences?: UpdatedLayoutEntityData) {
  const updatedLayoutEntities =
    !widgetPreferences || Object.keys(widgetPreferences).length === 0
      ? layoutEntities
      : layoutEntities.map(layoutEntity => {
          const updatedPreference = widgetPreferences[layoutEntity.id];
          if (updatedPreference) {
            return {
              ...layoutEntity,
              ...updatedPreference,
            };
          }
          return layoutEntity;
        });
  return updatedLayoutEntities;
}

export function getComponentTranslatedValue(
  componentId: string,
  optionPath: string,
  defaultValue: Translation,
  components: ExtendedComponentsListInput,
  defaultLanguage?: Language,
): string {
  const translatedDefaultValue = getTranslatedValue(defaultValue, '-', defaultLanguage);
  const component = components?.[componentId];
  if (component) {
    return getTranslatedValueStrict(dlv(component.options, optionPath), translatedDefaultValue, defaultLanguage);
  }
  return translatedDefaultValue;
}

type EntityOptions = PurposeOutput | PreferenceOutput | ValueOutput;

/**
 * Map the PurposeOutput in a map that the key is layoutEntityId and the values are their children layoutEntityIds in the correct order.
 */
export const mapLayoutEntityToChildrenOrder = (entities: PurposeOutput[]) => {
  const entityIdToChildrenOrder = new Map<string | null, string[]>();
  // Mapping the purpose outputs
  entityIdToChildrenOrder.set(
    null,
    entities.map(purpose => purpose.layoutEntityId),
  );

  // Mapping the preferences and values
  const entitiesToMap: EntityOptions[] = [...entities];
  while (entitiesToMap.length) {
    const entityToMap = entitiesToMap.pop();
    if ('preferences' in entityToMap) {
      entityIdToChildrenOrder.set(
        entityToMap.layoutEntityId,
        entityToMap.preferences.map(preference => preference.layoutEntityId),
      );
      entitiesToMap.push(...entityToMap.preferences);
    } else if ('values' in entityToMap) {
      entityIdToChildrenOrder.set(
        entityToMap.layoutEntityId,
        entityToMap.values.map(value => value.layoutEntityId),
      );
      entitiesToMap.push(...entityToMap.values);
    }
  }
  return entityIdToChildrenOrder;
};
