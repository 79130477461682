import { devOrganizationId } from '@constants';

export const createDefaultLayoutEntities = (server, defaultOrganization = devOrganizationId, widgetId = 'widget-1') => {
  server.create('layoutEntity', {
    id: `${widgetId}_layout_entity_1`,
    enabled: true,
    selected_purpose_id: 'selected-purpose-evca43as1',
    layout_component_id: `${widgetId}_section_1`,
    parent_id: null,
    created_at: '2021-12-17T11:29:11.399Z',
    updated_at: '2021-12-17T11:29:11.399Z',
    organization_id: defaultOrganization,
    widget_id: widgetId,
    order: 0,
  });

  server.create('layoutEntity', {
    id: `${widgetId}_layout_entity_2`,
    enabled: true,
    selected_purpose_id: null,
    layout_component_id: `${widgetId}_preference_1`,
    selectedPreferenceId: 'selected-preference-cf34osd11',
    parent_id: `${widgetId}_layout_entity_1`,
    created_at: '2021-12-17T11:29:11.399Z',
    updated_at: '2021-12-17T11:29:11.399Z',
    organization_id: defaultOrganization,
    widget_id: widgetId,
    order: 1,
  });

  server.create('layoutEntity', {
    id: `${widgetId}_layout_entity_value_1`,
    enabled: true,
    selected_purpose_id: null,
    layout_component_id: `${widgetId}_preference_value_1`,
    preferenceValueId: 'preference-value-rtca7haj1',
    parent_id: `${widgetId}_layout_entity_2`,
    created_at: '2021-12-17T11:29:11.399Z',
    updated_at: '2021-12-17T11:29:11.399Z',
    organization_id: defaultOrganization,
    widget_id: widgetId,
    order: 0,
  });

  server.create('layoutEntity', {
    id: `${widgetId}_layout_entity_value_2`,
    enabled: true,
    selected_purpose_id: null,
    layout_component_id: `${widgetId}_preference_value_2`,
    preferenceValueId: 'preference-value-rtca7haj2',
    parent_id: `${widgetId}_layout_entity_2`,
    created_at: '2021-12-17T11:29:11.399Z',
    updated_at: '2021-12-17T11:29:11.399Z',
    organization_id: defaultOrganization,
    widget_id: widgetId,
    order: 1,
  });

  server.create('layoutEntity', {
    enabled: true,
    selectedPreferenceId: 'selected-preference-asd4oh2cw',
    layout_component_id: `${widgetId}_preference_2`,
    created_at: '2021-12-17T11:29:11.399Z',
    updated_at: '2021-12-17T11:29:11.399Z',
    organization_id: defaultOrganization,
    widget_id: widgetId,
    order: 3,
  });
};
