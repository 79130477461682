import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSnackbar } from '@didomi/utility-react';
import { DidomiModal, DidomiModalContent, DidomiLoader, DidomiErrorState } from '@didomi/ui-atoms-react';
import { PreferenceForm } from '@components';
import { isPreferenceInTree, getTranslatedValue, createSanitizedTranslationFromValue } from '@utils';
import { ConfirmAfterEditPreferenceModal } from '@modals';
import { UpdatePreferencePayload, useConfigTrees, useUpdatePreference, usePreference } from '@hooks';
import { Preference } from '@interfaces';

/**
 * Edit Preference Page
 */
const EditPreference = (): JSX.Element => {
  const { push } = useHistory();
  const { id: preferenceId } = useParams<{ id: string }>();
  const { displaySnackbar } = useSnackbar();
  const [updatingPreference, setUpdatingPreference] = useState<UpdatePreferencePayload>();

  const { isLoading: loadingConfigurationTree, error: errorLoadingConfigurationTree, data: configTrees, refetch: refetchConfigurationTree } = useConfigTrees();

  const { data: preference, error: errorLoadingPreference, isLoading: loadingPreference, refetch: refetchPreference } = usePreference(preferenceId);

  // Transform the preference before saving it
  const transformedPreference = updatedPreference => ({
    ...updatedPreference,
    name: createSanitizedTranslationFromValue(updatedPreference.name, preference.name), // Change for not overriding the values provided by the server.
    description: createSanitizedTranslationFromValue(updatedPreference.description, preference.description), // It will be changed when we implement the translation manager on preference.mn
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    values: updatedPreference.values.map(({ preference_id, order, created_at, updated_at, ...value }) => {
      const savedPreferenceValue = preference.values.find(({ id }) => id === value.id) || { name: {} };
      return { ...value, name: createSanitizedTranslationFromValue(value.name, savedPreferenceValue.name) };
    }),
  });

  const { mutate: editPreference, isLoading: editingPreference } = useUpdatePreference({
    onSuccess: res => {
      displaySnackbar(`${getTranslatedValue(res.data.name)} updated successfully`, { icon: 'check' });
      push('/configuration');
    },
    onError: () => {
      displaySnackbar('There was an error saving the preference', { icon: 'danger-light' });
    },
  });

  const configurationTree = configTrees?.data[0];
  const existingPurposes = configurationTree?.selected_purposes;
  const isFormLoading = loadingPreference || loadingConfigurationTree;
  const hasError = errorLoadingPreference || errorLoadingConfigurationTree || !preference;

  /**
   * Starts editing a preference, we validate if the preference is being used in the tree
   * in which case we display warning modal, otherwise we go ahead and update it
   * @param {string} preferenceId The preference id to delete
   */
  const startUpdatingPreference = (newPreference: any): void => {
    const isInTree = isPreferenceInTree(preferenceId, existingPurposes);
    if (isInTree) {
      const hasModifiedPreference = hasChangedPreference(preference as any, newPreference);
      if (hasModifiedPreference) {
        setUpdatingPreference(newPreference);
        return;
      }
    }
    editPreference({
      id: preferenceId,
      ...transformedPreference(newPreference),
    });
  };

  /**
   * Validates if the name, description, type or values have changed in any way from
   * the original preference.
   * @param {Preference} preference The original preference
   * @param {Preference} newPreference The modified preference
   */
  const hasChangedPreference = (preference: Preference, newPreference: Preference): boolean => {
    if (getTranslatedValue(preference.name) !== getTranslatedValue(newPreference.name)) {
      return true;
    }

    if (getTranslatedValue(preference.description) !== getTranslatedValue(newPreference.description)) {
      return true;
    }

    if (preference.type !== newPreference.type) {
      return true;
    }

    const valuesIds = preference.values.map(v => v.id);
    const newValuesIds = newPreference.values.map(v => v.id);
    if (valuesIds.length !== newValuesIds.length || !valuesIds.every((v, i) => v === newValuesIds[i])) {
      return true;
    }

    return false;
  };

  /**
   * Recall fetch for preference or configuration tree based on the error.
   */
  const reload = (): void => {
    if (errorLoadingPreference || !preference) {
      refetchPreference();
    }
    if (errorLoadingConfigurationTree) {
      refetchConfigurationTree();
    }
  };

  if (!isFormLoading && hasError) {
    return (
      <DidomiErrorState className="h-full" illustrationName="content-loading-error" actionName="Reload" onActionClick={reload} actionIcon="reset">
        <div slot="title">It seems to be an error</div>
        <div slot="description">The preference cannot be loaded</div>
      </DidomiErrorState>
    );
  }

  return (
    <div className="mt-xs flex flex-col">
      <h2 className="h2 text-primary-blue-6">Preference content</h2>
      <PreferenceForm
        initialValues={{
          name: getTranslatedValue(preference?.name),
          description: getTranslatedValue(preference?.description),
          type: preference?.type,
          values: preference?.values?.map(value => ({ ...value, name: getTranslatedValue(value.name) })),
        }}
        isLoading={isFormLoading}
        onCancel={() => push('/configuration')}
        onSubmit={newPreference => startUpdatingPreference(newPreference)}
      />
      <DidomiModal isOpen={editingPreference} permanent="true">
        <DidomiModalContent className="flex flex-col items-center">
          <DidomiLoader />
          <div className="text-body-big font-semibold font-sans text-primary-blue-4 mt-m mb-xxs">Please wait,</div>
          <div className="text-body-big font-sans text-primary-blue-6">We are saving your preference...</div>
        </DidomiModalContent>
      </DidomiModal>
      <ConfirmAfterEditPreferenceModal
        isOpen={!!updatingPreference}
        onCancel={() => setUpdatingPreference(undefined)}
        onProceed={() => editPreference({ id: preferenceId, ...transformedPreference(updatingPreference) })}
      />
    </div>
  );
};

export { EditPreference };
