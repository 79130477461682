import React from 'react';
import { getTranslatedValue } from '@utils';
import { GenericEmptyState, PurposeCard } from '@components';
import { SelectedPurpose } from '@interfaces';

type PurposeCardProps = React.ComponentProps<typeof PurposeCard>;
interface PurposesListProps {
  purposes: SelectedPurpose[];
  entityIDsToBeDeleted: string[];
  selectedPurposeIDsToBeReset: string[];
  onRemovePurpose: (purposeId: string) => void;
  onRemovePreference: (preferenceId: string) => void;
  onDisplayPreview: (options: { name: string; description: string }) => void;
  onDisplayPreferencePreview: PurposeCardProps['onPreferencePreview'];
  showAllIds?: boolean;
  canEditConfigurationTree?: boolean;
  search: string;
  onReset: PurposeCardProps['onReset'];
  searchFilter: (purposes: SelectedPurpose[], search: string) => SelectedPurpose[];
}

// Purposes list used in Configuration page
export const PurposesList = ({
  purposes,
  entityIDsToBeDeleted,
  selectedPurposeIDsToBeReset,
  onRemovePurpose,
  showAllIds = false,
  canEditConfigurationTree,
  onRemovePreference,
  onDisplayPreview,
  onDisplayPreferencePreview,
  search,
  onReset,
  searchFilter,
}: PurposesListProps) => {
  const filteredPurposes = searchFilter(purposes, search);
  if (!filteredPurposes.length && purposes.length) {
    return <GenericEmptyState title="Sorry, no matches were found" illustration="no-matches-found" />;
  }

  return (
    <>
      {filteredPurposes.map((purpose, i) => (
        <div key={'purpose-' + i} data-cy={'purpose-' + i} className="mb-xxxs">
          <PurposeCard
            id={'purpose-' + i}
            highlight={search}
            onRemove={() => onRemovePurpose(purpose.id)}
            onPreview={() =>
              onDisplayPreview({
                name: getTranslatedValue(purpose?.purpose?.description),
                description: getTranslatedValue(purpose?.purpose?.details),
              })
            }
            onPreferencePreview={onDisplayPreferencePreview}
            onReset={onReset}
            data-testid={'purpose-' + i}
            showAllIds={showAllIds}
            canEditConfigurationTree={canEditConfigurationTree}
            selectedPurposeId={purpose.id}
            selectedPurposeIDsToBeReset={selectedPurposeIDsToBeReset}
            entityIDsToBeDeleted={entityIDsToBeDeleted}
            name={getTranslatedValue(purpose?.purpose?.description || '')}
            selectedPreferences={purpose.selected_preferences}
            onRemovePreference={prefId => onRemovePreference(prefId)}
          />
        </div>
      ))}
    </>
  );
};
