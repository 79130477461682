import { formatPaginationResponse } from '../utils';
import { generateUniqueId } from '@utils';

export const preferenceRoutes = {
  '/preferences': {
    get: (schema: any, args) => {
      const preferences = schema.preferences
        .where({
          organization_id: args.queryParams.organization_id,
        })
        .models.map(preference => ({
          ...preference.attrs,
          values: schema.preferenceValues.find(preference.attrs.valueIds).models?.map(v => v.attrs),
        }));

      return formatPaginationResponse(preferences, args);
    },
    post: (schema: any, request) => {
      const payload = JSON.parse(request.requestBody);

      const entity = schema.preferenceValues.create(payload);

      return entity.attrs;
    },
  },
  '/preferences/:id': {
    get: (schema: any, request) => {
      const preferenceId = request.params?.id;
      const preference = schema.preferences.findBy({
        id: preferenceId,
      });
      const values = schema.preferenceValues.find(preference?.attrs?.valueIds).models?.map(v => v?.attrs);

      return {
        ...preference?.attrs,
        values,
      };
    },
    patch: (schema: any, request) => {
      const parsedBody = JSON.parse(request.requestBody);
      const preferenceId = request.params?.id;
      const preferenceBody = parsedBody;
      const preferenceValues = parsedBody.values;
      const preferenceValueIds = preferenceValues.map(v => v.id);
      const updatedPreferenceValueIds = preferenceValueIds.filter(v => !!v);

      const preference = schema.preferences.findBy({
        id: preferenceId,
      });
      const currentValues = schema.preferenceValues.find(preference.attrs.valueIds).models;

      // Remove preference value that no longer exist
      for (const currentValue of currentValues) {
        if (!preferenceValueIds.includes(currentValue.id)) {
          currentValue.destroy();
        }
      }

      // Create new preference values
      for (const value of preferenceValues) {
        if (!value.id) {
          const newValue = schema.preferenceValues.create({
            id: generateUniqueId('preference-value'),
            // organizationId: preference.attrs.organizationId,
            name: value.name,
          });
          updatedPreferenceValueIds.push(newValue.id);
          newValue.save();
        }
      }

      // Update preference details
      preference.update('name', preferenceBody.name);
      preference.update('description', preferenceBody.description);
      preference.update('type', preferenceBody.type);
      preference.update('valueIds', updatedPreferenceValueIds);
      preference.save();

      return {
        ...preference.attrs,
        id: preferenceId,
        values: schema.preferenceValues.find(updatedPreferenceValueIds).models?.map(v => v.attrs),
      };
    },

    delete: (schema: any, request) => {
      const entity = schema.preferences.find(request.params.id);
      entity.destroy();

      return entity.attrs;
    },
  },
};
