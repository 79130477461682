import React from 'react';
import { DidomiButton, DidomiModal, DidomiModalHeader, DidomiModalActions } from '@didomi/ui-atoms-react';

type PreviewPurposeModalProps = {
  purposeName: string;
  purposeDescription: string;
  isOpen?: boolean;
  onCancel?: () => void;
  onProceed?: () => void;
  canEdit?: boolean;
};

/**
 * Preview purpose
 */
const PreviewPurposeModal = ({ purposeName, purposeDescription, isOpen, onCancel, onProceed, canEdit }: PreviewPurposeModalProps): JSX.Element => {
  return (
    <DidomiModal isOpen={isOpen} permanent="true">
      <DidomiModalHeader
        modalTitle={purposeName}
        modalDescription={purposeDescription}
        modalDescriptionSpace="none"
        illustrationName="purpose"
        illustrationSize="small"
        datatest-id="preview-purpose-modal"
      ></DidomiModalHeader>
      <DidomiModalActions>
        <DidomiButton variant="secondary" onClick={onCancel}>
          Close
        </DidomiButton>
        {canEdit && (
          <DidomiButton data-tracking="pmp-start-edit-purpose-from-preview-in-tree" onClick={onProceed}>
            Edit in data management
          </DidomiButton>
        )}
      </DidomiModalActions>
    </DidomiModal>
  );
};

export { PreviewPurposeModal };
