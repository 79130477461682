import { ErrorBoundary } from '@didomi/helpers-react';
import React from 'react';

export const SentryBoundary = ({ children }) => {
  return (
    <ErrorBoundary
      sentryConfig={{
        sentryDsn: CONFIG.sentryDsn,
        environment: CONFIG.environment,
        release: CONFIG.commitSha,
      }}
    >
      {children}
    </ErrorBoundary>
  );
};
