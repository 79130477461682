import { useCallback, useMemo, useState, useRef } from 'react';
import { useSnackbar } from '@didomi/utility-react';
import { Entities } from '@didomi/pmp-generator';
import { Direction } from '@enums';
import { UpdatedLayoutEntityData, LayoutEntityData } from '@types';
import { getLayoutEntityToEnabledMapping, getUpdatedLayoutEntities, mapLayoutEntityToChildrenOrder, snakeToCamelCase } from '@utils';
import { createSlideToDownAnimation, createSlideToUpAnimation } from '@constants';
import { ConfigTree, LayoutEntity } from '@interfaces';
import { tw } from '@twind/core';
import { usePatchLayoutEntities, PatchLayoutEntitiesPayload } from '@hooks';

const ENTITY_ANIMATION = {
  [Direction.DOWN]: createSlideToDownAnimation,
  [Direction.UP]: createSlideToUpAnimation,
};

interface UseEditWidgetPreferencesOptions {
  layoutEntities: LayoutEntity[];
  configurationTree: ConfigTree;
}

/**
 * Updates the widget preference value checking if the enabled and order value changed compared to the original value.
 */
const updateWidgetPreferences = (
  originalValues: Record<string, LayoutEntityData>,
  currentValues: UpdatedLayoutEntityData,
  layoutEntityId: string,
  newValue: Pick<LayoutEntityData, 'order' | 'enabled'>,
) => {
  const originalValue = originalValues[layoutEntityId];
  const currentValue = currentValues[layoutEntityId] || {};

  const newValues = {
    ...currentValues,
  };

  const compareNewValue = {
    ...originalValue,
    ...currentValue,
    ...newValue,
  };

  if (originalValue.enabled === compareNewValue.enabled && originalValue.order === compareNewValue.order) {
    delete newValues[layoutEntityId];
  } else {
    newValues[layoutEntityId] = {
      ...currentValue,
      ...newValue,
    };
  }

  return newValues;
};

export function useEditWidgetPreferences({ layoutEntities, configurationTree }: UseEditWidgetPreferencesOptions) {
  const [widgetPreferences, setWidgetPreferences] = useState<UpdatedLayoutEntityData>({});
  const { displaySnackbar } = useSnackbar();
  const layoutComponentToEntityMap = useMemo(() => getLayoutEntityToEnabledMapping(layoutEntities), [layoutEntities]);
  const entityIdToChildrenOrder = useRef<Map<string, string[]>>(new Map());
  // Store the last two ordered entities thats need to be animated on the next renderization.
  const entitiesToAnimate = useRef<Record<string, Direction>>({});
  // Store the entity id that needs to be scrolled after the reordering.
  const entityToScrollAfterReorder = useRef<string>('');
  const [scrollToComponent, setScrollToComponent] = useState<{ id: string }>(null);

  const { mutateAsync: updateLayoutEntity } = usePatchLayoutEntities({
    onError: () => {
      displaySnackbar('Widget can not be saved', { title: 'Sorry, an error occurred:', icon: 'danger-light', variant: 'error' });
    },
  });

  /**
   * Set the enabled option for a give layout entity id.
   * If allDescendants argument is true, it propagates this option to all descendants.
   */
  const setWidgetLayoutEntityEnabled = useCallback(
    (layoutEntityId: string, enabled: boolean, allDescendants: boolean = false) => {
      setWidgetPreferences(currentValues => {
        let updatedValues = { ...currentValues };
        const entitiesToUpdateEnabled = [layoutEntityId];
        while (entitiesToUpdateEnabled.length) {
          const entityToUpdateEnabled = entitiesToUpdateEnabled.pop();

          updatedValues = updateWidgetPreferences(layoutComponentToEntityMap, updatedValues, entityToUpdateEnabled, { enabled });

          if (allDescendants) {
            const children = entityIdToChildrenOrder.current.get(entityToUpdateEnabled) || [];
            entitiesToUpdateEnabled.push(...children);
          }
        }

        return updatedValues;
      });
    },
    [layoutComponentToEntityMap],
  );

  /**
   * Check if a given layout entity or its descendants has an enabled option as true
   */
  const hasEnabledLayoutEntity = useCallback(
    (layoutEntityId: string) => {
      const layoutEntityIdsToCheck = [layoutEntityId];

      while (layoutEntityIdsToCheck.length) {
        const layoutEntityIdToCheck = layoutEntityIdsToCheck.pop();

        if (layoutEntityIdToCheck in widgetPreferences) {
          if (widgetPreferences[layoutEntityIdToCheck]?.enabled) {
            return true;
          }
        } else if (layoutComponentToEntityMap[layoutEntityIdToCheck]?.enabled) {
          return true;
        }

        layoutEntityIdsToCheck.push(...entityIdToChildrenOrder.current.get(layoutEntityIdToCheck));
      }

      return false;
    },
    [layoutComponentToEntityMap, widgetPreferences],
  );

  const saveUpdatedLayoutEntities = useCallback(async () => {
    // Cleaning the animation for not executing after the save.
    entitiesToAnimate.current = {};
    const patchLayoutEntitiesDtoInput: PatchLayoutEntitiesPayload[] = [];
    for (const layoutEntityId in widgetPreferences) {
      patchLayoutEntitiesDtoInput.push({
        id: layoutEntityId,
        ...widgetPreferences[layoutEntityId],
      });
    }
    await updateLayoutEntity(patchLayoutEntitiesDtoInput);
    setWidgetPreferences({});
  }, [updateLayoutEntity, widgetPreferences]);

  const entities = useMemo(() => {
    if (!configurationTree || !layoutEntities?.length) {
      return undefined;
    }
    const updatedLayoutEntities = getUpdatedLayoutEntities(layoutEntities, widgetPreferences);
    const generatedEntities = Entities.generateEntities({
      configTree: snakeToCamelCase(configurationTree),
      layoutEntities: snakeToCamelCase(updatedLayoutEntities),
      shouldOmitEntities: false,
    });
    entityIdToChildrenOrder.current = mapLayoutEntityToChildrenOrder(generatedEntities.purposes);
    return generatedEntities;
  }, [configurationTree, layoutEntities, widgetPreferences]);

  const enabledEntities = useMemo(() => {
    if (!configurationTree || !layoutEntities?.length || !widgetPreferences) {
      return undefined;
    }

    const updatedLayoutEntities = getUpdatedLayoutEntities(layoutEntities, widgetPreferences);
    return Entities.generateEntities({
      configTree: snakeToCamelCase(configurationTree),
      layoutEntities: snakeToCamelCase(updatedLayoutEntities),
    });
  }, [layoutEntities, widgetPreferences, configurationTree]);

  /**
   * Return for a given entity id, if the up/down option should be disabled.
   */
  const getDisabledActionControls = useCallback(
    (entityId: string) => {
      const parentId = layoutComponentToEntityMap[entityId]?.parentId || null;
      const childrenOrder = entityIdToChildrenOrder.current.get(parentId);
      const childrenIndex = childrenOrder?.indexOf(entityId);

      return {
        // Up option is disabled if the entity id is the first item in the array.
        upOptionDisabled: !childrenOrder || childrenIndex === 0,
        // Down option is disabled if the entity id is the last item in the array.
        downOptionDisabled: !childrenOrder || childrenIndex === childrenOrder?.length - 1,
      };
    },
    [layoutComponentToEntityMap],
  );

  /**
   * Moves an entity up or down in the list.
   */
  const move = useCallback(
    (entityId: string, direction: Direction) => {
      const parentId = layoutComponentToEntityMap[entityId].parentId || null;
      const childrenOrder = entityIdToChildrenOrder.current.get(parentId);
      const childrenIndex = childrenOrder.indexOf(entityId);

      const indexToMove = direction === Direction.UP ? childrenIndex - 1 : childrenIndex + 1;

      // Do not move if the the index is outside the array boundaries.
      if (indexToMove < 0 || indexToMove >= childrenOrder.length) {
        return;
      }

      setScrollToComponent({ id: layoutComponentToEntityMap[entityId].componentId });

      const destinationEntityId = childrenOrder[indexToMove];

      entitiesToAnimate.current = {
        [entityId]: direction,
        [destinationEntityId]: direction === Direction.UP ? Direction.DOWN : Direction.UP,
      };
      entityToScrollAfterReorder.current = entityId;

      setWidgetPreferences(currentValues => {
        // Move the ids in the array
        const newOrder = [...childrenOrder];
        const temp = newOrder[childrenIndex];
        newOrder[childrenIndex] = newOrder[indexToMove];
        newOrder[indexToMove] = temp;

        // Updates all siblings using the array index. This fixes the issue when the entity does not have the proper order
        let newValues = { ...currentValues };
        newOrder.forEach((element, index) => {
          newValues = updateWidgetPreferences(layoutComponentToEntityMap, newValues, element, {
            order: index,
          });
        });

        return newValues;
      });
    },
    [layoutComponentToEntityMap],
  );

  /**
   * Return the class string animation that should be applied tho the wrapper element that is animated when the movement happens.
   */
  const getAnimation = useCallback((entityId: string) => {
    const direction = entitiesToAnimate.current[entityId];
    if (ENTITY_ANIMATION[direction]) {
      return tw(ENTITY_ANIMATION[direction]().toString());
    }

    return '';
  }, []);

  /**
   * Returns the listener over the element that should be scrolled when the movement happens.
   */
  const getScrollHandler = useCallback(
    (entityId: string) => (event: any) => {
      if (entityId === entityToScrollAfterReorder.current) {
        event.target.scrollIntoView({
          block: 'nearest',
          behavior: 'smooth',
        });
        entityToScrollAfterReorder.current = '';
      }
    },
    [],
  );

  /**
   * Returns a grouped property for the reordering feature to be easy to prop down in the elements.
   */
  const preferenceOrderingControls = useMemo(
    () => ({
      getDisabledActionControls,
      move,
      getAnimation,
      getScrollHandler,
    }),
    [getDisabledActionControls, move, getAnimation, getScrollHandler],
  );

  return {
    scrollToComponent,
    widgetPreferences,
    saveUpdatedLayoutEntities,
    setWidgetLayoutEntityEnabled,
    entities,
    enabledEntities,
    hasEnabledLayoutEntity,
    preferenceOrderingControls,
  };
}
