import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { useAxiosWithLogOut } from '@hooks';
import { apiRoutes } from '@constants';
import { AxiosResponse, AxiosError } from 'axios';
import { useActiveOrganization } from '@didomi/utility-react';
import { Widget } from '@interfaces';
import { WidgetFormat, WidgetLayoutShape, WidgetTemplateId } from '@enums';

export type CreateWidgetPayload = {
  name: string;
  format: WidgetFormat;
  layout_shape: WidgetLayoutShape;
  template_id: WidgetTemplateId;
  selected_purposes_ids?: string[];
  selected_preferences_ids?: string[];
};

export const useCreateWidget = (options?: UseMutationOptions<AxiosResponse<Widget>, AxiosError, CreateWidgetPayload>) => {
  const { organizationId } = useActiveOrganization();
  const axiosWithInterceptors = useAxiosWithLogOut();
  const queryClient = useQueryClient();

  const post = async (payload: CreateWidgetPayload) => axiosWithInterceptors.post(apiRoutes.widgets, payload, { params: { organization_id: organizationId } });

  return useMutation<AxiosResponse<Widget>, AxiosError, CreateWidgetPayload>(post, {
    ...options,
    onSuccess: (createdWidgetRes: AxiosResponse<Widget>, variables, context) => {
      // this will update all queries/caches that start with 'widgets'
      const dataKey = ['widgets'];

      queryClient.invalidateQueries(dataKey);

      if (options?.onSuccess) {
        options.onSuccess(createdWidgetRes, variables, context);
      }
    },
  });
};
