import React from 'react';
import EditWidgetBasicSection from './EditWidgetBasicSection';
import { WidgetTemplateId, WidgetThemeConfig } from '@enums';
import EditWidgetAdvancedSection from './EditWidgetAdvancedSection';
import EditWidgetHeadlessSection from './EditWidgetHeadlessSection';

interface EditWidgetThemeConfigProps {
  activeThemeConfig: WidgetThemeConfig;
  widgetTemplateId: WidgetTemplateId;
  widgetHeadless: boolean;
  onWidgetHeadlessChanged: (widgetHeadless: boolean) => void;
}

const WIDGET_THEME_CONFIGS = {
  [WidgetThemeConfig.BASIC]: ({ widgetTemplateId }) => <EditWidgetBasicSection widgetTemplateId={widgetTemplateId} />,
  [WidgetThemeConfig.ADVANCED]: () => <EditWidgetAdvancedSection />,
  [WidgetThemeConfig.HEADLESS]: ({ widgetHeadless, onWidgetHeadlessChanged }) => (
    <EditWidgetHeadlessSection widgetHeadless={widgetHeadless} onWidgetHeadlessChanged={onWidgetHeadlessChanged} />
  ),
};

const EditWidgetThemeConfig = ({ activeThemeConfig = WidgetThemeConfig.BASIC, widgetTemplateId, widgetHeadless, onWidgetHeadlessChanged }: EditWidgetThemeConfigProps) => {
  return <>{WIDGET_THEME_CONFIGS[activeThemeConfig]?.({ widgetTemplateId, widgetHeadless, onWidgetHeadlessChanged })}</>;
};

export default EditWidgetThemeConfig;
