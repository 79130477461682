import React, { useCallback, useState } from 'react';
import { DidomiIcon, DidomiTooltip } from '@didomi/ui-atoms-react';
import { Icons } from '@didomi/ui-foundation';
import { useResizeObserver } from '@didomi/helpers-react';

interface ContentEditorSectionProps {
  'data-testid'?: string;
  title: string;
  icon?: Icons;
  children?: React.ReactNode;
}

const ContentEditorSection = ({ 'data-testid': dataTestId = 'content-editor-section', title, icon, children }: ContentEditorSectionProps) => {
  const [titleRef, setTitleRef] = useState<HTMLSpanElement>();
  const [isTruncated, setIsTruncated] = useState(false);
  const [titleWidth, setTitleWidth] = useState(0);
  const handleTitleResize = useCallback(entries => {
    if (!entries.length) {
      return;
    }
    const titleEl = entries[0].target;
    const { width } = titleEl.getBoundingClientRect();
    setIsTruncated(titleEl.clientWidth < titleEl.scrollWidth);
    setTitleWidth(width);
  }, []);
  useResizeObserver(titleRef, handleTitleResize);

  return (
    <div data-testid={dataTestId}>
      <DidomiTooltip data-testid={`${dataTestId}-tooltip`} style={{ width: titleWidth || undefined }} placement="top" distance="xxxs" content={isTruncated ? title : ''}>
        <header className="flex items-center font-sans text-body-normal font-semibold text-primary-blue-6 mb-xxs cursor-default">
          {icon && <DidomiIcon data-testid={`${dataTestId}-icon`} className="mr-xxs shrink-0" name={icon} />}
          <span ref={setTitleRef} className="truncate" data-testid={`${dataTestId}-section-title`}>
            {title}
          </span>
        </header>
      </DidomiTooltip>
      {children && <div>{children}</div>}
    </div>
  );
};

export { ContentEditorSection };
