import React, { useCallback } from 'react';
import { DidomiButton, DidomiSelect, DidomiSelectOptions, DidomiSelectOption } from '@didomi/ui-atoms-react';
import { WidgetTable, ArchivedWidgetTable } from '@components';
import { useReleaseFlag, useHasAccessPolicies } from '@didomi/utility-react';
import { useHistory } from 'react-router-dom';
import { useQueryParams } from '@hooks';
import { ACCESS_POLICIES_CONFIG } from '@constants';
import { DidomiSelectCustomEvent } from '@didomi/ui-atoms';

/**
 * Widgets Default Page
 */
const Widgets = (): JSX.Element => {
  const { hasAccess: canEditWidgets } = useHasAccessPolicies(ACCESS_POLICIES_CONFIG.PMP_WIDGETS_EDITOR);

  const [hasWidgetArchivingFeature, isLoadingWidgetArchivingFeature] = useReleaseFlag('pmp_archive_feature');
  const { push } = useHistory();
  const { getQueryParam, setQueryParam } = useQueryParams();

  const filterByArchive = getQueryParam('archived') === 'true';
  const isArchivingEnabled = !isLoadingWidgetArchivingFeature && hasWidgetArchivingFeature;

  const setFilterByArchive = useCallback(
    (filter: string) => {
      setQueryParam('archived', filter, {
        resetValue: 'false',
        clearParams: true,
      });
    },
    [setQueryParam],
  );

  const onFilterSelect = (e: DidomiSelectCustomEvent<string>) => {
    setFilterByArchive(e.detail);
  };

  return (
    <div className="w-full h-full pt-6 flex flex-col">
      <div className="h-auto flex flex-col">
        <div className="flex flex-row-reverse items-center mb-s">
          {canEditWidgets && (
            <div>
              <DidomiButton data-tracking="pmp-create-widget" data-cy="create-widget" onClick={() => push(`/widgets/create`)} iconRight="new-create">
                Create a widget
              </DidomiButton>
            </div>
          )}
          {isArchivingEnabled && (
            <div className="mr-6">
              <DidomiSelect data-testid="filter-widgets" value={filterByArchive.toString()} onValueChange={onFilterSelect} data-cy="widgets-filter" hideErrorMessage>
                <DidomiSelectOptions>
                  <DidomiSelectOption label="Active widgets" value="false"></DidomiSelectOption>
                  <DidomiSelectOption label="Archived widgets" value="true"></DidomiSelectOption>
                </DidomiSelectOptions>
              </DidomiSelect>
            </div>
          )}
        </div>
      </div>
      {isArchivingEnabled && filterByArchive ? <ArchivedWidgetTable /> : <WidgetTable />}
    </div>
  );
};

export { Widgets };
