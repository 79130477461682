import React from 'react';
import { Form, Formik } from 'formik';
import {
  DidomiRadioGroupField,
  DidomiRadio,
  DidomiButton,
  DidomiTextInputField,
  DidomiTextareaField,
  DidomiHintbox,
  DidomiIcon,
  DidomiTooltip,
  DidomiSkeleton,
} from '@didomi/ui-atoms-react';
import { PreferenceValuesField } from '@components';
import { tx } from '@twind/core';
import * as Yup from 'yup';
import { PreferenceType } from '@enums';
const PreferenceSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
  organization_id: Yup.string(),
  values: Yup.array(Yup.object()).min(1, 'Required'),
  type: Yup.string().oneOf([PreferenceType.SINGLE, PreferenceType.MULTI], 'You must select the preference type'),
});

export type CreatePreferenceFormData = {
  name: string;
  description: string;
  organization_id: string;
  values: { name: string }[];
  type: PreferenceType;
};

type PreferenceFormProps = {
  initialValues: any;
  onSubmit?: (newPreferenceValue: CreatePreferenceFormData) => void;
  onCancel?: () => void;
  isLoading?: boolean;
  autoFocusNameInput?: boolean;
};

/**
 * Add/Edit Preference Form
 */
const PreferenceForm = ({ initialValues, onCancel, onSubmit, isLoading = false, autoFocusNameInput = false }: PreferenceFormProps): JSX.Element => (
  <Formik initialValues={initialValues} validationSchema={PreferenceSchema} onSubmit={onSubmit} enableReinitialize>
    {({ submitForm }) => (
      <DidomiSkeleton isLoading={isLoading} variant="layout" data-testid={`preference-form-skeleton-loading-${isLoading}`}>
        <Form className="mt-s flex flex-col">
          <DidomiTextInputField autofocus={autoFocusNameInput} name="name" label="Name" placeholder="Type a preference name" required data-skeleton />
          <DidomiTextareaField
            className={tx('mt-4', { 'mt-5': isLoading })}
            label="Description"
            placeholder="Type your preference description here…"
            name="description"
            required
            data-skeleton
          />
          <p className="italic text-xs mb-m" data-skeleton>
            * fields are required
          </p>
          <div className={tx('mt-4', { 'mt-5': isLoading })} data-skeleton>
            <div className="flex items-center">
              <h3 className="h3 text-primary-blue-6">Values</h3>
              <div className="hidden" id="preference-value-tooltip">
                <p className="text-body-small font-normal">
                  <strong>Values</strong> are the preference options displayed to the end-users. Each value can be displayed as a checkbox in the widget
                </p>
              </div>
              <DidomiTooltip placement="top" content-element-id="preference-value-tooltip" trigger="mouseenter focus" variant="helper" distance="xs">
                <DidomiIcon name="helper-text" className="ml-xxs" />
              </DidomiTooltip>
            </div>
            <PreferenceValuesField name="values" className="mt-xs" />
          </div>
          <DidomiHintbox
            className={tx({ 'h-40 mt-5': isLoading, 'mt-9': !isLoading })}
            titleText="Selection type"
            headerIconName="compliance"
            headerIconPosition="right"
            data-skeleton
          >
            <DidomiRadioGroupField name="type" orientation="vertical">
              <DidomiRadio value={PreferenceType.MULTI} data-testid="radio-multi">
                <strong>Multi-selection:</strong> end-users can make multiple choices for this preference
              </DidomiRadio>
              <DidomiRadio value={PreferenceType.SINGLE} data-testid="radio-single">
                <strong>Mono-selection:</strong> end-users can make only one choice for this preference
              </DidomiRadio>
            </DidomiRadioGroupField>
          </DidomiHintbox>
          <div className="w-full flex justify-end mt-5 mb-10">
            <DidomiButton type="button" variant="secondary" className="mr-xs" onClick={onCancel} data-skeleton>
              Cancel
            </DidomiButton>
            <DidomiButton data-tracking={`pmp-save-${initialValues?.name === '' ? 'new' : 'edited'}-preference`} type="button" onClick={submitForm} data-skeleton>
              Save preference
            </DidomiButton>
          </div>
        </Form>
      </DidomiSkeleton>
    )}
  </Formik>
);

export { PreferenceForm };
