import React from 'react';
import { useSnackbar } from '@didomi/utility-react';
import { LayoutEntity } from '@interfaces';
import { DidomiHintbox, DidomiRightPanel, DidomiSkeleton } from '@didomi/ui-atoms-react';
import { EntityOutput } from '@didomi/pmp-generator/dist/types/entities/models/entities.model';
import { EditPurposeControl } from './EditPurposeControl';
import { EditWidgetPreferencesBaseProps, ExtendedComponentsListInput, UpdatedLayoutEntityData } from '@types';
import { tx } from '@twind/core';
import { EditWidgetSinglePreference } from './EditWidgetSinglePreference';

interface EditWidgetPreferencesProps extends EditWidgetPreferencesBaseProps {
  isLoading?: boolean;
  preventDisableAllPreferenceValues?: boolean;
  entities: EntityOutput;
  layoutEntities: LayoutEntity[];
  renderSinglePreference?: boolean;
  components?: ExtendedComponentsListInput;
}

const hasSomePreferenceValueChecked = (layoutEntities: LayoutEntity[], widgetPreferenceValues: UpdatedLayoutEntityData, latestValue: Record<string, boolean>) => {
  const widgetPrefValuesMap = Object.entries(widgetPreferenceValues).reduce((flatted, [key, value]) => ({ ...flatted, [key]: value.enabled }), {});

  const valueEntitiesEnabledMap = layoutEntities
    .filter(entity => !!entity.preference_value_id)
    .reduce((map, entity) => {
      map[entity.id] = entity.enabled;

      return map;
    }, {});

  const mergedMap = {
    ...valueEntitiesEnabledMap,
    ...widgetPrefValuesMap,
    ...latestValue,
  };

  const hasSomePreferenceValues = Object.values(mergedMap).some(value => value === true);

  return hasSomePreferenceValues;
};

/**
 * Edit Widget Preferences Page
 */
const EditWidgetPreferences = ({
  isLoading,
  entities,
  widgetPreferences,
  setWidgetLayoutEntityEnabled,
  hasEnabledLayoutEntity,
  renderSinglePreference = false,
  layoutEntities,
  preventDisableAllPreferenceValues,
  components,
  getBreadcrumb,
  preferenceOrderingControls,
}: EditWidgetPreferencesProps): JSX.Element => {
  const { displaySnackbar } = useSnackbar();

  const handleSetWidgetPreferenceEnabled = (layoutEntityId: string, enabled: boolean) => {
    if (preventDisableAllPreferenceValues) {
      const hasSomePreferenceValues = hasSomePreferenceValueChecked(layoutEntities, widgetPreferences, { [layoutEntityId]: enabled });
      if (!hasSomePreferenceValues) {
        displaySnackbar('At least one value is required!', { icon: 'danger-light', variant: 'error' });
        enabled = true;
      }
    }

    return setWidgetLayoutEntityEnabled(layoutEntityId, enabled);
  };

  const EditPurposeControls: JSX.Element[] = entities?.purposes.map((selectedPurpose, index) => (
    <div
      key={selectedPurpose.id}
      className={preferenceOrderingControls?.getAnimation(selectedPurpose.layoutEntityId)}
      onAnimationEnd={preferenceOrderingControls?.getScrollHandler(selectedPurpose.layoutEntityId)}
    >
      <div className={tx({ 'mb-xxs': index < entities.purposes.length })}>
        {renderSinglePreference ? (
          <EditWidgetSinglePreference
            components={components}
            selectedPurpose={selectedPurpose}
            widgetPreferences={widgetPreferences}
            hasEnabledLayoutEntity={hasEnabledLayoutEntity}
            setWidgetLayoutEntityEnabled={handleSetWidgetPreferenceEnabled}
            getBreadcrumb={getBreadcrumb}
            preferenceOrderingControls={preferenceOrderingControls}
          />
        ) : (
          <div className="mt-xs">
            <EditPurposeControl
              selectionType={'multi'}
              selectedPurpose={selectedPurpose}
              widgetPreferences={widgetPreferences}
              hasEnabledLayoutEntity={hasEnabledLayoutEntity}
              setWidgetLayoutEntityEnabled={setWidgetLayoutEntityEnabled}
              getBreadcrumb={getBreadcrumb}
              preferenceOrderingControls={preferenceOrderingControls}
            />
          </div>
        )}
      </div>
    </div>
  ));

  return (
    <DidomiRightPanel titleText="Purposes &#38; preferences" showCreateButton={false} className="h-full w-[430px]" data-testid="preferences-right-panel">
      <div slot="content" className="h-full">
        <div data-testid="content-editor-content" className="flex flex-col h-full">
          <DidomiHintbox className="flex" titleText="Manage your widget content!" headerIconName="edit-half" contentAlign="start" variant="info">
            Easily switch the toggles to display or to hide the preferences you need in your widget!
          </DidomiHintbox>
          {isLoading ? (
            <div className="flex flex-grow flex-col mt-xs">
              <DidomiSkeleton className="h-32 mb-xs" isLoading variant="unit" />
              <DidomiSkeleton className="h-32 mb-xs" isLoading variant="unit" />
              <DidomiSkeleton className="flex flex-grow" isLoading variant="unit" />
            </div>
          ) : (
            <>{EditPurposeControls}</>
          )}
        </div>
      </div>
    </DidomiRightPanel>
  );
};

export { EditWidgetPreferences };
