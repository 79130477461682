import React from 'react';
import type { Icons } from '@didomi/ui-foundation';
import { DidomiHeaderOptions } from '@didomi/pmp-generator';
import { CollapsibleCard } from '../CollapsibleCard/CollapsibleCard';
import { ContentEditorSection } from './ContentEditorSection';
import { ContentEditorTextarea, ContentEditorFileInput, ContentEditorTextInput } from './components';
import type { ContentEditorForm as ContentEditorFormProps } from '@types';
import { ContentEditorSwitch } from './components/ContentEditorSwitch';
import { useContentEditorForm } from '@hooks';

const HINTS = {
  INTRO_INPUT: {
    icon: 'speaker-half' as Icons,
    title: 'Transparency is the key',
    description: 'Make it clear to your users why you need their preferences and build trust in your brand with a good introduction text.',
  },
  BANNER_IMAGE: {
    icon: 'edit-half' as Icons,
    title: 'Grab users attention...',
    description: "Banner image allows you to express your brand identity, so don't miss out!",
  },
};

export const ContentEditorHeaderForm = ({ data, setData }: ContentEditorFormProps<DidomiHeaderOptions>) => {
  const { getValueAndChangeHandlerFor } = useContentEditorForm<DidomiHeaderOptions>(data, setData);

  return (
    <ContentEditorSection data-testid="content-editor-header" title="Header">
      <ContentEditorSwitch
        data-testid="header-user-email-switch"
        hideErrorMessage
        labelVariant="no-box"
        valueSelected
        valueNotSelected={false}
        {...getValueAndChangeHandlerFor('user', false)}
      >
        Display the user email address
      </ContentEditorSwitch>
      {/* // Not required for MVP
      <ContentEditorSwitch hideErrorMessage labelVariant="no-box">
        Display the history button
      </ContentEditorSwitch> */}
      <CollapsibleCard contentId="content-editor-header-children" title="My brand content">
        <ContentEditorFileInput
          label="Brand logo"
          description="Recommended size is 128 x 128 pixels"
          placeholder="Enter a hosted logo URL"
          {...getValueAndChangeHandlerFor('logo', false)}
        />
        <ContentEditorTextInput label="Preference Center title" placeholder="Enter title" data-testid="preference-center-title" {...getValueAndChangeHandlerFor('mainTitle')} />
        <ContentEditorTextarea
          hint={HINTS.INTRO_INPUT}
          label="Introduction text"
          description="Explain legally the role of your Preference Center"
          placeholder="Enter an introduction text"
          rows={3}
          {...getValueAndChangeHandlerFor('introductionTitle')}
        />
        <ContentEditorFileInput
          hint={HINTS.BANNER_IMAGE}
          label="Banner image"
          description="Recommended size is 780 x 320 pixels"
          placeholder="Enter a hosted banner image URL"
          {...getValueAndChangeHandlerFor('bannerImage', false)}
        />
      </CollapsibleCard>
    </ContentEditorSection>
  );
};
