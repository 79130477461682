import React, { useState, useMemo } from 'react';
import { tx } from '@twind/core';
import { DidomiButton, DidomiChip, DidomiChipList } from '@didomi/ui-atoms-react';
import { useField } from 'formik';
import { KeyboardValue } from '../../enums';

type PreferenceValuesFieldProps = {
  name: string;
  className?: string;
};

const PreferenceValuesField = ({ name, className }: PreferenceValuesFieldProps): JSX.Element => {
  const [, { value, error, touched }, { setValue, setError }] = useField({ name });
  const [currentValue, setCurrentValue] = useState('');

  const handleInputChange = e => {
    setCurrentValue(e.target.value);
  };

  const valueIsAlreadyAdded = useMemo(() => value && !!value.find(v => v.name === currentValue), [currentValue, value]);
  const isCreationDisabled = !currentValue || valueIsAlreadyAdded;

  const handleCreateValue = () => {
    setValue([...value, { name: currentValue }]);
    setCurrentValue('');
  };

  const handleRemoveItem = (valueName: string) => {
    const newValues = value.filter(v => v.name !== valueName);

    if (newValues.length === 0) {
      setError('Required');
    }
    setValue(newValues);
  };

  const handleKeyDown = (ev: React.KeyboardEvent<HTMLInputElement>) => {
    if (ev.key !== KeyboardValue.Enter) {
      return;
    }
    ev.preventDefault();
    if (isCreationDisabled) {
      return;
    }
    handleCreateValue();
  };

  return (
    <>
      <div
        className={tx(className, 'w-full flex flex-wrap items-center items-center border-1 border-solid border-neutral-gray-5 rounded-lg px-xs pb-xs pt-xxs ', {
          'border-secondary-red-4': !!error && !!touched,
        })}
      >
        <DidomiChipList className="mt-xxs">
          <div>
            {value?.length > 0 &&
              value.map((v, i) => (
                <DidomiChip
                  data-testid={'value-' + i}
                  className="mr-xxxs"
                  key={v.name}
                  label={v.name}
                  variation="value"
                  rightIcon="close-sm"
                  onRightIconClick={() => handleRemoveItem(v.name)}
                />
              ))}
          </div>
        </DidomiChipList>
        <div className="flex items-center mt-xxs">
          <input
            title="Add value"
            className="border-1 border-neutral-gray-5 rounded-lg font-sans text-body-small p-xs mr-xxs outline-none focus:(border-neutral-gray-5 shadow-input-focused)"
            onChange={handleInputChange}
            value={currentValue}
            placeholder="Add value"
            onKeyDown={handleKeyDown}
          />
          <DidomiButton className="my-xxs" type="button" size="small" onClick={handleCreateValue} disabled={isCreationDisabled}>
            Create
          </DidomiButton>
        </div>
      </div>
      {error && touched && <div className="text-secondary-red-4 font-sans text-[12px] mt-xxxs">{error}</div>}
    </>
  );
};

export { PreferenceValuesField };
