import React from 'react';
import { PurposeOutput } from '@didomi/pmp-generator/dist/types/entities/models/entities.model';
import { DidomiIcon } from '@didomi/ui-atoms-react';
import { OverflowWithTooltip } from '@components';
import { getComponentTranslatedValue } from '@utils';
import { EditWidgetPreferencesBaseProps, ExtendedComponentsListInput } from '@types';
import { useTranslationContext } from '@didomi/widgets-utilities';
import { EditPreferenceControl } from './EditPreferenceControl';
import { EditWidgetBreadcrumb } from '../EditWidgetBreadcrumb/EditWidgetBreadcrumb';

interface EditWidgetSinglePreferenceProps extends EditWidgetPreferencesBaseProps {
  selectedPurpose: PurposeOutput;
  selectedEntityId?: string;
  components?: ExtendedComponentsListInput;
}

const EditWidgetSinglePreference = ({
  selectedPurpose,
  selectedEntityId,
  widgetPreferences,
  setWidgetLayoutEntityEnabled,
  setSelectedEntity,
  components,
  getBreadcrumb,
  preferenceOrderingControls,
}: EditWidgetSinglePreferenceProps) => {
  const { defaultLanguage } = useTranslationContext();
  const selectedPreferences = selectedPurpose.preferences;
  const renderPurpose = !!selectedPurpose.layoutEntityId;
  const breadcrumbs = getBreadcrumb(selectedPreferences?.[0]?.id);

  return (
    <>
      <EditWidgetBreadcrumb breadcrumbs={breadcrumbs} className="mt-6" />
      <section data-testid="edit-widget-single-preference-panel" className="border border-solid border-neutral-gray-4 rounded-lg bg-white p-xs pb-s mt-xs">
        {renderPurpose && (
          <div>
            <div className="flex items-center">
              <DidomiIcon name="purpose" />
              <OverflowWithTooltip
                className="w-full flex items-center justify-between text-body-normal font-semibold min-w-0 text-primary-blue-6 ml-2"
                value={getComponentTranslatedValue(selectedPurpose.component?.id, 'purposeOptions.title.content', selectedPurpose.name, components, defaultLanguage)}
                data-testid="edit-widget-single-preference-panel-purpose-name"
              />
            </div>
            <div className="ml-[6px] pl-xs pt-xs border-l border-solid border-primary-pink-3 text-body-normal text-primary-blue-6">
              <p className="pb-xxs" data-testid="edit-widget-single-preference-panel-yes-label">
                {getComponentTranslatedValue(selectedPurpose.component?.id, 'purposeOptions.yesLabel.content', 'Yes', components, defaultLanguage)}
              </p>
              <p data-testid="edit-widget-single-preference-panel-no-label">
                {getComponentTranslatedValue(selectedPurpose.component?.id, 'purposeOptions.noLabel.content', 'No', components, defaultLanguage)}
              </p>
            </div>
          </div>
        )}
        {selectedPreferences.map(selectedPreference => (
          <EditPreferenceControl
            key={selectedPreference.id}
            selectionType="multi"
            selectedPreference={selectedPreference}
            selectedEntityId={selectedEntityId}
            widgetPreferences={widgetPreferences}
            setWidgetLayoutEntityEnabled={setWidgetLayoutEntityEnabled}
            setSelectedEntity={setSelectedEntity}
            components={components}
            preferenceOrderingControls={preferenceOrderingControls}
            headerActionsEnabled={false}
          />
        ))}
      </section>
    </>
  );
};

export { EditWidgetSinglePreference };
