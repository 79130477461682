import { devOrganizationId } from '@constants';
import { createDefaultWidgetDeployments } from '../widgetsDeployments/seeds';

export function createDefaultArchivedWidgets(server, defaultOrganization = devOrganizationId) {
  server.create('archivedWidget', {
    id: 'archived-widget-1',
    organizationId: defaultOrganization,
  });
  server.create('archivedWidget', {
    id: 'archived-widget-2',
    organizationId: defaultOrganization,
  });
  server.create('archivedWidget', {
    id: 'archived-widget-3',
    organizationId: defaultOrganization,
  });
  createDefaultWidgetDeployments(server, defaultOrganization, 'archived-widget-1');
  createDefaultWidgetDeployments(server, defaultOrganization, 'archived-widget-2');
  createDefaultWidgetDeployments(server, defaultOrganization, 'archived-widget-3');
}
