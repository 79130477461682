import { ConfigTree, SelectedPreference, SelectedPurpose } from '@interfaces';

interface ConfigTreeMap {
  preferencesByParentId: Map<string, string[]>;
  selectedPreferenceToPreference: Map<string, string>;
  parentIds: Map<string, string>;
}

/**
 * Groups all preference IDs based on their parent value ID.
 */
const groupPreferenceIdsByValueId = (
  selectedPreferences,
  preferencesByParentId: Map<string, string[]>,
  selectedPreferenceToPreference: Map<string, string>,
  parentIds: Map<string, string>,
  parentId?: string,
): void => {
  selectedPreferences.forEach(selectedPreference => {
    // map selected preference id with preferences id
    selectedPreferenceToPreference.set(selectedPreference.id, selectedPreference.preference.id);

    // map selected preference id with parent id
    parentIds.set(selectedPreference.id, parentId);

    selectedPreference.preference.values.forEach(value => {
      const valuePreferenceIDs = value.selected_preferences.map(selectedPreference => selectedPreference.preference.id);
      // adds value ID as key, and all first level child preferences IDs as value
      preferencesByParentId.set(`${selectedPreference.id}-${value.id}`, valuePreferenceIDs);

      groupPreferenceIdsByValueId(value.selected_preferences, preferencesByParentId, selectedPreferenceToPreference, parentIds, selectedPreference.id);
    });
  });
};

/**
 * Helper mappings used by the config tree to check if a preference can be included in the config tree.
 *
 * preferencesByParentId: Map all the preferences that belongs to a parent value.
 * selectedPreferenceToPreference: Map the selected preference id to the preference id.
 * parentIds: Map all parent/child relations.
 */
export const configTreeMap = (configTree: ConfigTree): ConfigTreeMap => {
  const preferencesByParentId = new Map<string, string[]>();
  const selectedPreferenceToPreference = new Map<string, string>();
  const parentIds = new Map<string, string>();

  if (!configTree) {
    return { preferencesByParentId, selectedPreferenceToPreference, parentIds };
  }

  configTree.selected_purposes.forEach(selectedPurpose => {
    const purposePreferenceIDs = selectedPurpose.selected_preferences.map(selectedPreference => selectedPreference.preference.id);
    // adds purpose ID as key, and all first level child preferences IDs as value
    preferencesByParentId.set(selectedPurpose.id, purposePreferenceIDs);

    groupPreferenceIdsByValueId(selectedPurpose.selected_preferences, preferencesByParentId, selectedPreferenceToPreference, parentIds);
  });

  return { preferencesByParentId, selectedPreferenceToPreference, parentIds };
};

/**
 * Checks if a preference is nested within itself by traversing through its parent hierarchy.
 */
export const isPreferenceSelfNesting = (preferenceId: string, parentId: string, configTreeMap: ConfigTreeMap) => {
  let currentParentId = parentId;
  while (currentParentId) {
    if (configTreeMap.selectedPreferenceToPreference.get(currentParentId) === preferenceId) {
      return true;
    }

    currentParentId = configTreeMap.parentIds.get(currentParentId);
  }

  return false;
};

/**
 * Merges the selected purposes of the configuration tree with a temporary selected preference.
 * @param purposes configuration tree purposes
 * @param tempPreference temporary preference that is been added to the configuration tree
 * @returns list of purposes with the temporary preference include in the right place
 */
export const mergePurposesWithSelectedPreference = (purposes: SelectedPurpose[], tempPreference: SelectedPreference) => {
  if (purposes?.length === 0 || !tempPreference) {
    return purposes;
  }

  const purposeClone = structuredClone(purposes);

  purposeClone.forEach(purpose => {
    if (
      // checks if the preference should be nested under a purpose
      (tempPreference.parent_id === null || tempPreference.parent_id === undefined) &&
      // checks if the preference should be nested under this purpose
      tempPreference.parent_selected_purpose_id === purpose.id
    ) {
      insertPreference(purpose.selected_preferences, tempPreference);
    }

    mergePreferences(purpose.selected_preferences, tempPreference);
  });

  return purposeClone;
};

const mergePreferences = (selectedPreferences: SelectedPreference[], tempPreference: SelectedPreference) => {
  selectedPreferences?.forEach(selectedPreference => {
    selectedPreference.preference.values.forEach(value => {
      if (
        // checks if the preference should be nested under this preference value
        tempPreference.parent_preference_value_id === value.id &&
        // check if the preference should be nested under this selected preference
        tempPreference.parent_id === selectedPreference.id
      ) {
        insertPreference(value.selected_preferences, tempPreference);
      }

      mergePreferences(value.selected_preferences, tempPreference);
    });
  });
};

/**
 * Inserts the temporary preference in the correct position, base on the provider order.
 */
const insertPreference = (selectedPreferences: SelectedPreference[], tempPreference: SelectedPreference) => {
  const index = selectedPreferences.findIndex(selectedPreference => selectedPreference.order === tempPreference.order);
  if (index !== -1) {
    selectedPreferences.splice(index, 0, tempPreference);
  } else {
    selectedPreferences.push(tempPreference);
  }
};
