import React from 'react';
import { DidomiIconButton, DidomiMenu, DidomiMenuItem, DidomiTooltip } from '@didomi/ui-atoms-react';

type PreferenceCardOptionsProps = {
  onRemove?: () => void;
  onPreview?: () => void;
  onEdit?: () => void;
  showRemove?: boolean;
  showEdit?: boolean;
};

/**
 * Selected Preference card options component
 */
const SelectedPreferenceCardOptions = ({ onRemove, onPreview, onEdit, showRemove = true, showEdit = true }: PreferenceCardOptionsProps): JSX.Element => {
  return (
    <>
      {(onRemove || onPreview || onEdit) && (
        <DidomiTooltip placement="top" distance="xxxs" content="Options" className="mr-1">
          <DidomiIconButton data-testid="selected-preference-options" title="Preference options" variant="rounded" icon="submenu">
            <>
              <DidomiMenu placement="bottom-end" distance="xxxs" variant="main">
                {onPreview && (
                  <DidomiMenuItem data-tracking="pmp-preview-preference-from-ct" iconName="preview" onItemSelected={() => onPreview()}>
                    Preview
                  </DidomiMenuItem>
                )}
                {onRemove && showRemove && (
                  <DidomiMenuItem data-tracking="pmp-start-remove-preference-from-tree" iconName="back" onItemSelected={() => onRemove()}>
                    Remove
                  </DidomiMenuItem>
                )}
                {onEdit && showEdit && (
                  <DidomiMenuItem iconName="edit" onItemSelected={() => onEdit()}>
                    Edit in library
                  </DidomiMenuItem>
                )}
              </DidomiMenu>
            </>
          </DidomiIconButton>
        </DidomiTooltip>
      )}
    </>
  );
};

export { SelectedPreferenceCardOptions };
