import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { AxiosResponse, AxiosError } from 'axios';
import { useActiveOrganization } from '@didomi/utility-react';
import { Translations } from '@didomi/pmp-generator';
import { apiRoutes } from '@constants';
import { Purpose } from '@interfaces';
import { updateQueryClientCache } from '@utils';
import { useAxiosWithLogOut } from '../useAxiosWithInterceptors.hook';

export type CreatePurposePayload = {
  description: Translations;
  details: Translations;
};

export const useCreatePurpose = (options?: UseMutationOptions<AxiosResponse<Purpose>, AxiosError, CreatePurposePayload>) => {
  const { organizationId } = useActiveOrganization();
  const axiosWithInterceptors = useAxiosWithLogOut();
  const queryClient = useQueryClient();

  const post = async (payload: CreatePurposePayload) =>
    axiosWithInterceptors.post(
      apiRoutes.purposes,
      {
        ...payload,
        organization_id: organizationId,
      },
      { params: { organization_id: organizationId } },
    );

  return useMutation<AxiosResponse<Purpose>, AxiosError, CreatePurposePayload>(post, {
    ...options,
    onSuccess: (createdPurpose: AxiosResponse<Purpose>, variables, context) => {
      updateQueryClientCache(queryClient, createdPurpose, ['purpose']);

      if (options.onSuccess) {
        options.onSuccess(createdPurpose, variables, context);
      }
    },
  });
};
