import React from 'react';
import { DidomiIcon, DidomiIconButton } from '@didomi/ui-atoms-react';
import { generateUniqueId } from '@utils';

export type PreferenceCardProps = {
  id?: string;
  'data-testid'?: string;
};

/**
 * Loading Preference card component
 * This component is used as a skeleton while the preference are being loaded from the server
 */
const LoadingPreferenceCard = ({ id = generateUniqueId('loading-preference-card'), 'data-testid': dataTestid }: PreferenceCardProps): JSX.Element => {
  return (
    <div data-testid={dataTestid || 'loading-preference-card'} className="w-[382px] border-dashed rounded-2xl box-border">
      <div className="w-full border-4 border-solid border-neutral-gray-1 rounded-2xl box-border">
        <div className="py-m pl-6 pr-4 h-8 flex justify-between items-center border-neutral-gray-4 border-1 border-b-0 border-solid rounded-t-lg">
          <div className="flex-grow flex items-center min-w-0 select-none" id={id}>
            <DidomiIcon name="preference" aria-hidden className="pr-2 min-w-[15px] opacity-40" />
            <div className="animate-pulse bg-neutral-gray-2 rounded-sm h-s w-full mx-xxs" />
          </div>
          <div className="flex">
            <DidomiIconButton disabled={true} title="Preference options" variant="rounded" icon="submenu" />
            <DidomiIconButton className="children:(border-neutral-gray-4!)" title="Collapse preference" variant="rounded-border" disabled={true} icon="down-on-sm" />
          </div>
        </div>
        <div className="h-[162px] border-neutral-gray-4 border-1 border-solid rounded-b-lg">
          <div className="p-s">
            <div className="animate-pulse bg-neutral-gray-2 rounded-sm h-s w-full mb-5" />
            <div className="animate-pulse bg-neutral-gray-2 rounded-sm h-s w-full mb-5" />
            <div className="animate-pulse bg-neutral-gray-2 rounded-sm h-s w-full" />
          </div>
        </div>
      </div>
    </div>
  );
};

export { LoadingPreferenceCard };
