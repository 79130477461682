import React, { useState, useCallback, useEffect } from 'react';
import { ACCESS_POLICIES_CONFIG, WIDGET_STATUS_CHIP_TYPE, WIDGET_STATUS_LABELS, WIDGET_TEMPLATE_ID_LABELS } from '@constants';
import {
  DidomiTable,
  DidomiTableHeading,
  DidomiTableHeaderRow,
  DidomiTableTh,
  DidomiTableBody,
  DidomiTableRow,
  DidomiTableTd,
  DidomiIconButton,
  DidomiPaginator,
  DidomiTooltip,
  DidomiSkeleton,
  DidomiMenu,
  DidomiMenuItem,
  DidomiChip,
} from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';
import { useResizeObserver } from '@didomi/helpers-react';
import { GenericEmptyState, WidgetPreview, WidgetTitle } from '@components';
import { useSnackbar, useReleaseFlag, useHasAccessPolicies } from '@didomi/utility-react';
import { useHistory } from 'react-router-dom';
import { ArchiveWidgetModal, LoadingModal } from '@modals';
import { WidgetPublicationStatus } from '@enums';
import { useDeleteWidget, useWidgets, useDuplicateWidget } from '@hooks';
import { Widget } from '@interfaces';

const WidgetTable = (): JSX.Element => {
  const { hasAccess: canViewWidgets } = useHasAccessPolicies(ACCESS_POLICIES_CONFIG.PMP_WIDGETS_VIEWER);
  const { hasAccess: canEditWidgets } = useHasAccessPolicies(ACCESS_POLICIES_CONFIG.PMP_WIDGETS_EDITOR);

  const [hasVersioningRelease] = useReleaseFlag('pmp_versioning');

  const { push } = useHistory();

  const [archivingCandidate, setArchivingCandidate] = useState(null);
  const [widgetTitleColumn, setWidgetTitleColumn] = useState<HTMLDidomiTableTdElement>(null);
  const [widgetColumnWidth, setWidgetColumnWidth] = useState<number>();
  const [widgets, setWidgets] = useState<Widget[]>(null);
  const [duplicatingWidgetId, setDuplicatingWidgetId] = useState<string>(null);
  const { displaySnackbar } = useSnackbar();

  const [hasWidgetArchivingFeature, isLoadingWidgetArchivingFeature] = useReleaseFlag('pmp_archive_feature');
  const isArchivingEnabled = !isLoadingWidgetArchivingFeature && hasWidgetArchivingFeature;
  const canPreviewWidget = canEditWidgets || canViewWidgets;

  const { mutate: deleteWidget, isLoading: deletingWidget } = useDeleteWidget({
    onSuccess: () => {
      const action = archivingCandidate.publication_status === WidgetPublicationStatus.DRAFT ? 'deleted' : 'archived';
      displaySnackbar(`Widget is ${action}!`, { icon: 'check' });
      setArchivingCandidate(null);
      refetchWidgets();
    },
    onError: () => {
      const action = archivingCandidate.publication_status === WidgetPublicationStatus.DRAFT ? 'deleted' : 'archived';
      displaySnackbar(`Sorry, an error occurred: widget can not be ${action}`, { icon: 'danger-light' });
      setArchivingCandidate(null);
    },
  });

  const { mutate: duplicateWidget } = useDuplicateWidget({
    onSuccess: data => {
      setWidgets(previousWidgets => {
        return [data.data, ...previousWidgets];
      });
      setDuplicatingWidgetId(null);
      displaySnackbar('Copy of your widget is created', { icon: 'check' });
    },
    onError: () => {
      setDuplicatingWidgetId(null);
      displaySnackbar('widget can not be duplicated', { icon: 'danger-light', variant: 'error', title: 'Sorry, an error occurred:' });
    },
  });

  const {
    isLoading: loadingWidgets,
    isError: errorLoadingWidgets,
    data: { data, total: widgetsCount } = {},
    refetch: refetchWidgets,
    paginator: { limit, page: currPage, setLimit, setPage: setCurrPage },
  } = useWidgets({
    onError: () => {
      displaySnackbar('Widget list can not be loaded', {
        variant: 'error',
        permanent: true,
        title: 'An error occurred:',
        action: {
          action: /* istanbul ignore next */ () => {
            refetchWidgets();
          },
          name: 'Reload',
        },
        secondaryAction: {
          action: /* istanbul ignore next */ () => {},
          name: 'Close',
          closeInAction: true,
        },
      });
    },
  });

  useEffect(() => {
    setWidgets(data || []);
  }, [data]);

  const handleWidgetTitleColumnResize = useCallback(entries => {
    if (!entries.length) {
      return;
    }
    const rowElement = entries[0].target;
    const { width } = rowElement.getBoundingClientRect();
    const paddingLeft = 32;
    setWidgetColumnWidth(width - paddingLeft);
  }, []);

  useResizeObserver(widgetTitleColumn, handleWidgetTitleColumnResize);

  const loading = loadingWidgets;
  const error = errorLoadingWidgets;
  const widgetsList = widgets || [];
  const singleWidget = widgetsCount === 1;
  const noWidgets = (!widgetsCount && !loading) || error;
  const countText = widgetsCount ? `${widgetsCount} result${singleWidget ? '' : 's'}` : '';

  const confirmWidgetArchiving = () => {
    if (archivingCandidate) {
      deleteWidget(archivingCandidate.id);
    }
  };

  const onDuplicateWidget = (widgetId: string) => {
    // TODO: we can remove this, one we migrate duplicate widget
    setDuplicatingWidgetId(widgetId);
    duplicateWidget(widgetId);
  };

  return (
    <div className="flex flex-col">
      {!noWidgets && (
        <>
          <DidomiSkeleton isLoading={loading} className="mb-xxs w-40">
            <h4 className="h4 font-bold text-primary-blue-6 h-5 mb-xxs">{countText}</h4>
          </DidomiSkeleton>
          <DidomiTable data-testid={loading ? 'loading-table' : 'widget-table'} className="mb-4 w-auto">
            <DidomiTableHeading>
              <DidomiTableHeaderRow>
                <DidomiTableTh sortId="name" data-testid="sortName">
                  NAME
                </DidomiTableTh>
                <DidomiTableTh sortId="format">FORMAT</DidomiTableTh>
                <DidomiTableTh style={{ maxWidth: 200, paddingLeft: 15 }}>PUBLICATION STATUS</DidomiTableTh>
                {canPreviewWidget && <DidomiTableTh cellAlign="center" style={{ maxWidth: 350 }}></DidomiTableTh>}
              </DidomiTableHeaderRow>
            </DidomiTableHeading>
            <DidomiTableBody>
              {loading
                ? Array.from({ length: limit }).map((_, i) => (
                    <DidomiTableRow key={'loading-widget-' + i}>
                      <DidomiTableTd class="children:(w-full)">
                        <DidomiSkeleton isLoading={loading} className="h-s w-full pr-s" />
                      </DidomiTableTd>
                      <DidomiTableTd class="children:(w-full)">
                        <DidomiSkeleton isLoading={loading} className="h-s w-full pr-s" />
                      </DidomiTableTd>
                      {canPreviewWidget && (
                        <DidomiTableTd cellAlign="center" style={{ maxWidth: 250 }}>
                          <div className="flex p-1">
                            {canPreviewWidget && <DidomiIconButton icon="preview" variant="rounded" aria-label="Preview widget" className="mr-xs" disabled />}
                            {canEditWidgets && <DidomiIconButton icon="edit" variant="rounded" aria-label="Edit widget" className="mr-xs" disabled />}
                            {canEditWidgets && (
                              <DidomiIconButton icon="submenu" data-testid="archive" aria-label="Options" variant="rounded" className="mr-xs cursor-pointer" disabled />
                            )}
                          </div>
                        </DidomiTableTd>
                      )}
                    </DidomiTableRow>
                  ))
                : /* TODO: Add error states (currently not specified)  */
                  widgetsList?.map(({ id, name, template_id, publication_status }, index) => {
                    const statusChipVariation = publication_status === WidgetPublicationStatus.DRAFT ? 'basic-outline' : 'basic';
                    const isDuplicatingWidget = id === duplicatingWidgetId;
                    const enableVersioningMenuItem = [WidgetPublicationStatus.PUBLISHED, WidgetPublicationStatus.UNPUBLISHED_CHANGES].includes(publication_status);
                    // When disableActions is false, the tooltip was not showing.
                    // TODO - Remove this code after fix this bug on ui-library
                    const disableActions = duplicatingWidgetId !== null ? true : null;

                    return (
                      <DidomiTableRow key={id} selectionValue={id} data-testid={id} data-cy={id}>
                        <DidomiTableTd ref={index === 0 ? setWidgetTitleColumn : undefined}>
                          <WidgetTitle id={id} title={name} width={widgetColumnWidth} isLink={canEditWidgets} />
                        </DidomiTableTd>
                        <DidomiTableTd>
                          <div className="truncate w-full py-1">{WIDGET_TEMPLATE_ID_LABELS[template_id]}</div>
                        </DidomiTableTd>
                        <DidomiTableTd style={{ maxWidth: 200 }}>
                          <DidomiChip
                            data-testid={`widget-status-${publication_status}`}
                            variation={statusChipVariation}
                            label={WIDGET_STATUS_LABELS[publication_status]}
                            basicType={WIDGET_STATUS_CHIP_TYPE[publication_status]}
                          />
                        </DidomiTableTd>

                        {canPreviewWidget && (
                          <DidomiTableTd cellAlign="right" style={{ maxWidth: 350 }}>
                            <div className="flex p-1">
                              {canPreviewWidget && (
                                <WidgetPreview widgetId={id}>
                                  {({ preview, isLoading }) => {
                                    // When disabled is false, the tooltip was not showing.
                                    // TODO - Remove this code after fix this bug on ui-library
                                    const disabled = disableActions || isLoading ? true : null;
                                    return (
                                      <DidomiTooltip placement="top" distance="xxxs" content="Preview widget">
                                        <DidomiIconButton
                                          icon={isLoading ? 'reset' : 'preview'}
                                          data-testid={`widget-preview-${id}`}
                                          variant="rounded"
                                          aria-label="Preview widget"
                                          onClick={() => preview()}
                                          disabled={disabled}
                                          data-tracking="pmp-preview-widget-from-list"
                                          className={tx('mr-xs cursor-pointer', {
                                            'animate-spin': isLoading,
                                          })}
                                        />
                                      </DidomiTooltip>
                                    );
                                  }}
                                </WidgetPreview>
                              )}
                              {canEditWidgets && (
                                <DidomiTooltip placement="top" distance="xxxs" content="Edit widget">
                                  <DidomiIconButton
                                    data-tracking="pmp-edit-widget"
                                    data-testid={`edit-${id}`}
                                    icon="edit"
                                    variant="rounded"
                                    aria-label="Edit widget"
                                    className="mr-xs"
                                    onClick={() => push(`/widgets/edit/${id}`)}
                                    disabled={disableActions}
                                  />
                                </DidomiTooltip>
                              )}
                              {(canEditWidgets || hasVersioningRelease) && (
                                <div>
                                  <DidomiTooltip placement="top" distance="xxxs" content="Options">
                                    <DidomiIconButton
                                      id={`widget-options-${id}`}
                                      data-testid={`submenu-${id}-${disableActions}`}
                                      icon={isDuplicatingWidget ? 'reset' : 'submenu'}
                                      variant="rounded"
                                      aria-label="Options"
                                      disabled={disableActions}
                                      className={tx('mr-xs cursor-pointer', {
                                        'animate-spin': isDuplicatingWidget,
                                      })}
                                    />
                                  </DidomiTooltip>

                                  <DidomiMenu for={`widget-options-${id}`} placement="left-start">
                                    <div>
                                      <DidomiMenuItem
                                        data-testid={`duplicate-${id}-${isDuplicatingWidget}`}
                                        data-tracking="pmp-duplicate-widget"
                                        onItemSelected={() => !isDuplicatingWidget && onDuplicateWidget(id)}
                                        iconName="copy"
                                        disabled={!canEditWidgets}
                                      >
                                        Duplicate
                                      </DidomiMenuItem>
                                      {isArchivingEnabled && (
                                        <>
                                          <DidomiMenuItem
                                            data-testid={`archive`}
                                            iconName="archive"
                                            disabled={publication_status === WidgetPublicationStatus.DRAFT || !canEditWidgets}
                                            onItemSelected={() => setArchivingCandidate({ id, publication_status })}
                                          >
                                            Archive
                                          </DidomiMenuItem>
                                          <DidomiMenuItem
                                            data-testid={`delete`}
                                            iconName="delete"
                                            disabled={publication_status !== WidgetPublicationStatus.DRAFT || !canEditWidgets}
                                            onItemSelected={() => setArchivingCandidate({ id, publication_status })}
                                          >
                                            Delete
                                          </DidomiMenuItem>
                                        </>
                                      )}

                                      {hasVersioningRelease && (
                                        <>
                                          <div className="w-full h-[1px] bg-neutral-gray-5 my-xxs" />

                                          <DidomiTooltip variant="helper" placement="top" content={enableVersioningMenuItem ? null : 'Publish the widget to create a version'}>
                                            <div className="mt-xxs">
                                              <DidomiMenuItem
                                                data-testid="versions-history"
                                                iconName="timer"
                                                onItemSelected={() => push(`widgets/versions-history/${id}`)}
                                                disabled={!enableVersioningMenuItem}
                                              >
                                                Versions history
                                              </DidomiMenuItem>
                                            </div>
                                          </DidomiTooltip>
                                        </>
                                      )}
                                    </div>
                                  </DidomiMenu>
                                </div>
                              )}
                            </div>
                          </DidomiTableTd>
                        )}
                      </DidomiTableRow>
                    );
                  })}
            </DidomiTableBody>
          </DidomiTable>
          <DidomiPaginator
            data-testid="widget-paginator"
            className="self-end mb-s"
            page={currPage}
            itemCount={widgetsCount}
            size={limit}
            onPageSizeChange={setLimit}
            onPageChange={setCurrPage}
          />
        </>
      )}

      {noWidgets && error && (
        <div className="h-full">
          <GenericEmptyState illustration="list-cannot-be-loaded" className="h-full border-1 border-dashed border-neutral-gray-5 rounded-lg flex-1" />
        </div>
      )}
      {noWidgets && !loading && !error && (
        <div className="h-full">
          <GenericEmptyState
            title="You have no widget yet"
            illustration="no-widget-yet"
            className="border-1 border-dashed border-neutral-gray-5 rounded-lg py-8"
            ctaText="Create a widget"
            onCtaClick={() => push(`/widgets/create`)}
            ctaIcon={{
              position: 'right',
              icon: 'new-create',
            }}
          />
        </div>
      )}
      <ArchiveWidgetModal
        isOpen={!!archivingCandidate && !deletingWidget}
        onCancel={() => setArchivingCandidate(null)}
        onProceed={confirmWidgetArchiving}
        publicationStatus={archivingCandidate?.publication_status}
      />
      <LoadingModal isOpen={deletingWidget} title={archivingCandidate?.publication_status === WidgetPublicationStatus.DRAFT ? 'Deleting widget...' : 'Archiving widget...'} />
    </div>
  );
};

export { WidgetTable };
