import { Model, hasMany, belongsTo } from 'miragejs';

const selectedLayoutComponent = Model.extend({
  layout_component: belongsTo('layoutComponent'),
  layout: belongsTo('layout'),
});

const layout = Model.extend({
  selected_layout_components: hasMany('selectedLayoutComponent'),
});

export default { selectedLayoutComponent, layout };
