import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { DidomiRightPanel, DidomiRightPanelTab } from '@didomi/ui-atoms-react';
import { useIsScrollVisible } from '@didomi/helpers-react';
import {
  ContentEditorHeaderForm,
  ContentEditorFooterForm,
  ContentEditorSaveForm,
  ContentEditorSectionForm,
  ContentEditorPreferenceForm,
  ContentEditorMetaTagsForm,
} from '@components';
import { ContentEditorFormData, ContentEditorForm as ContentEditorFormProps } from '@types';
import { ComponentType } from '@didomi/pmp-generator';
import { useReleaseFlag } from '@didomi/utility-react';
import { CustomComponentType } from '@enums';

import { EditWidgetContentEditorTranslations } from './components/EditWidgetContentEditorTranslations';
import { EditWidgetContentEditorActiveEditorLanguageSelect } from './components/EditWidgetContentEditorActiveEditorLanguageSelect';
import { useTranslationContext, LanguageForm } from '@didomi/widgets-utilities';

interface EditWidgetContentEditorProps {
  componentId: string;
  selectedComponentType?: string;
  selectedComponentData?: ContentEditorFormData;
  setComponentData?: ContentEditorFormProps<ContentEditorFormData>['setData'];
  metadata?: any;
  hideSectionFields?: boolean;
  isLoading?: boolean;
}

const TypeToComponentMap: { [key: string]: React.ComponentType<ContentEditorFormProps<ContentEditorFormData>> } = {
  [ComponentType.HEADER]: ContentEditorHeaderForm,
  [ComponentType.FOOTER]: ContentEditorFooterForm,
  [ComponentType.SAVE]: ContentEditorSaveForm,
  [ComponentType.SECTION]: ContentEditorSectionForm,
  [ComponentType.PREFERENCE]: ContentEditorPreferenceForm,
  [CustomComponentType.META_TAGS]: ContentEditorMetaTagsForm,
};

/**
 * Edit Widget Content Editor
 */
const EditWidgetContentEditor = ({
  componentId,
  selectedComponentType,
  selectedComponentData,
  metadata,
  setComponentData,
  hideSectionFields = false,
  isLoading = false,
}: EditWidgetContentEditorProps) => {
  const [activeTab, setActiveTab] = useState(0);
  const [hasLanguagesFeature] = useReleaseFlag('widget_translation_management');
  const { selectedMissingTranslation } = useTranslationContext();
  const contentRef = useRef<HTMLDivElement>(null);
  const isScrollVisible = useIsScrollVisible(contentRef.current);
  const ComponentForm = selectedComponentType ? TypeToComponentMap[selectedComponentType] : ContentEditorHeaderForm;

  useEffect(() => {
    if (selectedMissingTranslation) {
      setActiveTab(0);
    }
  }, [selectedMissingTranslation]);

  useLayoutEffect(() => {
    if (!isScrollVisible || !contentRef.current?.scrollIntoView) {
      return;
    }

    setTimeout(() => contentRef.current.scrollIntoView(true), 0);
  }, [componentId, isScrollVisible]);

  const headers = [
    { name: 'Content edition', slotName: 'content-editor' },
    { name: 'Languages', slotName: 'languages' },
  ];

  return (
    <div className="h-full w-[430px]">
      <div className="hidden" id="content-editor-header-tooltip">
        <p className="text-body-small font-normal">
          The content editor allows you to manage <strong>the content of your widget</strong>. Titles, texts, preferences, purposes, brand logo, icons...
        </p>
      </div>
      {hasLanguagesFeature && (
        <div className="flex flex-col h-full">
          <EditWidgetContentEditorTranslations />
          <DidomiRightPanelTab className="pb-6 h-full" headers={headers} activeTab={activeTab} onActiveTabChange={event => setActiveTab(event.detail.active)}>
            <div slot="content-editor" className="h-full px-6 mt-7">
              <div ref={contentRef} data-testid="content-editor-content" className={`flex flex-col h-full`}>
                <EditWidgetContentEditorActiveEditorLanguageSelect isLoading={isLoading} />

                <ComponentForm key={componentId} metadata={metadata} data={selectedComponentData} setData={setComponentData} hideSectionFields={hideSectionFields} />
              </div>
            </div>

            <div slot="languages" className="h-full px-6 mt-7">
              <LanguageForm dataTestId={'content-editor-languages'} />
            </div>
          </DidomiRightPanelTab>
        </div>
      )}

      {!hasLanguagesFeature && (
        <DidomiRightPanel
          icon="helper-text"
          iconTooltipContentElementId="content-editor-header-tooltip"
          titleText="Content editor"
          showCreateButton={false}
          className="h-full"
          data-testid="content-editor-right-panel"
        >
          <div slot="content" className="h-full">
            <div ref={contentRef} data-testid="content-editor-content" className={`flex flex-col h-full ${!isScrollVisible ? ' pr-[6px]' : ''}`}>
              <ComponentForm key={componentId} metadata={metadata} data={selectedComponentData} setData={setComponentData} hideSectionFields={hideSectionFields} />
            </div>
          </div>
        </DidomiRightPanel>
      )}
    </div>
  );
};

export { EditWidgetContentEditor };
