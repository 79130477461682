import React, { useRef } from 'react';
import { DidomiHintbox, DidomiLargePush } from '@didomi/ui-atoms-react';
import { CollapsibleCard } from '@components';
import { useIsScrollVisible } from '@didomi/helpers-react';

const EditWidgetAdvancedSection = () => {
  const contentRef = useRef<HTMLDivElement>(null);
  const isScrollVisible = useIsScrollVisible(contentRef.current);

  return (
    <div ref={contentRef} data-testid="edit-widget-advanced-section" className={`flex flex-col h-full ${!isScrollVisible ? ' pr-[6px]' : ''}`}>
      <DidomiHintbox className="flex" titleText="Coming soon!" headerIconName="speaker-half" contentAlign="start" variant="info">
        <p>The full customization of your single purpose Widget will be available soon.</p>
        <p className="font-semibold">
          In the meantime, your Widget will automatically inherit the font-family of the website in which it’s integrated. It is editable through our APIs.
        </p>
      </DidomiHintbox>

      <DidomiLargePush
        className="mt-6"
        text="Developer documentation"
        highlighted-text="How to edit the Look & Feel of a widget?"
        icon="export"
        href="https://developers.didomi.io/api/widgets/privacy-widgets/edit-a-widget/content-and-design/components"
        target="_blank"
      ></DidomiLargePush>

      <CollapsibleCard contentId="widget-layout" variant="secondary" data-testid="widget-layout" title="Widget Layout" initialCollapsed disabled></CollapsibleCard>
      <div className="border-t border-solid border-neutral-gray-3 mt-6"></div>
      <CollapsibleCard contentId="text-style" variant="secondary" data-testid="text-style" title="Text style" initialCollapsed disabled></CollapsibleCard>
      <div className="border-t border-solid border-neutral-gray-3 mt-6"></div>
      <CollapsibleCard contentId="button-layout" variant="secondary" data-testid="button-layout" title="Button layout" initialCollapsed disabled></CollapsibleCard>
      <div className="border-t border-solid border-neutral-gray-3 mt-6"></div>
    </div>
  );
};

export default EditWidgetAdvancedSection;
