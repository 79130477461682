import { Factory } from 'miragejs';
import { devOrganizationId } from '@constants';
import { generateRandomId } from '@utils';
import { WidgetFormat, WidgetUsage, WidgetTemplateId } from '@enums';

const archivedWidget = Factory.extend({
  id() {
    return generateRandomId('archive-widget');
  },
  name(i) {
    return `Archived widget ${i}`;
  },
  organization_id: devOrganizationId,
  updated_at: new Date().toISOString(),
  created_at: new Date().toISOString(),
  format: WidgetFormat.EMBEDDABLE,
  template_id: WidgetTemplateId.SECTIONS,
  usage: WidgetUsage.PMP,
  last_domain_used: 'last-domain-used',
});

export default { archivedWidget };
