import { devOrganizationId } from '@constants';
import { ComponentType } from '@didomi/pmp-generator';

export const createDefaultLayoutComponents = (server, defaultOrganization = devOrganizationId, widgetId = 'widget-1') => {
  server.create('layoutComponent', {
    id: `${widgetId}_header_1`,
    created_at: '2021-12-17T11:29:11.399Z',
    updated_at: '2021-12-17T11:29:11.399Z',
    createdAt: '2021-12-17T11:29:11.399Z',
    updatedAt: '2021-12-17T11:29:11.399Z',
    type: ComponentType.HEADER,
    options: {},
    widget_id: widgetId,
    organization_id: defaultOrganization,
  });
  server.create('layoutComponent', {
    id: `${widgetId}_block_1`,
    created_at: '2021-12-17T11:29:11.399Z',
    updated_at: '2021-12-17T11:29:11.399Z',
    type: ComponentType.BLOCK,
    options: {
      style: {
        md: {
          maxWidth: '550px',
        },
        sm: {
          maxWidth: '470px',
        },
        default: {
          gap: '24px 0',
          width: '780px',
          display: 'flex',
          marginLeft: 'auto',
          marginRight: 'auto',
          flexDirection: 'column',
        },
      },
    },
    widget_id: widgetId,
    organization_id: defaultOrganization,
  });
  server.create('layoutComponent', {
    id: `${widgetId}_sections_1`,
    created_at: '2021-12-17T11:29:11.399Z',
    updated_at: '2021-12-17T11:29:11.399Z',
    type: ComponentType.SECTIONS,
    options: {},
    widget_id: widgetId,
    organization_id: defaultOrganization,
  });
  server.create('layoutComponent', {
    id: `${widgetId}_save_1`,
    created_at: '2021-12-17T11:29:11.399Z',
    updated_at: '2021-12-17T11:29:11.399Z',
    type: ComponentType.SAVE,
    options: {},
    widget_id: widgetId,
    organization_id: defaultOrganization,
  });
  server.create('layoutComponent', {
    id: `${widgetId}_footer_1`,
    created_at: '2021-12-17T11:29:11.399Z',
    updated_at: '2021-12-17T11:29:11.399Z',
    type: ComponentType.FOOTER,
    options: {},
    widget_id: widgetId,
    organization_id: defaultOrganization,
  });
  server.create('layoutComponent', {
    id: `${widgetId}_section_1`,
    created_at: '2021-12-17T11:29:11.399Z',
    updated_at: '2021-12-17T11:29:11.399Z',
    type: ComponentType.SECTION,
    options: {},
    widget_id: widgetId,
    organization_id: defaultOrganization,
  });
  server.create('layoutComponent', {
    id: `${widgetId}_preference_1`,
    created_at: '2021-12-17T11:29:11.399Z',
    updated_at: '2021-12-17T11:29:11.399Z',
    type: ComponentType.PREFERENCE,
    options: {},
    widget_id: widgetId,
    organization_id: defaultOrganization,
  });
  server.create('layoutComponent', {
    id: `${widgetId}_preference_value_1`,
    created_at: '2021-12-17T11:29:11.399Z',
    updated_at: '2021-12-17T11:29:11.399Z',
    type: ComponentType.PREFERENCE_VALUE,
    options: {
      label: {
        content: {
          en: 'Edited Preference Value title',
        },
      },
    },
    widget_id: widgetId,
    organization_id: defaultOrganization,
  });
  server.create('layoutComponent', {
    id: `${widgetId}_preference_value_2`,
    created_at: '2021-12-17T11:29:11.399Z',
    updated_at: '2021-12-17T11:29:11.399Z',
    type: ComponentType.PREFERENCE_VALUE,
    options: {
      label: {
        content: {
          en: 'Edited Preference Value title',
        },
      },
    },
    widget_id: widgetId,
    organization_id: defaultOrganization,
  });
  server.create('layoutComponent', {
    id: `${widgetId}_preference_2`,
    created_at: '2021-12-17T11:29:11.399Z',
    updated_at: '2021-12-17T11:29:11.399Z',
    type: ComponentType.PREFERENCE,
    options: {},
    widget_id: widgetId,
    organization_id: defaultOrganization,
  });
};
