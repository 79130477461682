import React from 'react';
import { DidomiIconButton, DidomiMenu, DidomiMenuItem, DidomiTooltip } from '@didomi/ui-atoms-react';

type PreferenceCardOptionsProps = {
  onPreview?: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
  onCopy?: () => void;
  showEdit?: boolean;
  showDelete?: boolean;
  showCopy?: boolean;
};

/**
 * Preference card options component
 */
const PreferenceCardOptions = ({ onPreview, onEdit, onDelete, onCopy, showEdit = true, showDelete = true, showCopy = true }: PreferenceCardOptionsProps): JSX.Element => {
  return (
    <>
      {(onPreview || onEdit || onDelete || onCopy) && (
        <DidomiTooltip placement="top" distance="xxxs" content="Options" className="pmp-mr-1">
          <DidomiIconButton data-testid="preference-options" title="Preference options" variant="rounded" icon="submenu">
            <>
              <DidomiMenu placement="bottom-end" distance="xxxs" variant="main">
                {onPreview && (
                  <DidomiMenuItem data-tracking="pmp-preview-preference-from-pl" iconName="preview" onItemSelected={onPreview}>
                    Preview
                  </DidomiMenuItem>
                )}
                {onEdit && showEdit && (
                  <DidomiMenuItem data-tracking="pmp-start-edit-preference" iconName="edit" onItemSelected={onEdit}>
                    Edit
                  </DidomiMenuItem>
                )}
                {onDelete && showDelete && (
                  <DidomiMenuItem data-tracking="pmp-start-delete-preference-from-pl" iconName="delete" onItemSelected={onDelete}>
                    Delete
                  </DidomiMenuItem>
                )}
                {onCopy && showCopy && (
                  <DidomiMenuItem iconName="copy" onItemSelected={onCopy}>
                    Duplicate
                  </DidomiMenuItem>
                )}
              </DidomiMenu>
            </>
          </DidomiIconButton>
        </DidomiTooltip>
      )}
    </>
  );
};

export { PreferenceCardOptions };
