/**
 * This file contains the the custom functions for dnd-kit.
 */
import { CollisionDetection, ClientRect, CollisionDescriptor } from '@dnd-kit/core';

const distanceBetween = (p1: ClientRect, p2: ClientRect) => {
  return Math.sqrt(Math.pow(p1.top - p2.top, 2) + Math.pow(p1.left - p2.left, 2));
};

const isOnDraggableZone = (collisionRect: ClientRect, rect: ClientRect) => {
  return collisionRect.left < rect.left + rect.width;
};

/**
 * The CustomConfigurationTreeCollision implementation is based on the top left corner distance compare.
 * As the configuration tree droppable containers are hierarchical aligned, the top left corner moves to the right
 * for each hierarchical level. This cause a issue for calculating the distance when the user moves the mouse horizontally.
 * For fixing this, on the calculation, all the droppable containers are positioned on left 0 position.
 * @returns CollisionDescriptor ordered by distance.
 */
export const CustomConfigurationTreeCollision: CollisionDetection = ({ droppableContainers, collisionRect, droppableRects }) => {
  const collisions: CollisionDescriptor[] = [];

  for (const droppableContainer of droppableContainers) {
    const { id } = droppableContainer;
    const rect = droppableRects.get(id);

    if (rect && isOnDraggableZone(collisionRect, rect)) {
      const distBetween = distanceBetween(
        {
          ...rect,
          left: 0,
        },
        collisionRect,
      );

      collisions.push({ id, data: { droppableContainer, value: distBetween } });
    }
  }

  return collisions.sort((a, b) => a.data.value - b.data.value);
};
