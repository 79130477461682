import React from 'react';
import { DidomiSkeleton, DidomiTooltip } from '@didomi/ui-atoms-react';
import { Language } from '@didomi/pmp-generator';
import { useTranslationContext, LanguageSelect } from '@didomi/widgets-utilities';
import { createFadeInAnimation } from '@constants';
import { tw } from '@twind/core';

const FADE_IN_ANIMATION = createFadeInAnimation();

interface EditWidgetContentEditorActiveEditorLanguageSelectProps {
  isLoading?: boolean;
}

export const EditWidgetContentEditorActiveEditorLanguageSelect = ({ isLoading = false }: EditWidgetContentEditorActiveEditorLanguageSelectProps) => {
  const { activeEditorLanguage, setActiveEditorLanguage, enabledLanguages, isLoading: isLoadingTranslations, missingTranslations } = useTranslationContext();
  const isActiveEditorLanguageDisabled = enabledLanguages?.length === 1;
  const showLoadingState = isLoadingTranslations || isLoading;
  const untranslatedLanguages = Object.keys(missingTranslations || {}) as Language[];

  const languageSelect = (
    <div className="mt-xs mb-6">
      <LanguageSelect
        key={untranslatedLanguages.length}
        className={tw(FADE_IN_ANIMATION.toString())}
        dropdownSize="xs"
        value={activeEditorLanguage}
        onValueChange={language => language && setActiveEditorLanguage(language as Language)}
        dataTestId="active-editor-language-select"
        availableValues={enabledLanguages}
        missingTranslations={untranslatedLanguages}
        disabled={isActiveEditorLanguageDisabled}
      />
    </div>
  );

  return (
    <div className="w-2/4 ml-auto">
      {showLoadingState && <DidomiSkeleton className="w-full h-[30px] rounded-[4px] mt-xs" isLoading data-testid="active-editor-language-select-skeleton" />}

      {!showLoadingState &&
        (isActiveEditorLanguageDisabled ? (
          <DidomiTooltip hideOnScroll placement="top" content="Add a second language first">
            <div data-testid="active-editor-language-tooltip-wrapper">{languageSelect}</div>
          </DidomiTooltip>
        ) : (
          languageSelect
        ))}
    </div>
  );
};
