import { devOrganizationId } from '@constants';

export const createDefaultConfigTree = (server, defaultOrganization = devOrganizationId) => {
  const CCommunicationType = server.create('selectedPreference', {
    created_at: '2021-10-19T15:28:46.493Z',
    updated_at: '2021-10-20T15:18:16.293Z',
    id: 'selected-preference-asd4oh2cw',
    order: 1,
    preference_id: 'preference-bvfw443ds',
    parent_id: 'selected-preference-cf34osd11',
    parent_preference_value_id: 'preference-value-rtca7haj1',
  });

  const CCFrequency = server.create('selectedPreference', {
    created_at: '2021-10-19T15:28:46.493Z',
    updated_at: '2021-10-20T15:18:16.293Z',
    id: 'selected-preference-6fv4oh2cw',
    order: 24,
    preference_id: 'preference-sdff43fd1',
    parent_id: 'selected-preference-cf34osd11',
    parent_preference_value_id: 'preference-value-rtca7haj1',
  });

  const CommunicationChannel = server.create('selectedPreference', {
    created_at: '2021-10-19T15:28:46.493Z',
    updated_at: '2021-10-20T15:18:16.293Z',
    id: 'selected-preference-cf34osd11',
    order: 0,
    preference_id: 'preference-hdfy2vfd1',
    parent_id: 'selected-purpose-evca43as1',
    parent_selected_purpose_id: 'selected-purpose-evca43as1',
    selected_preferences: [CCFrequency, CCommunicationType],
  });

  const CCPurpose = server.create('selectedPurpose', {
    created_at: '2021-10-19T15:28:46.493Z',
    updated_at: '2021-10-20T15:18:16.293Z',
    id: 'selected-purpose-evca43as1',
    configuration_tree_id: 'conf-tree-1',
    order: 0,
    purpose_id: 'purpose-cv3fw4df5s',
    selected_preferences: [CommunicationChannel],
  });

  const CBPurpose = server.create('selectedPurpose', {
    created_at: '2021-10-19T15:28:46.493Z',
    updated_at: '2021-10-20T15:18:16.293Z',
    id: 'selected-purpose-cd3fa4ga41',
    configuration_tree_id: 'conf-tree-1',
    order: 1,
    purpose_id: 'purpose-bvdfw43f1z',
    selected_preferences: [],
  });

  server.create('configTree', {
    id: 'conf-tree-1',
    organization_id: defaultOrganization,
    selected_purposes: [CCPurpose, CBPurpose],
    created_at: '2021-10-19T15:28:46.493Z',
    updated_at: '2021-10-20T15:18:16.293Z',
  });
};

export const createEmptyConfigTree = (server, defaultOrganization = devOrganizationId) => {
  server.create('configTree', {
    id: 'conf-tree-1',
    organization_id: defaultOrganization,
    selected_purposes: [],
    created_at: '2021-10-19T15:28:46.493Z',
    updated_at: '2021-10-20T15:18:16.293Z',
  });
};
