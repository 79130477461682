import { formatPaginationResponse } from '../utils';

export const widgetThemesRoutes = {
  '/widgets/themes/:id': {
    patch: (schema: any, request) => {
      const widgetTheme = schema.widgetThemes.find(request.params?.id);
      widgetTheme.update(JSON.parse(request.requestBody));

      return widgetTheme.attrs;
    },
  },
  '/widgets/themes': {
    get: (schema: any, request) => {
      const organizationId = request.queryParams?.organization_id;
      const filteredThemes = schema.widgetThemes
        .where({
          organization_id: organizationId,
        })
        .models.map(({ attrs }) => attrs);

      return formatPaginationResponse(filteredThemes, request);
    },
  },
};
