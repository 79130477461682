import { createServer } from 'miragejs';
import { createDefaultWidgets, createDefaultLayoutEntities, createDefaultDomains, createDefaultArchivedWidgets } from './seeds';
import { setupRoutes } from './routes';
import PreferenceModel from './preferences/model';
import PreferenceFactory from './preferences/factory';
import DomainModel from './domains/model';
import PurposeModel from './purposes/model';
import PurposeFactory from './purposes/factory';
import ConfigTreeModel from './configTree/model';
import ConfigTreeFactory from './configTree/factory';
import WidgetModel from './widgets/model';
import WidgetFactory from './widgets/factory';
import WidgetDeploymentModel from './widgetsDeployments/model';
import WidgetDeploymentFactory from './widgetsDeployments/factory';
import ArchivedWidgetModel from './archivedWidgets/model';
import ArchivedWidgetFactory from './archivedWidgets/factory';
import LayoutComponentModel from './layoutComponents/model';
import LayoutEntityModel from './layoutEntities/model';
import LayoutModel from './layouts/model';
import WidgetThemeModel from './widgetThemes/model';
import WidgetThemeFactory from './widgetThemes/factory';
import WidgetMetaTagsModel from './widgets/meta-tags/model';
import WidgetMetaTagsFactory from './widgets/meta-tags/factory';

type ServerProps = {
  environment?: 'development' | 'test';
  defaultOrganization?: string;
};

const MODELS = {
  ...PreferenceModel,
  ...DomainModel,
  ...PurposeModel,
  ...ConfigTreeModel,
  ...WidgetModel,
  ...WidgetDeploymentModel,
  ...ArchivedWidgetModel,
  ...LayoutComponentModel,
  ...LayoutEntityModel,
  ...LayoutModel,
  ...WidgetThemeModel,
  ...WidgetMetaTagsModel,
};

const FACTORIES = {
  ...PreferenceFactory,
  ...PurposeFactory,
  ...ConfigTreeFactory,
  ...WidgetFactory,
  ...WidgetDeploymentFactory,
  ...ArchivedWidgetFactory,
  ...WidgetThemeFactory,
  ...WidgetMetaTagsFactory,
};

export function makeServer({ environment, defaultOrganization }: ServerProps = { environment: 'development', defaultOrganization: 'didomi' }) {
  return createServer({
    environment,
    models: MODELS,
    factories: FACTORIES,

    routes() {
      //@ts-ignore
      this.urlPrefix = CONFIG.apiUrl;

      setupRoutes(this);

      this.passthrough('https://api-staging.didomi.io/*');
      this.passthrough('https://didomi-staging.eu.auth0.com/*');
      this.passthrough('https://uilib.didomi.io/*');
      this.passthrough('https://*--uilib-didomi.netlify.app/*');
      this.passthrough('https://api.privacy-center.org/v1/*');
      this.passthrough(request => {
        const uiLibBranchPattern = new RegExp(/https:\/\/(.*?)--uilib-didomi.netlify.app\/(.*?)/);
        if (uiLibBranchPattern.test(request.url)) {
          return true;
        }
      });
    },

    seeds(server) {
      createDefaultWidgets(server, defaultOrganization);
      createDefaultLayoutEntities(server, defaultOrganization);
      createDefaultDomains(server, defaultOrganization);
      createDefaultArchivedWidgets(server, defaultOrganization);
    },
  });
}
