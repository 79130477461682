import React from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import {
  DidomiModal,
  DidomiButton,
  DidomiModalHeader,
  DidomiModalContent,
  DidomiModalActions,
  DidomiIllustration,
  DidomiTextInputField,
  DidomiTextareaField,
} from '@didomi/ui-atoms-react';
import * as Yup from 'yup';

const PreferenceSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
});

type CreatePurposeModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSave: (_input: any, _formikMethods: FormikHelpers<any>) => void;
  onChange: (_input: any) => void;
};

export const CreatePurposeModal = ({ isOpen, onSave, onChange, onClose }: CreatePurposeModalProps) => {
  return (
    <Formik
      initialValues={{
        name: '',
        description: '',
      }}
      validationSchema={PreferenceSchema}
      onSubmit={onSave}
    >
      {({ resetForm, submitForm }) => (
        <Form className="w-full">
          <DidomiModal initialFocus="input[name='name']" isOpen={isOpen} onClose={onClose} onOpenChange={onChange} data-cy="purpose-modal">
            <DidomiModalHeader modalTitle="Create a purpose">
              <DidomiIllustration name="create-a-purpose" style={{ marginBottom: 'var(--space-m)' }} />
            </DidomiModalHeader>
            <DidomiModalContent className="w-full">
              <DidomiTextInputField name="name" label="Name" placeholder="Type a purpose name" required />
              <DidomiTextareaField name="description" label="Description" placeholder="Type your purpose description here..." required />
              <span
                style={{
                  fontFamily: 'IBM Plex Sans',
                  fontStyle: 'italic',
                  fontWeight: 'normal',
                  fontSize: '12px',
                  lineHeight: '18px',
                  textAlign: 'left',
                }}
              >
                * fields are required
              </span>
            </DidomiModalContent>
            <DidomiModalActions>
              <DidomiButton
                type="button"
                variant="secondary"
                onClick={() => {
                  resetForm();
                  onClose();
                }}
              >
                Cancel
              </DidomiButton>
              {/* TODO: Figure out why type="submit" does not trigger the form submission" */}
              <DidomiButton data-tracking="pmp-save-new-purpose" data-testid="create-purpose-save-button" type="button" data-cy="save-create-purpose-button" onClick={submitForm}>
                Save
              </DidomiButton>
            </DidomiModalActions>
          </DidomiModal>
        </Form>
      )}
    </Formik>
  );
};
