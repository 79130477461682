import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { useAxiosWithLogOut } from '@hooks';
import { apiRoutes } from '@constants';
import { AxiosResponse, AxiosError } from 'axios';
import { useActiveOrganization } from '@didomi/utility-react';
import { Widget } from '@interfaces';
import { WidgetFormat, WidgetLayoutShape, WidgetTemplateId } from '@enums';
import { Language } from '@didomi/pmp-generator';

export type UpdateWidgetPayload = {
  name: string;
  headless: boolean;
  default_language: Language;
  enabled_languages: Language[];
  format?: WidgetFormat;
  layout_shape?: WidgetLayoutShape;
  template_id?: WidgetTemplateId;
  domain?: string;
  subdomain_id?: string;
  meta_tags?: any;
  theme_id?: string;
};

export const useUpdateWidget = (id: string, options?: UseMutationOptions<AxiosResponse<Widget>, AxiosError, UpdateWidgetPayload>) => {
  const { organizationId } = useActiveOrganization();
  const axiosWithInterceptors = useAxiosWithLogOut();
  const queryClient = useQueryClient();

  const patch = (payload: UpdateWidgetPayload) => {
    return axiosWithInterceptors.patch<Widget>(`${apiRoutes.widgets}/${id}`, payload, {
      params: { organization_id: organizationId },
    });
  };

  return useMutation<AxiosResponse<Widget>, AxiosError, UpdateWidgetPayload>(patch, {
    ...options,
    onSuccess: (response: AxiosResponse<Widget>, variables, context) => {
      // this will update all queries/caches that start with 'widgets'
      const dataKey = ['widgets'];

      queryClient.invalidateQueries(dataKey);

      if (options?.onSuccess) {
        options.onSuccess(response, variables, context);
      }
    },
  });
};
