import React from 'react';
import { DidomiModal, DidomiModalHeader, DidomiButton, DidomiIllustration, DidomiModalActions } from '@didomi/ui-atoms-react';
import { WidgetPublicationStatus } from '@enums';

type ArchiveWidgetModalProps = {
  isOpen: boolean;
  onProceed: () => void;
  onCancel: () => void;
  publicationStatus: WidgetPublicationStatus;
};

const DELETE_MESSAGES = {
  title: 'Your widget will be deleted',
  confirm: 'Got it, delete!',
};

const ARCHIVE_MESSAGES = {
  title: `Your widget will be archived`,
  confirm: 'Got it, archive!',
};

const MODAL_MESSAGE_MAPPED_BY_PUBLICATION_STATUS = {
  [WidgetPublicationStatus.DRAFT]: DELETE_MESSAGES,
  [WidgetPublicationStatus.PUBLISHED]: ARCHIVE_MESSAGES,
  [WidgetPublicationStatus.UNPUBLISHED_CHANGES]: ARCHIVE_MESSAGES,
};

export const ArchiveWidgetModal = ({ isOpen, onCancel, onProceed, publicationStatus = WidgetPublicationStatus.DRAFT }: ArchiveWidgetModalProps) => {
  const messages = MODAL_MESSAGE_MAPPED_BY_PUBLICATION_STATUS[publicationStatus];
  return (
    <DidomiModal isOpen={isOpen} onCancel={onCancel} permanent="true">
      <DidomiIllustration name="warning-modal" className="mb-10"></DidomiIllustration>
      <DidomiModalHeader
        style={{ whiteSpace: 'pre' }}
        variant="warning"
        modalTitle={messages.title}
        modalSubTitle="Please be careful"
        modalDescription="You won't be able to edit or publish this widget anymore."
      ></DidomiModalHeader>
      <DidomiModalActions className="pt-5!">
        <DidomiButton variant="secondary" onClick={onCancel} data-cy="cancel-reset">
          Oops, no thanks
        </DidomiButton>
        <DidomiButton onClick={onProceed} data-cy="confirm-reset">
          <div className="w-full">{messages.confirm}</div>
        </DidomiButton>
      </DidomiModalActions>
    </DidomiModal>
  );
};
