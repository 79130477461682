import React from 'react';
import { DidomiTooltip, DidomiIcon } from '@didomi/ui-atoms-react';
import { OverflowWithTooltip } from '@components';

interface EditPreferenceControlTooltipProps {
  id: string;
  title: string;
  values: string[];
  icon: React.ComponentProps<typeof DidomiIcon>['name'];
}

const EditWidgetPreferencesControlTooltip = ({ id, title, values, icon }: EditPreferenceControlTooltipProps): JSX.Element => {
  return (
    <div data-testid={`edit-widget-preference-control-tooltip-${id}`}>
      <div className="hidden" id={`edit-widget-preference-control-tooltip-${id}`}>
        <section className="w-[340px] bg-white rounded-lg border border-solid border-neutral-gray-4">
          <div className="py-5 px-6 flex items-center border-b border-solid border-neutral-gray-4">
            <DidomiIcon name={icon} />
            <OverflowWithTooltip className="w-full flex items-center justify-between text-body-normal font-semibold min-w-0 text-primary-blue-6 ml-2" value={title} />
          </div>
          <ul className="list-none pt-2 ml-6 pb-8 pr-6">
            {values.map(value => (
              <li key={value} className="mt-2.5">
                <OverflowWithTooltip className="text-body-normal min-w-0 text-primary-blue-6" value={value} />
              </li>
            ))}
          </ul>
        </section>
      </div>
      <DidomiTooltip contentElementId={`edit-widget-preference-control-tooltip-${id}`} variant="box" placement="top" hideOnScroll>
        <DidomiIcon data-testid={`edit-widget-preference-control-tooltip-icon-${id}`} className="mr-4 mt-2" name="preview" />
      </DidomiTooltip>
    </div>
  );
};

export default EditWidgetPreferencesControlTooltip;
