/**
 * Removes empty props from the component options.
 *
 * Input = {
 *  title: {
 *    content: {
 *      en: 'English',
 *      es: '',
 *    },
 *  },
 *  description: {
 *    content: {
 *      en: '',
 *    },
 *  },
 * }
 *
 * Output = {
 *  title: {
 *    content: {
 *      en: 'English'
 *    },
 *  },
 * }
 *
 */
export const removeEmptyOptions = (componentOptions: any) => {
  if (componentOptions === null || componentOptions === '' || componentOptions === undefined) {
    return undefined;
  }

  if (typeof componentOptions !== 'object' || Array.isArray(componentOptions)) {
    return componentOptions;
  }

  const objectEntries = Object.entries(componentOptions);

  if (objectEntries.length === 0) {
    return undefined;
  }

  const newEntries = [];

  objectEntries.forEach(([key, value]) => {
    const parsedValue = removeEmptyOptions(value);
    if (parsedValue !== undefined) {
      newEntries.push([key, parsedValue]);
    }
  });

  if (newEntries.length === 0) {
    return undefined;
  }

  return Object.fromEntries(newEntries);
};
