import React, { useRef, useEffect } from 'react';
import { DidomiChip } from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';

interface RadioCardProps {
  id: string;
  selected: string;
  onChange: Function;
  description: string;
  disabled?: boolean;
  illustration: string;
  name: string;
}

const RadioCard = ({ id, name, description, illustration, disabled = false, selected, onChange }: RadioCardProps) => {
  const checked = id === selected;
  const ref = useRef<HTMLButtonElement>();

  useEffect(() => {
    if (ref.current && checked) {
      ref.current.focus();
    }
  }, [checked]);

  return (
    <button
      data-testid="radio-card-container"
      ref={ref}
      onClick={() => !disabled && onChange(id)}
      disabled={disabled}
      className={tx(
        'flex flex-col content-center shadow-sidenav-active-section pt-12 px-6 pb-8 items-center max-w-[303px] w-full min-w-[263px] h-[305px] 2xl:max-w-[392px] 2xl:min-w-[324px] 2xl:h-[369px] rounded-lg relative border-1 border-solid border-primary-pink-2 focus:outline-none focus:ring focus:ring-primary-blue-2',
        {
          'bg-primary-pink-0': checked,
          'bg-white': !checked,
          'hover:bg-white cursor-not-allowed': disabled,
          'hover:bg-primary-pink-0 cursor-pointer': !disabled,
        },
      )}
    >
      {disabled && <DidomiChip data-testid="chip-available-soon" label="Available soon" variation="basic" className="absolute top-4 right-4" />}

      {/* Card Contents */}
      <section className="flex flex-col items-center content-start">
        <img data-testid="image" src={illustration} alt="" className="w-[110px] h-[102px] 2xl:w-[180px] 2xl:h-[166px] mb-4" />

        {/* Name */}
        <span
          data-testid="name"
          className={tx('text-body-big font-semibold text-center mb-2', {
            'text-neutral-gray-0': disabled,
            'text-primary-blue-6': !disabled,
          })}
        >
          {name}
        </span>

        {/* Description */}
        <p
          data-testid="description"
          className={tx('font-extralight text-center text-sm h-[40px]', {
            'text-neutral-gray-0': disabled,
            'text-primary-blue-6': !disabled,
          })}
        >
          {description}
        </p>
      </section>

      {/* Selected Icon */}
      <div
        className={tx('border-1 border-solid p-1 rounded-full mt-2', {
          'border-neutral-gray-4': disabled,
          'border-primary-blue-5': !disabled,
        })}
      >
        <div
          data-testid="selected-icon"
          className={tx('h-3 w-3 rounded-full', {
            'bg-primary-blue-5': checked,
            'bg-none': !checked,
          })}
        />
      </div>
    </button>
  );
};

export { RadioCard };
