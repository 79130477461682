import React, { useState, useMemo, useEffect } from 'react';
import { DidomiBackButtonLink } from '@didomi/ui-atoms-react';
import { useSnackbar, useReleaseFlag } from '@didomi/utility-react';
import { useHistory } from 'react-router-dom';
import { HorizontalStep, HorizontalStepper } from '@components';
import { LoadingModal } from '@modals';
import hostedWidgetImage from '../../assets/illustrations/helpers/embeddable-widget/hosted.svg';
import embeddableWidgetImage from '../../assets/illustrations/helpers/embeddable-widget/embeddable.svg';
import embeddableWidgetDisabledImage from '../../assets/illustrations/helpers/embeddable-widget/embeddable-disabled.svg';
import embeddablePreferenceCenterImage from '../../assets/illustrations/helpers/embeddable-widget/embeddable-preference-center.svg';
import embeddablePreferenceCenterDisabledImage from '../../assets/illustrations/helpers/embeddable-widget/embeddable-preference-center-disabled.svg';
import { CreateWidgetTemplates } from './CreateWidgetTemplates';
import { CreateWidgetSinglePurpose } from './CreateWidgetSinglePurpose';
import { SelectedEntityType, WidgetFormat, WidgetLayoutShape, WidgetTemplateId } from '@enums';
import { useCreateWidget } from '../../hooks/data/widgets/useCreateWidget.hook';

const LOADING_MODAL_TITLE = {
  [WidgetTemplateId.FULL_PREFERENCE_CENTER]: 'Your Preference Center\nis loading...',
  [WidgetTemplateId.SECTIONS_AND_SAVE]: 'We are creating your\nMulti purpose widget...',
  [WidgetTemplateId.SECTIONS]: 'We are creating your\nSingle purpose widget...',
};

const NEXT_BUTTON_TEXT = {
  [WidgetTemplateId.FULL_PREFERENCE_CENTER]: 'Create a Preference Center',
  [WidgetTemplateId.SECTIONS_AND_SAVE]: 'Create a Multi purpose widget',
  [WidgetTemplateId.SECTIONS]: 'Next step',
};

const CreateWidget = (): JSX.Element => {
  const { push } = useHistory();
  const { displaySnackbar } = useSnackbar();
  const [hasEmbeddableWidgetsFlag] = useReleaseFlag('pmp_embeddable_widgets');
  const [hastEmbeddablePreferenceCenterFlag] = useReleaseFlag('pmp_embeddable_pc');

  const TEMPLATES = useMemo(() => {
    const fullPrefCenterTemplate = {
      id: WidgetTemplateId.FULL_PREFERENCE_CENTER,
      name: 'Preference Center',
      description: 'All preferences in one place, accessible through a dedicated URL',
      illustration: hostedWidgetImage,
      disabled: false,
    };

    const embeddableTemplates = [
      {
        id: WidgetTemplateId.SECTIONS,
        name: 'Single purpose widget',
        description: 'A contextual question to embed in your website',
        illustration: hasEmbeddableWidgetsFlag ? embeddableWidgetImage : embeddableWidgetDisabledImage,
        disabled: !hasEmbeddableWidgetsFlag,
      },
      {
        id: WidgetTemplateId.SECTIONS_AND_SAVE,
        name: 'Multi purpose widget',
        description: 'Several preferences in one place, accessible directly on your webpage',
        illustration: hastEmbeddablePreferenceCenterFlag ? embeddablePreferenceCenterImage : embeddablePreferenceCenterDisabledImage,
        disabled: !hastEmbeddablePreferenceCenterFlag,
      },
    ];

    if (hasEmbeddableWidgetsFlag && hastEmbeddablePreferenceCenterFlag) {
      return [...embeddableTemplates, fullPrefCenterTemplate];
    }

    return [fullPrefCenterTemplate, ...embeddableTemplates];
  }, [hasEmbeddableWidgetsFlag, hastEmbeddablePreferenceCenterFlag]);
  const defaultSelectedTemplateId = TEMPLATES[0].id;

  const [currentStep, setCurrentStep] = useState(0);
  const [selectedTemplateId, setSelectedTemplateId] = useState(defaultSelectedTemplateId);
  const [selectedEntity, setSelectedEntity] = useState({} as { type: SelectedEntityType; id: string });

  const handleSelectedTemplateChange = (value: WidgetTemplateId) => setSelectedTemplateId(value);
  const handleSelectedEntityChange = (type: SelectedEntityType, id: string) => setSelectedEntity({ type, id });

  useEffect(() => {
    setSelectedTemplateId(defaultSelectedTemplateId);
  }, [defaultSelectedTemplateId]);

  const { mutate: createWidget, isLoading: creatingWidget } = useCreateWidget({
    onSuccess: ({ data: widget }) => {
      if (widget.id) {
        push(`/widgets/edit/${widget.id}`);
      }
    },
    onError: () => {
      displaySnackbar('There was an error creating the widget', { icon: 'danger-light', variant: 'error' });
    },
  });

  const handleOnNext = () => {
    switch (selectedTemplateId) {
      case WidgetTemplateId.FULL_PREFERENCE_CENTER:
        handlePostWidget('My Preference Center', WidgetFormat.HOSTED);
        break;
      case WidgetTemplateId.SECTIONS_AND_SAVE:
        handlePostWidget('My Multi Purpose Widget', WidgetFormat.EMBEDDABLE);
        break;
      default:
        setCurrentStep(1);
        break;
    }
  };

  const handleGenerateSinglePurposeWidget = () => {
    const selectedPurposesIds = selectedEntity.type === 'purpose' ? [selectedEntity.id] : null;
    const selectedPreferencesIds = selectedEntity.type === 'preference' ? [selectedEntity.id] : null;
    handlePostWidget('My Single Purpose Widget', WidgetFormat.EMBEDDABLE, selectedPurposesIds, selectedPreferencesIds);
  };

  const handlePostWidget = (name: string, format: WidgetFormat, selectedPurposesIds?: string[], selectedPreferencesIds?: string[]) => {
    createWidget({
      name,
      format,
      layout_shape: WidgetLayoutShape.SMOOTHED,
      template_id: selectedTemplateId,
      selected_purposes_ids: selectedPurposesIds,
      selected_preferences_ids: selectedPreferencesIds,
    });
  };

  return (
    <div className="h-full">
      <div className="relative w-full flex flex-col bg-rainbow bg-fixed min-h-full">
        <div className="sticky top-9 ml-12">
          <DidomiBackButtonLink className="cursor-pointer" onClick={() => push('/widgets')} text={'Back to widgets'} />
        </div>
        <HorizontalStepper activeStep={currentStep} position="sticky">
          <HorizontalStep previousButtonDisplayed={false} nextButtonText={NEXT_BUTTON_TEXT[selectedTemplateId]} nextButtonDisabled={creatingWidget} onNext={handleOnNext}>
            <CreateWidgetTemplates templates={TEMPLATES} selectedTemplateId={selectedTemplateId} onSelectedTemplateChange={handleSelectedTemplateChange} />
          </HorizontalStep>
          <HorizontalStep
            nextButtonDisabled={!selectedEntity.id}
            nextButtonText={'Generate widget'}
            onPrevious={() => setCurrentStep(0)}
            onNext={handleGenerateSinglePurposeWidget}
          >
            <CreateWidgetSinglePurpose selectedEntityId={selectedEntity.id} setSelectedEntity={handleSelectedEntityChange} />
          </HorizontalStep>
        </HorizontalStepper>
        <LoadingModal isOpen={creatingWidget} title={LOADING_MODAL_TITLE[selectedTemplateId]} />
      </div>
    </div>
  );
};

export { CreateWidget };
