import React, { KeyboardEventHandler, SyntheticEvent, useCallback, useRef, useState } from 'react';
import { tx } from '@twind/core';
import { DidomiIcon, DidomiIconButton } from '@didomi/ui-atoms-react';
import { Icons } from '@didomi/ui-foundation';
import { useResizeObserver } from '@didomi/helpers-react';
import { KeyboardValue } from '@enums';
import { OverflowWithTooltip } from '@components';

interface CollapsibleCardProps {
  contentId: string;
  'data-testid'?: string;
  title: string;
  isTitleDisabled?: boolean;
  icon?: Icons;
  variant?: 'primary' | 'secondary' | 'bordered';
  description?: string;
  initialCollapsed?: boolean;
  removeTopMargin?: boolean;
  isCollapsible?: boolean;
  allowToggleOnHeaderClick?: boolean;
  animate?: boolean;
  headerChildren?: React.ReactNode;
  inlineHeaderChildren?: React.ReactNode;
  children?: React.ReactNode;
  headerDisplayDirection?: 'row' | 'column';
  isHeaderSelectable?: boolean;
  isHeaderSelected?: boolean;
  disabled?: boolean;
  titleHighlightText?: string;
  onHeaderClick?: () => void;
}

const ANIMATION_DURATION_THRESHOLD_HEIGHT = 500;

const CollapsibleCard = ({
  contentId,
  'data-testid': dataTestId,
  initialCollapsed,
  title,
  isTitleDisabled,
  description,
  icon,
  headerChildren,
  inlineHeaderChildren,
  children,
  removeTopMargin = false,
  variant = 'primary',
  isCollapsible = true,
  allowToggleOnHeaderClick = true,
  animate = false,
  headerDisplayDirection = 'column',
  isHeaderSelectable,
  isHeaderSelected,
  disabled = false,
  titleHighlightText,
  onHeaderClick = () => {},
}: CollapsibleCardProps) => {
  const isBorderedVariant = variant === 'bordered';
  const [isCollapsed, setIsCollapsed] = useState(!!initialCollapsed);
  const [contentHeight, setContentHeight] = useState(isCollapsed ? 0 : undefined);
  const contentRef = useRef<HTMLDivElement>();
  const contentInnerRef = useRef<HTMLDivElement>();
  const sectionClass = tx({
    'mt-m': !removeTopMargin,
    'opacity-50 pointer-events-none': disabled,
    'border border-solid border-neutral-gray-4 rounded-lg bg-white': isBorderedVariant,
  });
  const isHeaderDisplayRowDirection = headerDisplayDirection === 'row';
  const headerClass = tx({
    'flex justify-between': isHeaderDisplayRowDirection,
  });
  const titleClass = tx('text-body-normal font-semibold truncate w-full pr-2', {
    'text-primary-pink-5': variant === 'primary',
    'text-neutral-gray-6': variant === 'secondary',
    'ml-xxs text-primary-blue-6': isBorderedVariant,
    'opacity-30': isTitleDisabled,
  });
  const contentClass = tx({
    'overflow-hidden': animate,
    'duration-150': animate && contentHeight && contentHeight < ANIMATION_DURATION_THRESHOLD_HEIGHT,
    'duration-500': animate && contentHeight && contentHeight >= ANIMATION_DURATION_THRESHOLD_HEIGHT,
    'mt-xs': !isBorderedVariant,
    'ease-in': animate && isCollapsed,
    'ease-out': animate && !isCollapsed,
  });
  const isHeaderInteractable = allowToggleOnHeaderClick && isCollapsible;

  const selectableHeaderClass = tx('flex justify-between w-full', {
    'cursor-pointer rounded-[8px]': isHeaderSelectable,
    'hover:bg-neutral-gray-1': isHeaderSelectable && !isHeaderSelected,
    'bg-neutral-gray-2': isHeaderSelected,
  });

  const handleResize = useCallback(entries => {
    if (!entries.length) {
      return;
    }
    const target = entries[0].target;

    setContentHeight(target.scrollHeight);
  }, []);

  useResizeObserver(contentInnerRef.current, handleResize);

  const toggleCard = (ev: SyntheticEvent) => {
    if (isCollapsible) {
      ev.stopPropagation();
      setIsCollapsed(!isCollapsed);
    }
  };

  const handleKeyDown: KeyboardEventHandler<HTMLElement> = ev => {
    if (ev.key === KeyboardValue.Enter || ev.key === KeyboardValue.Space) {
      ev.preventDefault();
      setIsCollapsed(!isCollapsed);
    }
  };

  const selectableHeader = (
    <div
      className={tx('overflow-hidden relative flex items-center w-full', {
        'cursor-pointer': isHeaderInteractable,
        'p-xs': isBorderedVariant,
      })}
    >
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div className={selectableHeaderClass} onClick={onHeaderClick} data-testid={`${dataTestId}-selectable-header`}>
        {!!icon && (
          <div className="flex items-center">
            <DidomiIcon data-testid={`icon-${dataTestId}`} name={icon} />
          </div>
        )}
        <OverflowWithTooltip data-testid={`title-${dataTestId}`} value={title} className={titleClass} highlight={titleHighlightText} />
        {inlineHeaderChildren}
        {isCollapsible && (
          <DidomiIconButton
            data-testid={`toggle-${dataTestId}`}
            title={!isCollapsed ? 'Collapse layout' : 'Expand layout'}
            variant="option"
            aria-controls={`${contentId}-content`}
            aria-expanded={!isCollapsed ? 'true' : 'false'}
            icon={!isCollapsed ? 'up-on-sm' : 'down-on-sm'}
            onClick={toggleCard}
          />
        )}
        {headerChildren}
      </div>
    </div>
  );

  const defaultHeader = (
    <>
      <div
        className={tx('overflow-hidden relative flex items-center w-full', {
          'cursor-pointer': isHeaderInteractable,
          'p-xs': isBorderedVariant,
        })}
      >
        {!!icon && (
          <div className="flex items-center">
            <DidomiIcon data-testid={`icon-${dataTestId}`} name={icon} />
          </div>
        )}
        <OverflowWithTooltip data-testid={`title-${dataTestId}`} value={title} className={titleClass} />
        {inlineHeaderChildren}
        {isCollapsible && (
          <DidomiIconButton
            data-testid={`toggle-${dataTestId}`}
            title={!isCollapsed ? 'Collapse layout' : 'Expand layout'}
            variant="option"
            aria-controls={`${contentId}-content`}
            aria-expanded={!isCollapsed ? 'true' : 'false'}
            icon={!isCollapsed ? 'up-on-sm' : 'down-on-sm'}
            onClick={toggleCard}
          />
        )}
      </div>
      {headerChildren}
    </>
  );

  const innerChildren = (
    <section data-testid={dataTestId} className={sectionClass}>
      <header
        role={isHeaderInteractable ? 'button' : undefined}
        data-testid={`header-toggle-${dataTestId}`}
        tabIndex={-1}
        onKeyDown={allowToggleOnHeaderClick ? handleKeyDown : undefined}
        className={headerClass}
        onClick={allowToggleOnHeaderClick ? toggleCard : undefined}
      >
        {isHeaderSelectable ? selectableHeader : defaultHeader}
      </header>
      {(animate || !isCollapsed) && (
        <div
          ref={contentRef}
          data-testid={`content-${dataTestId}`}
          id={`${contentId}-content`}
          className={contentClass}
          style={animate ? { height: isCollapsed ? 0 : contentHeight, transitionProperty: 'height' } : undefined}
        >
          <div ref={contentInnerRef}>
            {description && <p className="text-body-small text-primary-blue-6">{description}</p>}
            {children}
          </div>
        </div>
      )}
    </section>
  );

  if (isBorderedVariant) {
    return <div className="bg-neutral-gray-1 rounded-lg p-xxxs w-full">{innerChildren}</div>;
  }
  return innerChildren;
};

export { CollapsibleCard };
