import React, { useMemo } from 'react';
import { useTranslationContext } from '@didomi/widgets-utilities';
import { DidomiUnresolvedIssues } from '@didomi/ui-atoms-react';

export const EditWidgetContentEditorTranslations = (): JSX.Element => {
  const { missingTranslationsFlat, selectedMissingTranslation, setMissingTranslationDrawerOpen, setSelectedMissingTranslation } = useTranslationContext();

  const amountOfMissingTranslations = missingTranslationsFlat?.length;

  const selectedMissingTranslationIndex = useMemo(() => {
    if (!missingTranslationsFlat || !selectedMissingTranslation) {
      return -1;
    }

    return missingTranslationsFlat.findIndex(
      missingTranslation => missingTranslation.componentId === selectedMissingTranslation.componentId && missingTranslation.language === selectedMissingTranslation.language,
    );
  }, [missingTranslationsFlat, selectedMissingTranslation]);

  const handlePrevious = () => {
    if (selectedMissingTranslationIndex === 0 || selectedMissingTranslationIndex === -1) {
      setSelectedMissingTranslation(missingTranslationsFlat[amountOfMissingTranslations - 1]);
    } else {
      const previousIndex = selectedMissingTranslationIndex - 1;
      setSelectedMissingTranslation(missingTranslationsFlat[previousIndex]);
    }
  };

  const handleNext = () => {
    if (selectedMissingTranslationIndex === amountOfMissingTranslations - 1) {
      setSelectedMissingTranslation(missingTranslationsFlat[0]);
    } else {
      const nextIndex = selectedMissingTranslationIndex + 1;
      setSelectedMissingTranslation(missingTranslationsFlat[nextIndex]);
    }
  };

  return (
    !!amountOfMissingTranslations && (
      <DidomiUnresolvedIssues
        className="mb-4"
        description={`${amountOfMissingTranslations} missing translations`}
        previousTooltip="See previous"
        nextTooltip="See next"
        previewTooltip="See all missing languages"
        onPrevious={handlePrevious}
        onNext={handleNext}
        onPreview={() => setMissingTranslationDrawerOpen(true)}
      />
    )
  );
};
