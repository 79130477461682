import React, { Fragment } from 'react';
import { DidomiPreferenceOptions, DidomiPreferenceValueOptions } from '@didomi/pmp-generator';
import { getTranslatedValue } from '@utils';
import { ContentEditorForm as ContentEditorFormProps, PreferenceAndValuesFormData, PreferenceFormMetadata, PreferenceMetadata, PreferenceValueMetadata } from '@types';
import { ContentEditorSection } from './ContentEditorSection';
import { useContentEditorForm } from '@hooks';
import { CollapsibleCard } from '@components';
import { useTranslationContext } from '@didomi/widgets-utilities';
import { ContentEditorTextarea, ContentEditorTextInput } from './components';
import { ContentEditorPreferenceValueForm } from './ContentEditorPreferenceValueForm';
import { EditWidgetBreadcrumb } from '../EditWidgetBreadcrumb/EditWidgetBreadcrumb';
import { useReleaseFlag } from '@didomi/utility-react';

export function ContentEditorPreferenceForm({ metadata, data, setData }: ContentEditorFormProps<PreferenceAndValuesFormData, PreferenceFormMetadata>) {
  const { componentId, preferenceValues, breadcrumbs, preferenceData } = metadata;
  const { activeEditorLanguage } = useTranslationContext();
  const { getValueAndChangeHandlerFor } = useContentEditorForm<DidomiPreferenceOptions>(data, setData, componentId);
  const currentPreferenceMeta = preferenceData[componentId] as PreferenceMetadata;
  const [hasLanguagesFeature] = useReleaseFlag('widget_translation_management');

  return (
    <>
      <EditWidgetBreadcrumb breadcrumbs={breadcrumbs} className="mb-xxs" />
      <ContentEditorSection data-testid="preference-value-heading" icon="preference" title={getTranslatedValue(currentPreferenceMeta.preference.name, '', activeEditorLanguage)}>
        <CollapsibleCard contentId="content-editor-preference-content" title="Preference Content" removeTopMargin>
          <ContentEditorTextInput
            data-testid="preference-title"
            id={metadata.entityId}
            label="Title"
            placeholder={getTranslatedValue(currentPreferenceMeta.preference.name, 'Preference Title', activeEditorLanguage)}
            hideErrorMessage={!hasLanguagesFeature || !!currentPreferenceMeta.preference.name?.[activeEditorLanguage]}
            {...getValueAndChangeHandlerFor(`${componentId}.title`)}
          />
          <ContentEditorTextarea
            data-testid="preference-description"
            label="Description"
            placeholder={getTranslatedValue(currentPreferenceMeta.preference.description, 'You can add a preference description here...', activeEditorLanguage)}
            {...getValueAndChangeHandlerFor(`${componentId}.description`)}
          />
        </CollapsibleCard>
        {!!preferenceValues?.length && (
          <CollapsibleCard contentId="content-editor-preference-values" title="Preference Values">
            {preferenceValues.map((value, index) => {
              const currentPreferenceMetaValue = preferenceData[value.componentId] as PreferenceValueMetadata;
              const isLastPreferenceValue = index === preferenceValues.length - 1;

              return (
                <Fragment key={value.entityId}>
                  <ContentEditorPreferenceValueForm
                    componentId={value.componentId}
                    data={data as Record<string, DidomiPreferenceValueOptions>}
                    metadata={currentPreferenceMetaValue}
                    setData={setData}
                    hasBottomMargin={index !== preferenceValues.length - 1}
                  />
                  {!isLastPreferenceValue && <div className="my-m border-t border-dashed border-neutral-gray-5" />}
                </Fragment>
              );
            })}
          </CollapsibleCard>
        )}
      </ContentEditorSection>
    </>
  );
}
