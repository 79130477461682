import React from 'react';
import { DidomiIllustration } from '@didomi/ui-atoms-react';
import { Preference } from '@interfaces';
import { substrIncludes, getTranslatedValue } from '../../utils';
import { DraggablePreferenceCard } from '../DraggablePreferenceCard/DraggablePreferenceCard';
import { PreferenceCardProps } from '../PreferenceCard/PreferenceCard';

interface PreferencesListProps extends Omit<PreferenceCardProps, 'name' | 'values' | 'onCopy'> {
  highlight?: string;
  preferences: Array<Preference>;
  onCopy: (preference: Preference) => void;
  canEditConfigurationTree?: boolean;
  disablePreferences?: boolean;
}

const PreferencesList = ({ highlight, preferences, onCopy, canEditConfigurationTree, disablePreferences, ...props }: PreferencesListProps) => {
  const filteredPreferences = React.useMemo(() => {
    if (highlight) {
      return preferences.filter(preference => {
        return substrIncludes(getTranslatedValue(preference.name), highlight);
      });
    }
    return preferences;
  }, [preferences, highlight]);
  return filteredPreferences.length > 0 ? (
    <>
      {filteredPreferences.map((p, i) => (
        <div key={'preference-' + i} className="mb-xxxs">
          <DraggablePreferenceCard
            id={'preference-' + i}
            data-testid={'preference-' + i}
            preferenceId={p.id}
            highlight={highlight}
            name={getTranslatedValue(p.name)}
            description={getTranslatedValue(p.description)}
            type={p.type}
            values={p.values.map(value => ({ ...value, name: getTranslatedValue(value.name) }))}
            onCopy={() => onCopy(p)}
            canEditConfigurationTree={canEditConfigurationTree}
            disabled={disablePreferences}
            {...props}
          />
        </div>
      ))}
    </>
  ) : (
    <div
      data-testid="preference-list-no-match-found"
      className="h-full w-full flex-1 flex flex-col items-center justify-center h-full border-1 border-dashed border-neutral-gray-5 rounded-lg"
    >
      <div className="font-sans text-body-big font-semibold text-primary-blue-4 mb-1">Sorry,</div>
      <h3 className="font-serif text-xl text-primary-blue-6 mb-4">No match found</h3>
      <DidomiIllustration name="traces-no-match-found" />
    </div>
  );
};

export { PreferencesList };
