import React from 'react';
import { CollapsibleCard } from '@components';
import { DidomiSectionOptions } from '@didomi/pmp-generator';
import { useContentEditorForm } from '@hooks';
import { getTranslatedValue } from '@utils';
import { ContentEditorFileInput, ContentEditorTextarea, ContentEditorTextInput } from './components';
import { ContentEditorSection } from './ContentEditorSection';
import { ContentEditorForm as ContentEditorFormProps, SectionFormMetadata } from '@types';
import { useTranslationContext } from '@didomi/widgets-utilities';
import { useReleaseFlag } from '@didomi/utility-react';

export function ContentEditorSectionForm({ metadata, data, setData, hideSectionFields }: ContentEditorFormProps<DidomiSectionOptions, SectionFormMetadata>) {
  const { activeEditorLanguage } = useTranslationContext();
  const isEnabled = metadata.enabled !== false;
  const { getValueAndChangeHandlerFor } = useContentEditorForm<DidomiSectionOptions>(data, setData);
  const { purpose } = metadata;
  const [hasLanguagesFeature] = useReleaseFlag('widget_translation_management');

  return (
    <ContentEditorSection data-testid="content-editor-section" title={getTranslatedValue(purpose?.description, '', activeEditorLanguage)} icon="purpose">
      {!hideSectionFields && (
        <>
          <ContentEditorTextInput data-testid="section-title" label="Section title" placeholder="You can add a section title here..." {...getValueAndChangeHandlerFor('title')} />
          <ContentEditorFileInput
            data-testid="section-file-icon"
            label="Card Icon"
            description="80x80 pixels maximum"
            placeholder="Enter a hosted icon image url"
            {...getValueAndChangeHandlerFor('logo')}
          />
        </>
      )}
      {isEnabled && (
        <>
          <CollapsibleCard data-testid="content-editor-purpose-content" contentId="content-editor-purpose-content" title="Purpose Content">
            <ContentEditorTextInput
              data-testid="purpose-title"
              id={purpose?.id}
              label="Title"
              hideErrorMessage={!hasLanguagesFeature || !!purpose?.description?.[activeEditorLanguage]}
              placeholder={getTranslatedValue(purpose?.description, 'Purpose title', activeEditorLanguage)}
              {...getValueAndChangeHandlerFor('purposeOptions.title')}
            />
            <ContentEditorTextarea
              label="Description"
              placeholder={getTranslatedValue(purpose?.details, 'You can add a purpose description here...', activeEditorLanguage)}
              {...getValueAndChangeHandlerFor('purposeOptions.description')}
            />
          </CollapsibleCard>
          <CollapsibleCard data-testid="content-editor-purpose-values" contentId="content-editor-purpose-values" title="Purpose Values">
            <ContentEditorTextInput label="I accept" placeholder="Yes" {...getValueAndChangeHandlerFor('purposeOptions.yesLabel')} />
            <ContentEditorFileInput label="Value icon" description="15x15 pixels maximum" placeholder="https://www..." {...getValueAndChangeHandlerFor('purposeOptions.yesIcon')} />
            <div className="my-m border-t border-dashed border-neutral-gray-5" />
            <ContentEditorTextInput label="I Refuse" placeholder="No" {...getValueAndChangeHandlerFor('purposeOptions.noLabel')} />
            <ContentEditorFileInput label="Value icon" description="15x15 pixels maximum" placeholder="https://www..." {...getValueAndChangeHandlerFor('purposeOptions.noIcon')} />
          </CollapsibleCard>
        </>
      )}
    </ContentEditorSection>
  );
}
