import { devOrganizationId } from '@constants';

export function createDefaultWidgetDeployments(server, defaultOrganization = devOrganizationId, widgetId = 'widget-archived-1') {
  server.create('widgetDeployment', {
    id: `${widgetId}-widget-deployment-1`,
    config: { components: { list: { _1bac: { id: '1bac' }, abc: { id: 'abc' } } } },
    organization_id: defaultOrganization,
    widget_id: widgetId,
    created_at: '2021-10-19T15:28:46.493Z',
    updated_at: '2021-10-20T15:18:16.293Z',
  });
  server.create('widgetDeployment', {
    id: `${widgetId}-widget-deployment-2`,
    config: { components: { list: { _1bac: { id: '1bac' }, abc: { id: 'abc' } } } },
    organization_id: defaultOrganization,
    widget_id: widgetId,
    created_at: '2021-10-19T15:28:46.493Z',
    updated_at: '2021-10-20T15:18:16.293Z',
  });
  server.create('widgetDeployment', {
    id: `${widgetId}-widget-deployment-3`,
    config: { components: { list: { _1bac: { id: '1bac' }, abc: { id: 'abc' } } } },
    organization_id: defaultOrganization,
    widget_id: widgetId,
    created_at: '2021-10-19T15:28:46.493Z',
    updated_at: '2021-10-20T15:18:16.293Z',
  });
}
