import { PaginatedResponse } from '@types';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useActiveOrganization } from '@didomi/utility-react';
import { apiRoutes } from '@constants';
import { useAxiosWithLogOut } from '../useAxiosWithInterceptors.hook';
import { Widget } from '@interfaces';
import { usePaginationQueryParams } from '@hooks';

export const useWidgets = (options?: UseQueryOptions<PaginatedResponse<Widget>>) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();

  const { limit, page, skip, setLimit, setPage } = usePaginationQueryParams();

  const fetch = async () => {
    const response = await axiosWithInterceptors.get<PaginatedResponse<Widget>>(apiRoutes.widgets, {
      params: {
        organization_id: organizationId,
        $limit: limit,
        $skip: skip,
        usage: 'pmp',
      },
    });
    return response.data;
  };

  return {
    ...useQuery<PaginatedResponse<Widget>>(
      [
        'widgets',
        {
          page,
          limit,
          organization_id: organizationId,
          usage: 'dsar',
        },
      ],
      fetch,
      {
        ...options,
      },
    ),
    paginator: {
      page,
      limit,
      setPage,
      setLimit,
    },
  };
};
