import React, { Children, ReactElement } from 'react';
import { StepperPosition } from '@types';

interface HorizontalStepperProps {
  activeStep: number;
  children: React.ReactChild | React.ReactChild[];
  position?: StepperPosition;
}

const HorizontalStepper = ({ activeStep = 0, position = 'default', children }: HorizontalStepperProps) => {
  const steps = Children.toArray(children);
  const currentStep = steps[activeStep] as ReactElement<{ position: StepperPosition }>;
  return React.cloneElement(currentStep, { position });
};

export { HorizontalStepper };
